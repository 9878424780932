/**
 * Returns an object consisting of props beyond the scope of the Component. Useful for getting and spreading unknown
 * props from the user.
 *
 * @param handledProps The props that are explicitly handled by the component.
 * @param props A ReactElement props object
 * @returns A shallow copy of the prop object
 */
export function getUnhandledProps(handledProps: string[], props: Record<string, unknown>) {
	return Object.keys(props).reduce(
		(acc, prop) => {
			// "childKey" and "innerRef" are internal props of Semantic UI React
			// "innerRef" can be removed when "Search" & "Dropdown components will be removed to be functional
			if (prop === 'childKey' || prop === 'innerRef') {
				return acc;
			}
			if (!handledProps.includes(prop)) {
				acc[prop] = props[prop];
			}
			return acc;
		},
		{} as Record<string, unknown>
	);
}
