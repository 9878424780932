import { QUERY } from 'api/Query';
import * as TasksPerspectiveTemplate from 'soy/perspectives/quality_control/tasks/TasksPerspectiveTemplate.soy.generated';
import * as dom from 'ts-closure-library/lib/dom/dom';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import { Dialog } from 'ts-closure-library/lib/ui/dialog';
import type { Callback } from 'ts/base/Callback';
import * as soy from 'ts/base/soy/SoyRenderer';
import { TaskMarkdownUtils } from 'ts/commons/markdown/TaskMarkdownUtils';
import { SmartTableSorter } from 'ts/commons/SmartTableSorter';
import { tsdom } from 'ts/commons/tsdom';
import { UIUtils } from 'ts/commons/UIUtils';
import { wrapResolvedTask } from 'ts/data/ExtendedResolvedTask';
import { ETaskStatus } from 'typedefs/ETaskStatus';
import type { ResolvedTask } from 'typedefs/ResolvedTask';
import type { Task } from 'typedefs/Task';

/** Dialog for choosing a task from the list of all open tasks. */
export class TaskSelectionDialog extends Dialog {
	/**
	 * @param project The project
	 * @param callback Will be called with the selected task id
	 * @param title The title of the dialog.
	 */
	public constructor(
		protected project: string,
		protected callback: Callback<number>,
		title = 'Add findings to task'
	) {
		super();
		this.setTitle(title);
	}

	/** Shows the dialog. */
	public show(): void {
		this.setDisposeOnHide(true);
		this.getDialogElement()!.setAttribute('style', 'max-width: 50%');
		this.loadTaskList();
	}

	/** Loads the list of open tasks. */
	protected async loadTaskList(): Promise<void> {
		const tasks = await QUERY.getResolvedTasks(this.project, { status: [ETaskStatus.OPEN.name] }).fetch();
		this.taskListReceived(tasks, true);
	}

	/**
	 * Callback for retrieving the list of tasks.
	 *
	 * @param resolvedTasks The tasks returned by the server
	 * @param enableAddTaskButton If set to true, the add button in the header of the dialog will be shown.
	 */
	protected taskListReceived(resolvedTasks: ResolvedTask[], enableAddTaskButton: boolean): void {
		const tasks = resolvedTasks.map(task => wrapResolvedTask(task));
		TaskMarkdownUtils.renderMarkdownInTaskFields(tasks);
		dom.append(
			this.getContentElement()!,
			soy.renderAsElement(TasksPerspectiveTemplate.taskSelectionDialog, {
				tasks,
				enableAddTaskButton
			})
		);
		this.setupOnTaskSelectedListener(tasks);
		const addTaskButton = document.getElementById('add-task');
		if (addTaskButton != null) {
			events.listen(addTaskButton, EventType.CLICK, () => this.onTaskSelected(0));
		}
		const table = document.getElementById('task-table');
		if (table != null) {
			const sorter = new SmartTableSorter(table);
			sorter.setDefaultSortFunction(UIUtils.smartSort);
			sorter.decorate(table as HTMLTableElement);
		}
		this.setButtonSet(null);
		this.setVisible(true);
		this.reposition();
	}

	/**
	 * Sets up the Listener for the OnTaskSelected Event
	 *
	 * @param tasks
	 */
	private setupOnTaskSelectedListener(tasks: Task[]): void {
		const taskLinks = tsdom.getElementsByClass('task-row');
		for (let i = 0; i < taskLinks.length; i++) {
			const link = taskLinks[i]!;
			const id = tasks[i]!.id;
			events.listen(link, EventType.CLICK, () => this.onTaskSelected(id));
		}
	}

	/**
	 * Invokes the callback, if one was supplied and closes the dialog.
	 *
	 * @param id The selected task id.
	 */
	protected onTaskSelected(id: number): void {
		this.dispose();
		this.callback(id);
	}
}
