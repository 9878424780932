import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

/** Defines all sub-views of the API perspective */
export const EApiPerspectiveView = {
	PUBLIC_API: {
		name: 'Public Teamscale REST API',
		anchor: 'public',
		requiresProject: false,
		view: () => import('./PublicApiView')
	} as ViewDescriptor,
	INTERNAL_API: {
		name: 'Internal Teamscale REST API',
		anchor: 'internal',
		requiresProject: false,
		view: () => import('./InternalApiView')
	} as ViewDescriptor
};
