import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QUERY } from 'api/Query';
import type { ProfilerInfo } from 'api/ServiceClientImplementation';
import { ServiceClientImplementation } from 'api/ServiceClientImplementation';
import clsx from 'clsx';
import type { Dispatch, SetStateAction } from 'react';
import { type JSX, useCallback, useEffect, useState } from 'react';
import type { Callback } from 'ts/base/Callback';
import { usePerspectiveContextQuery } from 'ts/base/services/PerspectiveContext';
import { useEventListener } from 'ts/commons/hooks/useEventListener';
import { useLocalStorage } from 'ts/commons/hooks/UseLocalStorage';
import { Icon } from 'ts/components/Icon';
import { Message, MessageContent, MessageHeader, MessageItem, MessageList } from 'ts/components/Message';
import { Segment } from 'ts/components/Segment';
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'ts/components/Table';
import { EFeatureToggle } from 'typedefs/EFeatureToggle';
import styles from './DebuggingPanel.module.less';

/** The possible display states of the UI Debugger. */
enum DebuggerDisplayState {
	LINKS,
	PROFILER,
	MINIFIED
}

/** Panel for controlling the integrated UI debugger and profiler. */
export function DebuggingPanel(): JSX.Element | null {
	const [enabled, setEnabled] = useUiDebuggerEnablement();
	useUiDebuggerEnablementEventListener(setEnabled);
	const [displayState, setDisplayState] = useState(DebuggerDisplayState.MINIFIED);

	if (!enabled) {
		return null;
	}
	return (
		<Segment
			raised
			className={clsx(styles.uiDebuggerSegment, {
				[styles.hoverDim!]: displayState === DebuggerDisplayState.MINIFIED
			})}
		>
			<ReactQueryDevtools initialIsOpen={false} />
			<DebuggerTabHeader
				displayState={displayState}
				onDisplayStateChange={setDisplayState}
				onClose={() => setEnabled(false)}
			/>
			{displayState === DebuggerDisplayState.LINKS ? <LinksPanel /> : null}
			{displayState === DebuggerDisplayState.PROFILER ? <ProfilerPanel /> : null}
		</Segment>
	);
}

/**
 * Returns the current enablement state of the ui debugger and allows to programmatically change it. By default the
 * debugger is disabled. The state is persisted across page reloads in the local storage and can be toggled with
 * Ctrl+Alt+D/B keyboard shortcut.
 */
export function useUiDebuggerEnablement(): [boolean, Dispatch<SetStateAction<boolean>>] {
	const [enabled, setEnabled] = useLocalStorage('ui-debugger-enabled', false);
	return [enabled, setEnabled];
}

/** Registers the key event listener for the UI debugger enablement. */
function useUiDebuggerEnablementEventListener(setEnabled: Dispatch<SetStateAction<boolean>>) {
	const handleUserKeyPress = useCallback(
		(event: KeyboardEvent) => {
			// We react on both D and B, as some OS/Browser combinations catch the Ctrl+Alt+D/B
			if (event.altKey && event.ctrlKey && (event.keyCode === 68 || event.keyCode === 66)) {
				setEnabled(enabled => !enabled);
			}
		},
		[setEnabled]
	);
	useEventListener('keydown', handleUserKeyPress);
}

/** Props for DebuggerTabHeader. */
type DebuggerTabHeaderProps = {
	displayState: DebuggerDisplayState;
	onDisplayStateChange: Callback<DebuggerDisplayState>;
	onClose: () => void;
};

/** Shows the header of the UI debugger. */
function DebuggerTabHeader({ displayState, onDisplayStateChange, onClose }: DebuggerTabHeaderProps): JSX.Element {
	return (
		<div className={styles.tabHeaders}>
			<strong>UI Debugger enabled</strong>
			<span className="pull-right">
				<button
					className="ts-dropdown"
					id="debugger-links"
					title="Useful links"
					onClick={() => onDisplayStateChange(DebuggerDisplayState.LINKS)}
				>
					<Icon name="linkify" />
				</button>
				<button
					className="ts-dropdown"
					id="debugger-profiler"
					title="Display profiling information"
					onClick={() => onDisplayStateChange(DebuggerDisplayState.PROFILER)}
				>
					<Icon name="wait" />
				</button>
				{displayState === DebuggerDisplayState.MINIFIED ? null : (
					<button
						className="ts-dropdown"
						id="debugger-collapse"
						title="Minify debugger"
						onClick={() => onDisplayStateChange(DebuggerDisplayState.MINIFIED)}
					>
						<Icon name="toggle down" />
					</button>
				)}
				<button className="ts-dropdown" id="debugger-close" title="Disable debugger" onClick={onClose}>
					<Icon name="remove" />
				</button>
			</span>
		</div>
	);
}

/** Shows the links panel of the debugger. */
function LinksPanel(): JSX.Element {
	const perspectiveContextQuery = usePerspectiveContextQuery();
	const performanceDetailsEnabled = perspectiveContextQuery.data?.teamscaleInfo.enabledFeatureToggles.includes(
		EFeatureToggle.ENABLE_LOG_PERFORMANCE_DETAILS.name
	);
	return (
		<div>
			<div>
				<strong>Debugging</strong>
			</div>
			<ul>
				<li>
					<a target="_blank" href={QUERY.getAllThreadsDumpAsHtml().url} rel="noreferrer">
						Thread Dump
					</a>
				</li>
				<li>
					<a target="_blank" href={QUERY.getHeapDumpFormPage().url} rel="noreferrer">
						Heap Dump
					</a>
				</li>
			</ul>
			<div>
				<strong>Administration</strong>
			</div>
			<ul>
				<li>
					<a target="_blank" href={QUERY.getSchedulerControlPage().url} rel="noreferrer">
						Scheduler control
					</a>
				</li>
				<li>
					<a target="_blank" href={QUERY.getTeamscaleShutdownPage().url} rel="noreferrer">
						Shutdown Teamscale
					</a>
				</li>
				<li>
					<a target="_blank" href={QUERY.runDatabaseCompaction().url} rel="noreferrer">
						Database compaction
					</a>
				</li>
			</ul>
			<div>
				<strong>Performance</strong>
			</div>
			<ul>
				<li>
					<a href={QUERY.getAggregatedTriggerPerformanceMetrics().url} target="_blank" rel="noreferrer">
						Aggregated Trigger Data
					</a>
				</li>
				<li>
					<a href={QUERY.getAnalysisStateChangeTiming().url} target="_blank" rel="noreferrer">
						Analysis State Changes
					</a>
				</li>
			</ul>
			<div>
				<strong>Detailed Performance Analysis</strong>
			</div>
			{performanceDetailsEnabled ? (
				<ul>
					<li>
						<a href={QUERY.getConcurrencyPerformanceDetails({}).url} target="_blank" rel="noreferrer">
							Trigger Concurrency Analysis
						</a>
					</li>
					<li>
						<a href={QUERY.getStorePerformanceDetails().url} target="_blank" rel="noreferrer">
							Trigger Storage Access Data (csv)
						</a>
					</li>
					<li>
						<a href={QUERY.getTriggerPerformanceDetails().url} target="_blank" rel="noreferrer">
							Detailed Trigger Execution Data (csv)
						</a>
					</li>
				</ul>
			) : (
				<DetailedPerformanceFeatureToggleMissingMessage />
			)}
		</div>
	);
}

function DetailedPerformanceFeatureToggleMissingMessage(): JSX.Element {
	return (
		<Message compact info>
			<MessageHeader>Detailed data is currently not available.</MessageHeader>
			<MessageContent>To enable detailed data, setup a clean instance:</MessageContent>
			<MessageList>
				<MessageItem>
					Activate the feature toggle <code>{EFeatureToggle.ENABLE_LOG_PERFORMANCE_DETAILS.id}</code>
				</MessageItem>
				<MessageItem>Restart with an empty database</MessageItem>
				<MessageItem>Setup/re-analyze everything</MessageItem>
			</MessageList>
			<MessageContent>
				<br />
				Activating it will have a negative impact on overall analysis performance. <br />
				<br />
				<i>
					Only proceed with activating the feature toggle, if you know what you are doing. It will{' '}
					<u>irreversibly break</u> your instance, if you enable/disable this on a database that already
					contains data of the previous feature toggle state!
				</i>
			</MessageContent>
		</Message>
	);
}

/**
 * Shows the profiler panel of the debugger that shows detailed service call metrics for the services that are called
 * while the panel is shown.
 */
function ProfilerPanel(): JSX.Element {
	const [profilingInfos, setProfilingInfos] = useState<ProfilerInfo[]>([]);
	useEffect(() => {
		ServiceClientImplementation.setProfilerHook(profilerInfo =>
			setProfilingInfos(infos => [...infos, profilerInfo])
		);
		return () => ServiceClientImplementation.setProfilerHook(null);
	}, []);
	if (profilingInfos.length === 0) {
		return <Message>No service calls executed so far</Message>;
	}
	return (
		<Table compact basic size="small" padded="very">
			<TableHeader>
				<TableRow>
					<TableHeaderCell>Called URL</TableHeaderCell>
					<TableHeaderCell>Start time</TableHeaderCell>
					<TableHeaderCell>Overall millis</TableHeaderCell>
					<TableHeaderCell>Storage millis</TableHeaderCell>
					<TableHeaderCell>Storage calls</TableHeaderCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				{profilingInfos.map(profilingInfo => (
					<TableRow key={profilingInfo.startTimeMillis + profilingInfo.url}>
						<TableCell>
							<a target="_blank" rel="noreferrer" href={profilingInfo.url}>
								{profilingInfo.url}
							</a>
						</TableCell>
						<TableCell>{profilingInfo.startTimeMillis}</TableCell>
						<TableCell>{profilingInfo.overallTimeMillis}</TableCell>
						<TableCell>{profilingInfo.storageTimeMillis}</TableCell>
						<TableCell>{profilingInfo.storageCalls}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}
