import { QUERY } from 'api/Query';
import { QUERY_CLIENT } from 'api/QueryClient';
import clsx from 'clsx';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { type JSX, useRef, useState } from 'react';
import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import { useCommit } from 'ts/base/hooks/UseCommit';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { ReactUtils } from 'ts/base/ReactUtils';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { ClipboardButton } from 'ts/commons/ClipboardButton';
import { Links } from 'ts/commons/links/Links';
import { openConfirmModal } from 'ts/commons/modal/ConfirmModal';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { PointInTimePicker } from 'ts/commons/time/PointInTimePicker';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import type { DropdownItemProps } from 'ts/components/Dropdown';
import { Dropdown } from 'ts/components/Dropdown';
import { Icon } from 'ts/components/Icon';
import { ToastNotification } from 'ts/components/Toast';
import { DashboardExporter } from 'ts/perspectives/dashboard/DashboardExporter';
import { DashboardSaveModal } from 'ts/perspectives/dashboard/DashboardSaveModal';
import { DashboardUtils } from 'ts/perspectives/dashboard/DashboardUtils';
import { EDashboardPerspectiveView } from 'ts/perspectives/dashboard/EDashboardPerspectiveView';
import { ModalMode } from 'ts/perspectives/dashboard/sidebar/Constants';
import type { SaveModalFormValues } from 'ts/perspectives/dashboard/sidebar/Hooks';
import type { WidgetDescriptor } from 'ts/perspectives/dashboard/widgets/WidgetFactory';
import { WidgetUtils } from 'ts/perspectives/dashboard/widgets/WidgetUtils';
import type { DashboardDescriptor } from 'typedefs/DashboardDescriptor';
import type { DashboardEntryWithPermissions } from 'typedefs/DashboardEntryWithPermissions';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';
import styles from './EditDashboardDropdown.module.less';

/** Props for EditDashboardDropdown. */
type EditDashboardProps = {
	dashboard: DashboardEntryWithPermissions;
	setDashboard: Dispatch<DashboardEntryWithPermissions | null>;
};

/** The dropdown in the dashboard perspective that allows to perform edit operations on an already existing dashboard. */
export function EditDashboardDropdown({ dashboard, setDashboard }: EditDashboardProps): JSX.Element | null {
	const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Closed);
	const dashboardDescriptor = QUERY.getDashboard(dashboard.id).useSuspendingQuery();
	const onSuccessCallbackRef = useRef<(formValue: SaveModalFormValues) => Promise<void>>();
	const itemProps: DropdownItemProps[] = [];
	const navigationHash = useNavigationHash();
	const commitDescriptor = useCommit();
	const projectId = navigationHash.getProject();
	const onRenameDashboard = useRenameDashboardCallback(
		setModalMode,
		dashboard,
		dashboardDescriptor,
		onSuccessCallbackRef,
		setDashboard
	);
	const onDeleteDashboard = useDeleteDashboardCallback(dashboard);
	const onSaveCopyAs = useSaveDashboardAsCopyCallback(setModalMode, dashboardDescriptor, onSuccessCallbackRef);
	const onSaveAsTemplate = useSaveDashboardAsTemplateCallback(
		setModalMode,
		dashboardDescriptor,
		onSuccessCallbackRef
	);
	const onExportDashboardAsHtml = useExportDashboardCallback(dashboard);
	const onUpdateBaseline = useUpdateBaselineCallback(dashboardDescriptor);

	const onClose = () => {
		setModalMode(ModalMode.Closed);
	};

	if (dashboard.canWrite) {
		addWriteMenuOptions(
			itemProps,
			dashboard.id,
			projectId,
			commitDescriptor,
			onRenameDashboard,
			onDeleteDashboard,
			onUpdateBaseline
		);
	}
	if (dashboard.canEditRoles) {
		addOwnerMenuOptions(itemProps, dashboard.id);
	}
	addDashboardSavePopupItems(itemProps, onSaveCopyAs, onSaveAsTemplate);
	itemProps.push({
		icon: <Icon />,
		text: 'Copy name to clipboard',
		as: ClipboardButton,
		// @ts-ignore
		clipboardText: dashboard.id
	});
	itemProps.push({
		icon: <Icon />,
		text: 'Export dashboard',
		as: 'a',
		href: QUERY.exportDashboard(dashboard.id).url
	});
	itemProps.push({
		icon: <Icon name="download" />,
		text: 'Export dashboard as HTML',
		onClick: onExportDashboardAsHtml
	});
	return (
		<>
			<Dropdown
				button
				floating
				icon={false}
				value=""
				data-testid="edit-dashboard-dropdown"
				className={clsx('icon', styles.editDropdownBg)}
				trigger={<Icon name="pencil alternate" />}
				options={itemProps.map(item => ({ ...item, key: item.text }) as DropdownItemProps)}
			/>
			{modalMode !== ModalMode.Closed ? (
				<DashboardSaveModal
					modalMode={modalMode}
					dashboardDescriptor={dashboardDescriptor}
					onSuccess={onSuccessCallbackRef.current!}
					onClose={onClose}
				/>
			) : null}
		</>
	);
}

function addWriteMenuOptions(
	itemOptions: DropdownItemProps[],
	dashboardId: string,
	projectId: string,
	commitDescriptor: UnresolvedCommitDescriptor,
	onRenameDashboard: () => void,
	onDeleteDashboard: () => void,
	onUpdateBaseline: () => void
) {
	itemOptions.push({
		text: 'Edit dashboard',
		icon: <Icon name="pencil alternate" color="grey" />,
		as: TeamscaleLink,
		to: Links.editDashboard(projectId, {
			name: dashboardId,
			commit: commitDescriptor
		})
	});
	itemOptions.push({
		icon: <Icon />,
		text: 'Rename...',
		onClick: onRenameDashboard
	});
	itemOptions.push({
		text: 'Set baselines and trends...',
		icon: <Icon name="time" color="grey" />,
		onClick: onUpdateBaseline
	});
	itemOptions.push({
		text: 'Delete',
		icon: <Icon name="trash" color="grey" />,
		onClick: onDeleteDashboard
	});
}

function addOwnerMenuOptions(itemOptions: DropdownItemProps[], dashboardId: string) {
	itemOptions.push({
		text: 'Change permissions',
		icon: <Icon name="users" color="grey" />,
		as: TeamscaleLink,
		to: Links.dashboardSharing({
			id: dashboardId
		})
	});
}

function addDashboardSavePopupItems(
	itemOptions: DropdownItemProps[],
	onSaveCopyAs: () => void,
	onSaveAsTemplate: () => void
) {
	itemOptions.push({
		text: 'Save copy as...',
		icon: <Icon name="copy" color="grey" />,
		onClick: onSaveCopyAs
	});
	itemOptions.push({
		icon: <Icon />,
		text: 'Save as template...',
		onClick: onSaveAsTemplate
	});
}

/** Allows the user to rename the given dashboard. */
function useRenameDashboardCallback(
	setModalMode: Dispatch<SetStateAction<ModalMode>>,
	dashboardEntry: DashboardEntryWithPermissions,
	dashboardDescriptor: DashboardDescriptor,
	onSuccessCallbackRef: MutableRefObject<((formValue: SaveModalFormValues) => Promise<void>) | undefined>,
	setDashboard: Dispatch<DashboardEntryWithPermissions | null>
): () => void {
	const currentProjectId = useNavigationHash().getProject();
	const onSuccess = async (
		newDashboardDescriptor: DashboardDescriptor,
		newDashboardEntry: DashboardEntryWithPermissions
	) => {
		await QUERY.getAllDashboards({ project: currentProjectId }).invalidate();
		QUERY_CLIENT.setQueryData(QUERY.getDashboard(newDashboardDescriptor.id!).queryKey, newDashboardDescriptor);
		await QUERY.getDashboard(newDashboardDescriptor.id!).invalidate();
		setDashboard(newDashboardEntry);
	};

	const successfulRenameCallback = async (formValues: SaveModalFormValues) => {
		const updatedDashboardDescriptor = {
			...dashboardDescriptor,
			...formValues
		};

		await QUERY.editDashboard(updatedDashboardDescriptor.id!, updatedDashboardDescriptor).fetch();
		await onSuccess(updatedDashboardDescriptor, { ...dashboardEntry, ...formValues });
	};

	return () => {
		onSuccessCallbackRef.current = successfulRenameCallback;
		setModalMode(ModalMode.Rename);
	};
}

/** Deletes the given dashboard. */
function useDeleteDashboardCallback(dashboardDescriptor: DashboardEntryWithPermissions): () => void {
	return () =>
		openConfirmModal({
			title: 'Confirmation needed',
			content: 'Really delete dashboard ' + dashboardDescriptor.name + '?',
			confirmText: 'Delete Dashboard',
			destructive: true,
			onConfirm: () =>
				QUERY.deleteDashboard(dashboardDescriptor.id)
					.fetch()
					.then(() => {
						const hash = NavigationHash.getCurrent();
						hash.setViewName(EDashboardPerspectiveView.DASHBOARD_SHOW.anchor);
						hash.remove(NavigationHash.ID_PARAMETER);
						hash.navigate(true);
					})
		});
}

/** Allows the user to create a copy of the given dashboard. */
function useSaveDashboardAsCopyCallback(
	setModalMode: Dispatch<SetStateAction<ModalMode>>,
	dashboardDescriptor: DashboardDescriptor,
	onSuccessCallbackRef: MutableRefObject<((formValue: SaveModalFormValues) => Promise<void>) | undefined>
): () => void {
	const hash = useNavigationHash();
	const projects = DashboardUtils.getReferencedProjects(dashboardDescriptor);
	const onSuccess = (dashboardId: string) => {
		hash.setId(dashboardId);
		setDashboardPerspectiveProject(projects, hash);
		hash.navigate();
	};

	const successfulSaveAsCopyCallback = async (formValues: SaveModalFormValues) => {
		const updatedDashboardDescriptor = {
			...dashboardDescriptor,
			...formValues
		};

		const dashboardId = await QUERY.createDashboard({ ...updatedDashboardDescriptor, id: undefined }).fetch();
		onSuccess(dashboardId);
		return;
	};

	return () => {
		onSuccessCallbackRef.current = successfulSaveAsCopyCallback;
		setModalMode(ModalMode.Copy);
	};
}

/**
 * Determines which project should be selected in the project selector after saving a dashboard with the given
 * dashboards involved. This ensures that the dashboard is not directly hidden by the current filter.
 */
function determineSelectedProject(hash: NavigationHash, projectsInDashboard: string[]) {
	let currentProject = hash.getProject();
	if (projectsInDashboard.length === 0 || currentProject === '') {
		currentProject = '';
	} else if (!projectsInDashboard.includes(currentProject)) {
		currentProject = projectsInDashboard[0]!;
	}
	return currentProject;
}

/**
 * Sets the project for the dashboard perspective in the localstore. If the project to use does not exist in the list of
 * projectsInDashboard, the first one in that list will be used (TS-27064).
 */
export function setDashboardPerspectiveProject(projectsInDashboard: string[], hash: NavigationHash): void {
	const projectId = determineSelectedProject(hash, projectsInDashboard);
	hash.setProjectAndPath(ProjectAndUniformPath.of(projectId, null));
}

/** Saves the given dashboard as template. */
function useSaveDashboardAsTemplateCallback(
	setModalMode: Dispatch<SetStateAction<ModalMode>>,
	dashboardDescriptor: DashboardDescriptor,
	onSuccessCallbackRef: MutableRefObject<((formValue: SaveModalFormValues) => Promise<void>) | undefined>
): () => void {
	const onSuccess = () => {
		const navigator = NavigationHash.getCurrent();
		if (navigator.getViewName() === EDashboardPerspectiveView.TEMPLATES.anchor) {
			navigator.reload();
			return;
		}
		NavigationUtils.updateLocation(Links.dashboardTemplates());
	};

	const successfulSaveAsTemplateCallback = async (formValues: SaveModalFormValues) => {
		const updatedDashboardDescriptor: DashboardTemplateDescriptor = {
			...dashboardDescriptor,
			name: formValues.name,
			description: formValues.comment!
		};

		await QUERY.createDashboardTemplate({ ...updatedDashboardDescriptor, id: undefined }).fetch();
		await QUERY.getAllDashboardTemplates().invalidate();
		onSuccess();
	};

	return () => {
		onSuccessCallbackRef.current = successfulSaveAsTemplateCallback;
		setModalMode(ModalMode.SaveTemplate);
	};
}

/** Exports the dashboard as HTML. */
function useExportDashboardCallback(dashboardDescriptor: DashboardEntryWithPermissions): () => void {
	return () =>
		void DashboardExporter.exportDashboardHtmlAsync(dashboardDescriptor).catch(
			ToastNotification.showIfServiceError
		);
}

/** Updates the baselines and trends of all widgets of the current dashboard to a user-selectable timestamp. */
function useUpdateBaselineCallback(dashboardDescriptor: DashboardDescriptor): () => void {
	const hash = useNavigationHash();
	const perspectiveContext = usePerspectiveContext();
	return () => {
		const widgetDescriptor = dashboardDescriptor.descriptor as {
			widgets: WidgetDescriptor[];
		};
		const visibleProjectsUsedInWidgets = WidgetUtils.getVisibleProjectsUsedInWidgets(
			dashboardDescriptor,
			perspectiveContext
		);
		determineAndSetBaselineForAllWidgets(
			visibleProjectsUsedInWidgets,
			widgetDescriptor.widgets,
			dashboardDescriptor,
			hash
		);
	};
}

/**
 * Opens a timepicker to ask for the timestamp and sets the selected timestamp in all widgets that contain baseline,
 * trend or findings-since properties.
 */
async function determineAndSetBaselineForAllWidgets(
	visibleProjectsUsedInWidgets: string[],
	widgets: WidgetDescriptor[],
	dashboardDescriptor: DashboardDescriptor,
	hash: NavigationHash
): Promise<void> {
	try {
		const newTimestamp = await PointInTimePicker.showDialog(
			visibleProjectsUsedInWidgets,
			[],
			false,
			null,
			'Set baseline (trend) for all widgets'
		);
		widgets.forEach(widget => {
			updateWidgetPointInTime(widget, newTimestamp);
		});
		await QUERY.editDashboard(dashboardDescriptor.id!, dashboardDescriptor)
			.fetch()
			.then(() => ReactUtils.queryClient.invalidateQueries());
		hash.reload();
		ToastNotification.success(`Successfully set the new baseline`);
	} catch {
		ToastNotification.error(`The new baseline could not be set. Kept the old baseline.`);
	}
}

/** Sets the trend, baseline or findings-since property of the widget to the given timestamp. */
function updateWidgetPointInTime(widget: WidgetDescriptor | null, pointInTime: TypedPointInTime): void {
	if (!widget) {
		return;
	}
	if ('Trend' in widget) {
		widget.Trend = pointInTime;
	} else if ('Baseline' in widget) {
		widget.Baseline = pointInTime;
	} else if ('Findings since' in widget) {
		widget['Findings since'] = pointInTime;
	}
}
