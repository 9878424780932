/** Simple data object that groups information about a failed server call. This class is immutable. */
export class ServiceCallError extends Error {
	/** The summary, based on the {@link fullDescription}. The value can be empty. */
	public readonly errorSummary: string;

	public constructor(
		/** HTTP status code. */
		public readonly statusCode: number,
		message: string,
		/**
		 * A technical description of the problem for reporting it back to the developers. Usually including a stack
		 * trace.
		 */
		public readonly technicalDetails: string,
		/**
		 * A full error description (can be an object or a text including a stacktrace). Can be null, e.g. in case of
		 * request timeout or failed connection.
		 */
		public readonly fullDescription: unknown = null
	) {
		super(message);
		this.errorSummary = message;
		if (this.fullDescription && typeof this.fullDescription === 'string') {
			this.errorSummary = ServiceCallError.extractSummaryFromError(this.fullDescription);
		} else if (this.fullDescription) {
			const errorObject = this.fullDescription as { message?: string };
			if ('message' in errorObject && typeof errorObject.message === 'string') {
				this.errorSummary = errorObject.message;
			}
		}
	}

	/**
	 * Given an stack trace technical error, this method extracts just only the short technical summary (e.g. 'Invalid
	 * parameter value: X').
	 *
	 * @param error Textual form of stack trace.
	 */
	public static extractSummaryFromError(error: string): string {
		const regexSummary = new RegExp('Message:[\\s\\r\\n]*(.*?)(\\r?\\n|$)');
		const matches = regexSummary.exec(error);
		let errorSummary = 'Sorry, the operation could not be completed.';
		if (matches !== null && matches.length > 1) {
			errorSummary = matches[1]!;
		}
		return errorSummary;
	}
}
