import { type JSX, Suspense } from 'react';
import { Await, useAsyncError, useLoaderData, useRevalidator } from 'react-router-dom';
import { PerspectiveLoadingIndicator } from 'ts/base/components/PerspectiveLoadingIndicator';
import { useKeyboardShortcutRegistry } from 'ts/base/hooks/UseKeyboardShortcut';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { PerspectiveSettingsBar } from 'ts/base/perspective/topbar/PerspectiveSettingsBar';
import type { TeamscaleViewLoaderData } from 'ts/base/TeamscaleViewRoot.loader';
import { NavigateToIssueDialog } from 'ts/commons/dialog/NavigateToIssueDialog';
import { ErrorFallback } from 'ts/commons/ErrorFallback';

/**
 * Handles drawing the skeleton of a view including the perspective settings bar. The view itself is also rendered here
 * once loading finished.
 */
export function TeamscaleViewRoot(): JSX.Element | null {
	const loaderData = useLoaderData() as TeamscaleViewLoaderData;
	useKeyboardShortcutRegistry();
	return (
		<>
			<PerspectiveSettingsBar
				viewDescriptor={loaderData.viewDescriptor}
				existingProjectIds={loaderData.existingProjectIds}
				defaultBranch={loaderData.defaultBranch}
			/>
			<Suspense fallback={<PerspectiveLoadingIndicator />}>
				<Await key={loaderData.loadingTimestamp} resolve={loaderData.view} errorElement={<ViewLoadingError />}>
					{resolvedView => resolvedView}
				</Await>
			</Suspense>
			<NavigateToIssueDialog key={useNavigationHash().toString()} />
		</>
	);
}

function ViewLoadingError() {
	const error = useAsyncError();
	const revalidator = useRevalidator();
	return <ErrorFallback error={error} resetErrorBoundary={revalidator.revalidate} />;
}
