import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import { Size } from 'ts-closure-library/lib/math/size';
import * as style from 'ts-closure-library/lib/style/style';
import type { NavigationHash } from './NavigationHash';
import { tsdom } from './tsdom';

/** Utility methods that affect a Teamscale perspective (globally). */
export class PerspectiveUtils {
	/**
	 * Value indicating that all project should be considered for some operation. It is used as an extra option in the
	 * project selector.
	 */
	public static ALL_PROJECTS = 'All Projects';

	/** The element id of the global branch chooser. */
	public static BRANCH_SELECT_ID = 'branch-select';

	/**
	 * The id of the main container element in Teamscale. See
	 *
	 * # rootLayout of
	 *
	 * PerspectiveBaseTemplate.soy
	 */
	public static MAIN_CONTAINER_ID = 'ts-main-container';

	/** Parameter name for toggling the (if available) kiosk mode. */
	public static KIOSK_MODE_PARAMETER = 'kioskViewMode';

	/** Default view mode. Relevant for views with implemented kiosk mode. */
	public static VIEW_MODE_DEFAULT = 'default';

	/** Kiosk view mode. Relevant for views with implemented kiosk mode. */
	public static VIEW_MODE_KIOSK = 'kiosk';

	/** Disables the branch choose and time selection button */
	public static disableTimeTravel(): void {
		const timeTravelButton = document.getElementById('jump-to-time-button');
		if (timeTravelButton != null) {
			timeTravelButton.classList.add('disabled');
		}
		PerspectiveUtils.disableBranchSelection();
	}

	/** Disables the branch chooser button */
	private static disableBranchSelection(): void {
		const branchSelect = document.getElementById(PerspectiveUtils.BRANCH_SELECT_ID);
		if (branchSelect != null) {
			branchSelect.classList.add('disabled');
		}
	}

	/**
	 * @deprecated We want to remove the right sidebar soon
	 * @returns The current size of the right sidebar, or (0, 0) if the sidebar was not found in the DOM.
	 */
	public static getRightSidebarSize(): Size {
		return PerspectiveUtils.getSidebarSize('right-sidebar-container');
	}

	/**
	 * @param sidebarElementId The DOM id to look for
	 * @returns The current size of the element with the given id, or (0, 0) if the element was not found in the DOM.
	 */
	public static getSidebarSize(sidebarElementId: string): Size {
		const sidebar = document.getElementById(sidebarElementId);
		if (sidebar != null) {
			return style.getSize(sidebar);
		}
		return new Size(0, 0);
	}

	/** @returns Element with id 'main'. Throws an error if the element is not present. */
	public static getMainElement(): HTMLElement {
		return tsdom.getElementById('main');
	}

	/** @returns Element with class 'ts-root-main'. Throws an error if the element is not present. */
	public static getRootLayoutElement(): HTMLElement {
		return tsdom.getElementByClass('ts-root-main');
	}

	/**
	 * Returns the ts-main-container element. This is the container into which the content of a page will be inserted.
	 * This could be and error or what TeamscaleViewBase#renderInto produces. It has no padding and takes up all of the
	 * screen space right of the main sidebar and below the perspective settings bar.
	 *
	 *     The layout looks like this:
	 *     body > #app > div > .ts-root-main > #ts-main-container
	 *
	 * @returns Element with class 'ts-main-container'. Throws an error if the element is not present.
	 */
	public static getMainContainer(): HTMLElement {
		return tsdom.getElementById(PerspectiveUtils.MAIN_CONTAINER_ID);
	}

	/**
	 * Prepares the navigation bar and main content container element according to the desired view mode (kiosk or
	 * default). Kiosk mode means that the left sidebar and the top navigation bar are suppressed.
	 */
	public static toggleFullScreenContentMode(
		container: Element,
		isKioskMode: boolean,
		enableAutoReloadInKiosk: boolean
	): void {
		const kioskCssClass = 'kiosk';
		const mainContainer = PerspectiveUtils.getRootLayoutElement();
		if (mainContainer.classList.contains(kioskCssClass) !== isKioskMode) {
			// We are changing into/from kiosk mode, make sure to fire a resize after
			// we are done. We listen to transitioned as this will fire once when the
			// entire page is on fullscreen/small again (and has adjusted any
			// animations).
			PerspectiveUtils.registerSingleWindowResizeEventOnTransitionEnd(PerspectiveUtils.getRootLayoutElement());
		}
		mainContainer.classList.toggle(kioskCssClass, isKioskMode);
		if (isKioskMode && enableAutoReloadInKiosk) {
			PerspectiveUtils.activateAutoReload(container);
		}
	}

	/** Schedules a reload of the dashboard after 2 minutes. */
	public static activateAutoReload(container: Element): void {
		setTimeout(
			() => {
				// If this element is no longer live, the view switched away
				if (!document.contains(container)) {
					return;
				}

				// It turns out that manually discarding elements and rerendering,
				// leaves DOM elements and other artifacts lying around. There is also
				// no clean solution, as jQuery uses caching internally, and these
				// caches are not easily cleared. The only clean solution is a page
				// reload, which will also flush all old DOM elements and JavaScript
				// objects.
				location.reload();
				PerspectiveUtils.activateAutoReload(container);
			},
			2 * 60 * 1000
		);
	}

	/**
	 * Resolve the view mode (default view or kiosk view). The kiosk mode is available for the dashboard perspective and
	 * via a URL param in the delta perspective, which is used by the Azure DevOps Extension (see TS-19049).
	 *
	 * @param hash Used to resolve the view mode
	 * @returns The view mode to be used
	 */
	public static resolveViewModeFromHash(hash: NavigationHash): string {
		if (hash.getBoolean(PerspectiveUtils.KIOSK_MODE_PARAMETER)) {
			return PerspectiveUtils.VIEW_MODE_KIOSK;
		}
		return PerspectiveUtils.VIEW_MODE_DEFAULT;
	}

	/**
	 * Depending on the given parameter, sets the main containers of the
	 *
	 * # rootLayout (see PerspectiveBaseTemplate.soy) such that there is no padding
	 *
	 * Between the container and the left sidebar & the global top bar. That way, the view's contents are 'fullscreen'
	 * within the root layout. Calling this method if the
	 *
	 * # rootLayout is not part of the page will cause an
	 *
	 * Error.
	 */
	public static applyPaddingLessMainLayout(enablePaddingless: boolean): void {
		PerspectiveUtils.getMainElement().classList.toggle('paddingless', enablePaddingless);
		PerspectiveUtils.getMainElement().classList.toggle('full-height', enablePaddingless);
	}

	/**
	 * Listens to the TRANSITIONED event on the given element and then fires a resize event on the entire window.
	 *
	 * It is often better to listen to a TRANSITIONED event, instead of listening to a resize event right away, as
	 * resize will trigger to often and too early in many cases. Thus waiting for the transition to end (e.g. expanding
	 * the sidebar fully) once and then sending a full window resize (e.g. to all widgets) is much faster.
	 *
	 * Usually you only want to listen to this kind of event once, as transitions are fired by many things (e.g. hovers
	 * on buttons), so a continued listening to them slows down the UI to a crawl.
	 *
	 * @param element The element on which to register the event
	 */
	public static registerSingleWindowResizeEventOnTransitionEnd(element: Element): void {
		events.listenOnce(element, EventType.TRANSITIONEND, () => {
			const event = new Event('resize');
			window.dispatchEvent(event);
		});
	}
}
