import { Assertions } from 'ts/commons/Assertions';
import { StringUtils } from 'ts/commons/StringUtils';

type EnumShape<T> = { values: T[] };

/** Helper methods for dealing with enums. */
export class EnumUtils {
	/**
	 * Takes a string value e.g. from a navigation hash or service response and resolves it to an enum value. Returns
	 * undefined when the string is falsy, empty or is not a valid enum literal.
	 */
	public static fromValue<E extends string, T extends { name: E; ordinal: number }>(
		value: E,
		enumClass: EnumShape<T>
	): T;
	public static fromValue<E extends string, T extends { name: E; ordinal: number }>(
		value: string | null | undefined,
		enumClass: EnumShape<T>,
		defaultValue: T
	): T;
	public static fromValue<E extends string, T extends { name: E; ordinal: number }>(
		value: string | null | undefined,
		enumClass: EnumShape<T>,
		defaultValue?: T
	): T {
		if (StringUtils.isEmptyOrWhitespace(value)) {
			Assertions.assert(
				defaultValue !== undefined,
				`The given value ${value} is not a valid enum literal! Only ${enumClass.values
					.map(e => e.name)
					.join(', ')} are allowed.`
			);
			return defaultValue;
		}
		const uppercaseValue = value.toUpperCase();
		// @ts-ignore
		const enumLiteral = enumClass[uppercaseValue];
		Assertions.assert(
			defaultValue !== undefined || enumLiteral !== undefined,
			`The given value ${value} is not a valid enum literal! Only ${enumClass.values
				.map(e => e.name)
				.join(', ')} are allowed.`
		);
		return enumLiteral ?? defaultValue;
	}

	/** Returns the string names of the given enum entries. */
	public static getValues<S extends string, T extends { name: S; ordinal: number }>(enumList: T[]): S[] {
		return enumList.map(enumLiteral => enumLiteral.name);
	}
}
