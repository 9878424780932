import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { EDashboardPerspectiveView } from 'ts/perspectives/dashboard/EDashboardPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the dashboard perspective. */
export class DashboardPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.DASHBOARD, EDashboardPerspectiveView);
	}
}
