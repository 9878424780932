/** Generated from EResourceType.java. Do not modify this file! */


export type EResourceTypeEntry = 'UNKNOWN' | 'FILE' | 'CONTAINER';

export class EResourceType {
	static readonly UNKNOWN = new EResourceType(0, 'UNKNOWN');
	static readonly FILE = new EResourceType(1, 'FILE');
	static readonly CONTAINER = new EResourceType(2, 'CONTAINER');
	static readonly values = [
		EResourceType.UNKNOWN,
		EResourceType.FILE,
		EResourceType.CONTAINER
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EResourceTypeEntry,

	){
	}

	toString() {
		return this.name;
	}
}

