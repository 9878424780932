import { linkTo } from 'ts/commons/links/LinkTo';
import { EUserPerspectiveView } from 'ts/perspectives/user/EUserPerspectiveView';
import { ETeamscalePerspective } from 'ts/typedefs/ETeamscalePerspective';

/** Returns a URL for editing the user. */
export function editUser(username: string) {
	return linkTo(ETeamscalePerspective.USER, EUserPerspectiveView.EDIT_PROFILE, {
		username
	});
}
