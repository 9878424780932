import { UserBasicPermissionInfo, UserPermissionInfo } from 'ts/base/hooks/PermissionInfoHook';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { Assertions } from 'ts/commons/Assertions';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { StringUtils } from 'ts/commons/StringUtils';
import { EBasicPermission } from 'typedefs/EBasicPermission';
import { EBasicPermissionScope } from 'typedefs/EBasicPermissionScope';
import { EBasicRole } from 'typedefs/EBasicRole';
import type { EConfigurationFeature } from 'typedefs/EConfigurationFeature';
import type { EGlobalPermission } from 'typedefs/EGlobalPermission';
import { EProjectPermission } from 'typedefs/EProjectPermission';
import type { PermissionLookupEBasicPermission } from 'typedefs/PermissionLookupEBasicPermission';
import type { PermissionSummary } from 'typedefs/PermissionSummary';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';
import type { ProjectRole } from 'typedefs/ProjectRole';
import type { RoleAssignmentWithGlobalInfo } from 'typedefs/RoleAssignmentWithGlobalInfo';

/** Describes a group of permissions. */
export type PermissionDescription = {
	readableName: string;
	description: string;
	permissions?: PermissionDescription[];
};

/** The additional fields that are added to an object by PermissionUtils.addBasicPermissions */
export type BasicPermissionExtension = { canEdit: boolean; canDelete: boolean; canEditRoles: boolean };

/**
 * Class with static utility methods for checking permissions. Permission information is stored in the perspective
 * context.
 */
export class PermissionUtils {
	/** Returns true if the user may access a given configuration feature. */
	public static mayAccessFeature(
		perspectiveContext: PerspectiveContext,
		configurationFeature: EConfigurationFeature
	): boolean {
		const permissionSummary = perspectiveContext.userInfo.permissionSummary;
		Assertions.assertObject(permissionSummary, 'Permission summary required.');
		return new UserPermissionInfo(permissionSummary).mayAccessFeature(configurationFeature);
	}

	/** Returns true if the user has the given global permission. */
	public static hasGlobalPermission(
		perspectiveContext: PerspectiveContext,
		globalPermission: EGlobalPermission
	): boolean {
		const permissionSummary = perspectiveContext.userInfo.permissionSummary;
		Assertions.assertObject(permissionSummary, 'Permission summary required.');
		return new UserPermissionInfo(permissionSummary).hasGlobalPermission(globalPermission);
	}

	/** Returns true if the user has a given project permission for a project ID. */
	public static hasProjectPermission(
		perspectiveContext: PerspectiveContext | null,
		project: string | null | undefined,
		projectPermission: EProjectPermission
	): boolean {
		if (perspectiveContext == null || project == null) {
			return false;
		}
		const permissionSummary = perspectiveContext.userInfo.permissionSummary;
		Assertions.assertObject(permissionSummary, 'Permission summary required.');
		return new UserPermissionInfo(permissionSummary).hasProjectPermission(project, projectPermission);
	}

	/** Sorts the role assignments for the UI. */
	public static sortRoleAssignments(roleAssignments: RoleAssignmentWithGlobalInfo[]): RoleAssignmentWithGlobalInfo[] {
		return ArrayUtils.sort(roleAssignments, [
			ArrayUtils.comparatorByKey(roleAssignment => roleAssignment.isGlobal),
			ArrayUtils.comparatorByKey(roleAssignment => roleAssignment.subjectType.toLowerCase()),
			ArrayUtils.comparatorByKey(roleAssignment => roleAssignment.subjectId.toLowerCase()),
			ArrayUtils.comparatorByKey(roleAssignment => roleAssignment.roleName.toLowerCase())
		]);
	}

	/** Sorts the roles for the UI. */
	public static sortRoles(roles: ProjectRole[]): void {
		ArrayUtils.sortBy(roles, 'readableName', StringUtils.compareCaseInsensitive);
	}

	/**
	 * Determines if the permission lookup contains the required basic permission.
	 *
	 * @param permissionLookup A lookup of Java type PermissionLookup.
	 */
	public static hasBasicPermission(
		permissionLookup: PermissionLookupEBasicPermission,
		instance: string,
		basicPermission: EBasicPermission
	): boolean {
		return new UserBasicPermissionInfo(permissionLookup).hasBasicPermission(instance, basicPermission);
	}

	/**
	 * Adds permission information (canEdit, canDelete, canEditRoles) as fields to the basic objects.
	 *
	 * @param permissionLookup A lookup
	 * @param basicObjects The basic objects to add permission information to.
	 * @param identityExtractor A function for extracting the identity (a key) to lookup permissions for.
	 */
	public static addBasicPermissions<T extends Record<string, unknown>>(
		permissionLookup: PermissionLookupEBasicPermission,
		basicObjects: T[],
		identityExtractor: (obj: T) => string
	): Array<ExtendTypeWith<T, BasicPermissionExtension>> {
		basicObjects.forEach(basicObject => {
			const instance = identityExtractor(basicObject);
			Object.assign(basicObject, new UserBasicPermissionInfo(permissionLookup).getPermissions(instance));
		});
		return basicObjects as unknown as Array<ExtendTypeWith<T, BasicPermissionExtension>>;
	}

	/** Returns the description for a basic role for a permission scope. */
	public static getBasicRoleDescription(basicRole: EBasicRole, permissionScope: EBasicPermissionScope): string {
		const scopeName = permissionScope.readableName;
		switch (basicRole.name) {
			case EBasicRole.OWNER.name:
				return 'An owner has all permissions for the ' + scopeName + '.';
			case EBasicRole.EDITOR.name:
				return 'Editors may view and edit the ' + scopeName + '.';
			case EBasicRole.VIEWER.name:
				return 'A viewer can just see the ' + scopeName + '.';
			default:
				Assertions.fail('Unknown basic role: ' + basicRole.name);
		}
	}

	/**
	 * Returns the basic role for the given name.
	 *
	 * @returns BasicRole
	 */
	public static getBasicRoleForReadableName(basicRoleName: string): EBasicRole {
		switch (basicRoleName) {
			case EBasicRole.OWNER.readableName:
				return EBasicRole.OWNER;
			case EBasicRole.EDITOR.readableName:
				return EBasicRole.EDITOR;
			case EBasicRole.VIEWER.readableName:
				return EBasicRole.VIEWER;
			default:
				Assertions.fail('Unknown basic role name: ' + basicRoleName);
		}
	}

	/** Returns the description for a basic permission for a permissions scope. */
	public static getBasicPermissionDescription(
		basicPermission: EBasicPermission,
		permissionScope: EBasicPermissionScope
	): string {
		const scopeName = permissionScope.readableName;
		switch (basicPermission.name) {
			case EBasicPermission.VIEW.name:
				let viewDescription = 'Makes the ' + scopeName + ' visible to the user or group members';
				if (permissionScope === EBasicPermissionScope.USERS) {
					viewDescription += ", but does not expose the user's access key";
				}
				return viewDescription + '.';
			case EBasicPermission.EDIT.name:
				let editDescription = 'Allows editing the ' + scopeName;
				if (permissionScope === EBasicPermissionScope.USERS) {
					editDescription += " and viewing/regenerating the user's access key";
				}
				return editDescription + '.';
			case EBasicPermission.DELETE.name:
				return 'Allows deleting the ' + scopeName + '.';
			case EBasicPermission.EDIT_ROLES.name:
				return (
					'Allows assigning roles to other users and groups to provide them with access to the ' +
					permissionScope.readableName.toLowerCase() +
					'.'
				);
			default:
				Assertions.fail('Unknown basic role: ' + basicPermission.name);
		}
	}

	/** Returns a lookup for readable name description of basic permissions or a permission scope. */
	public static getBasicPermissionDescriptionLookup(
		permissionScope: EBasicPermissionScope
	): Record<string, PermissionDescription> {
		const lookup: Record<string, PermissionDescription> = {};
		EBasicPermission.values.forEach(basicPermission => {
			lookup[basicPermission.name] = {
				readableName: basicPermission.readableName,
				description: PermissionUtils.getBasicPermissionDescription(basicPermission, permissionScope)
			};
		});
		return lookup;
	}

	/** Returns the informational text about roles in a permission scope. */
	public static getPermissionScopeInfoText(permissionScope: EBasicPermissionScope): string {
		return (
			'Roles which can be assigned to users and groups to provide them with different kinds of access to this ' +
			permissionScope.readableName +
			'.'
		);
	}

	/** Returns the informational text about role assignments. */
	public static getPermissionScopeRoleAssignmentInfoText(permissionScope: EBasicPermissionScope): string {
		return (
			'Assign roles to users or groups. The role provides the user or group members with a set of permissions controlling access to the ' +
			permissionScope.readableName +
			'.'
		);
	}

	/** Returns true if the user has the permission to add baselines for the given projects. */
	public static mayDefineBaselines(permissionSummary: PermissionSummary, projects: string[] | null): boolean {
		if (projects == null || projects.length === 0) {
			return false;
		}
		return new UserPermissionInfo(permissionSummary).hasProjectPermission(
			projects[0]!,
			EProjectPermission.EDIT_BASELINES
		);
	}
}
