import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import type {
	SemanticCOLORS,
	SemanticFLOATS,
	SemanticTEXTALIGNMENTS,
	SemanticVERTICALALIGNMENTS,
	SemanticWIDTHS
} from '../Generic';
import {
	createShorthandFactory,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	multipleProp,
	textAlignProp,
	valueAndKey,
	verticalAlignProp,
	widthProp
} from '../lib';

export type GridOnlyProp = string | 'computer' | 'largeScreen' | 'mobile' | 'tablet mobile' | 'tablet' | 'widescreen';

/** Props for {@link GridColumn}. */
export type GridColumnProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** A grid column can be colored. */
	color?: SemanticCOLORS;

	/** A column can specify a width for a computer. */
	computer?: SemanticWIDTHS;

	/** A column can sit flush against the left or right edge of a row. */
	floated?: SemanticFLOATS;

	/** A column can specify a width for a large screen device. */
	largeScreen?: SemanticWIDTHS;

	/** A column can specify a width for a mobile device. */
	mobile?: SemanticWIDTHS;

	/** A column can appear only for a specific device, or screen sizes. */
	only?: GridOnlyProp;

	/** A column can stretch its contents to take up the entire grid or row height. */
	stretched?: boolean;

	/** A column can specify a width for a tablet device. */
	tablet?: SemanticWIDTHS;

	/** A column can specify its text alignment. */
	textAlign?: SemanticTEXTALIGNMENTS;

	/** A column can specify its vertical alignment to have all its columns vertically centered. */
	verticalAlign?: SemanticVERTICALALIGNMENTS;

	/** A column can specify a width for a wide screen device. */
	widescreen?: SemanticWIDTHS;

	/** Represents width of column. */
	width?: SemanticWIDTHS;
};

/** A column sub-component for Grid. */
export const GridColumn = forwardRef(function GridColumn(props: GridColumnProps, ref: ForwardedRef<HTMLDivElement>) {
	const {
		children,
		className,
		computer,
		color,
		floated,
		largeScreen,
		mobile,
		only,
		stretched,
		tablet,
		textAlign,
		verticalAlign,
		widescreen,
		width
	} = props;

	const classes = clsx(
		color,
		keyOnly(stretched, 'stretched'),
		multipleProp(only, 'only'),
		textAlignProp(textAlign),
		valueAndKey(floated, 'floated'),
		verticalAlignProp(verticalAlign),
		widthProp(computer, 'wide computer'),
		widthProp(largeScreen, 'wide large screen'),
		widthProp(mobile, 'wide mobile'),
		widthProp(tablet, 'wide tablet'),
		widthProp(widescreen, 'wide widescreen'),
		widthProp(width, 'wide'),
		'column',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{children}
		</ElementType>
	);
});

export const createGridColumn = createShorthandFactory(GridColumn, children => ({ children }));
const handledProps = [
	'as',
	'children',
	'className',
	'color',
	'computer',
	'floated',
	'largeScreen',
	'mobile',
	'only',
	'stretched',
	'tablet',
	'textAlign',
	'verticalAlign',
	'widescreen',
	'width'
];
