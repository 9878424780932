import * as RightSidebarTemplate from 'soy/base/perspective/sidebar/right/RightSidebarTemplate.soy.generated';
import * as dom from 'ts-closure-library/lib/dom/dom';
import type { Key } from 'ts-closure-library/lib/events/eventhandler';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import { THRESHOLD_TO_MINIMIZE_SIDEBARS } from 'ts/base/perspective/sidebar/SidebarToggleHook';
import * as soy from 'ts/base/soy/SoyRenderer';
import { PerspectiveUtils } from 'ts/commons/PerspectiveUtils';
import { SemanticUIUtils } from 'ts/commons/SemanticUIUtils';
import { tsdom } from 'ts/commons/tsdom';
import { UIUtils } from 'ts/commons/UIUtils';

/** The right sidebar to append content into. Also hooks the logic to expand/shrink it via the collapse button. */
export class LegacyRightSidebar {
	/** The DOM id of the right sidebar. */
	private static readonly RIGHT_SIDEBAR_ID = 'right-sidebar-container';

	/** The DOM id of the menu that expands/shrinks the right sidebar */
	private static readonly RIGHT_TOGGLE_MENU_ID = 'right-sidebar-toggle-menu';

	/** The right sidebar toggle menu DOM Element */
	public toggleMenu: Element | null = null;

	/** The right sidebar DOM Element */
	public sidebarContainer: HTMLElement | null = null;

	private listener: Key | null = null;

	/** Current width of the window's viewport. */
	public windowWidth = 0;

	/** Segments that have been expanded prior to collapsing the sidebar. */
	public previouslyActiveElements: Element[] | null = null;

	/** The right sidebar accordion Element */
	public sidebarAccordion: HTMLElement | null = null;
	private sidebarElement?: Element;

	/**
	 * @param sidebarId A unique identifier for this sidebar that is used to persist the last collapse state for this
	 *   sidebar.
	 */
	public constructor(
		rootLayoutElement: Element,
		private readonly sidebarId: string
	) {
		this.init(rootLayoutElement);
	}

	/** Hooks the sidebar listeners to the sidebar (called from child class). */
	public hookSidebar(toggleMenu: Element): void {
		events.listen(toggleMenu, EventType.CLICK, () => this.toggle(true));
		this.listener = events.listen(window, EventType.RESIZE, () => this.setSidebarState());
		this.setSidebarState();
	}

	/** Determines the state of the sidebar (collapsed/expanded) and toggles the sidebar if necessary. */
	private setSidebarState(): void {
		const windowWidth = dom.getViewportSize().width;
		if (this.sidebarContainer == null || this.windowWidth === windowWidth) {
			return;
		}

		this.windowWidth = windowWidth;
		if (windowWidth < THRESHOLD_TO_MINIMIZE_SIDEBARS || this.isCollapsedByUser()) {
			if (!this.isCollapsed()) {
				this.toggle(false);
			}
		} else if (windowWidth >= THRESHOLD_TO_MINIMIZE_SIDEBARS) {
			// Expand sidebar if there is enough room and the user did not minimize the sidebar by himself
			if (!this.isCollapsedByUser() && this.isCollapsed()) {
				this.toggle(false);
			}
		}
	}

	/** @returns Whether the sidebar is collapsed. */
	public isCollapsed(): boolean {
		if (this.sidebarContainer == null) {
			return true;
		}
		return this.sidebarContainer.classList.contains('thin');
	}

	/** Expands/Shrinks the right Sidebar. */
	public toggle(storeAsUserDecision: boolean): void {
		if (this.sidebarContainer != null) {
			const isCollapsed = this.isCollapsed();
			this.sidebarContainer.classList.toggle('very', !isCollapsed);
			this.sidebarContainer.classList.toggle('thin', !isCollapsed);
			this.toggleMenu!.classList.toggle('minimized', !isCollapsed);
			const icon = dom.getElementByClass('icon', this.toggleMenu);
			icon?.classList.toggle('right', isCollapsed);
			icon?.classList.toggle('left', !isCollapsed);
			if (!isCollapsed) {
				this.previouslyActiveElements = tsdom.getElementsByClass('active', this.sidebarAccordion);
			}
			this.previouslyActiveElements?.forEach(element => element.classList.toggle('active', isCollapsed));
			this.sidebarElement!.classList.toggle('small-right-sidebar', !isCollapsed);
			if (storeAsUserDecision) {
				UIUtils.getLocalStorage().set(this.getSidebarCollapseKey(), !isCollapsed);
			}
			if (this.toggleMenu != null) {
				PerspectiveUtils.registerSingleWindowResizeEventOnTransitionEnd(this.toggleMenu);
			}
		}
	}

	private getSidebarCollapseKey(): string {
		return 'base-show-collapsed-right-sidebar-' + this.sidebarId;
	}

	/** Hides or shows the right sidebar completely. */
	public static enableSidebar(show: boolean): void {
		const sidebarContainer = tsdom.getElementById('right-sidebar-wrapper');
		sidebarContainer.style.display = show ? 'flex' : 'none';
	}

	/**
	 * Disables/enables the info/filter/findings tab of the right Sidebar
	 *
	 * @param label The name of the element to enable/disable: can be "filter", "findings" or "info".
	 * @param enable Whether the element will be visible or not
	 */
	public static setSectionVisible(label: string, enable: boolean, expanded = enable): void {
		tsdom.getElementById('sidebar-' + label + '-content').classList.toggle('active', expanded);
		tsdom.getElementById(label + '-menu-container').classList.toggle('hidden', !enable);
	}

	/** Inits the right sidebar */
	public init(rootLayoutElement: Element): void {
		const sidebarElement = soy.renderAsElement(RightSidebarTemplate.rightSidebar);
		this.sidebarElement = sidebarElement;
		this.toggleMenu = sidebarElement.querySelector('#' + LegacyRightSidebar.RIGHT_TOGGLE_MENU_ID);
		this.sidebarContainer = sidebarElement.querySelector('#' + LegacyRightSidebar.RIGHT_SIDEBAR_ID);
		this.sidebarAccordion = sidebarElement.querySelector('#right-sidebar-accordion');
		SemanticUIUtils.activateAccordion(this.sidebarAccordion!, false, false);
		events.listen(this.sidebarAccordion, EventType.CLICK, () => {
			if (this.isCollapsed()) {
				this.toggle(true);
			}
		});
		this.hookSidebar(this.toggleMenu!);
		rootLayoutElement.appendChild(sidebarElement);
	}

	/** Returns the info element of the right sidebar. */
	public static getSidebarInfoContent(): Element {
		return tsdom.getElementById('sidebar-info-content');
	}

	/** Returns the metrics element of the right sidebar. */
	public static getSidebarMetricsContent(): Element {
		return tsdom.getElementById('sidebar-metrics-content');
	}

	/** Returns the filter element of the right sidebar. */
	public static getSidebarFilterContent(): Element {
		return tsdom.getElementById('sidebar-filter-content');
	}

	/** Returns the findings element of the right sidebar. */
	public static getSidebarFindingsContent(): Element {
		return tsdom.getElementById('sidebar-findings-content');
	}

	/** Returns the display options element of the right sidebar. */
	public static getSidebarDisplayOptionsContent(): Element {
		return tsdom.getElementById('sidebar-display-options-content');
	}

	/** Returns the coverage element of the right sidebar. */
	public static getSidebarCoverageContent(): Element {
		return tsdom.getElementById('sidebar-coverage-content');
	}

	/** Returns the review element of the right sidebar. */
	public static getSidebarReviewContent(): HTMLElement {
		return tsdom.getElementById('sidebar-review-content');
	}

	/** Returns the parse log element of the right sidebar. */
	public static getSidebarParseLogContent(): HTMLElement {
		return tsdom.getElementById('sidebar-parse-log-content');
	}

	/** Returns the quality goal element of the right sidebar. */
	public static getSidebarQualityGoalContent(): Element {
		return tsdom.getElementById('sidebar-quality-goal-content');
	}

	/** @returns If the sidebar is (or should be) shown collapsed (very thin) */
	public isCollapsedByUser(): boolean {
		return UIUtils.getFromStorageWithDefault(this.getSidebarCollapseKey(), false);
	}

	/** Removes the listener from the window object. */
	public dispose(): void {
		if (this.listener !== null) {
			events.unlistenByKey(this.listener);
		}
		tsdom.removeNode(this.sidebarElement ?? null);
	}
}
