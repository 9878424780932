import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { EAdminPerspectiveView } from './EAdminPerspectiveView';

/** Descriptor for the admin perspective. */
export class AdminPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.ADMIN, EAdminPerspectiveView);
	}
}
