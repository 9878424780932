import { useSearchParams } from 'react-router-dom';
import { Links } from 'ts/commons/links/Links';

/** Returns the target to be used. This is the page to which the login page should redirect after a successful login. */
export function useTarget(): string {
	const [searchParams] = useSearchParams();
	const targetParameter = searchParams.get('target');

	// Prevent absolute URLs and URLs with protocol
	if (targetParameter && !targetParameter.startsWith('/') && !targetParameter.includes('//')) {
		return targetParameter + location.hash;
	}
	return Links.dashboard(null);
}
