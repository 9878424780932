import type { SemanticICONS } from 'ts/components/Generic';
import type { Appearance, Artifact } from 'ts/perspectives/dashboard/widgets/WidgetFactory';

/** The icon names of appearance categories */
export const APPEARANCE_CATEGORIES_ICONS: Record<Appearance, SemanticICONS> = {
	'Bar Charts': 'chart bar',
	'Pie Charts': 'chart pie',
	Trends: 'chart line',
	'Treemap Charts': 'object group',
	Tables: 'window maximize outline',
	'Text / Badge': 'paragraph',
	Other: 'database'
};

/** The icon names of artifact categories */
export const ARTIFACT_CATEGORIES_ICONS: Record<Artifact, SemanticICONS> = {
	'Test Gaps': 'object group outline',
	Findings: 'warning sign',
	Commits: 'code branch',
	'Issues & Spec Items': 'bullseye',
	Metrics: 'percent',
	Assessments: 'clipboard',
	Other: 'database'
};

/** The order of widget buttons in dashboard sidebar based on their appearance */
export const APPEARANCE_CATEGORY_ORDER: Appearance[] = [
	'Bar Charts',
	'Pie Charts',
	'Trends',
	'Treemap Charts',
	'Tables',
	'Text / Badge',
	'Other'
];

/** The order of widget buttons in dashboard sidebar based on their artifact */
export const ARTIFACT_CATEGORY_ORDER: Artifact[] = [
	'Test Gaps',
	'Findings',
	'Commits',
	'Issues & Spec Items',
	'Metrics',
	'Assessments',
	'Other'
];

/** The value of by appearance category */
export const APPEARANCE_TAB = 'appearance';

/** The value of by artifact category */
export const ARTIFACT_TAB = 'artifact';

/** Default dashboard name, if it doesn't have a name already */
export const DEFAULT_DASHBOARD_NAME = 'New dashboard';

/** Default template dashboard name */
export const DEFAULT_DASHBOARD_TEMPLATE_NAME = 'Template Name';

/** Default template dashboard description */
export const DEFAULT_DASHBOARD_TEMPLATE_DESCRIPTION = 'Template Description';

/** Error message when the dashboard name is empty */
export const EMPTY_DASHBOARD_NAME_ERROR_MESSAGE = 'The dashboard must have a name.';

/** The different modes that the saving modal can be in */
export enum ModalMode {
	Closed,
	Save,
	SaveAs,
	Rename,
	SaveTemplate,
	EditTemplate,
	Copy
}
