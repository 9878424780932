import { useContext } from 'react';
import { PerspectiveContextContext } from 'ts/base/context/PerspectiveContextContext';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';

/**
 * Hook that exposes the complete perspective context. If you only depend on a subset of the stored information consider
 * using more specialized hooks like:
 *
 * - UseProjectInfos
 * - UseTeamscaleInfo
 * - UseUserInfo
 * - UseUserPermissionInfo
 * - UseBranchesInfo
 */
export function usePerspectiveContext(): ExtendedPerspectiveContext {
	return useContext(PerspectiveContextContext)!;
}
