import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef, useEffect } from 'react';
import { AnalysisStateInfo } from 'ts/base/AnalysisStateInfo';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { useProjectIfExists } from 'ts/base/hooks/UseProject';
import { useUserInfo } from 'ts/base/hooks/UserInfoHook';
import { TeamscaleViewBase } from 'ts/base/view/TeamscaleViewBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { disposeAllOpenModals } from 'ts/commons/modal/ModalUtils';
import { TimeTravelState } from 'ts/commons/TimeTravelState';
import { tsdom } from 'ts/commons/tsdom';
import { Segment } from 'ts/components/Segment';
import { EventAnnouncementBanner } from 'ts/perspectives/admin/event-announcement/components/EventAnnouncementBanner';
import { useTeamscaleViewContext } from './TeamscaleViewContext';

/** Props for TeamscaleViewContent. */
type TeamscaleViewProps = {
	children: ReactNode;
	rightSidebar?: ReactNode;
	/**
	 * By default, the main view has a padding of 26px, but some views need more space like the architecture editor and
	 * the dashboard.
	 */
	hasSmallPadding?: boolean;
	/** Specifies the view name part of the document's title. By default, this is ViewDescriptor#name. */
	documentTitle?: string;
	/** Content type name of the current view, e.g. "Findings". Overrides TimeTravelOptions#contentName. */
	timeTravelContentName?: string;
};

/**
 * Wrapper around a Teamscale view that displays the analysis state and event announcements above the given children. It
 * also ensures that any open closure dialogs are removed from the DOM when the view is unmounted and sets the default
 * document title.
 */
export const TeamscaleView = forwardRef<HTMLDivElement, TeamscaleViewProps>(function TeamscaleView(
	{ children, rightSidebar, hasSmallPadding, documentTitle, timeTravelContentName }: TeamscaleViewProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const event = useUserInfo().eventAnnouncement;
	useRemoveModalsOnUnmount();
	const viewContext = useTeamscaleViewContext();
	useDocumentTitle(viewContext.viewDescriptor, documentTitle);
	useEffect(() => {
		if (timeTravelContentName) {
			TimeTravelState.INSTANCE.setContentName(timeTravelContentName);
		}
	}, [timeTravelContentName]);
	return (
		<div id="ts-main-container" tabIndex={-1} ref={ref}>
			<Segment
				basic
				id="main"
				style={{ padding: hasSmallPadding || viewContext.viewDescriptor.hasSmallPadding ? '13px' : undefined }}
			>
				<EventAnnouncementBanner event={event} />
				<AnalysisStateInfo />
				{children}
			</Segment>
			{rightSidebar}
		</div>
	);
});

function useDocumentTitle(viewDescriptor: ViewDescriptor, documentTitle: string | undefined) {
	const hash = useNavigationHash();
	const perspective = hash.getPerspective();
	const project = useProjectIfExists();
	useEffect(() => {
		if (viewDescriptor.hasSelfManagedTitle) {
			return;
		}
		document.title = perspective.displayName;
		const viewTitle = documentTitle ?? viewDescriptor.name;
		if (viewTitle !== '') {
			document.title += TeamscaleViewBase.TITLE_SEPARATOR + viewTitle;
		}
		if (project != null) {
			document.title += TeamscaleViewBase.TITLE_SEPARATOR + project.uniqueReadableName;
		}
		document.title += TeamscaleViewBase.TITLE_SEPARATOR + 'Teamscale';
	}, [documentTitle, perspective.displayName, project, viewDescriptor.hasSelfManagedTitle, viewDescriptor.name]);
}

/**
 * Removes any potential opened dialogs by removing their div elements on unmount. For more details on the Dialog HTML
 * structure, please refer to the {@code Dialog} class in the file: lib/ui/dialog.d.ts
 */
function useRemoveModalsOnUnmount() {
	useEffect(
		() => () => {
			tsdom.removeNodes(document.querySelectorAll('.modal-dialog'));
			tsdom.removeNodes(document.querySelectorAll('.modal-dialog-bg'));
			disposeAllOpenModals();
		},
		[]
	);
}
