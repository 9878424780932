import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import * as strings from 'ts-closure-library/lib/string/string';
import type { StringBuffer } from 'ts-closure-library/lib/string/stringbuffer';
import type { StyleRegion } from 'ts/commons/formatter/StyleRegion';
import type { PreprocessorExpansionsTransport } from 'typedefs/PreprocessorExpansionsTransport';

/**
 * Data context that provides token-styles and index values while formatting source code in {@link SourceFormatter} and
 * {@link SourceLineFormatter}
 */
export class SourceFormatterContext {
	/** Preprocessor expansions expansions generated by the preprocessor for the current code */
	public preprocessorExpansions: PreprocessorExpansionsTransport;

	/** Current index into the styleRegions array. */
	private currentRegionIndex = 0;

	/** The CSS classes used for formatting (same order as token styles). */
	private readonly cssClassNames: string[] = [];

	/**
	 * Regions in the source code that have the same token style
	 *
	 * @param preprocessorExpansions Can be null (meaning that there are no preprocessor expansions)
	 * @param project String denoting the current project.
	 */
	public constructor(
		private styleRegions: StyleRegion[],
		preprocessorExpansions: PreprocessorExpansionsTransport | null,
		private readonly project: string
	) {
		this.preprocessorExpansions = preprocessorExpansions ?? {
			offsetToExpansionGroup: {},
			expansionGroupToIncludedPath: {}
		};
	}

	/** Increments numeric index of current style region. */
	public incrementCurrentRegionIndex(): void {
		++this.currentRegionIndex;
	}

	/**
	 * <b>NOTE</b> <p>Calling this method without the {@code index} parameter cannot be used for partial re-renders, as
	 * this method assumes that the code rendering happens from top to bottom, and that this method is being called in
	 * the right sequence. The result hence depends on the state of this class, due to {@link currentRegionIndex}.</p>
	 *
	 * @param index Optional index number for specific style region
	 * @returns The current token-style region or a specific region at a provided index in #styleRegions.
	 */
	public getCurrentTokenStyleRegion(index?: number): StyleRegion | undefined {
		if (index != null) {
			return this.styleRegions[index];
		}
		return this.styleRegions[this.currentRegionIndex];
	}

	/**
	 * Inserts a CSS class name into the list of names.
	 *
	 * @param name A name to insert.
	 */
	public insertCSSClassName(name: string): void {
		this.cssClassNames.push(name);
	}

	/**
	 * Sets the given token data.
	 *
	 * @param styleRegions The new styleRegions
	 */
	public setStyleRegions(styleRegions: StyleRegion[]): void {
		this.styleRegions = styleRegions;
	}

	/**
	 * Retrieves the CSS class name at the given index.
	 *
	 * @param index A numeric integer for a name
	 */
	public getCSSClassName(index: number): string {
		return this.cssClassNames[index]!;
	}

	/** Returns the number of entries in the list of CSS class names. */
	public getCSSClassNamesCount(): number {
		return this.cssClassNames.length;
	}

	/** Returns the index inside the cssClassNames by the given value. */
	public getCSSClassIndex(value: string): number {
		return this.cssClassNames.indexOf(value);
	}

	/**
	 * Creates a <span> element for the given content and appends it to the given buffer. The content is html escaped
	 * first. The span has a "data-tag-<offsetInFile>" data-attribute and the given cssStyleClass.
	 *
	 * @param content The content to be wrapped.
	 * @param offsetInFile Offset to be used in the "token-" class
	 * @param cssStyleClass Css style class (pass '' if no cssStyleClass should be used)
	 * @param buffer The buffer to append the new element to
	 * @param fileIndex The file index (in case more than one file is shown in one view). Default is 0
	 * @returns The id of the created element
	 */
	public createAndAppendSpanForStyleRegion(
		content: string,
		offsetInFile: number,
		cssStyleClass: string,
		buffer: StringBuffer,
		fileIndex = 0
	): string {
		const contentAsHtml = strings.htmlEscape(content, false);
		const id = SourceFormatterContext.makeIdForFileAndOffset(fileIndex, offsetInFile);
		buffer.append(
			`<span id="${id}" class="codeLine ${cssStyleClass}" data-token-offset="${offsetInFile}">${contentAsHtml}</span>`
		);
		return id;
	}

	/**
	 * Creates a <span> element for the given content and appends it to the given buffer. The content is html escaped
	 * first. The span has a "data-tag-<offsetInFile>" data-attribute and the given cssStyleClass.
	 *
	 * @param content The content to be wrapped.
	 * @param offsetInFile Offset to be used in the "token-" class
	 * @param cssStyleClass Css style class (pass '' if no cssStyleClass should be used)
	 * @param buffer The buffer to append the new element to
	 * @param fileIndex The file index (in case more than one file is shown in one view). Default is 0
	 * @param linkTarget The target for this link element
	 * @returns The id of the created element
	 */
	private createAndAppendLinkForStyleRegion(
		content: string,
		offsetInFile: number,
		cssStyleClass: string,
		buffer: StringBuffer,
		fileIndex = 0,
		linkTarget: string
	): string {
		const contentAsHtml = strings.htmlEscape(content, false);
		const id = SourceFormatterContext.makeIdForFileAndOffset(fileIndex, offsetInFile);
		buffer.append(
			`<a href="${linkTarget}" id="${id}" class="codeLine ${cssStyleClass}" data-token-offset="${offsetInFile}">${contentAsHtml}</a>`
		);
		return id;
	}

	/** Returns the style regions. */
	public getTokenStyleRegions(): StyleRegion[] {
		return this.styleRegions;
	}

	/**
	 * Inserts a token-style region
	 *
	 * @param styleRegion The region to insert
	 */
	public insertStyleRegion(styleRegion: StyleRegion): void {
		this.styleRegions.push(styleRegion);
	}

	/** Removes all style regions */
	public clearStyleRegions(): void {
		this.styleRegions = [];
	}

	/**
	 * Create a unique identifier for a file and offset.
	 *
	 * @returns A string id for the given file index and offset
	 */
	public static makeIdForFileAndOffset(fileIndex: number, offsetInFile: number): string {
		return `file-${fileIndex}-token-${offsetInFile}`;
	}

	/**
	 * Generates and adds an HTML element for the given text (in the given style region) to the buffer.
	 *
	 * @param charOffsetInFile The (character-based) offset of the current style region
	 * @param tokenText The text of the current style region
	 * @param currentTokenStyleRegion The formatting style of the text
	 * @param buffer The buffer into which to fill the content
	 * @param fileIndex The file index (in case more than one file is shown in one view). Default is 0
	 */
	public generateAndAppendElementForStyleRegion(
		charOffsetInFile: number,
		text: string,
		currentTokenStyleRegion: StyleRegion,
		buffer: StringBuffer,
		fileIndex = 0
	): void {
		const linkTargetUniformPath = SourceFormatterContext.getIncludeDirectiveTargetFile(
			this.preprocessorExpansions,
			charOffsetInFile
		);
		if (linkTargetUniformPath != null) {
			const targetLink = LinkTemplate.code({
				project: this.project,
				commit: UnresolvedCommitDescriptor.wrap(this.preprocessorExpansions.commit),
				uniformPath: linkTargetUniformPath
			});
			this.createAndAppendLinkForStyleRegion(
				text,
				charOffsetInFile,
				currentTokenStyleRegion.styleCssClassName,
				buffer,
				fileIndex,
				targetLink
			);
		} else {
			this.createAndAppendSpanForStyleRegion(
				text,
				charOffsetInFile,
				currentTokenStyleRegion.styleCssClassName,
				buffer,
				fileIndex
			);
		}
	}

	/**
	 * Checks whether the token at the given offset is (1) an C/C++ include directive for which (2) the preprocessor
	 * found a matching file and included it. If both conditions hold, this method returns the uniform path of the
	 * included file. Otherwise it returns undefined.
	 *
	 * @param charOffsetInFile The (character-based) offset of the current token
	 */
	public static getIncludeDirectiveTargetFile(
		preprocessorExpansions: PreprocessorExpansionsTransport | undefined,
		charOffsetInFile: number
	): string | undefined {
		if (preprocessorExpansions?.offsetToExpansionGroup[charOffsetInFile] != null) {
			return preprocessorExpansions.expansionGroupToIncludedPath[
				preprocessorExpansions.offsetToExpansionGroup[charOffsetInFile]
			];
		}
		return undefined;
	}
}
