import { StringValuedMetric } from 'ts/commons/components/MetricFormatters';
import { MetricFormatterBase } from 'ts/commons/formatter/MetricFormatterBase';
import type { CounterSet } from 'typedefs/CounterSet';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';

/** A formatter for counter sets. */
export class CounterSetFormatter extends MetricFormatterBase<CounterSet> {
	public constructor(schemaEntry: MetricDirectorySchemaEntry) {
		super(schemaEntry);
	}

	public override formatValueAsJsx(value: CounterSet): JSX.Element {
		return <StringValuedMetric value={this.formatValueAsText(value)} />;
	}

	public override formatValueAsText(value: CounterSet): string {
		return Object.keys(value.map).sort().join(', ');
	}

	public override parseFromString(value: string): CounterSet {
		const elements = value.split(', ');
		const map = elements.reduce(
			(acc, key) => {
				acc[key] = 1;
				return acc;
			},
			{} as Record<string, number>
		);
		return { map, total: elements.length };
	}
}
