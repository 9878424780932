/**
 * HTTP Status Codes defined in RFC 2616, RFC 6585, RFC 4918 and RFC 7538.
 *
 * @enum {number}
 * @see http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html
 * @see http://tools.ietf.org/html/rfc6585
 * @see https://tools.ietf.org/html/rfc4918
 * @see https://tools.ietf.org/html/rfc7538
 */
export enum HttpStatus {
	// Informational 1xx
	CONTINUE = 100,
	SWITCHING_PROTOCOLS = 101,

	// Successful 2xx
	OK = 200,
	CREATED = 201,
	ACCEPTED = 202,
	NON_AUTHORITATIVE_INFORMATION = 203,
	NO_CONTENT = 204,
	RESET_CONTENT = 205,
	PARTIAL_CONTENT = 206,
	MULTI_STATUS = 207,

	// Redirection 3xx
	MULTIPLE_CHOICES = 300,
	MOVED_PERMANENTLY = 301,
	FOUND = 302,
	SEE_OTHER = 303,
	NOT_MODIFIED = 304,
	USE_PROXY = 305,
	TEMPORARY_REDIRECT = 307,
	PERMANENT_REDIRECT = 308,

	// Client Error 4xx
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	PAYMENT_REQUIRED = 402,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	METHOD_NOT_ALLOWED = 405,
	NOT_ACCEPTABLE = 406,
	PROXY_AUTHENTICATION_REQUIRED = 407,
	REQUEST_TIMEOUT = 408,
	CONFLICT = 409,
	GONE = 410,
	LENGTH_REQUIRED = 411,
	PRECONDITION_FAILED = 412,
	REQUEST_ENTITY_TOO_LARGE = 413,
	REQUEST_URI_TOO_LONG = 414,
	UNSUPPORTED_MEDIA_TYPE = 415,
	REQUEST_RANGE_NOT_SATISFIABLE = 416,
	EXPECTATION_FAILED = 417,
	UNPROCESSABLE_ENTITY = 422,
	LOCKED = 423,
	FAILED_DEPENDENCY = 424,
	PRECONDITION_REQUIRED = 428,
	TOO_MANY_REQUESTS = 429,
	REQUEST_HEADER_FIELDS_TOO_LARGE = 431,

	// Server Error 5xx
	INTERNAL_SERVER_ERROR = 500,
	NOT_IMPLEMENTED = 501,
	BAD_GATEWAY = 502,
	SERVICE_UNAVAILABLE = 503,
	GATEWAY_TIMEOUT = 504,
	HTTP_VERSION_NOT_SUPPORTED = 505,
	INSUFFICIENT_STORAGE = 507,
	NETWORK_AUTHENTICATION_REQUIRED = 511
}

/**
 * Returns whether the given status should be considered successful.
 *
 * Successful codes are OK (200), CREATED (201), ACCEPTED (202), NO CONTENT (204), PARTIAL CONTENT (206) and NOT
 * MODIFIED (304).
 *
 * @param {number} status The status code to test.
 * @returns {boolean} Whether the status code should be considered successful.
 */
export function isSuccess(status: number): boolean {
	switch (status) {
		case HttpStatus.OK:
		case HttpStatus.CREATED:
		case HttpStatus.ACCEPTED:
		case HttpStatus.NO_CONTENT:
		case HttpStatus.PARTIAL_CONTENT:
		case HttpStatus.NOT_MODIFIED:
			return true;

		default:
			return false;
	}
}
