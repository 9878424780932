import { ServiceClientImplementation } from 'api/ServiceClientImplementation';

/** Downloads a file as blob. */
export function downloadBlob(relativeUrl: string): Promise<Blob> {
	return ServiceClientImplementation.call('GET', relativeUrl, {
		// For a blob download, we need to accept all (not just JSON)
		acceptType: '*/*',
		responseType: 'blob'
	});
}
