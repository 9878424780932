import clsx from 'clsx';
import { type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import type { SemanticShorthandContent } from '../Generic';
import { childrenUtils, createShorthandFactory, getComponentType, getUnhandledProps } from '../lib';

/** Props for {@link LabelDetail}. */
export type LabelDetailProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;
};

export const LabelDetail = forwardRef(function LabelDetail(props: LabelDetailProps, ref: ForwardedRef<HTMLDivElement>) {
	const { children, className, content } = props;

	const classes = clsx('detail', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
});

export const createLabelDetail = createShorthandFactory(LabelDetail, val => ({
	content: val
}));
const handledProps = ['as', 'children', 'className', 'content'];
