import type { ColumnDef, RowData, Table, TableOptions } from '@tanstack/react-table';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { type JSX, useMemo } from 'react';
import type { Callback } from 'ts/base/Callback';
import { DataTable, type DataTableProps, FractionalColumnSizing } from 'ts/components/Table';
import type { ESortOrder } from 'typedefs/ESortOrder';
import {
	convertFromReactTableSortingState,
	convertToReactTableSortBy,
	getUpdaterHandler
} from './TanstackTableSortUtils';

/** Props for the sortOrder state. */
export type SortOptions = {
	sortByField: string;
	sortOrder: ESortOrder;
};

/**
 * To effectively disable pagination, we use a very huge integer as page size. To avoid risking overflows we're not
 * using {@link Number#MAX_SAFE_INTEGER} directly.
 */
export const MAX_TABLE_SIZE = Math.round(Number.MAX_SAFE_INTEGER / 2);

/**
 * Internal value used as page size for showing all values without pagination. Used in the hash state but not passed to
 * the tanstack table.See {@link #MAX_TABLE_SIZE}.
 */
export const NO_PAGING_PAGE_SIZE = -1;

type SortableTableOptions<T extends RowData, TValue = unknown> = {
	columnDefinitions: Array<ColumnDef<T, TValue>>;
	data: T[];
	defaultSortOptions?: SortOptions;
	sortOptions?: SortOptions;
	onSortOptionsChanged?: Callback<SortOptions>;
	tableOptions?: Partial<TableOptions<T>>;
};

/**
 * Provides a table instance that is configured to sort its content. In contrast to the SortableTable component the hook
 * allows you to customize the returned table as needed before passing it to the Table component for rendering.
 */
export function useSortableTable<T extends RowData, TValue = unknown>({
	columnDefinitions,
	data,
	defaultSortOptions,
	sortOptions,
	onSortOptionsChanged,
	tableOptions = {}
}: SortableTableOptions<T, TValue>): Table<T> {
	const defaultSorting = useMemo(
		() => convertToReactTableSortBy(defaultSortOptions?.sortByField, defaultSortOptions?.sortOrder),
		[defaultSortOptions?.sortByField, defaultSortOptions?.sortOrder]
	);
	const sorting = useMemo(
		() => convertToReactTableSortBy(sortOptions?.sortByField, sortOptions?.sortOrder),
		[sortOptions?.sortByField, sortOptions?.sortOrder]
	);
	return useReactTable({
		data,
		columns: columnDefinitions,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableSorting: true,
		enableSortingRemoval: false,
		...tableOptions,
		...(onSortOptionsChanged
			? {
					onSortingChange: getUpdaterHandler(sorting, sortingState =>
						onSortOptionsChanged(convertFromReactTableSortingState(sortingState))
					)
				}
			: undefined),
		state: {
			...(sortOptions ? { sorting } : undefined),
			...tableOptions.state
		},
		initialState: {
			...(defaultSortOptions ? { sorting: defaultSorting } : undefined),
			...tableOptions.state
		},
		_features: [FractionalColumnSizing]
	});
}

/** Props for SortableTable. */
export type SortableTableProps<T extends RowData, TValue = unknown> = Omit<DataTableProps<T>, 'sortable' | 'table'> &
	SortableTableOptions<T, TValue>;

/** A table that is automatically sortable. All data must be available on the client side. */
export function SortableTable<T extends RowData, TValue = unknown>({
	columnDefinitions,
	data,
	defaultSortOptions,
	sortOptions,
	onSortOptionsChanged,
	tableOptions,
	...tableProps
}: SortableTableProps<T, TValue>): JSX.Element {
	const table = useSortableTable({
		defaultSortOptions,
		columnDefinitions,
		sortOptions,
		onSortOptionsChanged,
		data,
		tableOptions
	});
	return <DataTable sortable table={table} {...tableProps} />;
}
