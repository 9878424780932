import { QUERY } from 'api/Query';
import { type JSX, useEffect } from 'react';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { useProjectIfExists } from 'ts/base/hooks/UseProject';
import { ProjectResolver } from 'ts/base/ProjectResolver';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { ProjectSelector } from 'ts/base/scaffolding/ProjectSelector';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { PerspectiveUtils } from 'ts/commons/PerspectiveUtils';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { StringUtils } from 'ts/commons/StringUtils';
import { TimetravelUtils } from 'ts/commons/TimetravelUtils';
import { MenuItem } from 'ts/components/Menu';
import { EDeltaView } from 'ts/perspectives/delta/EDeltaView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Props for GlobalProjectSelector. */
type GlobalProjectSelectorProps = {
	projectIsFixed: boolean;
	showAllProjects: boolean;
};

/** The global project selector that is shown in the perspective settings bar. */
export function GlobalProjectSelector({ projectIsFixed, showAllProjects }: GlobalProjectSelectorProps): JSX.Element {
	const hash = useNavigationHash();
	const projectInfo = useProjectIfExists();
	const primaryId = projectInfo?.primaryId;
	useEffect(() => {
		if (primaryId != null) {
			QUERY.registerProjectViewedByUser(primaryId)
				.fetch()
				.catch(() => {
					return;
				});
		}
	}, [primaryId]);

	/** Callback for updating the current project value in the browser local storage. */
	function onProjectSelectionChange(projectId: string): void {
		if (!StringUtils.isEmptyOrWhitespace(projectId)) {
			QUERY.registerProjectViewedByUser(projectId)
				.fetch()
				.catch(() => {
					return;
				});
		}
		ProjectResolver.setCurrentProject(projectId);
		TimetravelUtils.setCurrentCommit(null);
		const link = createProjectLinkToCurrentPerspective(hash, projectId);
		NavigationUtils.updateLocation(link, true);
	}

	return (
		<MenuItem
			fitted
			className="no-right-margin"
			title={`Selected project: ${primaryId ?? PerspectiveUtils.ALL_PROJECTS}`}
		>
			<MenuItem className="side-fitting project-selector-container">
				<ProjectSelector
					asItem={ProjectLink}
					activeProjectId={primaryId}
					selectorId="project-select"
					showAllProjectsItem={showAllProjects}
					projectIsFixed={projectIsFixed}
					onChange={onProjectSelectionChange}
				/>
			</MenuItem>
		</MenuItem>
	);
}

/** Props for ProjectLink. */
type ProjectLinkProps = { 'data-value': string } & Record<string, unknown>;

/** Links to the given project while keeping the current perspective and view. */
function ProjectLink({ 'data-value': projectId, ...props }: ProjectLinkProps) {
	const hash = useNavigationHash();
	return <TeamscaleLink to={createProjectLinkToCurrentPerspective(hash, projectId)} {...props} />;
}

/**
 * Creates a link to the same perspective with the provided projectId. Any uniform path or other query arguments are
 * removed, except when on the {@link ETeamscalePerspective.SEARCH} perspective.
 */
function createProjectLinkToCurrentPerspective(hash: NavigationHash, projectId: string): string {
	if (hash.getPerspective() !== ETeamscalePerspective.SEARCH) {
		hash.clearArguments();
	}
	if (hash.getPerspective() === ETeamscalePerspective.DELTA) {
		hash.setViewName(EDeltaView.PARAMETER.anchor);
	}
	const path = ProjectAndUniformPath.of(projectId, null);
	hash.setProjectAndPath(path);
	return hash.toString();
}
