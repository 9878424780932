import { type ElementType, type ForwardedRef, forwardRef } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { InputProps } from '../Input';
import { Input } from '../Input';
import { getComponentType, getUnhandledProps } from '../lib';
import type { BasicFormFieldProps } from './FormField';
import { FormField } from './FormField';

/** Props for {@link FormInput}. */
export type FormInputProps = ExtendTypeWith<
	ExtendTypeWith<InputProps, BasicFormFieldProps>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;
	}
>;

/**
 * Sugar for <Form.Field control={Input} />.
 *
 * @see Form
 * @see Input
 */
export const FormInput = forwardRef(function FormInput(props: FormInputProps, ref: ForwardedRef<HTMLInputElement>) {
	const { control = Input } = props;

	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props, { defaultAs: FormField });

	return <ElementType {...rest} control={control} ref={ref} />;
});
const handledProps = ['as', 'control'];
