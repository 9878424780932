import { useMemo } from 'react';
import { useLocation } from 'ts/base/hooks/UseLocation';
import { NavigationHash } from 'ts/commons/NavigationHash';

/** Returns the navigation hash of the current view. */
export function useNavigationHash(): NavigationHash {
	const normalizedHash = useLocation(location => NavigationHash.fromPath(location).toString());
	const memoizedHash = useMemo(() => NavigationHash.parse(normalizedHash), [normalizedHash]);
	if (normalizedHash !== memoizedHash.toString()) {
		// Happens when the memoized NavigationHash object was altered
		return NavigationHash.parse(normalizedHash);
	}
	return memoizedHash;
}
