import * as dom from 'ts-closure-library/lib/dom/dom';
import { WidgetParameterBase } from './WidgetParameterBase';

export class TitleParameter extends WidgetParameterBase<string> {
	/**
	 * Parameter for the widget title. Allows other parameters to change its value.
	 *
	 * @param name The name of the parameter.
	 * @param description The description of the parameter.
	 */
	public constructor(name: string, description: string) {
		super(name, description);
	}

	/**
	 * Changes the value of this parameter. Must only be called while the parameter is actually displayed on screen,
	 * i.e. the edit dialog is open.
	 *
	 * @param value The new value of the parameter.
	 */
	public setValue(value: string): void {
		this.getTextField().value = value;
	}

	public override extractValue(): string {
		return this.getTextField().value;
	}

	/** @returns The text input field */
	public getTextField(): HTMLInputElement {
		return dom.getElementByClass('text-input', this.container) as HTMLInputElement;
	}
}
