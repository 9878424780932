import clsx from 'clsx';
import { type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import type { SemanticShorthandContent } from '../Generic';
import { childrenUtils, createShorthandFactory, getComponentType, getUnhandledProps } from '../lib';

/** Props for {@link ListDescription}. */
export type ListDescriptionProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;
};

/** A list item can contain a description. */
export const ListDescription = forwardRef(function ListDescription(
	props: ListDescriptionProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const { children, className, content } = props;

	const classes = clsx(className, 'description');
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
});

export const createListDescription = createShorthandFactory(ListDescription, content => ({ content }));
const handledProps = ['as', 'children', 'className', 'content'];
