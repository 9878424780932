import type { CodeLineSelection } from 'ts/commons/code/CodeLineSelection';

/** Representation of a selection in a code file. */
export abstract class CodeSelection {
	/** The identifier used for indicating that a selection is character-based. */
	public static OFFSET_INDICATOR = 'char-';

	/** The delimiter between two elements of the URL parameter value. */
	public static URL_PARAMETER_SEPARATOR = '-';

	/**
	 * Resolves the selection from character based to line based if it's not yet a line based selection.
	 *
	 * @param lineOffsets The offsets of the lines in the code view
	 * @returns The selection as line based region
	 */
	public abstract resolve(lineOffsets: number[]): CodeLineSelection;
}
