import * as strings from 'ts-closure-library/lib/string/string';
import { ProjectResolver } from 'ts/base/ProjectResolver';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Base class for perspective descriptors that describe the subviews a perspective has. */
export abstract class PerspectiveViewDescriptorBase {
	private readonly viewDescriptors: ViewDescriptor[];

	protected constructor(
		public readonly perspective: ETeamscalePerspective,
		viewDescriptorEnum: Record<string, ViewDescriptor>
	) {
		this.viewDescriptors = Object.values(viewDescriptorEnum);
	}

	/** Returns the sub-views of a perspective that the user has access to. */
	public getAccessibleViewDescriptors(context: ExtendedPerspectiveContext, hash: NavigationHash): ViewDescriptor[] {
		return this.viewDescriptors.filter(
			subView => subView.canBeAccessed === undefined || subView.canBeAccessed(context, hash)
		);
	}

	/** Returns the sub-view that matches the given viewName and action or null if no match is found. */
	public getViewDescriptor(viewName: string, action: string | undefined): ViewDescriptor | null {
		return PerspectiveViewDescriptorBase.findViewDescriptor(this.viewDescriptors, viewName, action);
	}

	/**
	 * Returns the view descriptor for the given view name or the first view if none matches.
	 *
	 * @param viewName The name of the view to search for.
	 */
	public static findViewDescriptor(
		viewDescriptors: ViewDescriptor[],
		viewName: string,
		action: string | undefined
	): ViewDescriptor | null {
		let matchForAnchorOnly: ViewDescriptor | null = null;
		for (const viewDescriptor of viewDescriptors) {
			if (strings.caseInsensitiveEquals(viewDescriptor.anchor, viewName)) {
				matchForAnchorOnly = viewDescriptor;
				if (action === viewDescriptor.action) {
					return viewDescriptor;
				}
			}
		}
		if (matchForAnchorOnly !== null) {
			return matchForAnchorOnly;
		}
		return null;
	}

	/**
	 * Determines the project to be shown for the currently active perspective. A complete documentation and detailed
	 * description about the logic and the possible return values can be found in ticket TS-25878.
	 */
	public getProject(
		perspectiveContext: ExtendedPerspectiveContext,
		hash: NavigationHash,
		viewDescriptor: ViewDescriptor
	): string | null {
		if (!viewDescriptor.requiresProject || perspectiveContext.getAllProjects().length === 0) {
			return null;
		}

		const projectResolver = new ProjectResolver(perspectiveContext);
		if (viewDescriptor.showAllProjects) {
			return projectResolver.getProjectForDashboardOrReportsPerspective(hash);
		}
		return projectResolver.resolveProjectFromHashOrLocalStorage(hash);
	}

	/**
	 * Returns the link to the view on the perspective taking the #keepPathAndArgumentsOfCurrentViewForSubviews into
	 * account.
	 */
	public getLinkToPerspective(
		perspectiveContext: ExtendedPerspectiveContext,
		hash: NavigationHash,
		viewDescriptor: ViewDescriptor
	): string {
		let project;
		if (viewDescriptor.requiresProject) {
			project = this.getProject(perspectiveContext, hash, viewDescriptor);
		}
		return (
			linkTo(
				this.perspective,
				viewDescriptor,
				// Links to project-specific perspectives or to the Dashboard perspective should keep the currently selected project (if available)
				project
			) +
			(viewDescriptor.keepPathAndArgumentsOfCurrentViewForSubviews
				? '/' + hash.getProjectAndPath().getPath() + hash.getArgumentsString()
				: '')
		);
	}
}
