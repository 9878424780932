import type { LineRegion } from 'ts/commons/formatter/SourceFormatter';
import { PathUtils } from 'ts/commons/PathUtils';
import type { EExtendedResourceTypeEntry } from 'typedefs/EExtendedResourceType';
import { EExtendedResourceType } from 'typedefs/EExtendedResourceType';
import type { ElementLocation } from 'typedefs/ElementLocation';
import type { FindingLocation } from 'typedefs/FindingLocation';
import type { QualifiedNameLocation } from 'typedefs/QualifiedNameLocation';
import type { TextRegionLocation } from 'typedefs/TextRegionLocation';
import type { TokenStyle } from 'typedefs/TokenStyle';
import { StyleRegion } from './StyleRegion';
import type { TokenStyleCssCache } from './TokenStyleCssCache';

/** Util methods for source formatting */
export class SourceFormatterUtils {
	/**
	 * Builds an array of sequences/regions of consecutive tokens that have the same token style from given information
	 * about tokens/styles and the style definitions. Also merges consecutive tokens with the same style. But does not
	 * merge identifiers and delimiters, to allow highlighting.
	 *
	 * The format of the tokens and tokenStyles arrays are defined by FormattedTokenElementInfo.java. In particular
	 * tokens has a strange format, it contains 3 ints per token (start Offset, end Offset, style id).
	 *
	 * @param {number[]} tokens The current list of tokens (in the format described above)
	 * @param {TokenStyle[]} tokenStyles The token styles used by the given list of tokens
	 * @param {TokenStyleCssCache} tokenStyleCssCache Object holding all css styles used for token rendering.
	 * @returns {!StyleRegion[]} The style regions array.
	 * @protected
	 */
	public static buildStyleRegions(
		tokens: number[],
		tokenStyles: TokenStyle[],
		tokenStyleCssCache: TokenStyleCssCache,
		requiredOffsets: number[]
	): StyleRegion[] {
		if (tokens.length === 0) {
			// If there are no tokens (i.e., no text), we add a default style since the renderer will
			// usually replace the text with " ".
			return [new StyleRegion(0, 0, tokenStyleCssCache.defaultCssClassName)];
		}
		const styleRegions: StyleRegion[] = [];
		let lastInsertedStyleIsIdentifierOrDelimiter = false;
		let lastInsertedStyleCssClassName = undefined;
		for (let i = 0; i < tokens.length; i += 3) {
			const currentTokenStartOffset = tokens[i]!;
			const currentStyle = tokenStyles[tokens[i + 2]!]!;
			const currentStyleCssClassName = tokenStyleCssCache.getOrAddTokenStyleName(currentStyle);
			const anyIsIdentifierOrDelimiter =
				currentStyle.identifier || currentStyle.delimiter || lastInsertedStyleIsIdentifierOrDelimiter;
			if (
				!anyIsIdentifierOrDelimiter &&
				currentStyleCssClassName === lastInsertedStyleCssClassName &&
				!requiredOffsets.includes(currentTokenStartOffset)
			) {
				// Same style as previous token
				styleRegions[styleRegions.length - 1]!.endOffset = tokens[i + 1]!;
			} else {
				// New css style
				styleRegions.push(new StyleRegion(currentTokenStartOffset, tokens[i + 1]!, currentStyleCssClassName));
				lastInsertedStyleCssClassName = currentStyleCssClassName;
				lastInsertedStyleIsIdentifierOrDelimiter = currentStyle.identifier || currentStyle.delimiter;
			}
		}
		// Extend last region to "infinity", this makes the rendering code a lot
		// easier. There might be characters at the end of the file that are not
		// parsed as tokens here, these will use the style of the last region handled
		// here.
		styleRegions[styleRegions.length - 1]!.endOffset = 1e9;
		return styleRegions;
	}

	/**
	 * Adds the finding locations to the affected lines.
	 *
	 * @param location The finding's location
	 */
	public static getStartAndEndLineNumberForFindingLocation(
		sourceCodeText: string,
		lineOffsets: number[],
		location: ElementLocation | TextRegionLocation | FindingLocation | QualifiedNameLocation,
		resourceTypesForFile: EExtendedResourceTypeEntry[]
	): LineRegion | undefined {
		if (
			resourceTypesForFile.includes(EExtendedResourceType.SIMULINK_DATA_DICTIONARY.name) &&
			'qualifiedName' in location // This must be a QualifiedNameLocation
		) {
			const oneBasedLineNumber = SourceFormatterUtils.determineLineNumberForDataDictionaryFindingLocation(
				sourceCodeText,
				lineOffsets,
				location
			);
			return { start: oneBasedLineNumber, end: oneBasedLineNumber };
		} else if (PathUtils.isTextRegionLocation(location)) {
			const startLine = location.rawStartLine;
			let endLine = location.rawEndLine;
			if (!endLine) {
				endLine = startLine;
			}
			return { start: startLine, end: endLine };
		}
		return undefined;
	}

	/**
	 * Returns the line number in which a given finding location in a Simulink Data Dictionary file should be displayed.
	 *
	 * Findings in Simulink Data Dictionaries have QualifiedNameLocations. If the finding is on the entry with name
	 * "myEntry" in data dictionary "foo.sldd", then the qualifiedName is "myEntry".
	 *
	 * To determine the line number, we assume that lines start with entry names followed by a ": ". This is the way
	 * SimulinkDataDictionary.buildLineBasedRepresentation encodes its contents.
	 */
	private static determineLineNumberForDataDictionaryFindingLocation(
		sourceCodeText: string,
		lineOffsets: number[],
		location: QualifiedNameLocation
	) {
		const elementName = location.qualifiedName;
		const searchString = elementName + ': ';
		let oneBasedLineNumber = 0;
		for (let lineNumber = 0; lineNumber < lineOffsets.length; lineNumber++) {
			const lineOffset = lineOffsets[lineNumber]!;
			if (sourceCodeText.startsWith(searchString, lineOffset)) {
				// Line numbers must be given 1-based (first line has number 1)
				oneBasedLineNumber = lineNumber + 1;
				break;
			}
		}
		return oneBasedLineNumber;
	}
}
