import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { EActivityPerspectiveView } from 'ts/perspectives/activity/EActivityPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/**
 * Returns a URL to the history of a file.
 *
 * @param project The project.
 * @param uniformPath The uniform path for which to show the history.
 * @param commit The commit for which to show the history or null if not required.
 */
export function fileHistory(project: string, uniformPath: string, commit: UnresolvedCommitDescriptor | undefined) {
	return linkTo(ETeamscalePerspective.ACTIVITY, EActivityPerspectiveView.RESOURCE_HISTORY, project, uniformPath, {
		commit
	});
}

/**
 * Returns a URL to the commit stream of a project.
 *
 * @param project The project.
 * @param params
 * @param params.user The user to filter the view on.
 */
export function commitStream(project: string, params?: { t?: UnresolvedCommitDescriptor | null; users?: string }) {
	return linkTo(ETeamscalePerspective.ACTIVITY, EActivityPerspectiveView.COMMITS, project, null, params);
}

/**
 * Returns a URL to the method history view of a method specified by uniformPath, region and commit.
 *
 * @param project The project which contains the commit.
 * @param uniformPath The uniform path for which to show the code.
 * @param params
 * @param params.commit The commit (must have timestamp and branchName).
 * @param params.startOffset Start of the offset-based region the method lies in.
 * @param params.endOffset End of the offset-based region the method lies in.
 * @param params.partitions
 * @param params.crossAnnotationProjects
 * @param params.baseline The baseline timestamp as URL Token in milliseconds.
 * @param params.lastChangedTimestamp Commit timestamp of the last change to a method (Used in the TGA treemap with
 *   issue context).
 * @param params.isTested Whether the method was tested last (Used in the TGA treemap with issue context).
 * @param params.isViewWithoutBaselineOptionLink Whether the link was called from the code perspective.
 */
export function methodHistory(
	project: string,
	uniformPath: string,
	{
		commit,
		...params
	}: {
		startOffset: number;
		endOffset: number;
		partitions: string[];
		crossAnnotationProjects: string[];
		commit?: UnresolvedCommitDescriptor | null;
		baseline?: string | null;
		lastChangedTimestamp?: number;
		isTested?: boolean;
		isViewWithoutBaselineOptionLink?: boolean;
	}
) {
	return linkTo(ETeamscalePerspective.ACTIVITY, EActivityPerspectiveView.METHOD_HISTORY, project, uniformPath, {
		t: commit,
		...params,
		methodHistoryTab: 0
	});
}

/** Returns the URL to a merge request */
export function mergeRequestDetails(project: string, mergeRequestId: string) {
	return linkTo(ETeamscalePerspective.ACTIVITY, EActivityPerspectiveView.MERGE_REQUESTS, project, mergeRequestId);
}

/** Returns a URL for an issue query. */
export function issueQuery(project: string, query: string, queryName?: string) {
	return linkTo(ETeamscalePerspective.ACTIVITY, EActivityPerspectiveView.ISSUES, project, {
		q: query,
		queryname: queryName
	});
}
