import type { JSX } from 'react';
import type { FieldPath, FieldPathValue, FieldValues, UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';
import type { ProjectSelectorProps } from 'ts/base/scaffolding/ProjectSelector';
import { ProjectSelector } from 'ts/base/scaffolding/ProjectSelector';

/** Props for FormProjectSelector. */
type FormProjectSelectorProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Pick<
	UseControllerProps<TFieldValues, TName>,
	'name' | 'control' | 'rules'
> &
	Omit<ProjectSelectorProps, 'activeProjectId' | 'onChange'>;

/** This is a wrapper around ProjectSelector that is compatible with react-hook-form. */
export function FormProjectSelector<
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ name, control, rules, ...projectSelectorProps }: FormProjectSelectorProps<TFieldValues, TName>): JSX.Element {
	const controller = useController<TFieldValues, TName>({ name, control, rules });
	return (
		<ProjectSelector
			activeProjectId={controller.field.value}
			onChange={value => controller.field.onChange(value as FieldPathValue<TFieldValues, TName>)}
			{...projectSelectorProps}
		/>
	);
}
