import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { EMetricsPerspectiveView } from 'ts/perspectives/metrics/EMetricsPerspectiveView';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** @returns A URL to show a single file. */
export function showFile(project: string, uniformPath: string, commit?: UnresolvedCommitDescriptor) {
	return linkTo(ETeamscalePerspective.METRICS, EMetricsPerspectiveView.FILES, project, uniformPath, {
		t: commit
	});
}

/** Returns a URL for a test query. */
export function testQuery(
	project: string,
	query: string,
	params: {
		queryName?: string;
		commit?: UnresolvedCommitDescriptor | null;
	}
) {
	return linkTo(ETeamscalePerspective.METRICS, EMetricsPerspectiveView.TESTS, project, {
		t: params.commit,
		action: 'filter',
		q: query,
		queryname: params.queryName
	});
}

/** Returns a URL to the test detail perspective. */
export function testExecutionOrImplementation(
	project: string,
	uniformPath: string,
	commit: UnresolvedCommitDescriptor | CommitDescriptor | null,
	partition?: string | null
) {
	return linkTo(ETeamscalePerspective.METRICS, EMetricsPerspectiveView.TESTS, project, uniformPath, {
		t: UnresolvedCommitDescriptor.wrap(commit),
		partition
	});
}
