/** Describes a region in the code (sequence of tokens) that must be formatted in the same token style. */
export class StyleRegion {
	/**
	 * Constructs a style region.
	 *
	 * @param startOffset Offset of the first token in the region
	 * @param endOffset Offset of the first token after the region
	 * @param styleCssClassName Css-class name for token style for this region
	 */
	public constructor(
		public startOffset: number,
		public endOffset: number,
		public styleCssClassName: string
	) {}
}
