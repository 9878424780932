/**
 * Contains different types of points in time. <b>Note:</b> The entries of this enum have to match the ones used in
 * EQualityArtifactBaselineType.java
 */
export enum EPointInTimeType {
	BASELINE = 'BASELINE',
	TIMESTAMP = 'TIMESTAMP',
	TIMESPAN = 'TIMESPAN',
	REVISION = 'REVISION',
	GIT_TAG = 'GIT_TAG',
	SYSTEM_VERSION = 'SYSTEM_VERSION'
}
