import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { EDeltaView } from './EDeltaView';

/** Descriptor for the delta perspective. */
export class DeltaPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.DELTA, EDeltaView);
	}
}
