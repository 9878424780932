import { linkTo } from 'ts/commons/links/LinkTo';
import { EApiPerspectiveView } from 'ts/perspectives/api/EApiPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Returns a URL for showing the public Open API documentation. */
export function showPublicApiDocumentation() {
	return linkTo(ETeamscalePerspective.API_DOCUMENTATION, EApiPerspectiveView.PUBLIC_API);
}

/** Returns a URL for showing the internal Open API documentation. */
export function showInternalApiDocumentation() {
	return linkTo(ETeamscalePerspective.API_DOCUMENTATION, EApiPerspectiveView.INTERNAL_API);
}
