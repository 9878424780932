import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ForwardedRef, forwardRef } from 'react';
import { getComponentType, getUnhandledProps } from '../lib';

/** Props for {@link DropdownDivider}. */
export type DropdownDividerProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** Additional classes. */
	className?: string;
};

/** A dropdown menu can contain dividers to separate related content. */
export const DropdownDivider = forwardRef(function DropdownDivider(
	props: DropdownDividerProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const { className } = props;

	const classes = clsx('divider', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return <ElementType {...rest} className={classes} ref={ref} />;
});
const handledProps = ['as', 'className'];
