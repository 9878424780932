import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

/** Defines all sub-views of the model compare perspective */
export const EModelComparePerspectiveView = {
	MODEL_COMPARE: {
		name: 'Model Compare',
		anchor: 'compare',
		requiresProject: true,
		viewCreator: () => import('./ModelCompareView')
	} as ViewDescriptor
};
