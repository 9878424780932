import { useElementSize } from '@mantine/hooks';
import { type ComponentPropsWithoutRef, useLayoutEffect } from 'react';
import { useColorBlindModeEnabled } from 'ts/base/hooks/ColorBlindModeHook';
import type { TrendChartBase, TrendZoomInfo } from 'ts/commons/charts/TrendChartBase';
import { tsdom } from 'ts/commons/tsdom';

type TrendChartComponentProps = ComponentPropsWithoutRef<'div'> & {
	trendChart: TrendChartBase;
	onZoom?: (trendZoomInfo: TrendZoomInfo) => void;
};

/** Renders a flot based trend chart component into a React component. */
export function TrendChartComponent({ trendChart, onZoom, ...divProps }: TrendChartComponentProps) {
	const { ref, width, height } = useElementSize();
	const colorBlindModeEnabled = useColorBlindModeEnabled();
	useLayoutEffect(() => {
		const container = ref.current!;
		container.appendChild(trendChart.render(width + 'px', height + 'px', colorBlindModeEnabled, onZoom));
		return () => tsdom.removeAllChildren(container);
	}, [trendChart, colorBlindModeEnabled, height, ref, width, onZoom]);
	return <div ref={ref} {...divProps} />;
}
