import clsx from 'clsx';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { JSX } from 'react';
import type { Callback } from 'ts/base/Callback';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { isRelevantEvent } from 'ts/base/soy/SoyRenderer';
import { BranchSelector } from 'ts/commons/BranchSelector';
import type { ValueAwareComponentProps } from 'ts/commons/InMenuSearchableDropdown';
import { MenuItem } from 'ts/components/Menu';

/** Props for GlobalBranchSelector. */
type GlobalBranchSelectorProps = {
	selectedBranch?: string | null;
	projectIds: string[];
	onBranchChanged?: Callback<string>;
};

/** The global branch selector, which sits in the perspective settings bar and navigates to the selected branch. */
export function GlobalBranchSelector({
	selectedBranch,
	projectIds,
	onBranchChanged
}: GlobalBranchSelectorProps): JSX.Element {
	return (
		<MenuItem className="floating-width structure-only">
			<MenuItem id="branch-slash" className="structure-only" style={{ fontSize: '20px', color: 'lightgray' }}>
				/
			</MenuItem>
			<MenuItem fitted>
				<BranchSelector
					selectorId="branch-select"
					className={clsx('floating-width', 'button', 'unobtrusive')}
					selectedBranch={selectedBranch}
					projectIds={projectIds}
					asItem={BranchLink}
					onBranchChanged={onBranchChanged}
				/>
			</MenuItem>
		</MenuItem>
	);
}

/** Props for BranchLink. */
type BranchLinkProps = ValueAwareComponentProps & Omit<JSX.IntrinsicElements['a'], 'children'>;

/** Links to the given project while keeping the current perspective and view. */
function BranchLink({ 'data-value': branchName, onClick, ...props }: BranchLinkProps): JSX.Element {
	const hash = useNavigationHash();
	const timestamp = hash.getTimestamp();
	const newCommit = new UnresolvedCommitDescriptor(timestamp, branchName);
	hash.setCommit(newCommit);
	// Make the item a link so that it can be opened in a new tab with right-click -> new tab or middle click, but do
	// prevent the browser default of opening the link with a full page reload. We handle that in the onChange handler
	// instead, because that is also triggered when using keyboard navigation and allows us to perform custom handling
	// in the dashboard edit view
	return (
		<a
			href={hash.toString()}
			onClick={event => {
				if (isRelevantEvent(event.nativeEvent)) {
					event.preventDefault();
					// @ts-ignore
					onClick(event);
				}
			}}
			{...props}
		/>
	);
}
