import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { SemanticShorthandContent } from '../Generic';
import { childrenUtils, createShorthandFactory, getComponentType, getUnhandledProps } from '../lib';

/** Props for {@link HeaderSubheader}. */
export type HeaderSubheaderProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'div'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** Shorthand for primary content. */
		content?: SemanticShorthandContent;
	}
>;

/** Headers may contain subheaders. */
export const HeaderSubheader = forwardRef(function HeaderSubheader(
	props: HeaderSubheaderProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const { children, className, content } = props;

	const classes = clsx('sub header', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
});

export const createHeaderSubheader = createShorthandFactory(HeaderSubheader, content => ({ content }));
const handledProps = ['as', 'children', 'className', 'content'];
