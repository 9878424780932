import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { EQualityPerspectiveView } from 'ts/perspectives/quality_control/EQualityPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the quality control perspective. */
export class QualityControlPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.QUALITY_CONTROL, EQualityPerspectiveView);
	}
}
