import { useCallback, useEffect, useRef, useState } from 'react';

/** Describes the current state of a promise. */
type AsyncResult<T> = {
	execute: () => Promise<void>;
	status: 'idle' | 'pending' | 'success' | 'error';
	value: T | null;
	error: Error | null;
	isPending: boolean;
};

/**
 * Hook that allows to consume a promise in a React component.
 *
 * @param asyncFunction A function that returns the promise when the asynchronous action should start to execute.
 * @param immediate Whether the async action should be executed immediately on mount of the component (Default true). If
 *   set to false you can call the execute function returned from the hook to trigger the async action at a later point
 *   in time.
 * @param throwOnError Whether the hook should throw the error during the next render to trigger the nearest error
 *   boundary (default) or whether the error should be set in the error field of the returned object.
 */
export function useAsync<T>(
	asyncFunction: () => Promise<T>,
	{ immediate = true, throwOnError = true }: { immediate?: boolean; throwOnError?: boolean } = {}
): AsyncResult<T> {
	// In case the promise should be executed immediately directly start with a pending state
	const initialState = immediate ? 'pending' : 'idle';
	const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'error'>(initialState);
	const [value, setValue] = useState<T | null>(null);
	const [error, setError] = useState(null);
	const cancelFunction = useRef<() => void | undefined>();

	// The execute function wraps asyncFunction and
	// handles setting state for pending, value, and error.
	// useCallback ensures the below useEffect is not called
	// on every render, but only if asyncFunction changes.
	const execute = useCallback(() => {
		cancelFunction.current?.();

		// While the promise is running we set the state to pending
		setStatus('pending');
		setValue(null);
		setError(null);

		let cancelled = false;
		cancelFunction.current = () => {
			cancelled = true;
		};

		return asyncFunction()
			.then(response => {
				if (!cancelled) {
					// This must be a function so that when response is a
					// function itself it is not called by setValue
					setValue(() => response);
					setStatus('success');
				}
			})
			.catch(error => {
				if (!cancelled) {
					setError(error);
					setStatus('error');
				}
			});
	}, [asyncFunction]);

	// Call execute if we want to fire it right away.
	// Otherwise execute can be called later, such as
	// in an onClick handler.
	useEffect(() => {
		if (immediate) {
			void execute();
		}
		return () => cancelFunction.current?.();
	}, [execute, immediate]);

	if (status === 'error' && throwOnError) {
		throw error;
	}

	return { execute, status, value, error, isPending: status === 'pending' };
}
