import clsx from 'clsx';
import {
	type ComponentPropsWithoutRef,
	type ElementType,
	type ForwardedRef,
	forwardRef,
	type MouseEvent,
	type ReactNode,
	useLayoutEffect,
	useRef
} from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { SemanticShorthandContent } from '../Generic';
import {
	childrenUtils,
	doesNodeContainClick,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	useMergedRefs,
	verticalAlignProp
} from '../lib';

/** Props for {@link DimmerInner}. */
export type DimmerInnerProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'div'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** An active dimmer will dim its parent container. */
		active?: boolean;

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** Shorthand for primary content. */
		content?: SemanticShorthandContent;

		/** A disabled dimmer cannot be activated */
		disabled?: boolean;

		/** Called when the dimmer is clicked. */
		onClick?: (event: MouseEvent<HTMLDivElement>, data: DimmerInnerProps) => void;

		/** Handles click outside Dimmer's content, but inside Dimmer area. */
		onClickOutside?: (event: MouseEvent<HTMLDivElement>, data: DimmerInnerProps) => void;

		/** A dimmer can be formatted to have its colors inverted. */
		inverted?: boolean;

		/** A dimmer can be formatted to be fixed to the page. */
		page?: boolean;

		/** A dimmer can be controlled with simple prop. */
		simple?: boolean;

		/** A dimmer can have its content top or bottom aligned. */
		verticalAlign?: 'bottom' | 'top';
	}
>;

/** An inner element for a Dimmer. */
export const DimmerInner = forwardRef(function DimmerInner(props: DimmerInnerProps, ref: ForwardedRef<HTMLDivElement>) {
	const { active, children, className, content, disabled, inverted, page, simple, verticalAlign } = props;

	const containerRef = useMergedRefs(ref, useRef(null));
	const contentRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (!containerRef.current?.style) {
			return;
		}

		if (active) {
			containerRef.current.style.setProperty('display', 'flex', 'important');
		} else {
			containerRef.current.style.removeProperty('display');
		}
	}, [active, containerRef]);

	const handleClick = (e: MouseEvent<HTMLDivElement>) => {
		props.onClick?.(e, props);

		if (contentRef.current !== e.target && doesNodeContainClick(contentRef.current, e.nativeEvent)) {
			return;
		}

		props.onClickOutside?.(e, props);
	};

	const classes = clsx(
		'ui',
		keyOnly(active, 'active transition visible'),
		keyOnly(disabled, 'disabled'),
		keyOnly(inverted, 'inverted'),
		keyOnly(page, 'page'),
		keyOnly(simple, 'simple'),
		verticalAlignProp(verticalAlign),
		'dimmer',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	const childrenContent = childrenUtils.isNil(children) ? content : children;

	return (
		<ElementType {...rest} className={classes} onClick={handleClick} ref={containerRef}>
			{childrenContent ? (
				<div className="content" ref={contentRef}>
					{childrenContent}
				</div>
			) : null}
		</ElementType>
	);
});
const handledProps = [
	'active',
	'as',
	'children',
	'className',
	'content',
	'disabled',
	'inverted',
	'onClick',
	'onClickOutside',
	'page',
	'simple',
	'verticalAlign'
];
