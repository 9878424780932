import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import { SanitizedJs } from 'ts-closure-library/lib/soy/data';
import { SanitizedUri } from 'ts-closure-library/lib/soy/data';
import { SanitizedCss } from 'ts-closure-library/lib/soy/data';
import { SanitizedHtml } from 'ts-closure-library/lib/soy/data';
import { SanitizedContentKind } from 'ts-closure-library/lib/soy/data';
import { SanitizedHtmlAttribute } from 'ts-closure-library/lib/soy/data';
import { SanitizedTrustedResourceUri } from 'ts-closure-library/lib/soy/data';
/**
 * @fileoverview Provides Soy runtime checks for safe types.
 */

/**
 * Checks whether a given value is of a given content kind.
 *
 * @param {?} value The value to be examined.
 * @param {!SanitizedContentKind} contentKind The desired content
 *     kind.
 * @param {!Object} constructor
 * @return {boolean} Whether the given value is of the given kind.
 * @private
 */
let isContentKind_ = function(value, contentKind, constructor) {
	// EDITED: empty-check for 'value' (can be "")
	const ret = Boolean(value && value !== '' && value.contentKind === contentKind);
	if (ret) {
		// EDITED: instanceof instead of .constructor checks
		asserts.assert(value instanceof constructor);
	}
	return ret;
};

/**
 * @param {?} value
 * @return {boolean}
 */
let isHtml = function(value) {
	return isContentKind_(value, SanitizedContentKind.HTML, SanitizedHtml);
};

/**
 * @param {?} value
 * @return {boolean}
 */
let isCss = function(value) {
	return isContentKind_(value, SanitizedContentKind.CSS, SanitizedCss);
};

/**
 * @param {?} value
 * @return {boolean}
 */
let isAttribute = function(value) {
	return isContentKind_(value, SanitizedContentKind.ATTRIBUTES, SanitizedHtmlAttribute);
};

/**
 * @param {?} value
 * @return {boolean}
 */
let isJS = function(value) {
	return isContentKind_(value, SanitizedContentKind.JS, SanitizedJs);
};

/**
 * @param {?} value
 * @return {boolean}
 */
let isTrustedResourceURI = function(value) {
	return isContentKind_(value, SanitizedContentKind.TRUSTED_RESOURCE_URI, SanitizedTrustedResourceUri);
};

/**
 * @param {?} value
 * @return {boolean}
 */
let isURI = function(value) {
	return isContentKind_(value, SanitizedContentKind.URI, SanitizedUri);
};

export { isAttribute, isCss, isHtml, isJS, isTrustedResourceURI, isURI };