import clsx from 'clsx';
import { type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import type { SemanticShorthandCollection } from '../Generic';
import { childrenUtils, createShorthandFactory, getComponentType, getUnhandledProps } from '../lib';
import { createMessageItem, type MessageItemProps } from './MessageItem';

/** Props for {@link MessageList}. */
export type MessageListProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand Message.Items. */
	items?: SemanticShorthandCollection<MessageItemProps>;
};

/** A message can contain a list of items. */
export const MessageList = forwardRef(function MessageList(
	props: MessageListProps,
	ref: ForwardedRef<HTMLUListElement>
) {
	const { children, className, items } = props;

	const classes = clsx('list', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props, { defaultAs: 'ul' });

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{childrenUtils.isNil(children) ? items?.map(item => createMessageItem(item)) : children}
		</ElementType>
	);
});

export const createMessageList = createShorthandFactory<
	typeof MessageList,
	SemanticShorthandCollection<MessageItemProps>
>(MessageList, val => ({ items: val }));
const handledProps = ['as', 'children', 'className', 'items'];
