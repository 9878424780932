import type { AsyncZippable } from 'fflate';
import * as cssom from 'ts-closure-library/lib/cssom/cssom';
import * as dom from 'ts-closure-library/lib/dom/dom';
import { ColorUtils } from 'ts/commons/ColorUtils';
import { DateUtils } from 'ts/commons/DateUtils';
import { downloadBlob } from 'ts/commons/export/BlobDownloader';
import { PerspectiveUtils } from 'ts/commons/PerspectiveUtils';
import { tsdom } from 'ts/commons/tsdom';
import { ToastNotification } from 'ts/components/Toast';
import type { DashboardEntryWithPermissions } from 'typedefs/DashboardEntryWithPermissions';

/**
 * Helper collection to export a dashboard as a zip archive including a rendered html version of the dashboard, a
 * non-interactive version of the canvas elements and all fonts and other resources needed to show the dashboard.
 */
export class DashboardExporter {
	private static readonly TOAST_ID = 'dashboard-export-progress';

	/** The number of widgets that we are currently still loading. */
	private static pendingWidgetPromises = 0;
	/** The number of widgets that needs to be loaded on the current dashboard. */
	private static totalWidgets = 0;
	private static loadingSignal?: AbortSignal;

	/**
	 * Waits until all the given preloadPromises are settled and then calls renderAll and waits for them again.
	 * Internally, this keeps track of how many widgets are still pending.
	 */
	public static loadWidgets(
		preloadPromises: Array<Promise<void>>,
		renderAll: () => Array<Promise<void>>,
		signal: AbortSignal
	) {
		this.loadingSignal = signal;
		this.pendingWidgetPromises = preloadPromises.length;
		this.totalWidgets = preloadPromises.length;
		return Promise.allSettled(preloadPromises).then(() =>
			renderAll().map(promise =>
				promise.finally(() => {
					if (!signal.aborted) {
						this.pendingWidgetPromises--;
					}
				})
			)
		);
	}

	/** Callback for exporting the dashboard as HTML. */
	public static async exportDashboardHtmlAsync(dashboardDescriptor: DashboardEntryWithPermissions): Promise<void> {
		if (!(await this.waitForWidgetsToLoad())) {
			return;
		}
		const contentElement = PerspectiveUtils.getMainElement();
		tsdom.removeNode(document.getElementById('toggle-icons'));
		this.convertCanvasToStaticImages(contentElement);
		this.disableAllLinks(contentElement);
		const css = cssom.getAllCssText();

		// We do not use SOY template here, as we want only text (not elements)
		const html =
			'<html><head>' +
			'<meta http-equiv="content-type" content="application/xhtml+xml; charset=UTF-8" />' +
			'<style>' +
			css +
			'</style></head>' +
			'<body style="overflow: auto; padding: 20px">' +
			dom.getOuterHtml(contentElement) +
			'</body></html>';
		const [{ saveToZip, strToU8 }, assets] = await Promise.all([
			import('ts/commons/ZipUtils'),
			this.loadAndStoreWebFonts(css)
		]);
		const filename = dashboardDescriptor.name + '_' + DateUtils.formatForFileName(new Date()) + '.zip';
		await saveToZip({ 'index.html': strToU8(html), ...assets }, filename);
		window.location.reload();
	}

	/**
	 * Wait for all widgets to be loaded and rendered and shows a Toast with the current progress.
	 *
	 * @returns True if the loading was awaited successfully or false if the loading was aborted i.e. user navigated
	 *   away.
	 */
	private static async waitForWidgetsToLoad(): Promise<boolean> {
		if (!this.loadingSignal || this.loadingSignal.aborted) {
			// No dashboard shown ATM
			return false;
		}
		const toastOptions = {
			id: this.TOAST_ID,
			loading: true,
			color: ColorUtils.TEAMSCALE_GREEN,
			withCloseButton: false
		};
		this.loadingSignal.addEventListener('abort', () => ToastNotification.hide(this.TOAST_ID));
		if (this.pendingWidgetPromises > 0) {
			ToastNotification.info(
				`Waiting for ${this.pendingWidgetPromises} out of ${this.totalWidgets} widgets to load for the export...`,
				toastOptions
			);
			while (this.pendingWidgetPromises > 0) {
				await this.sleep(100);
				// false-positive, the signal can be aborted while we give the control back to the event loop.
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				if (this.loadingSignal.aborted) {
					return false;
				}
				ToastNotification.updateInfo(
					this.TOAST_ID,
					`Waiting for ${this.pendingWidgetPromises} out of ${this.totalWidgets} widgets to load for the export...`,
					toastOptions
				);
			}
		}
		return true;
	}

	private static sleep(millis: number) {
		return new Promise(resolve => {
			setTimeout(resolve, millis);
		});
	}

	/** Converts all canvas elements to static images. */
	private static convertCanvasToStaticImages(contentElement: Element): void {
		const canvasElements = contentElement.querySelectorAll('canvas');

		// Use reverse iteration, as canvasElements can be a "live" array in some
		// browsers
		for (let i = canvasElements.length - 1; i >= 0; --i) {
			const image = document.createElement('img');
			image.src = canvasElements[i]!.toDataURL('image/png');
			image.style.width = '100%';
			image.style.height = '100%';
			canvasElements[i]!.parentElement!.style.overflow = 'hidden';
			dom.replaceNode(image, canvasElements[i]!);
		}
	}

	/** Disables all links. */
	private static disableAllLinks(contentElement: Element): void {
		const linkElements = contentElement.querySelectorAll('a');

		// Use reverse iteration, as linkElements can be a "live" array in some
		// browsers
		for (let i = linkElements.length - 1; i >= 0; --i) {
			const span = dom.createElement('span');
			linkElements[i]!.childNodes.forEach(linkChild => span.appendChild(linkChild));
			dom.replaceNode(span, linkElements[i]!);
		}
	}

	/** Loads all web fonts found in the css and stores them in the ZIP. */
	private static async loadAndStoreWebFonts(css: string): Promise<AsyncZippable> {
		let match;
		const fontRegex = /"\.\/([-a-zA-Z0-9.]+\.woff2)/g;
		const fontFiles = [];
		while ((match = fontRegex.exec(css))) {
			const file = match[1]!;
			fontFiles.push(file);
		}
		const entries = await Promise.all(
			fontFiles.map(async file => {
				const blob = await downloadBlob(`assets/${file}`);
				const arrayBuffer = await blob.arrayBuffer();
				return { [file]: new Uint8Array(arrayBuffer) };
			})
		);
		return Object.assign({} as AsyncZippable, ...entries);
	}
}
