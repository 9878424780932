import type { NavigationHash } from 'ts/commons/NavigationHash';
import { StringUtils } from 'ts/commons/StringUtils';
import { UIUtils } from 'ts/commons/UIUtils';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';

/**
 * Selection of utility methods to determine the right project to show.
 *
 * We use a three stage mechanism here. If a project is set in the URL this one is always chosen. We don't care whether
 * the project exists or not, because we want to show a corresponding error in case it does not. If there is no project
 * in the URL we check the session storage for a valid project and return that. If there is no valid project in the
 * session storage we fall back to the project in the local storage. If that one also does not exist we either fall back
 * to null (All projects) on the dashboard perspective and #getDefaultProject on all other perspectives. We use the
 * session and the local storage to support scenarios where the user has multiple tabs open at the same time, and we
 * want to track the selected project for each tab separately.
 */
export class ProjectResolver {
	/** The key for the local storage which contains the name of the project for the current browser session. */
	public static readonly CURRENT_PROJECT_KEY = 'current-project';
	/** The list of projects */
	private readonly projects: string[];

	/** List of projects in initial analysis. */
	private readonly initialProjects: string[];

	public constructor(private readonly context: ExtendedPerspectiveContext) {
		this.projects = this.context.projectsInfo.projects;
		this.initialProjects = this.context.projectsInfo.initialProjects;
	}

	/** Return project for base perspective. */
	public resolveProjectFromHashOrLocalStorage(hash: NavigationHash): string | null {
		// Try loading the project from the navigation hash.
		const projectId: string | null = hash.getProject();

		if (!projectId) {
			const projectFromStorage = this.getProjectFromSessionOrLocalStorage();
			return projectFromStorage ?? this.getDefaultProject();
		}

		return projectId;
	}

	/** Return project for dashboard perspective. */
	public getProjectForDashboardOrReportsPerspective(hash: NavigationHash): string | null {
		const dashboardName = hash.getId();
		let projectId: string | null = hash.getProject();

		if (StringUtils.isEmptyOrWhitespace(projectId) && StringUtils.isEmptyOrWhitespace(dashboardName)) {
			return this.getProjectFromSessionOrLocalStorage();
		}
		if (StringUtils.isEmptyOrWhitespace(projectId)) {
			projectId = null;
		}

		return projectId;
	}

	/**
	 * Determines the default project.
	 *
	 * @returns The project or null if no project exist.
	 */
	private getDefaultProject(): string | null {
		if (this.projects.length > 0) {
			return this.projects[0]!;
		} else if (this.initialProjects.length > 0) {
			return this.initialProjects[0]!;
		}
		return null;
	}

	/**
	 * Gets the id of the current project from the browser session storage or the local storage.
	 *
	 * @returns The project id of the current project.
	 */
	public getProjectFromSessionOrLocalStorage(): string | null {
		const sessionStorage = UIUtils.getSessionStorage();
		const sessionProjectId = sessionStorage.get(ProjectResolver.CURRENT_PROJECT_KEY) as string | null;
		if (!this.context.projectExists(sessionProjectId)) {
			// If the project from session-storage is invalid, remove it from session-storage
			sessionStorage.remove(ProjectResolver.CURRENT_PROJECT_KEY);
		} else {
			return sessionProjectId;
		}

		const localStorage = UIUtils.getLocalStorage();
		const localProjectId = localStorage.get(ProjectResolver.CURRENT_PROJECT_KEY) as string | null;
		if (!this.context.projectExists(localProjectId)) {
			// If the project from local-storage is invalid, remove it from local-storage
			localStorage.remove(ProjectResolver.CURRENT_PROJECT_KEY);
		} else {
			return localProjectId;
		}
		return null;
	}

	/**
	 * Stores the project id in the current-project field in the browsers local storage. If the projectId is null the
	 * current project name will be removed.
	 *
	 * @param projectId The id of the project to store.
	 */
	public static setCurrentProject(projectId: string | null): void {
		const sessionStorage = UIUtils.getSessionStorage();
		const localStorage = UIUtils.getLocalStorage();
		if (projectId) {
			sessionStorage.set(ProjectResolver.CURRENT_PROJECT_KEY, projectId);
			localStorage.set(ProjectResolver.CURRENT_PROJECT_KEY, projectId);
		} else {
			sessionStorage.remove(ProjectResolver.CURRENT_PROJECT_KEY);
			localStorage.remove(ProjectResolver.CURRENT_PROJECT_KEY);
		}
	}
}
