import type { ServiceCallError } from 'api/ServiceCallError';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as WidgetParameterTemplate from 'soy/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy.generated';
import * as dom from 'ts-closure-library/lib/dom/dom';
import { Event } from 'ts-closure-library/lib/events/event';
import { EventTarget } from 'ts-closure-library/lib/events/eventhandler';
import * as soy from 'ts/base/soy/SoyRenderer';
import { MarkdownUtils } from 'ts/commons/markdown/MarkdownUtils';
import { tsdom } from 'ts/commons/tsdom';

/**
 * Base class for widgets parameters. A parameter has a name and knows how to display a control for editing the current
 * value.
 */
export abstract class WidgetParameterBase<T = unknown> {
	/** The value for defining the absence of errors. */
	public static NO_ERROR = '';

	/** Event that is called when a parameter changed its value. */
	public static PARAMETER_CHANGED_EVENT = 'PARAMETER_CHANGE';

	/** Maximum substring length of error displayed. */
	public static ERROR_SUBSTRING_LENGTH = 100;

	/** The container of this parameter. */
	protected container: Element | null = null;

	/** The description of an error in this parameter. */
	private errorMessage: string;

	/** Whether the parameter includes a formatting help link or not. */
	public formattingHelpLink: boolean | undefined;

	/** Used for being able to emit events if the parameter's value changes. */
	public eventTarget: EventTarget;

	/**
	 * @param name The name of the parameter.
	 * @param description The description of the parameter.
	 * @param formattingHelpLink Indicates whether the parameter will include a formatting help link or not.
	 */
	protected constructor(
		public name: string,
		public description: string,
		formattingHelpLink?: boolean
	) {
		this.errorMessage = WidgetParameterBase.NO_ERROR;
		this.formattingHelpLink = formattingHelpLink;
		this.eventTarget = new EventTarget();
	}

	public getEventTarget(): EventTarget {
		return this.eventTarget;
	}

	/** @param container The new container of this parameter. */
	public setContainer(container: Element): void {
		this.container = container;
	}

	/** @returns The container of this parameter. */
	public getContainer(): Element | null {
		return this.container;
	}

	/**
	 * Shows an error message above the parameter input area for the given HTTP error.
	 *
	 * @param status The status number of the error.
	 * @param statusText The status text of the error.
	 * @param technicalErrorDescription The technical description of the error.
	 */
	protected errorCallback(error: ServiceCallError): void {
		const summaryError = error.errorSummary;
		let truncatedError = summaryError.substring(0, WidgetParameterBase.ERROR_SUBSTRING_LENGTH);
		if (summaryError.length > WidgetParameterBase.ERROR_SUBSTRING_LENGTH) {
			truncatedError += '...';
		}
		this.setError(truncatedError + ' See System > Javascript logs for more details & preceding errors.');
		this.displayErrorMessage();
	}

	/** Returns the name. */
	public getName(): string {
		return this.name;
	}

	/** Returns the description. */
	public getDescription(): string {
		return this.description;
	}

	/**
	 * Renders an input element for this parameter as HTML.
	 *
	 * @param value The current value.
	 * @param commit The commit selected in the time travel of the dashboard perspective
	 */

	// Used in subclasses
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public renderInput(value: T, commit: UnresolvedCommitDescriptor | null): void {
		const element = soy.renderAsElement(WidgetParameterTemplate.inputText, { currentValue: value });
		this.container!.appendChild(element);
	}

	/** Performs a refresh of data loaded needed for this widget parameter. */

	// Used in subclasses
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public refresh(commit: UnresolvedCommitDescriptor | null): void {
		// Do nothing by default.
	}

	/** Extracts the value from the input element. */
	public abstract extractValue(): T;

	/** Clears the error message. */
	public clearError(): void {
		this.errorMessage = WidgetParameterBase.NO_ERROR;
	}

	/**
	 * Sets the error message.
	 *
	 * @param errorMessage The new error message.
	 */
	public setError(errorMessage: string): void {
		this.errorMessage = errorMessage;
	}

	/** @returns Whether there is an error message set. */
	public hasError(): boolean {
		return this.errorMessage !== WidgetParameterBase.NO_ERROR;
	}

	/**
	 * Gets executed when the widget's dialog gets prepared. This template method should be overwritten by subclasses
	 * that want to react on the dialog's preparation.
	 */
	public onDialogPreparation(): void {
		// Default implementation does nothing
	}

	/**
	 * Gets executed when the widget's dialog gets disposed (it does not matter whether by clicking on OK or CANCEL).
	 * Gets executed when the widget's dialog gets prepared. This template method should be overwritten by subclasses
	 * that want to react on the dialog's disposal.
	 *
	 * @returns Whether the widget should attempt to rerender. Should be false when the handler triggered a navigate
	 *   event.
	 */
	public onDialogDispose(): boolean {
		// Default implementation does nothing
		return true;
	}

	/** Attaches the formatting help link to the parameter, if it includes one. */
	public attachFormattingHelpLink(): void {
		if (this.formattingHelpLink == null || !this.formattingHelpLink) {
			return;
		}
		const link = soy.renderAsElement(WidgetParameterTemplate.markupFormattingHelpLink);
		MarkdownUtils.attachSyntaxAdvancedTooltip(link);
		const label = dom.getElementByClass('parameter-name', this.container!.parentElement)!;
		label.appendChild(link);
	}

	/** Emits the PARAMETER_CHANGED_EVENT, indicating that a parameter has changed its value. */
	protected emitParameterChangeEvent(): void {
		this.eventTarget.dispatchEvent(new Event(WidgetParameterBase.PARAMETER_CHANGED_EVENT, this));
	}

	/** Returns the value of the text input field. Can be called from inheriting classes. */
	protected getInputFieldValue(): string {
		return (dom.getElementByClass('text-input', this.container) as HTMLInputElement).value;
	}

	/** Returns the integer index of this parameter in the widget edit dialog box. */
	private getParameterIndex(): number {
		const parentTableRowContainer = this.getContainer()!.parentElement!;
		const parameterIndex = (parentTableRowContainer.getAttribute('id') ?? '').split('-')[1]!;
		return parseInt(parameterIndex);
	}

	/** Removes error message and markers for this parameter. */
	public removeErrorMessage(): void {
		this.displayOrHideErrorMessage(this.getParameterIndex(), '', false);
	}

	/**
	 * Highlights an invalid parameter and displays its error message. If index of parameter is not provided, its
	 * determined internally.
	 *
	 * @param optIndex The index of the parameter
	 */
	public displayErrorMessage(optIndex?: number): void {
		const index = optIndex ?? this.getParameterIndex();
		this.displayOrHideErrorMessage(index, this.errorMessage, true);
	}

	/**
	 * Controls visibility of parameter error.
	 *
	 * @param index The index of the parameter
	 * @param display True to display, false to hide.
	 */
	private displayOrHideErrorMessage(index: number, errorText: string, display: boolean): void {
		// The container may not be attached to the document yet, so use its root node
		const root = this.container!.getRootNode() as Element | Document;
		const errorMarkerText = root.querySelector('#parameter-error-text-' + index)!;
		errorMarkerText.innerHTML = errorText;
		const parameterElement = root.querySelector('#parameter-' + index)!;
		const errorClasses = ['error', 'message'];
		if (display) {
			parameterElement.classList.add(...errorClasses);
		} else {
			parameterElement.classList.remove(...errorClasses);
		}
		tsdom.setElementShown(root.querySelector('#parameter-error-' + index)!, display);
	}
}
