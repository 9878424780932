import clsx from 'clsx';
import {
	type ComponentPropsWithoutRef,
	type ElementType,
	type ForwardedRef,
	forwardRef,
	type Key,
	type MouseEvent,
	type ReactNode
} from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { SemanticShorthandContent } from '../Generic';
import { createHTMLImage, getComponentType, getUnhandledProps, keyOnly } from '../lib';

/** Props for {@link SearchResult}. */
export type SearchResultProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'div'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** The item currently selected by keyboard shortcut. */
		active?: boolean;

		/** Additional classes. */
		className?: string;

		/** Shorthand for primary content. */
		content?: SemanticShorthandContent;

		/** Additional text with less emphasis. */
		description?: string;

		/** A unique identifier. */
		id?: number | string;

		/** Add an image to the item. */
		image?: string;

		/** Called on click. */
		onClick?: (event: MouseEvent<HTMLDivElement>, data: SearchResultProps) => void;

		/** Customized text for price. */
		price?: string;

		/**
		 * Renders the result contents.
		 *
		 * @param props - The SearchResult props object.
		 * @returns - Renderable result contents.
		 */
		renderer?: (props: SearchResultProps) => ReactNode;

		/** Display title. */
		title: string;
		childKey?: Key;
	}
>;

// Note: You technically only need the 'content' wrapper when there's an
// image. However, optionally wrapping it makes this function a lot more
// complicated and harder to read. Since always wrapping it doesn't affect
// the style in any way let's just do that.
//
// Note: To avoid requiring a wrapping div, we return an array here so to
// prevent rendering issues each node needs a unique key.
const defaultRenderer = ({ image, price, title, description }: SearchResultProps) => [
	image && (
		<div key="image" className="image">
			{createHTMLImage(image, { autoGenerateKey: false })}
		</div>
	),
	<div key="content" className="content">
		{price ? <div className="price">{price}</div> : null}
		{title ? <div className="title">{title}</div> : null}
		{description ? <div className="description">{description}</div> : null}
	</div>
];

export const SearchResult = forwardRef(function SearchResult(
	props: SearchResultProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const { active, className, renderer = defaultRenderer } = props;

	const handleClick = (e: MouseEvent<HTMLDivElement>) => {
		props.onClick?.(e, props);
	};

	const classes = clsx(keyOnly(active, 'active'), 'result', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	// Note: You technically only need the 'content' wrapper when there's an
	// image. However, optionally wrapping it makes this function a lot more
	// complicated and harder to read. Since always wrapping it doesn't affect
	// the style in any way let's just do that.
	return (
		<ElementType {...rest} className={classes} onClick={handleClick} ref={ref}>
			{renderer(props)}
		</ElementType>
	);
});
const handledProps = [
	'active',
	'as',
	'className',
	'content',
	'description',
	'id',
	'image',
	'onClick',
	'price',
	'renderer',
	'title'
];
