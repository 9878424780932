import * as WidgetParameterTemplate from 'soy/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy.generated';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import * as soy from 'ts/base/soy/SoyRenderer';
import { tsdom } from 'ts/commons/tsdom';
import { WidgetParameterBase } from './WidgetParameterBase';

/** Base class for parameters that have an uneditable input that is filled via a dialog. */
export abstract class DialogParameterBase<T> extends WidgetParameterBase<T> {
	/**
	 * @param name The name of the parameter.
	 * @param description The description of the parameter.
	 */
	protected constructor(name: string, description: string) {
		super(name, description);
	}

	/** Renders an input element for this parameter as HTML. */
	protected renderStringInput(value: string): void {
		this.getRenderedStringInput(value);
	}

	/** @returns The rendered input element for this parameters as HTML. */
	protected getRenderedElement(value: string): HTMLElement {
		const template = WidgetParameterTemplate.inputDialog;
		return soy.renderAsElement(template, { currentValue: value });
	}

	/**
	 * @returns The rendered input element for this parameter as HTML, with added click-listener to show this
	 *   parameter's dialog.
	 */
	protected getRenderedStringInput(value: string): HTMLElement {
		const element = this.getRenderedElement(value);
		this.container!.appendChild(element);
		const button = element.querySelectorAll('button')[0]!;
		events.listen(button, EventType.CLICK, () => this.showDialog());
		return element;
	}

	/** @returns The input element that holds the value selected by the dialog. */
	protected getValueInput(): HTMLInputElement {
		return tsdom.getElementByClass('value-marker', this.container) as HTMLInputElement;
	}

	/** @returns The currently set value. */
	protected getValue(): string {
		return this.getValueInput().value;
	}

	/** @param value The value to set on the input field. */
	protected setStringValue(value: string): void {
		this.getValueInput().value = value;
	}

	/** Shows the dialog to select a new project and path. */
	public abstract showDialog(): void;
}
