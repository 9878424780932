/** Generated from ETeamscalePerspective.java. Do not modify this file! */


export type ETeamscalePerspectiveEntry = 'DASHBOARD' | 'ACTIVITY' | 'FINDINGS' | 'METRICS' | 'REQUIREMENTS_TRACING' | 'TEST_GAPS' | 'QUALITY_CONTROL' | 'AUDIT' | 'COMPARE' | 'MODEL_COMPARE' | 'ARCHITECTURE' | 'DELTA' | 'PROJECTS' | 'SYSTEM' | 'ADMIN' | 'SEARCH' | 'USER' | 'API_DOCUMENTATION' | 'CHECKS' | 'SNIPPET_ANALYSIS';

export class ETeamscalePerspective {
	static readonly DASHBOARD = new ETeamscalePerspective(0, 'DASHBOARD', "Dashboard", "dashboard", true, "image outline");
	static readonly ACTIVITY = new ETeamscalePerspective(1, 'ACTIVITY', "Activity", "activity", true, "code branch");
	static readonly FINDINGS = new ETeamscalePerspective(2, 'FINDINGS', "Findings", "findings", true, "exclamation triangle");
	static readonly METRICS = new ETeamscalePerspective(3, 'METRICS', "Metrics", "metrics", true, "percent");
	static readonly REQUIREMENTS_TRACING = new ETeamscalePerspective(4, 'REQUIREMENTS_TRACING', "Requirements Tracing", "requirements-tracing", true, "exchange");
	static readonly TEST_GAPS = new ETeamscalePerspective(5, 'TEST_GAPS', "Test Gaps", "testgaps", true, "object group outline");
	static readonly QUALITY_CONTROL = new ETeamscalePerspective(6, 'QUALITY_CONTROL', "Quality Control", "qualitycontrol", true, "check circle outline");
	static readonly AUDIT = new ETeamscalePerspective(7, 'AUDIT', "Audit", "audit", true, "eye");
	static readonly COMPARE = new ETeamscalePerspective(8, 'COMPARE', "Compare", "compare", false, "");
	static readonly MODEL_COMPARE = new ETeamscalePerspective(9, 'MODEL_COMPARE', "Model Compare", "model-compare", false, "");
	static readonly ARCHITECTURE = new ETeamscalePerspective(10, 'ARCHITECTURE', "Architecture", "architecture", true, "sitemap");
	static readonly DELTA = new ETeamscalePerspective(11, 'DELTA', "Delta", "delta", true, "chart line");
	static readonly PROJECTS = new ETeamscalePerspective(12, 'PROJECTS', "Project Configuration", "project", true, "sun outline");
	static readonly SYSTEM = new ETeamscalePerspective(13, 'SYSTEM', "System", "system", true, "hdd outline");
	static readonly ADMIN = new ETeamscalePerspective(14, 'ADMIN', "Admin", "admin", true, "hashtag");
	static readonly SEARCH = new ETeamscalePerspective(15, 'SEARCH', "Search", "search", false, "");
	static readonly USER = new ETeamscalePerspective(16, 'USER', "Users", "user", false, "");
	static readonly API_DOCUMENTATION = new ETeamscalePerspective(17, 'API_DOCUMENTATION', "API Documentation", "api", false, "");
	static readonly CHECKS = new ETeamscalePerspective(18, 'CHECKS', "Checks", "checks", false, "");
	static readonly SNIPPET_ANALYSIS = new ETeamscalePerspective(19, 'SNIPPET_ANALYSIS', "Snippet Analysis", "snippet_analysis", false, "");
	static readonly values = [
		ETeamscalePerspective.DASHBOARD,
		ETeamscalePerspective.ACTIVITY,
		ETeamscalePerspective.FINDINGS,
		ETeamscalePerspective.METRICS,
		ETeamscalePerspective.REQUIREMENTS_TRACING,
		ETeamscalePerspective.TEST_GAPS,
		ETeamscalePerspective.QUALITY_CONTROL,
		ETeamscalePerspective.AUDIT,
		ETeamscalePerspective.COMPARE,
		ETeamscalePerspective.MODEL_COMPARE,
		ETeamscalePerspective.ARCHITECTURE,
		ETeamscalePerspective.DELTA,
		ETeamscalePerspective.PROJECTS,
		ETeamscalePerspective.SYSTEM,
		ETeamscalePerspective.ADMIN,
		ETeamscalePerspective.SEARCH,
		ETeamscalePerspective.USER,
		ETeamscalePerspective.API_DOCUMENTATION,
		ETeamscalePerspective.CHECKS,
		ETeamscalePerspective.SNIPPET_ANALYSIS
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: ETeamscalePerspectiveEntry,
		public readonly displayName: string,
		public readonly simpleName: string,
		public readonly visible: boolean,
		public readonly iconClass: string
	){
	}

	toString() {
		return this.name;
	}
}

