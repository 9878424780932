import { type JSX, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { StringUtils } from 'ts/commons/StringUtils';
import { Button } from 'ts/components/Button';
import { Form, FormInput, FormTextArea } from 'ts/components/Form';
import { Message } from 'ts/components/Message';
import { Modal, ModalActionButtons } from 'ts/components/Modal';
import { EMPTY_DASHBOARD_NAME_ERROR_MESSAGE, ModalMode } from 'ts/perspectives/dashboard/sidebar/Constants';
import type { SaveModalFormValues } from 'ts/perspectives/dashboard/sidebar/Hooks';
import { useSaveModalForm } from 'ts/perspectives/dashboard/sidebar/Hooks';
import type { DashboardDescriptor } from 'typedefs/DashboardDescriptor';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';

type DashboardSaveModalProps = {
	/** Specify the mode of the modal */
	modalMode: ModalMode;
	/** Dashboard information */
	dashboardDescriptor: DashboardDescriptor | DashboardTemplateDescriptor;
	/** Function that will be called after successfully saving the dashboard */
	onSuccess: (formValues: SaveModalFormValues) => Promise<void>;
	/** Function that will be called after closing the modal */
	onClose: () => void;
};

/** A dialog for saving a dashboard. Can also be used to copy or rename a dashboard. */
export function DashboardSaveModal({
	modalMode,
	dashboardDescriptor,
	onSuccess,
	onClose
}: DashboardSaveModalProps): JSX.Element {
	const { register, handleSubmit, setValue, control } = useSaveModalForm(modalMode, dashboardDescriptor);
	const [errorMessages, setErrorMessages] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const commentValue = useWatch({ control, name: 'comment' });

	const onFormSubmit = async (formValues: SaveModalFormValues) => {
		setIsLoading(true);
		if (StringUtils.isEmptyOrWhitespace(formValues.name)) {
			setErrorMessages(EMPTY_DASHBOARD_NAME_ERROR_MESSAGE);
			setIsLoading(false);
			return;
		}

		await onSuccess(formValues);

		onClose();
		setIsLoading(false);
	};

	const isDashboardNameInputDisabled = modalMode === ModalMode.Save;
	const isDashboardGroupInputDisabled = modalMode === ModalMode.Save;
	const isTemplateMode = modalMode === ModalMode.SaveTemplate || modalMode === ModalMode.EditTemplate;

	return (
		<Modal opened onClose={onClose} title={getModalTitle(modalMode)}>
			<Message error hidden={errorMessages.length === 0} content={errorMessages} />
			<Form data-testid="dashboard-form" onSubmit={handleSubmit(onFormSubmit)}>
				<FormInput
					label="Dashboard Name"
					input={register('name')}
					placeholder="Enter name"
					disabled={isDashboardNameInputDisabled}
					data-testid="dashboard-name"
				/>
				{isTemplateMode ? null : (
					<FormInput
						label="Dashboard Group"
						input={register('group')}
						placeholder="Enter group (Optional)"
						disabled={isDashboardGroupInputDisabled}
						data-testid="dashboard-group"
					/>
				)}
				<FormTextArea
					label="Description"
					placeholder="Describe the content or the changes"
					value={commentValue}
					onChange={(event, data) => setValue('comment', data.value as string)}
					data-testid="dashboard-description"
				/>
				<ModalActionButtons grow>
					<Button
						type="submit"
						loading={isLoading}
						disabled={isLoading}
						primary
						content="Save"
						data-testid="dashboard-save"
					/>
					<Button content="Cancel" onClick={onClose} />
				</ModalActionButtons>
			</Form>
		</Modal>
	);
}

function getModalTitle(modalMode: ModalMode): string {
	switch (modalMode) {
		case ModalMode.Save:
			return 'Save Dashboard';
		case ModalMode.SaveAs:
			return 'Save Dashboard As';
		case ModalMode.Rename:
			return 'Rename the Dashboard';
		case ModalMode.SaveTemplate:
			return 'Save as Template';
		case ModalMode.EditTemplate:
			return 'Edit Template';
		case ModalMode.Copy:
			return 'Save a Copy';
		default:
			return '';
	}
}
