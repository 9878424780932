import { useThrottle } from '@react-hook/throttle';
import { useCallback, useState } from 'react';
import type { Callback } from 'ts/base/Callback';

/**
 * <p>Can be used to 'split' a state so that there is a value that changes instantly, and a throttled value that only
 * changes X times per second.</p>
 *
 * Sample use case: There is an input field whose change triggers an expensive operation (e.g. a server call). We want
 * to update the input field as the user types (so we use the state), but we will use the throttled state for the
 * expensive operation.
 *
 * @param fps How many times per second the state is updated at most
 * @param leading Whether to call setState on the leading edge (right away). When false, setState will not be called
 *   until the next frame is due
 */
export function useThrottledState<S>(
	initialState: S,
	fps?: number,
	leading?: boolean
): { state: S; throttledState: S; setState: Callback<S> } {
	const [state, setLiveState] = useState(initialState);
	const [throttledState, setThrottledState] = useThrottle(initialState, fps, leading);
	const setState = useCallback(
		(state: S) => {
			setLiveState(state);
			setThrottledState(state);
		},
		[setThrottledState]
	);
	return { state, throttledState, setState };
}
