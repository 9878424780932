import { MantineProvider } from '@mantine/core';
import { type QueryClient, QueryClientProvider, useSuspenseQuery } from '@tanstack/react-query';
import { QUERY_CLIENT } from 'api/QueryClient';
import type { ReactNode } from 'react';
import { type JSX } from 'react';
import type { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import { PerspectiveContextContext } from 'ts/base/context/PerspectiveContextContext';
import { ProjectInfosContext } from 'ts/base/context/ProjectInfosContext';
import { TeamscaleInfoContext } from 'ts/base/context/TeamscaleInfoContext';
import { UserInfoContext } from 'ts/base/context/UserInfoContext';
import { StaticLocationContextProvider } from 'ts/base/hooks/UseLocation';
import { MANTINE_THEME } from 'ts/base/MantineTheme';
import { EXTENDED_PERSPECTIVE_CONTEXT_QUERY } from 'ts/base/services/PerspectiveContext';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';

/** The allowed extra properties to be set on the wrapper element into which the React component is embedded. */
export type WrapperStyles = { className?: string; style?: Partial<CSSStyleDeclaration> };

/** Utilities for integrating React into Teamscale views. */
export class ReactUtils {
	/** The query client used */
	public static queryClient = QUERY_CLIENT;

	/**
	 * Renders the given react component into the container element. You must also make sure to call ReactUtils.unmount
	 * before destroying the DOM Node. In a typical view you would do this in the dispose() lifecycle method. The
	 * perspective context is made available inside the component via the following hooks:
	 *
	 * - UsePerspectiveContext
	 * - UseUserInfo
	 * - UseUserPermissionInfo
	 * - UseProjectInfos
	 * - UseTeamscaleInfo
	 */
	public static render(component: ReactNode, container: Element): Root {
		const root = createRoot(container);
		ReactUtils.replace(component, root);
		return root;
	}

	/**
	 * Unmounts the React component from the given React root. This needs to be called to free the resources allocated
	 * for rendering the component. In a typical view you would do this in the dispose() lifecycle method.
	 */
	public static unmount(root: Root | undefined | null): void {
		setTimeout(() => root?.unmount());
	}

	/** Replaces the currently rendered content with the new component. */
	public static replace(component: ReactNode, root: Root): void {
		root.render(
			<StaticLocationContextProvider>
				<BaseProviders>
					<PerspectiveContextProviders>{component}</PerspectiveContextProviders>
				</BaseProviders>
			</StaticLocationContextProvider>
		);
	}

	/**
	 * Convenience method that creates a separate child in the given container into which the given component is
	 * rendered.
	 *
	 * @returns The auto-created wrapper element
	 */
	public static append(
		component: ReactNode,
		container: Element | DocumentFragment,
		wrapperStyles?: WrapperStyles
	): Root {
		const root = ReactUtils.appendRoot(container, wrapperStyles);
		ReactUtils.replace(component, root);
		return root;
	}

	/**
	 * Convenience method that creates a separate child in the given container as first child into which the given
	 * component is rendered.
	 *
	 * @returns The auto-created wrapper element
	 */
	public static prepend(
		component: ReactNode,
		container: Element | DocumentFragment,
		wrapperStyles?: WrapperStyles
	): Root {
		const wrapper = ReactUtils.createWrapperElement(wrapperStyles);
		container.prepend(wrapper);
		return ReactUtils.render(component, wrapper);
	}

	/**
	 * Appends a new DOM element that will serve as a React root element and can be filled with #replace or
	 * #replaceStatic later on.
	 */
	public static appendRoot(container: Element | DocumentFragment, wrapperStyles?: WrapperStyles): Root {
		const wrapper = ReactUtils.createWrapperElement(wrapperStyles);
		container.appendChild(wrapper);
		return createRoot(wrapper);
	}

	private static createWrapperElement(wrapperStyles?: WrapperStyles) {
		const wrapper: HTMLDivElement = document.createElement('div');
		wrapper.style.display = 'contents';
		if (wrapperStyles?.className) {
			wrapper.className = wrapperStyles.className;
		}
		if (wrapperStyles?.style) {
			Object.assign(wrapper.style, wrapperStyles.style);
		}
		return wrapper;
	}
}

/** Props for BaseContextProviders. */
type BaseContextProvidersProps = { perspectiveContext: ExtendedPerspectiveContext; children: ReactNode };

/** Provides the basic perspective context for React components in Teamscale. */
export function StaticPerspectiveContextProviders({
	perspectiveContext,
	children
}: BaseContextProvidersProps): JSX.Element {
	return (
		<PerspectiveContextContext.Provider value={perspectiveContext}>
			<UserInfoContext.Provider value={perspectiveContext.userInfo}>
				<ProjectInfosContext.Provider value={perspectiveContext.projectsInfo}>
					<TeamscaleInfoContext.Provider value={perspectiveContext.teamscaleInfo}>
						{children}
					</TeamscaleInfoContext.Provider>
				</ProjectInfosContext.Provider>
			</UserInfoContext.Provider>
		</PerspectiveContextContext.Provider>
	);
}

/** Provides the basic perspective context for React components in Teamscale. */
export function PerspectiveContextProviders({ children }: { children: ReactNode }): JSX.Element {
	const perspectiveContext = useSuspenseQuery({ ...EXTENDED_PERSPECTIVE_CONTEXT_QUERY, refetchOnMount: false }).data;
	return (
		<StaticPerspectiveContextProviders perspectiveContext={perspectiveContext}>
			{children}
		</StaticPerspectiveContextProviders>
	);
}

/** Props for BaseProviders. */
type BaseProvidersProps = { children: ReactNode; client?: QueryClient };

/** Provides suspense handling, client and error boundary for React components in Teamscale. */
export function BaseProviders({ children, client }: BaseProvidersProps): JSX.Element {
	return (
		<MantineProvider theme={MANTINE_THEME}>
			<QueryClientProvider client={client ?? ReactUtils.queryClient}>
				<SuspendingErrorBoundary>{children}</SuspendingErrorBoundary>
			</QueryClientProvider>
		</MantineProvider>
	);
}
