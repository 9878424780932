import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { EFindingsPerspectiveView } from 'ts/perspectives/findings/EFindingsPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the findings perspective */
export class FindingsPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.FINDINGS, EFindingsPerspectiveView);
	}
}
