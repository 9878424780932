export const htmlInputAttrs = [
	// REACT
	'selected',
	'defaultValue',
	'defaultChecked',

	// LIMITED HTML PROPS
	'accept',
	'autoCapitalize',
	'autoComplete',
	'autoCorrect',
	'autoFocus',
	'checked',
	'disabled',
	'enterKeyHint',
	'form',
	'id',
	'inputMode',
	'lang',
	'list',
	'max',
	'maxLength',
	'min',
	'minLength',
	'multiple',
	'name',
	'pattern',
	'placeholder',
	'readOnly',
	'required',
	'step',
	'title',
	'type',
	'value'
];

const htmlInputEvents = [
	// EVENTS
	// keyboard
	'onKeyDown',
	'onKeyPress',
	'onKeyUp',

	// focus
	'onFocus',
	'onBlur',

	// form
	'onChange',
	'onInput',

	// mouse
	'onClick',
	'onContextMenu',
	'onDrag',
	'onDragEnd',
	'onDragEnter',
	'onDragExit',
	'onDragLeave',
	'onDragOver',
	'onDragStart',
	'onDrop',
	'onMouseDown',
	'onMouseEnter',
	'onMouseLeave',
	'onMouseMove',
	'onMouseOut',
	'onMouseOver',
	'onMouseUp',

	// selection
	'onSelect',

	// touch
	'onTouchCancel',
	'onTouchEnd',
	'onTouchMove',
	'onTouchStart'
];

const htmlInputProps = [...htmlInputAttrs, ...htmlInputEvents];

export const htmlImageProps = ['alt', 'height', 'src', 'srcSet', 'width', 'loading'];

type PartitionHTMLPropsOptions = {
	/** An array of html input props */
	htmlProps?: string[];
	/** Includes all input props that starts with "aria-" */
	includeAria?: boolean;
};

/**
 * Returns an array of objects consisting of: props of html input element and rest.
 *
 * @param props A ReactElement props object
 * @param [options={}] Default is `{}`
 * @returns An array of objects
 */
export function partitionHTMLProps(
	props: Record<string, unknown>,
	options: PartitionHTMLPropsOptions = {}
): [inputProps: Record<string, unknown>, rest: Record<string, unknown>] {
	const { htmlProps = htmlInputProps, includeAria = true } = options;
	const inputProps: Record<string, unknown> = {};
	const rest: Record<string, unknown> = {};

	Object.entries(props).forEach(([prop, val]) => {
		const possibleAria = includeAria && (/^aria-.*$/.test(prop) || prop === 'role');
		const target = htmlProps.includes(prop) || possibleAria ? inputProps : rest;
		target[prop] = val;
	});

	return [inputProps, rest];
}
