import { type ForwardedRef, forwardRef } from 'react';
import { createShorthandFactory, getUnhandledProps } from '../lib';
import { Portal } from '../lib/Portal/Portal';
import { DimmerInner, type DimmerInnerProps } from './DimmerInner';

/** Props for {@link Dimmer}. */
export type DimmerProps = {
	/** An active dimmer will dim its parent container. */
	active?: boolean;

	/** A dimmer can be formatted to be fixed to the page. */
	page?: boolean;
} & DimmerInnerProps;

/** A dimmer hides distractions to focus attention on particular content. */
export const Dimmer = forwardRef(function Dimmer(props: DimmerProps, ref: ForwardedRef<HTMLDivElement>) {
	const { active, page } = props;
	const rest = getUnhandledProps(handledProps, props);

	if (page) {
		const handlePortalMount = () => {
			document.body.classList.add('dimmed', 'dimmable');
		};

		const handlePortalUnmount = () => {
			document.body.classList.remove('dimmed', 'dimmable');
		};

		return (
			<Portal
				closeOnEscape={false}
				closeOnDocumentClick={false}
				onMount={handlePortalMount}
				onUnmount={handlePortalUnmount}
				open={active}
				openOnTriggerClick={false}
			>
				<DimmerInner {...rest} active={active} page={page} ref={ref} />
			</Portal>
		);
	}

	return <DimmerInner {...rest} active={active} page={page} ref={ref} />;
});

export const createDimmer = createShorthandFactory(Dimmer);
const handledProps = ['active', 'page'];
