/**
 * Why choose inheritance over a HOC? Multiple advantages for this particular use case. In short, we need identical
 * functionality to setState(), unless there is a prop defined for the state key. Also:
 *
 * 1. Single Renders Calling setState() does not cause two renders. Consumers and tests do not have to wait two renders to
 *    get state. See www.react.run/4kJFdKoxb/27 for an example of this issue.
 * 2. Simple Testing Using a HOC means you must either test the undecorated component or test through the decorator.
 *    Testing the undecorated component means you must mock the decorator functionality. Testing through the HOC means
 *    you can not simply shallow render your component.
 * 3. Statics HOC wrap instances, so statics are no longer accessible. They can be hoisted, but this is more looping over
 *    properties and storing references. We rely heavily on statics for testing and sub components.
 * 4. Instance Methods Some instance methods may be exposed to users via refs. Again, these are lost with HOC unless
 *    hoisted and exposed by the HOC.
 */
import * as _ from 'es-toolkit/compat';
import { Component } from 'react';

const getDefaultPropName = (prop: string) => `default${prop[0]!.toUpperCase() + prop.slice(1)}`;

/**
 * Return the auto controlled state value for a give prop. The initial value is chosen in this order:
 *
 * - Regular props
 * - Then, default props
 * - Then, initial state
 * - Then, `checked` defaults to false
 * - Then, `value` defaults to '' or [] if props.multiple
 * - Else, undefined
 *
 * @param propName A prop name
 * @param [props] A props object
 * @param [state] A state object
 * @param [includeDefaults=false] Whether or not to heed the default props or initial state. Default is `false`
 */
const getAutoControlledStateValue = (propName: string, props: object, state: object, includeDefaults = false) => {
	// regular props
	// @ts-ignore
	const propValue = props[propName];
	if (propValue !== undefined) {
		return propValue;
	}

	if (includeDefaults) {
		// defaultProps
		// @ts-ignore
		const defaultProp = props[getDefaultPropName(propName)];
		if (defaultProp !== undefined) {
			return defaultProp;
		}

		// initial state - state may be null or undefined
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (state) {
			// @ts-ignore
			const initialState = state[propName];
			if (initialState !== undefined) {
				return initialState;
			}
		}
	}

	// React doesn't allow changing from uncontrolled to controlled components,
	// default checked/value if they were not present.
	if (propName === 'checked') {
		return false;
	}
	if (propName === 'value') {
		// @ts-ignore
		return props.multiple ? [] : '';
	}

	// otherwise, undefined
};

export type ModernAutoControlledComponentState<P> = {
	autoControlledProps: string[];
	getAutoControlledStateFromProps?: (
		props: P,
		state1: ModernAutoControlledComponentState<P>,
		state2: ModernAutoControlledComponentState<P>
	) => Partial<ModernAutoControlledComponentState<P>>;
};

export class ModernAutoControlledComponent<P, S extends ModernAutoControlledComponentState<P>> extends Component<P, S> {
	protected constructor(
		props: P,
		autoControlledProps: string[],
		getAutoControlledStateFromProps: (props: P, state1: S, state2: S) => Partial<S>
	) {
		super(props);

		const state = this.getInitialAutoControlledState();

		if (import.meta.env.DEV) {
			const { name } = this.constructor;

			// prevent listing defaultProps in autoControlledProps
			//
			// Default props are automatically handled.
			// Listing defaults in autoControlledProps would result in allowing defaultDefaultValue props.
			const illegalAutoControlled = autoControlledProps.filter(prop => prop.startsWith('default'));
			if (illegalAutoControlled.length > 0) {
				console.error(
					[
						'Do not add default props to autoControlledProps.',
						'Default props are automatically handled.',
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						`See ${name} autoControlledProps: "${illegalAutoControlled}".`
					].join(' ')
				);
			}
		}

		// Auto controlled props are copied to state.
		// Set initial state by copying auto controlled props to state.
		// Also look for the default prop for any auto controlled props (foo => defaultFoo)
		// so we can set initial values from defaults.
		const initialAutoControlledState = autoControlledProps.reduce(
			(acc, prop) => {
				acc[prop] = getAutoControlledStateValue(prop, this.props, state, true);

				if (import.meta.env.DEV) {
					const defaultPropName = getDefaultPropName(prop);
					const { name } = this.constructor;
					// prevent defaultFoo={} along side foo={}
					// @ts-ignore
					if (!_.isUndefined(this.props[defaultPropName]) && !_.isUndefined(this.props[prop])) {
						console.error(
							`${name} prop "${prop}" is auto controlled. Specify either ${defaultPropName} or ${prop}, but not both.`
						);
					}
				}

				return acc;
			},
			{} as Record<string, unknown>
		) as Partial<S>;

		// @ts-ignore
		this.state = {
			...state,
			...initialAutoControlledState,
			autoControlledProps,
			getAutoControlledStateFromProps
		};
	}

	public static getDerivedStateFromProps<
		P extends Record<string, unknown>,
		S extends ModernAutoControlledComponentState<P>
	>(props: P, state: S) {
		const { autoControlledProps, getAutoControlledStateFromProps } = state;

		// Solve the next state for autoControlledProps
		const newStateFromProps = autoControlledProps.reduce(
			(acc, prop) => {
				const isNextDefined = !_.isUndefined(props[prop]);

				// if next is defined then use its value
				if (isNextDefined) {
					acc[prop] = props[prop];
				}

				return acc;
			},
			{} as Record<string, unknown>
		);

		// Due to the inheritance of the AutoControlledComponent we should call its
		// getAutoControlledStateFromProps() and merge it with the existing state
		if (getAutoControlledStateFromProps) {
			const computedState = getAutoControlledStateFromProps(
				props,
				{
					...state,
					...newStateFromProps
				},
				state
			);

			// We should follow the idea of getDerivedStateFromProps() and return only modified state
			return { ...newStateFromProps, ...computedState };
		}

		return newStateFromProps;
	}

	protected getInitialAutoControlledState(): Partial<S> {
		return {};
	}
}
