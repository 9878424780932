import { QUERY } from 'api/Query';
import type { JSX } from 'react';
import React, { useLayoutEffect } from 'react';
import { useCustomCss } from 'ts/base/hooks/UseCustomCss';
import { useTarget } from 'ts/perspectives/login/hooks/UseTarget';

/** The lazy loaded login page. */
const LoginPage = React.lazy(() => import('./components/LoginPage'));

/** The login page. */
export function TeamscaleLoginPage(): JSX.Element | null {
	const redirectionTarget = useTarget();
	const { data: loginContext } = QUERY.getLoginContext({ 'redirection-target': redirectionTarget }).useQuery();
	useCustomCss(loginContext?.customCss);
	useLayoutEffect(() => {
		document.getElementById('app')!.classList.add('login');
		return () => {
			document.getElementById('app')!.classList.remove('login');
		};
	}, []);
	if (!loginContext) {
		return null;
	}
	return <LoginPage {...loginContext} />;
}
