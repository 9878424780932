import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

/** Defines all sub-views of the checks perspective */
export const EChecksPerspectiveView = {
	/** . */
	CHECKS: {
		name: 'Checks',
		anchor: 'checks',
		requiresProject: false,
		view: () => import('ts/commons/checks/ChecksView')
	} as ViewDescriptor
} as const;
