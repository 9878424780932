import type { AnalysisState } from 'typedefs/AnalysisState';
import { EAnalysisState } from 'typedefs/EAnalysisState';

/**
 * An enriched version of the analysis state that also holds the project and branch for which the information was
 * fetched.
 */
export type AnalysisStateWithProjectAndBranch = AnalysisState & {
	branchName: string | null;
	projectId: string;
};

/** Utility class to display a warning about the current analysis state. */
export class AnalysisStateWarningUtils {
	/** Determines whether we have completed the history analysis and found NO commits. */
	public static finishedAnalysisWithoutCommits(analysisState: AnalysisStateWithProjectAndBranch): boolean {
		return AnalysisStateWarningUtils.finishedAnalysis(analysisState) && analysisState.timestamp === 0;
	}

	/** Determines whether we have completed the history analysis AND found commits. */
	public static finishedAnalysisWithCommits(analysisState: AnalysisStateWithProjectAndBranch): boolean {
		return AnalysisStateWarningUtils.finishedAnalysis(analysisState) && analysisState.timestamp > 0;
	}

	/** Determines whether we have completed the history analysis. */
	public static finishedAnalysis(analysisState: AnalysisStateWithProjectAndBranch): boolean {
		return analysisState.state === EAnalysisState.LIVE_ANALYSIS.name;
	}
}
