import type { ElementType } from 'react';

/**
 * Returns a createElement() type based on the props of the Component. Useful for calculating what type a component
 * should render as.
 *
 * @param props A ReactElement props object
 * @param [options={}] Default is `{}`
 * @param [options.defaultAs] A default element type.
 * @param [options.getDefault] A function that returns a default element type.
 * @returns A ReactElement type
 */
export function getComponentType<P extends { as?: ElementType; href?: string }>(
	props: P,
	options: { defaultAs?: ElementType; getDefault?: () => ElementType | undefined } = {}
): ElementType {
	const { defaultAs, getDefault } = options;

	// ----------------------------------------
	// user defined "as" element type

	if (props.as && props.as !== defaultAs) {
		return props.as;
	}

	// ----------------------------------------
	// computed default element type

	if (getDefault) {
		const computedDefault = getDefault();
		if (computedDefault) {
			return computedDefault;
		}
	}

	// ----------------------------------------
	// infer anchor links

	if (props.href) {
		return 'a';
	}

	// ----------------------------------------
	// use defaultProp or 'div'

	return defaultAs || 'div';
}
