import { getRandomString } from 'ts-closure-library/lib/string/string';
import { openModal } from 'ts/commons/modal/ModalUtils';
import { TimePickerDialogContent } from 'ts/commons/time/components/TimePickerDialogContent';
import type { ETimePickerType } from 'ts/commons/time/ETimePickerType';
import type { TypedPointInTime } from './TypedPointInTime';

/**
 * Renders a dialog that lets the user choose a date and time in the history. This may be specified by selecting date
 * and time or, if a project is given, specified by giving a revision or baseline from the project. For .NET projects, a
 * program version may be chosen.
 */
export class PointInTimePicker {
	/**
	 * Shows the time picker dialog.
	 *
	 * @param projects An array of projects from which to retrieve baselines or revisions for selection. This may be
	 *   null to indicate the selection of a project-independent date. In this case only a basic date/time picker will
	 *   be shown.
	 * @param disabledTabs Optional tabs that should be disabled.
	 * @param dateOnly If true, setting the time is hidden and defaults to 00:00.
	 * @param defaultValue The given value will be shown on startup of the dialog if it is set to a valid value.
	 * @param dialogTitle The dialog title to use.
	 */
	public static showDialog(
		projects: string[] | null,
		disabledTabs: ETimePickerType[] = [],
		dateOnly = false,
		defaultValue: TypedPointInTime | null = null,
		dialogTitle = 'Timetravel to...',
		id?: string
	): Promise<TypedPointInTime> {
		return new Promise(resolve => {
			// The ID is used to store the state of this time picker instance
			const timePickerId = id ?? getRandomString();
			openModal({
				title: dialogTitle,
				contentRenderer: close => (
					<TimePickerDialogContent
						onChange={resolve}
						onClose={() => {
							close();
							if (id == null) {
								this.removeLocalStorageEntry(timePickerId);
							}
						}}
						disabledTabs={disabledTabs}
						dateOnly={dateOnly}
						defaultValue={defaultValue}
						id={timePickerId}
						projects={projects}
					/>
				),
				size: 'auto',
				'data-testid': 'time-picker-modal'
			});
		});
	}

	private static removeLocalStorageEntry(timePickerId: string): void {
		Object.keys(localStorage)
			.filter(x => x.startsWith('timepicker-' + timePickerId))
			.forEach(x => localStorage.removeItem(x));
	}
}
