import type { Placement } from '@popperjs/core';
import * as _ from 'es-toolkit/compat';

export const positionsMapping = {
	'top center': 'top',
	'top left': 'top-start',
	'top right': 'top-end',

	'bottom center': 'bottom',
	'bottom left': 'bottom-start',
	'bottom right': 'bottom-end',

	'right center': 'right',
	'left center': 'left'
} as const;

export const placementMapping = _.invert(positionsMapping) as Record<Placement, keyof typeof positionsMapping>;
