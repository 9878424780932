import { useContext } from 'react';
import { UserInfoContext } from 'ts/base/context/UserInfoContext';

/** Hook that provides whether the color blind mode is currently enabled or not. */
export function useColorBlindModeEnabled(): boolean {
	const context = useContext(UserInfoContext);
	if (!context) {
		throw new Error('Could not obtain context');
	}
	return context.isColorBlindModeEnabled();
}
