import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

/** Defines all sub-views of the search perspective */
export const ESearchPerspectiveView = {
	SEARCH: {
		name: 'Search',
		anchor: 'search',
		requiresProject: true,
		showAllProjects: true,
		view: () => import('./SearchView')
	} as ViewDescriptor
} as const;
