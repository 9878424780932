import { Kbd } from '@mantine/core';
import type { KeyboardShortcut } from 'ts/base/scaffolding/KeyboardShortcutRegistry';
import { openModal } from 'ts/commons/modal/ModalUtils';
import { ScrollArea } from 'ts/components/Modal';

/** Help dialog for the shortcuts. */
export function openKeyboardShortcutsModal(shortcuts: KeyboardShortcut[], onClose: () => void) {
	openModal({
		title: 'Keyboard shortcuts for this view',
		contentRenderer: () => <KeyboardShortcutsDialogContent shortcuts={shortcuts} />,
		scrollAreaComponent: ScrollArea.Autosize,
		onClose
	});
}

function KeyboardShortcutsDialogContent({ shortcuts }: { shortcuts: KeyboardShortcut[] }) {
	return (
		<table>
			<tbody>
				{shortcuts.map((shortcut, idx) => (
					<tr key={idx}>
						<td className="pr-4 pt-2 pb-2 text-right">
							<Kbd>{shortcut.shortcut}</Kbd>
						</td>
						<td>{shortcut.name}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}
