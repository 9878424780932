import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { ETestGapsPerspectiveView } from './ETestGapsPerspectiveView';

/** Descriptor for the test gaps perspective. */
export class TestGapsPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.TEST_GAPS, ETestGapsPerspectiveView);
	}
}
