import * as strings from 'ts-closure-library/lib/string/string';
import { StringBuffer } from 'ts-closure-library/lib/string/stringbuffer';
import type { SourceFormatterContext } from 'ts/commons/formatter/SourceFormatterContext';
import type { StyleRegion } from 'ts/commons/formatter/StyleRegion';
import { StringUtils } from 'ts/commons/StringUtils';

/**
 * Responsible for formatting single lines of code while formatting source code file contents for the code perspective.
 * The line formatting process involves processing each text/token/style-region found on a line.
 */
export class SourceLineFormatter {
	/** Data context providing data during code formatting */
	private readonly formatterContext: SourceFormatterContext;

	/** Storage for formatted token. */
	private readonly buffer: StringBuffer;

	/**
	 * Offset (counted from the line start) within the current line that has been processed (= has been added to the
	 * buffer).
	 */
	private localOffset = 0;

	/**
	 * @param context Provides context info during code formatting
	 * @param lineContent The line to be formatted.
	 * @param lineStartOffset Line offset w.r.t. the start of the file.
	 * @param wrapNonTokens If text between tokens should be enclosed in <span> elements, defaults to false
	 * @param fileIndex The index of the current file (if more than one file is displayed).
	 */
	private constructor(
		context: SourceFormatterContext,
		public lineContent: string,
		public lineStartOffset: number,
		public wrapNonTokens: boolean,
		public fileIndex: number
	) {
		this.formatterContext = context;
		this.buffer = new StringBuffer();
	}

	/** Formats the contents of a code line as HTML. */
	public static formatLine(
		context: SourceFormatterContext,
		lineContent: string,
		lineStartOffset: number,
		opt_wrapNonTokens = false,
		fileIndex: number
	): string {
		return new SourceLineFormatter(
			context,
			lineContent,
			lineStartOffset,
			opt_wrapNonTokens,
			fileIndex
		).formatLineTokens();
	}

	/** Formats the contents of a code line as HTML. */
	private formatLineTokens(): string {
		const lineLength = this.lineContent.length;
		let currentTokenStyleRegion = this.formatterContext.getCurrentTokenStyleRegion();
		while (this.localOffset < lineLength) {
			// There is still text to add for the current line. The loop can't run endlessly because we add at least one char in each iteration (and increment this.localOffset).
			if (currentTokenStyleRegion == null) {
				// Either there were no style regions or we are beyond the last region. Add the entire line, without formatting and return.
				this.processNonTokenText(lineLength);
				this.localOffset += lineLength;
				return this.getBuffer().toString();
			}
			const lengthToStyleRegion = SourceLineFormatter.computeNumberOfLineCharsUntilOffset(
				currentTokenStyleRegion.startOffset,
				this.localOffset,
				this.lineStartOffset,
				this.lineContent.length
			);
			if (lengthToStyleRegion > 0) {
				// There is non-token text (or whitespace) before the current region. Add only that text in the current iteration.
				this.processNonTokenText(lengthToStyleRegion);
				this.localOffset += lengthToStyleRegion;
			} else {
				this.addCurrentStyleRegion(currentTokenStyleRegion);
				// The current code style region might be completely added now. In that case, the addCurrentStyleRegion has incremented to the next region.
				currentTokenStyleRegion = this.formatterContext.getCurrentTokenStyleRegion();
			}
		}
		return this.getBuffer().toString();
	}

	/** Adds the text of the current style region (at least the part that is in the current line) to the buffer. */
	private addCurrentStyleRegion(currentTokenStyleRegion: StyleRegion) {
		const lengthToStyleRegionEnd =
			SourceLineFormatter.computeNumberOfLineCharsUntilOffset(
				currentTokenStyleRegion.endOffset,
				this.localOffset,
				this.lineStartOffset,
				this.lineContent.length
			) +
			// +1 because we want to add the char with the endOffset (in addition to the chars before it)
			1;
		const charOffsetInFile = this.lineStartOffset + this.localOffset;
		const regionText = this.lineContent.substring(this.localOffset, this.localOffset + lengthToStyleRegionEnd);
		if (StringUtils.isEmptyOrWhitespace(regionText) && !this.wrapNonTokens) {
			// No CSS class if only whitespace, to prevent bug #6016
			this.buffer.append(strings.htmlEscape(regionText, false));
		} else {
			this.formatterContext.generateAndAppendElementForStyleRegion(
				charOffsetInFile,
				regionText,
				currentTokenStyleRegion,
				this.buffer,
				this.fileIndex
			);
		}
		this.localOffset += lengthToStyleRegionEnd;
		if (this.lineStartOffset + this.localOffset > currentTokenStyleRegion.endOffset) {
			this.formatterContext.incrementCurrentRegionIndex();
		}
	}

	/**
	 * Returns the number of chars in the current line that come before the given target offset (the target offset is
	 * given as chars from file begin). If the target offset is at or before the start of the current line, return 0. If
	 * the target offset is after the end of the current line, returns the line length. Otherwise, returns the number of
	 * chars in the current line before the target offset.
	 */
	public static computeNumberOfLineCharsUntilOffset(
		targetOffset: number,
		localOffset: number,
		lineStartOffset: number,
		lineLength: number
	): number {
		if (targetOffset <= lineStartOffset) {
			// The targetOffset is in a previous line or exactly the start of the current line.
			return 0;
		} else if (targetOffset > lineStartOffset + lineLength) {
			// The targetOffset is in some following line. Return the number of remaining chars in the current line (next line is handled separately).
			return lineLength - localOffset;
		}
		const currentOffsetFromFileStart = lineStartOffset + localOffset;
		// The targetOffset is in the current line.
		return targetOffset - currentOffsetFromFileStart;
	}

	/**
	 * Processes a text that is not part of a token.
	 *
	 * @param lengthToToken Distance to token along current line.
	 */
	private processNonTokenText(lengthToToken: number): void {
		const nonTokenContent = this.lineContent.substring(this.localOffset, this.localOffset + lengthToToken);
		if (this.wrapNonTokens) {
			this.formatterContext.createAndAppendSpanForStyleRegion(
				nonTokenContent,
				this.lineStartOffset + this.localOffset,
				'',
				this.buffer,
				this.fileIndex
			);
		} else {
			this.buffer.append(strings.htmlEscape(nonTokenContent, false));
		}
	}

	/** Returns the store of formatted tokens. */
	public getBuffer(): StringBuffer {
		return this.buffer;
	}
}
