/// <reference types="semantic-ui" />
import $ from 'jquery';
import accordion from 'semantic-ui-accordion';
import checkbox from 'semantic-ui-checkbox';
import dimmer from 'semantic-ui-dimmer';
import dropdown from 'semantic-ui-dropdown';
import modal from 'semantic-ui-modal';
import popup from 'semantic-ui-popup';
import tab from 'semantic-ui-tab';
import transition from 'semantic-ui-transition';
import * as UIUtilsTemplate from 'soy/commons/UIUtilsTemplate.soy.generated';
import * as dom from 'ts-closure-library/lib/dom/dom';
import * as forms from 'ts-closure-library/lib/dom/forms';
import { TagName } from 'ts-closure-library/lib/dom/tagname';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import * as soy from 'ts/base/soy/SoyRenderer';
import { Assertions } from 'ts/commons/Assertions';
import type { EventHandler } from 'ts/commons/UIUtils';
import { UIUtils } from './UIUtils';

$.fn.accordion = accordion;
$.fn.checkbox = checkbox;
$.fn.dimmer = dimmer;
$.fn.dropdown = dropdown;
$.fn.modal = modal;
$.fn.popup = popup;
$.fn.tab = tab;
$.fn.transition = transition;

/** Helper class that encapsulates access to Semantic UI. */
export class SemanticUIUtils {
	/** The default options to use for searchable SemanticUI dropdowns (see also activateDropdowns()) */
	private static readonly DEFAULT_SEARCH_DROPDOWN_OPTIONS: Partial<SemanticUI.DropdownSettings> = {
		fullTextSearch: 'exact',
		selectOnKeydown: false,
		forceSelection: false
	};

	/**
	 * Activates a SemanticUI dropdown for the given element with optional settings defined at
	 * https://semantic-ui.com/modules/dropdown.html#/settings
	 *
	 * @param options Optional settings for the dropdown(s) behavior.
	 */
	public static activateDropdown(dropdownElement: Element, options: Partial<SemanticUI.DropdownSettings> = {}): void {
		$(dropdownElement).dropdown(options as SemanticUI.DropdownSettings);
		// Ensure that no error is printed in the console when a dropdown selection leads to a navigation event
		// and therefore the default hide animation fails, because the menu to transition was already removed from the DOM
		$.fn.transition.settings.silent = true;
	}

	/**
	 * Activates multiple SemanticUI dropdowns for the given elements with optional settings defined at
	 * https://semantic-ui.com/modules/dropdown.html#/settings
	 *
	 * @param options Optional settings for the dropdown(s) behavior.
	 */
	public static activateDropdowns(
		dropdownElements: Element[] | NodeListOf<Element>,
		options: Partial<SemanticUI.DropdownSettings> = {}
	): void {
		dropdownElements.forEach((dropdownElement: Element) =>
			SemanticUIUtils.activateDropdown(dropdownElement, options)
		);
	}

	/** Returns the default search options together with the additional options. */
	private static getSearchOptions(
		additionalOptions: Partial<SemanticUI.DropdownSettings>
	): Partial<SemanticUI.DropdownSettings> {
		// Disabled as the suggested change slows down the Typescript compiler by ~20sec
		// eslint-disable-next-line prefer-object-spread
		const options = Object.assign({}, SemanticUIUtils.DEFAULT_SEARCH_DROPDOWN_OPTIONS);
		return Object.assign(options, additionalOptions);
	}

	/**
	 * Activates searchable SemanticUI dropdown for the element using the default search options (e.g. full text
	 * search).
	 *
	 * @param options According to https://semantic-ui.com/modules/dropdown.html#/settings
	 */
	public static activateSearchableDropdown(
		element: Element,
		options: Partial<SemanticUI.DropdownSettings> = {}
	): void {
		SemanticUIUtils.activateDropdown(element, SemanticUIUtils.getSearchOptions(options));
	}

	/**
	 * Activates searchable SemanticUI dropdowns for dropdown elements matching the given IDs using the default search
	 * options (e.g. full text search). NOTE: This has to be done after the elements have been appended to the DOM.
	 *
	 * @param options According to https://semantic-ui.com/modules/dropdown.html#/settings
	 */
	public static activateSearchableDropdowns(
		dropdownElements: Element[] | NodeListOf<Element>,
		options: Partial<SemanticUI.DropdownSettings> = {}
	): void {
		dropdownElements.forEach((dropdownElement: Element) => {
			SemanticUIUtils.activateSearchableDropdown(dropdownElement, options);
		});
	}

	/**
	 * Adds a Semantic UI dropdown _menu_ as last child of the given dropdown activating element. This can be useful as
	 * it avoids having to specify an empty menu in a SOY template when dynamically populating dropdown items.
	 */
	public static addMenuToDropdownElement(activatingElement: Element): void {
		activatingElement.classList.toggle('dropdown', true);
		const menuElement = dom.createDom(TagName.DIV, { class: 'menu' });
		activatingElement.appendChild(menuElement);
	}

	/**
	 * Adds an action to the given dropdown element without extra space for an optional icon. After adding all items the
	 * dropdown element must be (re-)activated.
	 *
	 * @param dropdown The dropdown element.
	 * @param id The id of the item.
	 * @param title The title of the dropdown item.
	 * @param isLink The check if the item should be rendered as link.
	 * @param callback The callback for when the item is clicked.
	 * @returns The inserted action item.
	 */
	public static addDropdownAction(
		dropdown: Element,
		id: string,
		title: string,
		isLink: boolean,
		callback?: EventHandler
	): Element {
		const dropdownAction = soy.renderAsElement(UIUtilsTemplate.dropdownAction, {
			id,
			title,
			isLink
		});
		if (callback != null) {
			events.listen(dropdownAction, EventType.CLICK, UIUtils.preventDefaultEventAction(callback));
		}
		dom.getElementByClass('menu', dropdown)!.appendChild(dropdownAction);
		return dropdownAction;
	}

	/**
	 * Determines whether a dropdown element is on the upper or the lower side of the browser's viewpoint and returns
	 * the direction (downward or upward) depending on that. Caution: Element needs to be in the DOM or the return value
	 * will always be 'downward'
	 */
	public static getDropdownDirection(dropdownElement: Element): 'upward' | 'downward' {
		const clientY = dropdownElement.getBoundingClientRect().top;
		if (clientY < $(window).height()! / 2) {
			return 'downward';
		} else {
			return 'upward';
		}
	}

	/**
	 * Activates a SemanticUI popup (a 'floating dialog') on the page.
	 *
	 * @param popupElement The element the popup should attach to (not the popup element itself)
	 * @param options Additional options (see SemanticUI page)
	 * @see closePopup
	 */
	public static activatePopup(popupElement: Element, options: Partial<SemanticUI.PopupSettings> = {}): void {
		const createdPopup = $(popupElement).popup(options as SemanticUI.PopupSettings);
		if (options.on === 'manual') {
			createdPopup.popup('show');
		}
	}

	/**
	 * Activates a SemanticUI tab on the page
	 *
	 * @param tabsCssSelector The selector for the menu-tab element
	 * @param options Additional options (see SemanticUI page)
	 */
	public static activateTabs(
		tabsCssSelector = '.ui.menu .item.tab',
		options: Partial<SemanticUI.TabSettings> = {}
	): void {
		$(tabsCssSelector).tab(options as SemanticUI.TabSettings);
	}

	public static selectTab(tab: HTMLElement): void {
		const parent = $(tab).parent();

		const activeTab = parent.find('.tab.active');
		activeTab.removeClass('active');
		tab.classList.add('active');

		const activeItem = parent.find('.item.active');
		activeItem.removeClass('active');
		const newActiveItem = parent.find(`.item[data-tab='${tab.dataset.tab!}']`);
		newActiveItem.addClass('active');
	}

	/**
	 * Activates a SemanticUI modal.
	 *
	 * @param modalElement The modal element that should be displayed
	 */
	public static showModal(modalElement: Element, options: Partial<SemanticUI.ModalSettings> = {}): void {
		$(modalElement)
			.modal(options as SemanticUI.ModalSettings)
			.modal('show');
	}

	/**
	 * Adds or removes a progress indicator to the given button. The button is disabled if a spinner should be shown and
	 * enabled otherwise.
	 *
	 * @static
	 */
	public static setShowButtonSpinner(button: Element, showAsSpinnerAndDisable: boolean): void {
		button.classList.toggle('loading', showAsSpinnerAndDisable);
		forms.setDisabled(button, showAsSpinnerAndDisable);
	}

	/**
	 * Adds given callbacks as on checked and on unchecked listeners to a group.
	 *
	 * @param onCheckedCallback Callback to be called when a checkbox is checked
	 * @param onUncheckedCallback Callback to be called when a checkbox is unchecked.
	 */
	public static addRadioButtonListeners(
		radioButtons: ArrayLike<Element>,
		onCheckedCallback: (this: HTMLInputElement) => void,
		onUncheckedCallback?: (this: HTMLInputElement) => void
	): void {
		$(radioButtons).checkbox({ onChecked: onCheckedCallback, onUnchecked: onUncheckedCallback });
	}

	/** Sets the selected value of the Semantic UI dropdown. */
	public static setDropdownValue(dropdownElement: Element, value: string | null | string[]): void {
		$(dropdownElement).dropdown('set selected', value);
	}

	/**
	 * Returns either the string if text is a string or null if it's either undefined or null. Otherwise an assertion
	 * error is thrown.
	 */
	private static assertStringNullOrUndefined(text: string | null): string | null {
		if (text == null) {
			return null;
		}
		return Assertions.assertString(text);
	}

	/** Gets the selected value or values of a Semantic UI dropdown element. */
	public static getDropdownValue(dropdownElement: Element): string | null {
		return SemanticUIUtils.assertStringNullOrUndefined($(dropdownElement).dropdown('get value'));
	}

	/**
	 * Activates a Semantic UI accordion element.
	 *
	 * @param exclusive Whether always only one area of the accordion can be expanded.
	 * @param triggerOnTitle Whether the accordion can be triggered by clicking on the title. If set to false only the
	 *   icon is working as trigger.
	 */
	public static activateAccordion(accordionElement: Element, exclusive = true, triggerOnTitle = true): void {
		if (triggerOnTitle) {
			$(accordionElement).accordion({ exclusive });
			return;
		}
		$(accordionElement).accordion({
			exclusive,
			selector: { trigger: '.title.accordion-trigger,.title > .accordion-trigger' }
		});
	}

	/**
	 * Activates Semantic UI radio buttons. After the activation, clicking on a label next to the radio button will also
	 * change its state.
	 */
	public static activateRadioButtons(radioButtons: ArrayLike<Element>): void {
		$(radioButtons).checkbox();
	}

	/** Check if Semantic UI radio checkbox identified by given id is checked. */
	public static isCheckboxCheckedById(id: string): boolean {
		return $('.ui.checkbox')
			.find('#' + id)
			.is(':checked');
	}

	/**
	 * Closes an open popup.
	 *
	 * @param popup Either the id of the popup itself (string) or the activating element (Element)
	 * @see activatePopup
	 */
	public static closePopup(popup: Element | string): void {
		let jqueryPopup;
		if (typeof popup === 'string') {
			jqueryPopup = $('#' + popup);
		} else {
			jqueryPopup = $(popup);
		}
		jqueryPopup.popup('hide');
	}
}
