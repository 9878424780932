import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { EComparePerspectiveView } from 'ts/perspectives/compare/EComparePerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the compare perspective. */
export class ComparePerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.COMPARE, EComparePerspectiveView);
	}
}
