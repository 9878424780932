import { linkTo } from 'ts/commons/links/LinkTo';
import { EAdminPerspectiveView } from 'ts/perspectives/admin/EAdminPerspectiveView';
import { ETeamscalePerspective } from 'ts/typedefs/ETeamscalePerspective';

/** Returns a URL for User Groups List. */
export function userGroups() {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.GROUPS);
}

/** Returns a URL for creating a new user. */
export function createUser() {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.USERS, {
		action: 'create'
	});
}

/** Returns a URL for showing the user list. */
export function showUsers() {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.USERS);
}

/** Returns a URL for editing the roles of a user. */
export function editUserRoles(username: string) {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.USERS, {
		action: 'roles',
		username
	});
}

/** Returns a URL for creating a group. */
export function createGroup() {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.GROUPS, {
		action: 'create',
		groupId: ':new'
	});
}

/**
 * Returns a URL for editing a group.
 *
 * @param groupName The group name.
 */
export function editGroup(groupName: string) {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.GROUPS, {
		action: 'edit',
		groupId: groupName
	});
}

/** Returns a URL for editing the roles of a group. */
export function editGroupRoles(groupName: string) {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.GROUPS, {
		action: 'roles',
		groupId: groupName
	});
}

/** Returns a URL for the admin global settings view. */
export function globalSettings() {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.SETTINGS);
}

/** Returns a URL for showing the backup import progress. */
export function backupImport(backupId: string) {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.BACKUP, { 'import-id': backupId });
}

/**
 * Returns a URL for showing the backup export progress and eventually download or the backup export view if no ID is
 * given.
 */
export function backupExport(backupId?: string) {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.BACKUP, { 'export-id': backupId });
}

/** Returns a URL for the admin settings page. */
export function showAdminSettings(action?: 'Server Limits' | 'Automatic Backup') {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.SETTINGS, { action });
}

/** Returns a URL for the comparison with the given ID. */
export function showInstanceComparison(params: { snapshotId: string; comparisonId: string }) {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.INSTANCE_COMPARISON, {
		subView: 'comparison',
		...params
	});
}

/** Returns a URL for the comparison contribution with the given ID/contributor. */
export function showInstanceComparisonContribution(params: {
	snapshotId: string;
	comparisonId: string;
	contributor: string;
	project?: string;
}) {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.INSTANCE_COMPARISON, {
		subView: 'comparison/contributor',
		...params
	});
}

/** Returns a URL for the comparison contribution element details with the given ID/contributor/diffEntryName. */
export function showInstanceComparisonContributionElementDetails(params: {
	snapshotId: string;
	comparisonId: string;
	contributor: string;
	diffEntryName: string;
	remoteDetails: boolean;
	project?: string;
	title?: string;
}) {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.INSTANCE_COMPARISON, {
		subView: 'comparison/contributor/details',
		...params
	});
}

/** Returns a URL for editing the roles assigned for an external storage backend. */
export function editExternalStorageBackendRolesLink(externalStorageBackendName: string) {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.EXTERNAL_STORAGE_BACKEND_ROLES, {
		name: externalStorageBackendName
	});
}

/** Returns a URL to the list of external accounts. */
export function editExternalAccountsLink() {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.EXTERNAL_ACCOUNTS);
}

/** Returns a URL for editing the roles assigned for an external account. */
export function editExternalCredentialsRolesLink(credentialsName: string) {
	return linkTo(ETeamscalePerspective.ADMIN, EAdminPerspectiveView.EXTERNAL_ACCOUNT_ROLES, {
		name: credentialsName
	});
}
