import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

/** Defines all sub-views of the requirements perspective */
export const ERequirementsPerspectiveView = {
	OVERVIEW: {
		name: 'Specification Item Overview',
		anchor: 'overview',
		requiresProject: true,
		viewCreator: () => import('./views/SpecItemOverviewView'),
		timeTravel: {
			contentName: 'Spec Items'
		}
	} as ViewDescriptor,
	DETAILS: {
		name: 'Requirements Details',
		anchor: 'details',
		requiresProject: true,
		viewCreator: () => import('./spec_item_detail/SpecItemDetailsView'),
		timeTravel: {
			contentName: 'Spec Items'
		}
	} as ViewDescriptor
};
