import { Assertions } from 'ts/commons/Assertions';
import { AssessmentMetric } from 'ts/commons/components/MetricFormatters';
import { NumericValueFormatter } from 'ts/commons/formatter/NumericValueFormatter';
import type { Assessment } from 'typedefs/Assessment';
import { ETrafficLightColor } from 'typedefs/ETrafficLightColor';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';
import { ArrayUtils } from './../ArrayUtils';
import { MetricFormatterBase, type MetricFormatterOptions } from './MetricFormatterBase';

/** The options applicable to formatting assessment metrics. */
export type AssessmentFormatterOptions = {
	/** An explicit value for the tooltip that overrides any default implied by the value to be formatted. */
	additionalTooltip?: string;
};

/** A formatter for assessments. */
export class AssessmentFormatter extends MetricFormatterBase<Assessment> {
	public constructor(schemaEntry: MetricDirectorySchemaEntry) {
		super(schemaEntry);
	}

	public override formatValueAsText(value: Assessment): string {
		Assertions.assert(
			typeof value === 'object' && 'mapping' in value,
			`Expected an Assessment, but was ${JSON.stringify(value)}`
		);
		function getColorFrequency(color: ETrafficLightColor): number {
			return value.mapping[color.ordinal] ?? 0;
		}

		/**
		 * Computes the string output for a color containing the name, absolute value and relative value.
		 *
		 * Example output: "Red: 2 (20.0%)"
		 */
		function computeFormattedColor(color: ETrafficLightColor, sum: number): string {
			const colorName = color.assessmentDisplayName;
			const colorAbsoluteValue = getColorFrequency(color);
			const colorRelativeValue = NumericValueFormatter.formatAsPercentage(getColorFrequency(color) / sum);
			return colorName + ': ' + colorAbsoluteValue + ' (' + colorRelativeValue + ')';
		}

		const sum = ArrayUtils.sum(value.mapping);
		if (sum === 0) {
			return '[]';
		}

		// in the same order as the assessment bar
		const builder = [];

		const ratingColors = ETrafficLightColor.values;
		for (const color of ratingColors) {
			if (getColorFrequency(color) <= 0) {
				continue;
			}
			builder.push(computeFormattedColor(color, sum));
		}
		return `[${builder.join(', ')}]`;
	}

	public override formatValueAsJsx(value: Assessment, options: MetricFormatterOptions): JSX.Element {
		Assertions.assert(
			typeof value === 'object' && 'mapping' in value,
			`Expected an Assessment, but was ${JSON.stringify(value)}`
		);
		const tooltip =
			this.formatValueAsText(value) + (options.additionalTooltip ? `\n${options.additionalTooltip}` : '');
		return <AssessmentMetric assessmentMetric={value} tooltip={tooltip} metricSchemaEntry={this.schemaEntry} />;
	}

	public override parseFromString(value: string): Assessment {
		const stringValue = value;
		const assessmentCounts = ArrayUtils.repeat(0, ETrafficLightColor.values.length);
		if (ETrafficLightColor.values.some(color => color.name === stringValue)) {
			// Assessment has size 1 and only displays dominant color, e.g. GREEN
			AssessmentFormatter.parseAssessmentCountsFromColorName(stringValue, assessmentCounts);
			return { mapping: assessmentCounts };
		}
		// Assessment is in array syntax, e.g. "[Red: 1, Yellow: 5, Green: 12]" -> parse and count values
		AssessmentFormatter.parseAssessmentCountsForAssessmentDisplay(stringValue, assessmentCounts);
		return { mapping: assessmentCounts };
	}

	private static parseAssessmentCountsFromColorName(stringValue: string, assessmentCounts: number[]) {
		const assessmentColor = ETrafficLightColor.values.find(color => color.name === stringValue)!;
		AssessmentFormatter.setAssessmentDisplayCountIndex(assessmentCounts, assessmentColor.assessmentDisplayName, 1);
	}

	private static parseAssessmentCountsForAssessmentDisplay(stringValue: string, assessmentCounts: number[]) {
		const assessmentRegex = /(?<=\[|,\s)([^,\]]+:\s*\d+)/g;
		const matches: RegExpMatchArray | null = stringValue.match(assessmentRegex);

		if (matches) {
			for (const match of matches) {
				const [word, number] = match.split(/:\s*/);
				const assessmentColorPrefix = word;
				const assessmentValue = parseInt(number!, 10);
				AssessmentFormatter.setAssessmentDisplayCountIndex(
					assessmentCounts,
					assessmentColorPrefix!,
					assessmentValue
				);
			}
		}
	}

	/**
	 * Sets the assessment count of the passed object to the passed value. Index of the assessment is determined by the
	 * first traffic light assessment display value
	 */
	private static setAssessmentDisplayCountIndex(
		assessmentCounts: number[],
		assessmentColorPrefix: string,
		assessmentValue: number
	): void {
		for (let i = 0; i < ETrafficLightColor.values.length; ++i) {
			const assessment = ETrafficLightColor.values[i]!;
			if (assessment.assessmentDisplayName === assessmentColorPrefix) {
				assessmentCounts[i] = assessmentValue;
				return;
			}
		}
	}
}
