import type { JSX } from 'react';
import { QUERY } from 'api/Query';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { DEFAULT_STALE_TIME } from 'ts/base/perspective/topbar/warnings/GlobalWarnings';

/** Warns the user about insufficient RAM. */
export function useMemoryWarning(): JSX.Element | null {
	const { data: sufficientRamResponse } = QUERY.hasSufficientRam().useQuery({
		staleTime: DEFAULT_STALE_TIME
	});
	if (sufficientRamResponse === undefined || sufficientRamResponse.hasSufficientRam) {
		return null;
	}
	return (
		<GlobalWarning
			message="Insufficient Memory"
			severity="warning"
			tooltip={
				sufficientRamResponse.availableMemory +
				' RAM for the JVM is insufficient for ' +
				sufficientRamResponse.numberOfWorkers +
				' Teamscale workers (can lead to OutOfMemory). The minimum setting should be 2 GB per worker.'
			}
		/>
	);
}
