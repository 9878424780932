import { DateUtils } from 'ts/commons/DateUtils';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { ETaskResolution } from 'typedefs/ETaskResolution';
import { ETaskStatus } from 'typedefs/ETaskStatus';
import type { ResolvedTask } from 'typedefs/ResolvedTask';

/** The properties that we want to append to ResolvedTask objects. */
type ResolvedTaskExtension = {
	/** A formatted version of #created. */
	createdFormatted: string;

	/** A formatted version of #updated. */
	updatedFormatted: string;

	/** A readable version of #status. */
	statusReadable: string;
	/** A readable version of #resolution. */
	resolutionReadable: string;
};

/** The ResolvedTask extended with the above properties. */
type ExtendedResolvedTask = ExtendTypeWith<ResolvedTask, ResolvedTaskExtension>;

/** Extends the given ResolvedTask with readable status infos and formatted dates. */
export function wrapResolvedTask(resolvedTask: ResolvedTask): ExtendedResolvedTask {
	let statusReadable = 'No status';
	if (resolvedTask.status) {
		statusReadable = ETaskStatus[resolvedTask.status].readableName;
	}
	let resolutionReadable = 'No resolution';
	if (resolvedTask.resolution) {
		resolutionReadable = ETaskResolution[resolvedTask.resolution].readableName;
	}
	return Object.assign(resolvedTask, {
		createdFormatted: DateUtils.formatTimestamp(resolvedTask.created),
		updatedFormatted: DateUtils.formatTimestamp(resolvedTask.updated),
		statusReadable,
		resolutionReadable
	});
}
