import { QUERY } from 'api/Query';
import type { JSX } from 'react';
import type { Callback } from 'ts/base/Callback';
import { Assertions } from 'ts/commons/Assertions';
import { EnumUtils } from 'ts/commons/EnumUtils';
import { Links } from 'ts/commons/links/Links';
import { type TaskFilters } from 'ts/commons/links/QualityControlLinks';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { UIUtils } from 'ts/commons/UIUtils';
import { Icon } from 'ts/components/Icon';
import type { TaskDataContainer } from 'ts/data/TaskDataContainer';
import { FindingsUtils } from 'ts/perspectives/findings/FindingsUtils';
import type { ETaskStatusEntry } from 'typedefs/ETaskStatus';
import { ETaskStatus } from 'typedefs/ETaskStatus';
import type { Task } from 'typedefs/Task';
import type { TaskWithDetailedFindings } from 'typedefs/TaskWithDetailedFindings';
import type { TrackedFinding } from 'typedefs/TrackedFinding';

/** Mapping from task statuses to icons components. */
export const TASK_STATUS_ICONS: Record<ETaskStatusEntry, JSX.Element> = {
	OPEN: <Icon name="list" color="red" style={{ paddingLeft: '0', float: 'left' }} />,
	RESOLVED: <Icon name="hourglass outline" color="yellow" style={{ float: 'left' }} />,
	VERIFIED: <Icon name="tasks" color="green" style={{ float: 'left' }} />,
	DISCARDED: <Icon name="times circle outline" color="grey" style={{ float: 'left' }} />
};

const VALID_STATUS_VALUES = EnumUtils.getValues(ETaskStatus.values);

/** Mapping from task statuses to readable names. */
export const TASK_STATUS_TOOLTIPS: Record<ETaskStatusEntry, string> = {
	OPEN: 'Open',
	RESOLVED: 'Ready to review',
	VERIFIED: 'Closed',
	DISCARDED: 'Discarded'
};

/** Utility functions related to tasks. */
export class TaskUtils {
	/** The statuses parameter. */
	public static readonly STATUSES_PARAMETER = 'statuses';

	/** The tags parameter. */
	public static readonly TAGS_PARAMETER = 'tags';

	/** The name of the query parameter used to store the assignee filter in the navigation hash. */
	public static readonly ASSIGNEE_FILTER_PARAMETER = 'assignee';

	/** The name of the query parameter used to store the author filter in the navigation hash. */
	public static readonly AUTHOR_FILTER_PARAMETER = 'author';

	private static readonly FINDINGS_FOR_TASK_SESSION_STORAGE_KEY = 'new-task';

	/**
	 * Navigates to the edit view of the given task in the specified project.
	 *
	 * @param project The project to which the task belongs.
	 * @param taskId ID of the task. Can be 0 to create a new task.
	 * @param keepTaskFilters Whether to keep the current task filter stored in the hash. Defaults to false
	 * @param openInNewTab Whether to open the task details in a new tab. Defaults to false.
	 * @param createFromStoredData Whether the new task should be prefilled with data stored in the session storage,
	 *   i.e., findings and code snippets.
	 */
	public static navigateToTaskEditView(
		project: string,
		taskId: number,
		keepTaskFilters = false,
		openInNewTab = false,
		createFromStoredData = false
	): void {
		TaskUtils.navigateToTaskView('new', project, taskId, keepTaskFilters, openInNewTab, createFromStoredData);
	}

	/**
	 * Navigates to the task detail view of the given task in the specified project.
	 *
	 * @param project The project to which the task belongs.
	 * @param taskId ID of the task. Can be 0 to create a new task.
	 * @param keepTaskFilters Whether to keep the current task filter stored in the hash. Defaults to false.
	 * @param openInNewTab Whether to open the task details in a new tab. Defaults to false.
	 * @param createFromStoredData Whether the new task should be prefilled with data stored in the session storage,
	 *   i.e., findings and code snippets.
	 */
	public static navigateToTaskDetailView(
		project: string,
		taskId: number,
		keepTaskFilters = false,
		openInNewTab = false,
		createFromStoredData = false
	): void {
		TaskUtils.navigateToTaskView('details', project, taskId, keepTaskFilters, openInNewTab, createFromStoredData);
	}

	/** Navigates to the specified view of the given task in the specified project */
	private static navigateToTaskView(
		action: 'new' | 'details',
		project: string,
		taskId: number,
		keepTaskFilters = false,
		openInNewTab = false,
		createFromStoredData = false
	): void {
		let taskFilters: TaskFilters | undefined = undefined;
		if (keepTaskFilters) {
			taskFilters = this.getTaskFilters(NavigationHash.getCurrent());
		}
		const link = Links.taskViewLink(project, taskId, action, taskFilters, createFromStoredData);
		if (openInNewTab) {
			NavigationUtils.openInNewTab(link, true);
		} else {
			NavigationUtils.updateLocation(link);
		}
	}

	/**
	 * Callback method that acts on saved or created task from service client call.
	 *
	 * @param taskId The id of the task to be added to. (0 for creating a new task)
	 * @param savedOrCreatedTask Updated task from service client call.
	 * @param project
	 * @param optCallback Called after task is created or saved with findings.
	 */
	private static onAddFindingsToTask(
		taskId: number,
		savedOrCreatedTask: Task,
		project: string,
		optCallback?: Callback<Task> | null
	): void {
		if (taskId === 0) {
			if (optCallback != null) {
				optCallback(savedOrCreatedTask);
			} else {
				TaskUtils.navigateToTaskEditView(project, savedOrCreatedTask.id, false, true);
			}
		} else if (optCallback != null) {
			optCallback(savedOrCreatedTask);
		} else {
			TaskUtils.navigateToTaskDetailView(project, savedOrCreatedTask.id, false, true);
		}
	}

	/**
	 * Creates a new task (if id is 0) or saves an existing one with findings, code snippets or some other data. The
	 * subject stored in <code>newTaskDetails</code> is used for new tasks only.
	 *
	 * @param project The project
	 * @param taskId The id of the task to be added to. (0 for creating a new task)
	 * @param newTaskDetails Bundles all needed information required to create or update task
	 * @param updateTaskCallback Performs update of task and returns a new task in a promise object.
	 * @param postSaveCallback Called after task is created or saved with findings.
	 */
	private static async saveExistingTask(
		project: string,
		taskId: number,
		newTaskDetails: TaskDataContainer,
		updateTaskCallback: (result: TaskWithDetailedFindings) => Promise<Task>,
		postSaveCallback?: Callback<Task>
	): Promise<void> {
		if (taskId === 0) {
			TaskUtils.putTaskDataInSessionStore(newTaskDetails);
			TaskUtils.navigateToTaskEditView(project, taskId, false, true, true);
			return;
		}
		const retrievedTask = await QUERY.getTask(project, taskId, {}).fetch();
		const savedOrCreatedTask = await updateTaskCallback(retrievedTask);
		TaskUtils.onAddFindingsToTask(taskId, savedOrCreatedTask, project, postSaveCallback);
	}

	/**
	 * Adds a code snippet stored inside <code>newTaskDetails</code> to task identified by <code>taskId</code>.
	 *
	 * @param project The project
	 * @param taskId The id of the task to be added to. (0 for creating a new task)
	 * @param newTaskDetails Bundles all needed information required to create or update task
	 * @param postSaveCallback Called after task is created or saved with findings.
	 */
	public static async addCodeSnippetToTask(
		project: string,
		taskId: number,
		newTaskDetails: TaskDataContainer,
		postSaveCallback?: Callback<Task>
	): Promise<void> {
		const updateTaskCallback = (result: TaskWithDetailedFindings): Promise<Task> => {
			const task = result.task;
			task.codeSnippets!.push(newTaskDetails.codeSnippet!);
			return QUERY.updateTask(project, taskId, {}, task as Task).fetch();
		};
		await TaskUtils.saveExistingTask(project, taskId, newTaskDetails, updateTaskCallback, postSaveCallback);
	}

	/**
	 * Adds findings stored inside <code>newTaskDetails</code> to task identified by <code>taskId</code>.
	 *
	 * @param project The project
	 * @param taskId The id of the task to be added to. (0 for creating a new task)
	 * @param newTaskDetails Bundles all needed information required to create or update task
	 * @param postSaveCallback Called after task is created or saved with findings.
	 */
	public static async addFindingsToTask(
		project: string,
		taskId: number,
		newTaskDetails: TaskDataContainer,
		postSaveCallback?: Callback<Task>
	): Promise<void> {
		const updateCallback = (task: TaskWithDetailedFindings): Promise<Task> =>
			TaskUtils.doAddFindingsToTask(project, newTaskDetails.branch!, taskId, newTaskDetails.findings, task);
		await TaskUtils.saveExistingTask(project, taskId, newTaskDetails, updateCallback, postSaveCallback);
	}

	/**
	 * Adds given findings to task and returns a promise with updated task
	 *
	 * @param project The project
	 * @param branch The branch of the findings (may be null to indicate the default branch).
	 * @param taskId The id of the task to be added to. (0 for creating a new task)
	 * @param findings The list of findings
	 * @param taskWithDetailedFindings
	 */
	public static async doAddFindingsToTask(
		project: string,
		branch: string,
		taskId: number,
		findings: TrackedFinding[],
		taskWithDetailedFindings: TaskWithDetailedFindings
	): Promise<Task> {
		const task = taskWithDetailedFindings.task;
		if (task.findings == null) {
			task.findings = [];
		}
		const existingFindingIds = task.findings.map(finding => finding.findingId);
		for (const finding of findings) {
			if (!existingFindingIds.includes(finding.id)) {
				task.findings.push({
					findingId: finding.id,
					branchName: this.getFindingBranchName(finding, branch)
				});
			}
		}
		if (taskId === 0) {
			return QUERY.createTask(project, task).fetch();
		}
		return QUERY.updateTask(project, taskId, {}, task).fetch();
	}

	/**
	 * Returns the branch name that should be used for storing the provided finding in a task. Usually this resolves to
	 * the provided branch (retrieved from current selected branch). Only exception is if the finding is a spec item
	 * finding, which only exists on its own specific branch.
	 */
	public static getFindingBranchName(finding: TrackedFinding, branch?: string | null): string | undefined {
		if (FindingsUtils.isSpecItemFindingLocation(finding.location)) {
			// Keep the branch of spec item findings and don't overwrite with selected branch
			return finding.birth.branchName;
		}
		return branch ?? undefined;
	}

	/** Gets the task data stored in the browser's session store. */
	public static getTaskDataFromSessionStore(): TaskDataContainer | null {
		const sessionStorage = UIUtils.getSessionStorage();
		return sessionStorage.get(TaskUtils.FINDINGS_FOR_TASK_SESSION_STORAGE_KEY) as TaskDataContainer;
	}

	/**
	 * Stores the given task data in the browser's session store.
	 *
	 * @param task The task data to store
	 */
	public static putTaskDataInSessionStore(task: TaskDataContainer): void {
		const sessionStorage = UIUtils.getSessionStorage();
		sessionStorage.set(TaskUtils.FINDINGS_FOR_TASK_SESSION_STORAGE_KEY, task);
	}

	/** Returns the task filters */
	public static getTaskFilters(hash: NavigationHash): TaskFilters {
		const statuses = hash
			.getArray(TaskUtils.STATUSES_PARAMETER)
			?.filter(statusName => VALID_STATUS_VALUES.includes(statusName as ETaskStatusEntry))
			.map(statusName => ETaskStatus[statusName as ETaskStatusEntry]) ?? [ETaskStatus.OPEN];
		const tags = hash.getArray(TaskUtils.TAGS_PARAMETER, []);
		const assignees = hash.getArray(TaskUtils.ASSIGNEE_FILTER_PARAMETER, []);
		const authors = hash.getArray(TaskUtils.AUTHOR_FILTER_PARAMETER, []);
		return { statuses, assignees, authors, tags };
	}

	/**
	 * Returns the possible task statuses for the given task and current user with a developer role. This always
	 * includes the current status of the task.
	 */
	public static getPossibleStatusesForDev(status: ETaskStatusEntry): ETaskStatus[] {
		switch (status) {
			case ETaskStatus.OPEN.name:
				return [ETaskStatus.OPEN, ETaskStatus.RESOLVED];
			case ETaskStatus.RESOLVED.name:
				return [ETaskStatus.RESOLVED, ETaskStatus.OPEN];
			case ETaskStatus.DISCARDED.name:
				return [ETaskStatus.DISCARDED];
			case ETaskStatus.VERIFIED.name:
				return [ETaskStatus.VERIFIED];
			default:
				Assertions.fail('Unknown task status: ' + status);
		}
	}

	/**
	 * Returns the possible task statuses for the given task and current user with a TQE role (may edit tasks). This
	 * always includes the current status of the task.
	 */
	public static getPossibleStatusesForTqe(status: ETaskStatusEntry): ETaskStatus[] {
		switch (status) {
			case ETaskStatus.OPEN.name:
				return [ETaskStatus.OPEN, ETaskStatus.RESOLVED, ETaskStatus.DISCARDED];
			case ETaskStatus.RESOLVED.name:
				return [ETaskStatus.RESOLVED, ETaskStatus.VERIFIED, ETaskStatus.DISCARDED, ETaskStatus.OPEN];
			case ETaskStatus.DISCARDED.name:
				return [ETaskStatus.DISCARDED, ETaskStatus.OPEN];
			case ETaskStatus.VERIFIED.name:
				return [ETaskStatus.VERIFIED, ETaskStatus.OPEN];
			default:
				Assertions.fail('Unknown task status: ' + status);
		}
	}
}
