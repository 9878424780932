import { ColorUtils } from 'ts/commons/ColorUtils';

/** Red color to mark the difference in code compare view */
export const DIFF_RED = {
	color: '#f77',
	background: '#fdd'
};

/** Blue color to mark the difference in code compare view */
export const DIFF_BLUE = {
	color: '#77f',
	background: '#ddf'
};

/** Green color to mark the difference in code compare view */
export const DIFF_GREEN = {
	color: '#6e6',
	background: '#cec'
};

/** Yellow color to mark the difference in code compare view. Also used in color-blind mode */
export const DIFF_YELLOW = {
	color: '#F0E442',
	background: ColorUtils.LIGHT_YELLOW
};

/** Green color to mark the difference in code compare view. Only used in color-blind mode */
export const DIFF_GREEN_COLOR_BLIND_MODE = {
	color: '#009E73',
	background: ColorUtils.LIGHT_GREEN
};

/** Blue color to mark the difference in code compare view. Only used in color-blind mode */
export const DIFF_BLUE_COLOR_BLIND_MODE = {
	color: '#648FFF',
	background: ColorUtils.LIGHT_BLUE
};

/** Highlighting color in code compare view */
export const CODE_HIGHLIGHT = {
	color: 'rgba(36,114,255,.55)',
	background: '#eee'
};
