import clsx from 'clsx';
import {
	type ChangeEvent,
	type ComponentPropsWithoutRef,
	type ElementType,
	type ForwardedRef,
	forwardRef
} from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { createShorthandFactory, getComponentType, getUnhandledProps } from '../lib';

/** Props for {@link DropdownSearchInput}. */
export type DropdownSearchInputProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'div'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** An input can have the auto complete. */
		autoComplete?: string;

		/** Additional classes. */
		className?: string;

		/** An input can receive focus. */
		tabIndex?: number | string;

		/** The HTML input type. */
		type?: string;

		/** Stored value. */
		value?: string;

		onChange?: (e: ChangeEvent<HTMLInputElement>, props: DropdownSearchInputProps) => void;
	}
>;

/** A search item sub-component for Dropdown component. */
export const DropdownSearchInput = forwardRef(function DropdownSearchInput(
	props: DropdownSearchInputProps,
	ref: ForwardedRef<HTMLInputElement>
) {
	const { autoComplete = 'off', className, tabIndex, type = 'text', value } = props;

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;

		props.onChange?.(e, { ...props, value: newValue });
	};

	const classes = clsx('search', className);
	const ElementType = getComponentType(props, { defaultAs: 'input' });
	const rest = getUnhandledProps(handledProps, props);

	return (
		<ElementType
			aria-autocomplete="list"
			{...rest}
			autoComplete={autoComplete}
			className={classes}
			onChange={handleChange}
			ref={ref}
			tabIndex={tabIndex}
			type={type}
			value={value}
		/>
	);
});

export const createDropdownSearchInput = createShorthandFactory(DropdownSearchInput, type => ({ type }));
const handledProps = ['as', 'autoComplete', 'className', 'tabIndex', 'type', 'value'];
