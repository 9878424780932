import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import type { SemanticShorthandContent, SemanticTEXTALIGNMENTS } from '../Generic';
import { childrenUtils, getComponentType, getUnhandledProps, keyOnly, textAlignProp } from '../lib';

/** Props for {@link Container}. */
export type ContainerProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** Container has no maximum width. */
	fluid?: boolean;

	/** Reduce maximum width to more naturally accommodate text. */
	text?: boolean;

	/** Describes how the text inside this component should be aligned. */
	textAlign?: SemanticTEXTALIGNMENTS;
};

/** A container limits content to a maximum width. */
export const Container = forwardRef(function Container(props: ContainerProps, ref: ForwardedRef<HTMLDivElement>) {
	const { children, className, content, fluid, text, textAlign } = props;
	const classes = clsx(
		'ui',
		keyOnly(text, 'text'),
		keyOnly(fluid, 'fluid'),
		textAlignProp(textAlign),
		'container',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
});
const handledProps = ['as', 'children', 'className', 'content', 'fluid', 'text', 'textAlign'];
