import type { UseQueryResult } from '@tanstack/react-query';
import { keepPreviousData } from '@tanstack/react-query';
import { QUERY } from 'api/Query';
import clsx from 'clsx';
import { type JSX, type KeyboardEvent, useRef, useState } from 'react';
import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import { useKeyboardShortcut } from 'ts/base/hooks/UseKeyboardShortcut';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { useProjectIdOrEmpty, useProjectIfExists } from 'ts/base/hooks/UseProject';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { GlobalSearchHandler } from 'ts/base/scaffolding/GlobalSearchHandler';
import { Links } from 'ts/commons/links/Links';
import { UIUtils } from 'ts/commons/UIUtils';
import { Icon } from 'ts/components/Icon';
import { Input } from 'ts/components/Input';
import type { ESearchSuggestionTypeEntry } from 'typedefs/ESearchSuggestionType';
import type { SearchSuggestion } from 'typedefs/SearchSuggestion';
import styles from './GlobalSearchBar.module.less';

/** Queries search suggestions for the currently partially entered query string. */
function useSearchSuggestions(
	query: string,
	projectId: string | undefined
): UseQueryResult<Record<ESearchSuggestionTypeEntry, SearchSuggestion[]>> {
	return QUERY.getSearchAutocompletionResults({ token: query, project: projectId }).useQuery({
		placeholderData: keepPreviousData
	});
}

/** The global search bar with autocompletion. */
export function GlobalSearchBar(): JSX.Element {
	const [showResultsPanel, setShowResultsPanel] = useState(false);
	const projectId = useProjectIdOrEmpty();
	const perspectiveContext = usePerspectiveContext();
	const hash = useNavigationHash();
	const [query, setQuery] = useState(hash.getString('query') ?? '');
	const { isPending, data } = useSearchSuggestions(query, projectId);
	const inputRef = useRef<HTMLInputElement>(null);
	useKeyboardShortcut('S', 'Jump to search field', () => inputRef.current?.focus());

	const sortOrder: ESearchSuggestionTypeEntry[] = ['FILE', 'ISSUE', 'REVISION', 'TOKEN'];
	const overallSuggestions = Object.values(data ?? [])
		.flatMap(suggestions => suggestions)
		.sort((suggestionA, suggestionB) => sortOrder.indexOf(suggestionA.type) - sortOrder.indexOf(suggestionB.type));
	const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

	// We don't use the 'Search' component of Semantic UI react here, as we need more flexibility with showing the results, e.g. the 'Configure Search' link at the bottom.
	return (
		<div className="ui search" onBlur={() => setShowResultsPanel(false)}>
			<Input
				id="search"
				loading={isPending}
				style={{ width: showResultsPanel ? '400px' : undefined }}
				className={clsx(styles.darkInput, '!min-w-[120px] [&_input]:flex-1 [&_input]:flex max-w-[250px] mr-2')}
				placeholder="Search..."
				icon={!showResultsPanel ? 'search' : null}
				onFocus={() => setShowResultsPanel(true)}
				onChange={(event, value) => {
					setQuery(value.value);
				}}
				ref={inputRef}
				onKeyDown={handleKeydown}
			/>
			{showResultsPanel && data ? (
				<SearchResultsPanel
					suggestions={overallSuggestions}
					selectedSuggestionIndex={selectedSuggestionIndex}
					query={query}
				/>
			) : null}
		</div>
	);

	function handleKeydown(e: KeyboardEvent<HTMLInputElement>) {
		if (e.code === 'Enter') {
			if (selectedSuggestionIndex > -1 && selectedSuggestionIndex < overallSuggestions.length) {
				new GlobalSearchHandler(perspectiveContext).handleSearchQuery(
					overallSuggestions[selectedSuggestionIndex]!.full,
					e.ctrlKey
				);
			} else {
				new GlobalSearchHandler(perspectiveContext).handleSearchQuery(query, e.ctrlKey);
			}
		} else if (e.code === 'ArrowDown') {
			setSelectedSuggestionIndex(Math.min(selectedSuggestionIndex + 1, overallSuggestions.length - 1));
		} else if (e.code === 'ArrowUp') {
			setSelectedSuggestionIndex(Math.max(0, selectedSuggestionIndex - 1));
		}
	}
}

function getSearchItemIcon(suggestion: SearchSuggestion): JSX.Element {
	switch (suggestion.type) {
		case 'FILE':
			return <Icon name="file alternate outline" />;
		case 'ISSUE':
			return <Icon name="sticky note outline" />;
		default:
			return <Icon name="search" style={{ color: '#aaa' }} />;
	}
}

type SearchResultsPanelProps = {
	suggestions: SearchSuggestion[];
	selectedSuggestionIndex: number;
	query: string;
};

function SearchResultsPanel({ suggestions, selectedSuggestionIndex, query }: SearchResultsPanelProps): JSX.Element {
	const perspectiveContext = usePerspectiveContext();
	const projectId = useProjectIfExists()?.primaryId;

	return (
		<div
			className="results transition visible bg-white shadow-lg pt-1 leading-[2.33] !w-[400px] !mt-0 !right-0 break-all"
			style={{
				left: 'initial'
			}}
		>
			{suggestions.map((suggestion, i) => {
				return (
					<div key={suggestion.project + '-' + suggestion.full}>
						<a
							className={'w-full text-[#333] item ' + (i === selectedSuggestionIndex ? ' active' : '')}
							onMouseDown={event => {
								if (event.button === 0) {
									//Left click with our without pressing ctrl
									new GlobalSearchHandler(perspectiveContext).handleSearchQuery(
										suggestion.full,
										UIUtils.isCtrlKey(event)
									);
								} else if (event.button === 1) {
									//Middle click
									new GlobalSearchHandler(perspectiveContext).handleSearchQuery(
										suggestion.full,
										true
									);
								}
							}}
							style={{
								marginLeft: '0'
							}}
						>
							{getSearchItemIcon(suggestion)}
							<span className="ml-2 overflow-hidden overflow-ellipsis">{suggestion.title}</span>
						</a>
					</div>
				);
			})}{' '}
			<div style={{ fontWeight: '600', padding: '.5em 1.5em' }}>
				<TeamscaleLink
					onMouseDown={e =>
						// Prevent the onBlur() event of the surrounding element, which would prevent the link from working
						e.preventDefault()
					}
					to={Links.searchResults(projectId, query)}
				>
					<Icon name="search" /> Configure search...
				</TeamscaleLink>
			</div>
		</div>
	);
}
