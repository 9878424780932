import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import { createLabel } from 'ts/components/Label/Label';
import { createDimmer, type DimmerProps } from '../Dimmer/Dimmer';
import type {
	SemanticFLOATS,
	SemanticShorthandContent,
	SemanticShorthandItem,
	SemanticSIZES,
	SemanticVERTICALALIGNMENTS
} from '../Generic';
import type { LabelProps } from '../Label';
import {
	childrenUtils,
	createShorthandFactory,
	getComponentType,
	getUnhandledProps,
	htmlImageProps,
	keyOnly,
	keyOrValueAndKey,
	partitionHTMLProps,
	valueAndKey,
	verticalAlignProp
} from '../lib';

/** Props for {@link Image}. */
export type ImageProps = ComponentPropsWithoutRef<'img'> & {
	/** An element type to render as (string or function). */
	// as?: ElementType;

	/** An image may be formatted to appear inline with text as an avatar. */
	avatar?: boolean;

	/** An image may include a border to emphasize the edges of white or transparent content. */
	bordered?: boolean;

	/** An image can appear centered in a content block. */
	centered?: boolean;

	/** Primary content. */
	children?: ReactNode;

	/** An image may appear circular. */
	circular?: boolean;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** An image can show that it is disabled and cannot be selected. */
	disabled?: boolean;

	/** Shorthand for Dimmer. */
	dimmer?: SemanticShorthandItem<DimmerProps>;

	/** An image can sit to the left or right of other content. */
	floated?: SemanticFLOATS;

	/** An image can take up the width of its container. */
	fluid?: boolean;

	/** An image can be hidden. */
	hidden?: boolean;

	/** Renders the Image as an <a> tag with this href. */
	href?: string;

	/** An image may appear inline. */
	inline?: boolean;

	/** Shorthand for Label. */
	label?: SemanticShorthandItem<LabelProps>;

	/** An image may appear rounded. */
	rounded?: boolean;

	/** An image may appear at different sizes. */
	size?: SemanticSIZES;

	/** An image can specify that it needs an additional spacing to separate it from nearby content. */
	spaced?: boolean | 'left' | 'right';

	/** Whether or not to add the ui className. */
	ui?: boolean;

	/** An image can specify its vertical alignment. */
	verticalAlign?: SemanticVERTICALALIGNMENTS;

	/** An image can render wrapped in a `div.ui.image` as alternative HTML markup. */
	wrapped?: boolean;
};

/**
 * An image is a graphic representation of something.
 *
 * @see Icon
 */
export const Image = forwardRef(function Image(props: ImageProps, ref: ForwardedRef<HTMLImageElement>) {
	const {
		avatar,
		bordered,
		centered,
		children,
		circular,
		className,
		content,
		dimmer,
		disabled,
		floated,
		fluid,
		hidden,
		href,
		inline,
		label,
		rounded,
		size,
		spaced,
		verticalAlign,
		wrapped,
		ui = true
	} = props;

	const classes = clsx(
		keyOnly(ui, 'ui'),
		size,
		keyOnly(avatar, 'avatar'),
		keyOnly(bordered, 'bordered'),
		keyOnly(circular, 'circular'),
		keyOnly(centered, 'centered'),
		keyOnly(disabled, 'disabled'),
		keyOnly(fluid, 'fluid'),
		keyOnly(hidden, 'hidden'),
		keyOnly(inline, 'inline'),
		keyOnly(rounded, 'rounded'),
		keyOrValueAndKey(spaced, 'spaced'),
		valueAndKey(floated, 'floated'),
		verticalAlignProp(verticalAlign),
		'image',
		className
	);

	const rest = getUnhandledProps(handledProps, props);
	const [imgTagProps, rootProps] = partitionHTMLProps(rest, { htmlProps: htmlImageProps });

	const ElementType = getComponentType(props, {
		defaultAs: 'img',
		getDefault: () => {
			if (dimmer != null || label != null || wrapped != null || !childrenUtils.isNil(children)) {
				return 'div';
			}
			return undefined;
		}
	});

	if (!childrenUtils.isNil(children)) {
		return (
			<ElementType {...rest} className={classes} ref={ref}>
				{children}
			</ElementType>
		);
	}
	if (!childrenUtils.isNil(content)) {
		return (
			<ElementType {...rest} className={classes} ref={ref}>
				{content}
			</ElementType>
		);
	}

	if (ElementType === 'img') {
		return <ElementType {...rootProps} {...imgTagProps} className={classes} ref={ref} />;
	}

	return (
		<ElementType {...rootProps} className={classes} href={href}>
			{createDimmer(dimmer, { autoGenerateKey: false })}
			{createLabel(label, { autoGenerateKey: false })}
			<img {...imgTagProps} ref={ref} />
		</ElementType>
	);
});

export const createImage = createShorthandFactory(Image, value => ({ src: value }));
const handledProps = [
	'as',
	'avatar',
	'bordered',
	'centered',
	'children',
	'circular',
	'className',
	'content',
	'dimmer',
	'disabled',
	'floated',
	'fluid',
	'hidden',
	'href',
	'inline',
	'label',
	'rounded',
	'size',
	'spaced',
	'ui',
	'verticalAlign',
	'wrapped'
];
