import { linkTo } from 'ts/commons/links/LinkTo';
import { ESearchPerspectiveView } from 'ts/perspectives/search/ESearchPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/**
 * Returns a URL to search results.
 *
 * @param project The project to search in.
 * @param query The query that is to be searched.
 */
export function searchResults(project: string | undefined, query: string) {
	return linkTo(ETeamscalePerspective.SEARCH, ESearchPerspectiveView.SEARCH, project, { query });
}

/** Returns a URL to search view */
export function searchView() {
	return linkTo(ETeamscalePerspective.SEARCH, ESearchPerspectiveView.SEARCH);
}
