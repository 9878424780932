import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { StringUtils } from 'ts/commons/StringUtils';

/** Utils for formatting a during into human-readable text and to parse such a text back to a number. */
export class DurationUtils {
	/** A pattern describing durations given as strings */
	private static readonly DURATION_PATTERN =
		'((?<days>\\d+)d)?' +
		'\\s*((?<hours>\\d+)h)?' +
		'\\s*((?<minutes>\\d+)m(\\s+|$)+)?' +
		'\\s*((?<seconds>\\d+)s)?' +
		'\\s*((?<millis>\\d+)ms)?$';

	/**
	 * Converts a given value in seconds to hours, minutes and remaining seconds
	 *
	 * @param totalSeconds The total number of seconds
	 */
	public static convertSecondsToFormattedTime(totalSeconds: number, abbreviateValues = true): string {
		if (totalSeconds <= 0) {
			return '0s';
		}
		if (!abbreviateValues) {
			if (totalSeconds < 1) {
				return Number(totalSeconds).toFixed(3) + 's';
			}
			return Number(totalSeconds).toFixed(0) + 's';
		}

		const secondsPerDay = 3600 * 24;
		const result: Array<{ value: number; unit: string }> = [
			{ unit: 'd', value: Math.floor(totalSeconds / secondsPerDay) },
			{ unit: 'h', value: Math.floor((totalSeconds % secondsPerDay) / 3600) },
			{ unit: 'm', value: Math.floor((totalSeconds % 3600) / 60) },
			{ unit: 's', value: Math.floor(totalSeconds % 60) },
			{ unit: 'ms', value: Math.round((totalSeconds - Math.floor(totalSeconds)) * 1000) }
		];

		return ArrayUtils.trimArray(result, element => element.value === 0)
			.map(element => `${element.value}${element.unit}`)
			.join(' ');
	}

	/** Converts a given string in the format of `DURATION_PATTERN` to seconds (a number). */
	public static convertDurationTextToSeconds(durationText: string): number {
		if (StringUtils.isEmptyOrWhitespace(durationText)) {
			return 0;
		}

		const matchResult = new RegExp(DurationUtils.DURATION_PATTERN).exec(durationText);

		let resultSeconds = 0;
		if (matchResult?.groups && Object.values(matchResult.groups).filter(v => v).length > 0) {
			resultSeconds += Number.parseInt(matchResult.groups.days ?? '0') * 24 * 60 * 60;
			resultSeconds += Number.parseInt(matchResult.groups.hours ?? '0') * 60 * 60;
			resultSeconds += Number.parseInt(matchResult.groups.minutes ?? '0') * 60;
			resultSeconds += Number.parseInt(matchResult.groups.seconds ?? '0');
			resultSeconds += Number.parseInt(matchResult.groups.millis ?? '0') / 1000;

			return resultSeconds;
		}
		return NaN;
	}
}
