import clsx from 'clsx';
import { type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { SemanticShorthandContent } from '../Generic';
import { childrenUtils, createShorthandFactory, getComponentType, getUnhandledProps, keyOnly } from '../lib';
import { Segment, type SegmentProps } from '../Segment';

/** Props for {@link TabPane}. */
export type TabPaneProps = ExtendTypeWith<
	SegmentProps,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** A tab pane can be active. */
		active?: boolean;

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** Shorthand for primary content. */
		content?: SemanticShorthandContent;

		/** A Tab.Pane can display a loading indicator. */
		loading?: boolean;
	}
>;

/** A tab pane holds the content of a tab. */
export const TabPane = forwardRef(function TabPane(props: TabPaneProps, ref: ForwardedRef<HTMLDivElement>) {
	const { active = true, children, className, content, loading } = props;

	const classes = clsx(keyOnly(active, 'active'), keyOnly(loading, 'loading'), 'tab', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props, { defaultAs: Segment });

	const calculatedDefaultProps: Partial<SegmentProps> = {};

	if (ElementType === Segment) {
		calculatedDefaultProps.attached = 'bottom';
	}

	return (
		<ElementType {...calculatedDefaultProps} {...rest} className={classes} ref={ref}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
});

export const createTabPane = createShorthandFactory(TabPane, content => ({ content }));
const handledProps = ['active', 'as', 'children', 'className', 'content', 'loading'];
