import { QueryClient } from '@tanstack/react-query';

/** The query client used */
export const QUERY_CLIENT = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
			// We want to handle errors by default via the error boundary to avoid that error handling is ignored.
			throwOnError: true,
			// Site should also work when not connected to the Internet (i.e. Demo Setup)
			networkMode: 'offlineFirst'
		},
		mutations: {
			// Site should also work when not connected to the Internet (i.e. Demo Setup)
			networkMode: 'offlineFirst'
		}
	}
});
