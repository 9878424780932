import clsx from 'clsx';
import { type JSX, useState } from 'react';
import type { DropdownProps } from 'ts/components/Dropdown';
import type { Callback } from 'ts/base/Callback';
import { useProjectInfos } from 'ts/base/hooks/ProjectsInfosHook';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import type { DropdownItemOptions, ValueAwareComponentType } from 'ts/commons/InMenuSearchableDropdown';
import { addDropdownItemsWithHeader, InMenuSearchableDropdown } from 'ts/commons/InMenuSearchableDropdown';
import { PerspectiveUtils } from 'ts/commons/PerspectiveUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import type { ExtendedPerspectiveContextProjectInfos } from 'ts/data/ExtendedPerspectiveContextProjectInfos';
import type { ExtendedProjectInfo } from 'ts/data/ExtendedProjectInfo';
import styles from './ProjectSelector.module.less';

/** Props for ProjectSelector. */
export type ProjectSelectorProps = {
	selectorId?: string;
	/** Whether the project selector should allow to select "All Projects". */
	showAllProjectsItem?: boolean;
	/** The active/selected project ID. */
	activeProjectId?: string;
	/** If this is true, project selection is disabled */
	projectIsFixed?: boolean;
	/** Returns the primary project ID of the project that was selected or an empty string if all projects was selected. */
	onChange: Callback<string>;
	/** The component that should be used to render the project items. */
	asItem?: ValueAwareComponentType;
};

/** Shows the project selector. */
export function ProjectSelector({
	selectorId,
	showAllProjectsItem = false,
	activeProjectId = '',
	projectIsFixed,
	onChange,
	asItem
}: ProjectSelectorProps): JSX.Element {
	const [filterText, setFilterText] = useState('');
	const projectInfos = useProjectInfos();

	const items = useProjectDropdownItems(activeProjectId, showAllProjectsItem, filterText, asItem);
	return (
		<InMenuSearchableDropdown
			button
			id={selectorId}
			disabled={projectIsFixed}
			value={activeProjectId}
			onChange={(event: unknown, data: DropdownProps) => onChange(String(data.value))}
			filterQuery={filterText}
			onFilterChange={setFilterText}
			className={clsx(
				'auto-menu-width',
				'unobtrusive',
				'floating-width',
				'icon',
				'limit-height-to-screen',
				styles.projectDropdown
			)}
			text={getSelectedProjectLabel(projectInfos, activeProjectId, showAllProjectsItem)}
			items={items}
		/>
	);
}

/** Returns the text to show on the collapsed project selector dropdown. */
function getSelectedProjectLabel(
	projectInfos: ExtendedPerspectiveContextProjectInfos,
	selectedProjectId: string | undefined,
	showAllProjects: boolean
) {
	const selectedProjectInfo = projectInfos.findProjectInfo(selectedProjectId ?? null);
	if (selectedProjectInfo !== null) {
		return selectedProjectInfo.uniqueReadableName;
	} else if (showAllProjects) {
		return PerspectiveUtils.ALL_PROJECTS;
	} else {
		return 'Project...';
	}
}

/** Determines the dropdown items that should be shown already taking into account filtering. */
function useProjectDropdownItems(
	activeProjectId: string,
	showAllProjects: boolean,
	filterText: string,
	asItem?: ValueAwareComponentType
): DropdownItemOptions[] {
	const projectInfos = useProjectInfos();
	const recentProjects = [...projectInfos.recentProjects];
	const currentProject = projectInfos.findProjectInfo(activeProjectId);
	ensureRecentProjectsIncludesCurrentProject(recentProjects, currentProject);
	const recentProjectIds = new Set(recentProjects.map(projectInfo => projectInfo.primaryId));
	const otherProjects = projectInfos.projectsInfo.filter(projectInfo => !recentProjectIds.has(projectInfo.primaryId));
	const items: DropdownItemOptions[] = [];
	if (showAllProjects && filterText === '') {
		addDropdownItemsWithHeader(items, 'General', [{ text: PerspectiveUtils.ALL_PROJECTS, value: '', as: asItem }]);
	}

	function filter(project: ExtendedProjectInfo): boolean {
		return StringUtils.isContainedInAnyIgnoreCase(filterText, project.name, ...project.publicIds);
	}

	function toProjectItem(project: ExtendedProjectInfo): DropdownItemOptions {
		return {
			text: project.uniqueReadableName,
			value: project.primaryId,
			as: asItem
		};
	}

	addDropdownItemsWithHeader(items, 'Recent projects', recentProjects.filter(filter).map(toProjectItem));
	addDropdownItemsWithHeader(
		items,
		recentProjects.length > 0 ? 'Other Projects' : 'Projects',
		otherProjects.filter(filter).map(toProjectItem)
	);

	return items;
}

/**
 * The maximum number of recently visited projects to show. Must correspond to the respective entry in
 * PerspectiveContextUtils.java.
 */
const MAX_RECENT_PROJECTS = 5;

/** Updates the recent projects in the local perspective context when a new project is selected. */
function ensureRecentProjectsIncludesCurrentProject(
	recentProjects: ExtendedProjectInfo[],
	currentProject: ExtendedProjectInfo | null
): void {
	if (currentProject === null) {
		return;
	}
	// Optimization in case the current project is also the most recent project
	if (!ArrayUtils.isEmpty(recentProjects) && recentProjects[0]!.internalId === currentProject.internalId) {
		return;
	}
	// Else, reorder current project to front
	const index = recentProjects.findIndex(project => project.internalId === currentProject.internalId);
	if (index !== -1) {
		ArrayUtils.removeAt(recentProjects, index);
	}
	recentProjects.unshift(currentProject);
	while (recentProjects.length > MAX_RECENT_PROJECTS) {
		recentProjects.pop();
	}
}
