import type { CodeSnippet } from 'typedefs/CodeSnippet';
import type { TrackedFinding } from 'typedefs/TrackedFinding';

/**
 * A Container used to store data relevant for the creation of a task. This is used to temporarily store task data in
 * the browser's session storage.
 *
 * @see ts.tasks.TaskUtils.addFindingsToTask
 */
export class TaskDataContainer {
	/** The list of findings, which should be added to the new task. */
	public findings: TrackedFinding[];

	/** A source code snippet to add to a new or existing task. */
	public codeSnippet: CodeSnippet | null;

	/**
	 * @param subject Subject, which should be used for the new task.
	 * @param description Description, which should be used for the new task.
	 * @param findings List of findings, which should be added to the new task.
	 * @param blacklistedFindingIds The findings that are blacklisted
	 * @param branch The branch for which the findings should be added to the task.
	 * @param codeSnippets List of source code snippets.
	 */
	public constructor(
		public subject: string,
		public description: string,
		findings: TrackedFinding[] | undefined | null,
		public blacklistedFindingIds: string[],
		public branch: string | null,
		codeSnippet: CodeSnippet | null
	) {
		this.findings = findings ?? [];
		this.codeSnippet = codeSnippet;
	}
}
