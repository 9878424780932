import type { JSX } from 'react';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import {
	convertDefinedPointInTimeToOption,
	DefinedPointInTimeDropdown,
	useControlledSelectValue
} from 'ts/commons/time/components/TimePickerUtils';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';

function extractPointInTime(value: string): TypedPointInTime | null {
	const { name, project } = JSON.parse(value);
	if (name == null || project == null) {
		return null;
	}
	return TimeUtils.systemVersion(name, project);
}

function setInitialValue(defaultValue: TypedPointInTime | null): string | undefined {
	if (defaultValue != null && defaultValue.type === EPointInTimeType.SYSTEM_VERSION) {
		return JSON.stringify({
			name: defaultValue.value.name,
			project: defaultValue.value.project
		});
	}
	return undefined;
}

/** A component for picking a system version. */
export function SystemVersionPicker(): JSX.Element | null {
	const { systemVersions } = useTimePickerContext();
	const [selectedValue, setSelectedValue] = useControlledSelectValue(
		'systemVersion',
		extractPointInTime,
		setInitialValue
	);

	if (ArrayUtils.isEmptyOrUndefined(systemVersions)) {
		return null;
	}
	return (
		<DefinedPointInTimeDropdown
			testId="system-version-select"
			selectedValue={selectedValue}
			setSelectedValue={setSelectedValue}
			displayObjects={systemVersions}
			optionMapper={convertDefinedPointInTimeToOption}
		/>
	);
}
