import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ForwardedRef, forwardRef } from 'react';
import { getComponentType, getUnhandledProps, keyOnly } from '../lib';

/** Props for {@link PlaceholderImage}. */
export type PlaceholderImageProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** Additional classes. */
	className?: string;

	/** An image can modify size correctly with responsive styles. */
	square?: boolean;

	/** An image can modify size correctly with responsive styles. */
	rectangular?: boolean;
};

/** A placeholder can contain an image. */
export const PlaceholderImage = forwardRef(function PlaceholderImage(
	props: PlaceholderImageProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const { className, square, rectangular } = props;
	const classes = clsx(keyOnly(square, 'square'), keyOnly(rectangular, 'rectangular'), 'image', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return <ElementType {...rest} className={classes} ref={ref} />;
});
const handledProps = ['as', 'className', 'rectangular', 'square'];
