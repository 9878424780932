import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useLocation } from 'ts/base/hooks/UseLocation';
import { NavigationHash } from 'ts/commons/NavigationHash';

/** Options for useHashState. */
type HashStateOptions<T> = {
	/**
	 * Validates the extracted value. If the value is determined to be not acceptable in the current context the
	 * fallback value will be used instead.
	 */
	isValid?: (value: T) => boolean;

	/**
	 * Whether the view should be reloaded after updating the URL. By default only the URL is applied and React
	 * components are re-rendered, without re-creating the containing view.
	 */
	reloadView?: boolean;

	/**
	 * Allows to conditionally disable storing the state in the URL, but stores the state locally in the component
	 * instead.
	 */
	doNotUpdate?: boolean;
};

/** All data types that can be serialized into the navigation hash. */
type HashSerializableTypes = string | number | boolean | string[];

/**
 * Derives the result type of useHashState from the type of the given default value. If null is given we assume that the
 * value is of type string. If the fallback is a concrete fixed string/boolean/number/string[] we want to widen the type
 * to all kinds of strings. Otherwise, we assume the type of the default value matches the type of the result.
 */
type Result<T> = T extends null
	? string | null
	: T extends string
		? string
		: T extends boolean
			? boolean
			: T extends number
				? number
				: T extends string[]
					? string[]
					: T;

/**
 * Provides a state that is persisted in the URL. Setting the state does not reload the whole view, but it does
 * re-render the React components on the page.
 *
 * @param key The key under which the value is stored in the URL as a query like parameter in the navigation hash.
 * @param fallbackValue Uses the given value when the navigation hash does not contain a value for the key
 */
export function useHashState<T extends HashSerializableTypes | null>(
	key: string,
	fallbackValue: T,
	options?: Partial<HashStateOptions<Result<T>>>
): [Result<T>, Dispatch<SetStateAction<Result<T>>>] {
	const extractedValue = useLocation(location =>
		extractValueFromNavigationHash(NavigationHash.fromPath(location), key, fallbackValue)
	);

	let derivedInitialValue = extractedValue;
	const validator = options?.isValid;
	if (validator && !validator(extractedValue)) {
		derivedInitialValue = fallbackValue as Result<T>;
	}

	const [localState, setLocalState] = useState<Result<T>>(derivedInitialValue);
	if (options?.doNotUpdate) {
		return [localState, setLocalState];
	}

	const setValue = (value: SetStateAction<Result<T>>): void => {
		let valueToStore: Result<T>;
		// Allow value to be a function so we have same API as useState
		if (value instanceof Function) {
			valueToStore = value(derivedInitialValue);
		} else {
			valueToStore = value;
		}

		// The navigation hash might have changed in the meantime
		const currentHash = NavigationHash.getCurrent();

		if (valueToStore == null) {
			currentHash.remove(key);
		} else if (Array.isArray(valueToStore)) {
			currentHash.setArray(key, valueToStore);
		} else {
			currentHash.set(key, valueToStore);
		}
		if (options?.reloadView) {
			currentHash.navigate();
		} else {
			currentHash.applyUrlWithoutReload();
		}
	};

	return [derivedInitialValue, setValue];
}

function extractValueFromNavigationHash<T extends HashSerializableTypes | null>(
	hash: NavigationHash,
	key: string,
	fallbackValue: T
): Result<T> {
	if (typeof fallbackValue === 'number') {
		return (hash.getNumber(key) ?? fallbackValue) as unknown as Result<T>;
	} else if (typeof fallbackValue === 'boolean') {
		return hash.getBoolean(key, fallbackValue) as unknown as Result<T>;
	} else if (Array.isArray(fallbackValue)) {
		return hash.getArray(key, fallbackValue) as unknown as Result<T>;
	}
	return (hash.getString(key) ?? fallbackValue) as unknown as Result<T>;
}
