/** Generated from ETestGapState.java. Do not modify this file! */


export type ETestGapStateEntry = 'EXECUTED' | 'NOT_EXECUTED' | 'TESTED_CHURN' | 'UNTESTED_CHANGE' | 'UNTESTED_ADDITION' | 'UNCHANGED' | 'NA';

export class ETestGapState {
	static readonly EXECUTED = new ETestGapState(0, 'EXECUTED', "Was executed at least once within the time range", "Executed");
	static readonly NOT_EXECUTED = new ETestGapState(1, 'NOT_EXECUTED', "Not executed within the time range", "Not executed");
	static readonly TESTED_CHURN = new ETestGapState(2, 'TESTED_CHURN', "Was tested after the latest modification", "Tested");
	static readonly UNTESTED_CHANGE = new ETestGapState(3, 'UNTESTED_CHANGE', "Method existed at the baseline and was not tested after its latest modification", "Untested modification");
	static readonly UNTESTED_ADDITION = new ETestGapState(4, 'UNTESTED_ADDITION', "Method was added after the baseline and was not tested after its latest modification", "Untested addition");
	static readonly UNCHANGED = new ETestGapState(5, 'UNCHANGED', "Was not changed since the baseline", "Unchanged");
	static readonly NA = new ETestGapState(6, 'NA', "", "");
	static readonly values = [
		ETestGapState.EXECUTED,
		ETestGapState.NOT_EXECUTED,
		ETestGapState.TESTED_CHURN,
		ETestGapState.UNTESTED_CHANGE,
		ETestGapState.UNTESTED_ADDITION,
		ETestGapState.UNCHANGED,
		ETestGapState.NA
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: ETestGapStateEntry,
		public readonly longDescription: string,
		public readonly shortDescription: string
	){
	}

	toString() {
		return this.name;
	}
}

