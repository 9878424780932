/* eslint-disable @typescript-eslint/consistent-type-definitions,@typescript-eslint/no-empty-object-type */
// noinspection JSUnusedGlobalSymbols

import {
	type Column,
	ColumnSizing as BuiltInColumnSizing,
	type ColumnSizingColumnDef,
	type ColumnSizingDefaultOptions,
	type RowData,
	type Table,
	type TableFeature
} from '@tanstack/react-table';

type FractionalColumnSizingTableState = {
	/** Holds the width in pixels that the table should try to fill. */
	intrinsicAvailableWidth: number;
};

declare module '@tanstack/react-table' {
	/** An extension to the Tanstack Table ColumnSizingColumnDef. */
	interface ColumnSizingColumnDef {
		/**
		 * The desired unit for the size property of the column definition. Default is 'fr', which means a fraction of
		 * the remaining space. If there are two columns with 1fr and 2fr respectively the first column receives 1/3 or
		 * the available width and the second one 2/3.
		 */
		sizeUnit?: 'px' | 'fr';
	}

	/** Merges the FractionalColumnSizingTableState state into the TableState. */
	interface TableState extends FractionalColumnSizingTableState {}

	/** Methods added by the feature to the table instance. */
	interface FractionalColumnSizingInstance {
		/** Sets the available width in pixels that the table should try to fill. */
		setIntrinsicAvailableWidth: (width: number) => void;
	}

	/** Enhance the table definition by FractionalColumnSizingInstance. */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface Table<TData extends RowData> extends FractionalColumnSizingInstance {}
}

/** By default we distribute the space evenly across all columns. */
const defaultColumnSizing = {
	size: 1,
	minSize: 20,
	maxSize: Number.MAX_SAFE_INTEGER,
	sizeUnit: 'fr'
} as const;

function getColumnSizeUnit<D, V>(column: Column<D, V>) {
	return column.columnDef.sizeUnit ?? defaultColumnSizing.sizeUnit;
}

function getMinSize<D>(column: Column<D, unknown>) {
	return column.columnDef.minSize ?? defaultColumnSizing.minSize;
}

function getMaxSize<D>(column: Column<D, unknown>) {
	return column.columnDef.maxSize ?? defaultColumnSizing.maxSize;
}

function minMax<D>(column: Column<D, unknown>, pixelSize: number) {
	return Math.min(Math.max(getMinSize(column), pixelSize), getMaxSize(column));
}

/**
 * Enhances Tanstack table with support for fractional size units. Columns can claim either a fixed pixel width via
 * `sizeUnit: 'px'` or a flexible size via `sizeUnit: 'fr'` in their column definition. For this to work the
 * `intrinsicAvailableWidth` needs to be set on the table.
 */
export const FractionalColumnSizing: TableFeature = {
	getDefaultColumnDef: (): ColumnSizingColumnDef => {
		return defaultColumnSizing;
	},

	getInitialState: (state): FractionalColumnSizingTableState => {
		return {
			intrinsicAvailableWidth: 0,
			...state
		};
	},

	getDefaultOptions: <TData extends RowData>(table: Table<TData>): ColumnSizingDefaultOptions => {
		return {
			...BuiltInColumnSizing.getDefaultOptions?.(table),
			columnResizeMode: 'onChange'
		};
	},

	createColumn: <TData extends RowData, TValue>(column: Column<TData, TValue>, table: Table<TData>): void => {
		column.getSize = () => {
			const tableState = table.getState();
			const columnSizing = tableState.columnSizing;
			const columnSize = columnSizing[column.id];
			if (columnSize || getColumnSizeUnit(column) === 'px') {
				return minMax(column, columnSize ?? column.columnDef.size ?? defaultColumnSizing.size);
			}

			// Calculate fractional column size via a Min-Max-bound linear-scaling algorithm
			const visibleFlatColumns = table.getVisibleFlatColumns();

			// First determine the columns with a fixed pixel width, which either have a sizeUnit of px or have already
			// been resized manually.
			let totalFractions = 0;
			let fixedSize = 0;
			const flexColumns: Array<Column<TData, unknown>> = [];
			for (const visibleColumn of visibleFlatColumns) {
				if (getColumnSizeUnit(visibleColumn) === 'px' || columnSizing[visibleColumn.id]) {
					fixedSize += visibleColumn.getSize();
				} else {
					totalFractions += visibleColumn.columnDef.size!;
					flexColumns.push(visibleColumn);
				}
			}

			// Try to distribute the flexible parts according to their fractions and fix those values that would run
			// below or above their defined min-max bounds. Repeat this process until the min-max bounds are satisfied
			// for all columns.
			const intrinsicAvailableWidth = tableState.intrinsicAvailableWidth;
			const widths: Record<string, number> = {};
			let oneFraction;
			let previousTotalFractions;
			do {
				previousTotalFractions = totalFractions;
				oneFraction = (intrinsicAvailableWidth - fixedSize) / totalFractions;
				for (const flexColumn of flexColumns) {
					let pixelSize = oneFraction * flexColumn.columnDef.size!;
					if (pixelSize < getMinSize(column) && widths[column.id] === undefined) {
						pixelSize = getMinSize(column);
						widths[flexColumn.id] = pixelSize;
						fixedSize += pixelSize;
						totalFractions--;
					}
				}

				oneFraction = (intrinsicAvailableWidth - fixedSize) / totalFractions;
				for (const flexColumn of flexColumns) {
					let pixelSize = oneFraction * flexColumn.columnDef.size!;
					if (pixelSize >= getMaxSize(column) && widths[column.id] === undefined) {
						pixelSize = getMaxSize(column);
						widths[flexColumn.id] = pixelSize;
						fixedSize += pixelSize;
						totalFractions--;
					}
				}
			} while (previousTotalFractions > totalFractions);

			// Assign the remaining widths to the remaining fractional columns
			oneFraction = (intrinsicAvailableWidth - fixedSize) / totalFractions;
			for (const flexColumn of flexColumns) {
				if (widths[column.id] === undefined) {
					widths[flexColumn.id] = oneFraction * flexColumn.columnDef.size!;
				}
			}

			return widths[column.id]!;
		};
	},

	createTable: <TData extends RowData>(table: Table<TData>): void => {
		table.setIntrinsicAvailableWidth = width =>
			table.setState(state => ({ ...state, intrinsicAvailableWidth: width }));
	}
};
