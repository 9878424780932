import type { JSX } from 'react';
import type { FieldPath, FieldValues, PathValue } from 'react-hook-form';
import { useFormContext, useWatch } from 'react-hook-form';
import type { FormDropdownProps } from 'ts/components/Form';
import { FormDropdown } from 'ts/components/Form';

type HookFormDropdownProps<
	TFieldValues extends FieldValues = FieldValues,
	TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<FormDropdownProps, 'name' | 'value' | 'onChange'> & {
	name: TFieldName;
};

/**
 * This is a wrapper around Form.Dropdown that is compatible with react-hook-form. The implementation assumes that the
 * react-hook-form exposed value is a string or string[] (when <code>multiple</code> is set of on the dropdown).
 */
export function HookFormDropdown<
	TFieldValues extends FieldValues = FieldValues,
	TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ name, ...props }: HookFormDropdownProps<TFieldValues, TFieldName>): JSX.Element {
	const { control, setValue } = useFormContext<TFieldValues>();

	const dropdownValue = useWatch({ name, control });

	return (
		<FormDropdown
			value={dropdownValue}
			onChange={(_, data) => setValue(name, data.value as PathValue<TFieldValues, TFieldName>)}
			{...props}
		/>
	);
}
