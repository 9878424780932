import { useSuspenseQueries } from '@tanstack/react-query';
import type { QueryOperation } from 'api/Query';

type GetResults<T> = T extends QueryOperation<infer TData> ? TData : unknown;

type SuspendingQueriesResult<T extends readonly unknown[] | []> = { [P in keyof T]: GetResults<T[P]> };

/**
 * Queries a list of given operations from QUERY, suspends while they are loading and returns the actual data as an
 * array afterwards.
 */
export function useSuspendingQueries<T extends unknown[] | []>(...operations: T): SuspendingQueriesResult<T> {
	const queries = operations.map(operation => (operation as QueryOperation<unknown>).suspenseQuery());
	return useSuspenseQueries({ queries }).map(result => result.data) as SuspendingQueriesResult<T>;
}
