import { type ForwardedRef, forwardRef } from 'react';
import { Checkbox, type CheckboxProps } from '../Checkbox';
import { getUnhandledProps } from '../lib';

/** Props for {@link Radio}. */
export type RadioProps = {
	/** Format to emphasize the current selection state. */
	slider?: boolean;

	/** Format to show an on or off choice. */
	toggle?: boolean;

	/** HTML input type, either checkbox or radio. */
	type?: 'checkbox' | 'radio';
} & CheckboxProps;

/**
 * A Radio is sugar for <Checkbox radio />. Useful for exclusive groups of sliders or toggles.
 *
 * @see Checkbox
 * @see Form
 */
export const Radio = forwardRef(function Radio(props: RadioProps, ref: ForwardedRef<HTMLInputElement>) {
	const { slider, toggle, type = 'radio' } = props;

	const rest = getUnhandledProps(handledProps, props);
	// radio, slider, toggle are exclusive
	// use an undefined radio if slider or toggle are present
	const radio = !(slider || toggle) || undefined;

	return <Checkbox {...rest} type={type} radio={radio} slider={slider} toggle={toggle} ref={ref} />;
});
const handledProps = ['slider', 'toggle', 'type'];
