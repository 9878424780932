import type { PointInTime } from './PointInTime';

/** Base class for point in time formatters. */
export abstract class PointInTimeFormatter<T extends PointInTime> {
	/** Creates a readable string. */
	public abstract format(pointInTime: T): string;

	/** Creates a readable string when indicating a trend since the point in time to format. */
	public formatAsTrend(pointInTime: T): string {
		return 'Trend since ' + this.format(pointInTime);
	}

	/** Creates a readable string when used as a trend in the task summary widget. */
	public formatAsTaskTrend(pointInTime: T): string {
		return 'Non-open tasks counted starting at ' + this.format(pointInTime);
	}

	/** Creates a readable string when used as a baseline in the findings perspective. */
	public formatAsBaseline(pointInTime: T): string {
		return 'since ' + this.format(pointInTime);
	}
}
