import type { SemanticTEXTALIGNMENTS } from 'ts/components/Generic';
import { numberToWord } from './numberToWord';

/*
 * There are 3 prop patterns used to build up the className for a component.
 * Each utility here is meant for use in a classnames() argument.
 *
 * There is no util for valueOnly() because it would simply return val.
 * Use the prop value inline instead.
 *   <Label size='big' />
 *   <div class="ui big label"></div>
 */

/**
 * Props where only the prop key is used in the className.
 *
 * @example
 * 	<Label tag />
 * 	<div class="ui tag label"></div>
 *
 * @param {any} val A props value
 * @param {string} key A props key
 */
export const keyOnly = (val: unknown, key: string) => (val ? key : undefined);

/**
 * Props that require both a key and value to create a className.
 *
 * @example
 * 	<Label corner='left' />
 * 	<div class="ui left corner label"></div>
 *
 * @param {any} val A props value
 * @param {string} key A props key
 */
export const valueAndKey = (val: string | boolean | number | undefined, key: string) =>
	val && val !== true && `${val} ${key}`;

/**
 * Props whose key will be used in className, or value and key.
 *
 * @example
 * 	Key Only
 * 	<Label pointing />
 * 	<div class="ui pointing label"></div>
 *
 * @example
 * 	Key and Value
 * 	<Label pointing='left' />
 * 	<div class="ui left pointing label"></div>
 *
 * @param {any} val A props value
 * @param {string} key A props key
 */
export const keyOrValueAndKey = (val: string | boolean | undefined, key: string) =>
	val && (val === true ? key : `${val} ${key}`);

//
// Prop to className exceptions
//

/**
 * The "multiple" prop implements control of visibility and reserved classes for Grid subcomponents.
 *
 * @example
 * 	<Grid.Row only='mobile' />
 * 	<Grid.Row only='mobile tablet' />
 * 	<div class="mobile only row"></div>
 * 	<div class="mobile only tablet only row"></div>
 *
 * @param {any} val The value of the "multiple" prop
 * @param {any} key A props key
 */
export const multipleProp = (val: string | boolean | undefined, key: string) => {
	if (!val || val === true) {
		return null;
	}

	return val
		.replace('large screen', 'large-screen')
		.replace(/ vertically/g, '-vertically')
		.split(' ')
		.map(prop => `${prop.replace('-', ' ')} ${key}`)
		.join(' ');
};

/**
 * The "textAlign" prop follows the useValueAndKey except when the value is "justified'. In this case, only the class
 * "justified" is used, ignoring the "aligned" class.
 *
 * @example
 * 	<Container textAlign='justified' />
 * 	<div class="ui justified container"></div>
 *
 * @example
 * 	<Container textAlign='left' />
 * 	<div class="ui left aligned container"></div>
 *
 * @param {any} val The value of the "textAlign" prop
 */
export const textAlignProp = (val: SemanticTEXTALIGNMENTS | undefined) =>
	val === 'justified' ? 'justified' : valueAndKey(val, 'aligned');

/**
 * The "verticalAlign" prop follows the useValueAndKey.
 *
 * @example
 * 	<Grid verticalAlign='middle' />
 * 	<div class="ui middle aligned grid"></div>
 *
 * @param {any} val The value of the "verticalAlign" prop
 */
export const verticalAlignProp = (val: string | undefined) => valueAndKey(val, 'aligned');

/**
 * Create "X", "X wide" and "equal width" classNames. "X" is a numberToWord value and "wide" is configurable.
 *
 * @example
 * 	<Grid columns='equal' />
 * 	<div class="ui equal width grid"></div>
 *
 * 	<Form widths='equal' />
 * 	<div class="ui equal width form"></div>
 *
 * 	<FieldGroup widths='equal' />
 * 	<div class="equal width fields"></div>
 *
 * @example
 * 	<Grid columns={4} />
 * 	<div class="ui four column grid"></div>
 *
 * @param val The prop value
 * @param [widthClass=''] The class. Default is `''`
 * @param [canEqual=false] Flag that indicates possibility of "equal" value. Default is `false`
 */
export const widthProp = (val: string | number | undefined, widthClass: string | null = '', canEqual = false) => {
	if (canEqual && val === 'equal') {
		return 'equal width';
	}
	const valType = typeof val;
	if ((valType === 'string' || valType === 'number') && widthClass) {
		return `${numberToWord(val)} ${widthClass}`;
	}
	return numberToWord(val);
};
