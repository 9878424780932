import * as WidgetParameterTemplate from 'soy/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy.generated';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import * as soy from 'ts/base/soy/SoyRenderer';
import { StringUtils } from 'ts/commons/StringUtils';
import { tsdom } from 'ts/commons/tsdom';
import { WidgetParameterBase } from './WidgetParameterBase';

export class BooleanParameter extends WidgetParameterBase<boolean> {
	/**
	 * Parameter for boolean options.
	 *
	 * @param name The name of the parameter.
	 * @param description The description of the parameter.
	 * @param formattingHelpLink Indicates whether the parameter will include a formatting help link or not.
	 */
	public constructor(name: string, description: string, formattingHelpLink?: boolean) {
		super(name, description, formattingHelpLink);
	}

	public override renderInput(value: boolean): void {
		const checkboxId = 'checkbox-' + StringUtils.replaceAll(this.name, ' ', '-').toLowerCase();
		const template = WidgetParameterTemplate.inputCheckbox;
		const element = soy.renderAsElement(template, { checked: value, id: checkboxId });
		this.container!.appendChild(element);
		events.listen(this.getCheckbox(), EventType.CHANGE, () => {
			this.emitParameterChangeEvent();
		});
	}

	public override extractValue(): boolean {
		return this.getCheckbox().checked;
	}

	/** @returns The checkbox element of this parameter. */
	public getCheckbox(): HTMLInputElement {
		return tsdom.getElementsByTagNameAndClass('input', 'checkbox', this.container!)[0]!;
	}
}
