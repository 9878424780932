import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ERequirementsPerspectiveView } from 'ts/perspectives/requirement_tracing/ERequirementsPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the requirements tracing perspective. */
export class RequirementsTracingPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.REQUIREMENTS_TRACING, ERequirementsPerspectiveView);
	}
}
