// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/commons/formatter/PreprocessorExpressionTemplate.soy

/**
 * @fileoverview Templates in namespace ts.commons.formatter.PreprocessorExpressionTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';


/**
 * @param {!$preprocessorExpansionButtonWrapper.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $preprocessorExpansionButtonWrapper = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $preprocessorExpansionButtonWrapper$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.buttonTitle, opt_data.buttonText, opt_data.expansionButtonId, opt_data.wrapperDisplayStyle);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} buttonTitle
 * @param {string} buttonText
 * @param {string} expansionButtonId
 * @param {string} wrapperDisplayStyle
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $preprocessorExpansionButtonWrapper$ = function($$areYouAnInternalCaller, $ijData, buttonTitle, buttonText, expansionButtonId, wrapperDisplayStyle) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.formatter.PreprocessorExpressionTemplate.preprocessorExpansionButtonWrapper']) {
    return soy.$$stubsMap['ts.commons.formatter.PreprocessorExpressionTemplate.preprocessorExpansionButtonWrapper']({buttonTitle: buttonTitle, buttonText: buttonText, expansionButtonId: expansionButtonId, wrapperDisplayStyle: wrapperDisplayStyle}, $ijData);
  }
  soy.assertParamType(typeof buttonTitle === 'string', 'buttonTitle', buttonTitle, '@param', 'string');
  soy.assertParamType(typeof buttonText === 'string', 'buttonText', buttonText, '@param', 'string');
  soy.assertParamType(typeof expansionButtonId === 'string', 'expansionButtonId', expansionButtonId, '@param', 'string');
  soy.assertParamType(typeof wrapperDisplayStyle === 'string', 'wrapperDisplayStyle', wrapperDisplayStyle, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span style="display:' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(wrapperDisplayStyle)) + '; z-index: 1; vertical-align: top"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.formatter.PreprocessorExpressionTemplate.preprocessorExpansionButtonWrapper ts/commons/formatter/PreprocessorExpressionTemplate.soy:22"' : '') + '><span class="ui small blue icon button" style="z-index: 1; padding: 1px" id="' + soy.$$escapeHtmlAttribute(expansionButtonId) + '" title="' + soy.$$escapeHtmlAttribute(buttonTitle) + '">' + soy.$$escapeHtml(buttonText) + '</span></span>');
};
export { $preprocessorExpansionButtonWrapper as preprocessorExpansionButtonWrapper };
export { $preprocessorExpansionButtonWrapper$ as preprocessorExpansionButtonWrapper$ };
/**
 * @typedef {{
 *  buttonTitle: string,
 *  buttonText: string,
 *  expansionButtonId: string,
 *  wrapperDisplayStyle: string,
 * }}
 */
$preprocessorExpansionButtonWrapper.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $preprocessorExpansionButtonWrapper.soyTemplateName = 'ts.commons.formatter.PreprocessorExpressionTemplate.preprocessorExpansionButtonWrapper';
}
