import { linkTo } from 'ts/commons/links/LinkTo';
import { ESystemPerspectiveView } from 'ts/perspectives/system/ESystemPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Returns a URL for showing the Execution Status view. */
export function systemExecutionStatus() {
	return linkTo(ETeamscalePerspective.SYSTEM, ESystemPerspectiveView.EXECUTION_STATUS);
}

/** Returns a URL to the project parse log view. */
export function parseLog(project: string) {
	return linkTo(ETeamscalePerspective.SYSTEM, ESystemPerspectiveView.PARSE_LOG, {
		project
	});
}

/** Returns a URL to the system license info view. */
export function showSystemLicenseInfo() {
	return linkTo(ETeamscalePerspective.SYSTEM, ESystemPerspectiveView.LICENSE_INFO);
}
