import { TeamscaleViewBase } from 'ts/base/view/TeamscaleViewBase';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { UIUtils } from 'ts/commons/UIUtils';
import { CompareCodeComponent } from 'ts/perspectives/compare/CompareCodeComponent';

/** The code compare view, which shows a diff view for two files. */
export default class CodeCompareView extends TeamscaleViewBase {
	/** The name of the parameter that stores the compare mode in the navigation hash. */
	public static readonly COMPARE_MODE_PARAMETER = 'compare-mode';

	/** The component which renders the comparison */
	private compareCodeComponent?: CompareCodeComponent;

	public constructor() {
		super();
	}

	public override appendMainContent(container: Element): void {
		const storedDiffModeIndex = parseInt(
			UIUtils.getFromHashOrStorage(
				CodeCompareView.COMPARE_MODE_PARAMETER,
				CompareCodeComponent.COMPARISON_MODE,
				'0'
			)
		);
		const isColorBlindEnabled = this.perspectiveContext?.userInfo.isColorBlindModeEnabled() ?? false;
		this.compareCodeComponent = new CompareCodeComponent();
		this.compareCodeComponent
			.loadAndAppendContent(
				container,
				NavigationHash.getCurrent(),
				isColorBlindEnabled,
				undefined,
				undefined,
				storedDiffModeIndex
			)
			.catch(this.getErrorHandler(container));
	}

	public override dispose(): void {
		this.compareCodeComponent?.dispose();
		super.dispose();
	}
}
