import * as dom from 'ts-closure-library/lib/dom/dom';
import * as style from 'ts-closure-library/lib/style/style';
import { navigateTo } from 'ts/base/routing/NavigateTo';
import { ROUTER } from 'ts/base/routing/Router';
import { StringUtils } from 'ts/commons/StringUtils';
import { FlexCodeContainer } from 'ts/perspectives/metrics/code/FlexCodeContainer';
import { PerspectiveUtils } from './PerspectiveUtils';

/** Utility methods for navigation. */
export class NavigationUtils {
	/**
	 * Updates the location (i.e. the entire URL) to a new value.
	 *
	 * @param href The new location (URL as string)
	 * @param replace If this is true, the URL is replaced, i.e. the back button will not contain the current page. This
	 *   is useful for redirects. If this is false, the back button will allow navigation to the current page, which
	 *   should be used for "normal" links.
	 */
	public static updateLocation(href: string, replace = false, state?: unknown): void {
		navigateTo(href, replace, state);
	}

	/**
	 * Opens the given location in a new tab.
	 *
	 * @param href The new location (URL as string)
	 * @param focus Whether to focus the tab.
	 */
	public static openInNewTab(href: string, focus?: boolean): void {
		const url = new URL(StringUtils.stripPrefix(href, '/'), document.head.baseURI);
		const windowObjectReference = window.open(url, '_blank');
		if (focus) {
			windowObjectReference?.focus();
		}
	}

	/**
	 * Scrolls an element into central view.
	 *
	 * @param element The element to scroll to.
	 */
	public static jumpToElement(element: Element): void {
		const mainContainer = PerspectiveUtils.getMainContainer();
		const scrollingElement = dom.getAncestorByClass(element, FlexCodeContainer.SCROLL_CONTAINER_CLASS);
		style.scrollIntoContainerView(scrollingElement, mainContainer);
		style.scrollIntoContainerView(element, scrollingElement, true);
	}

	/** Navigates back to the previous page. */
	public static navigateBack(): void {
		ROUTER.navigate(-1);
	}
}
