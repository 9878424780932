import { Assertions } from 'ts/commons/Assertions';
import { AssessmentFormatter } from 'ts/commons/formatter/AssessmentFormatter';
import { CounterSetFormatter } from 'ts/commons/formatter/CounterSetFormatter';
import { StringValueFormatter } from 'ts/commons/formatter/StringValueFormatter';
import { EMetricValueType, type EMetricValueTypeEntry } from 'typedefs/EMetricValueType';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';
import type { MetricValue } from 'typedefs/MetricValue';
import type { MetricFormatterBase } from './MetricFormatterBase';
import { NumericValueFormatter } from './NumericValueFormatter';
import { TimestampMetricFormatter } from './TimestampMetricFormatter';

/** A factory that creates different metric value formatters. */
export class MetricFormatterFactory {
	/**
	 * Creates a matching formatter for the given value type. Will call failForUnsupportedMetricType() for unknown value
	 * types.
	 *
	 * @param schemaEntry The schema entry for the value
	 * @param options - Further formatting options (optional).
	 */
	public static createFormatterForMetric(
		schemaEntry: MetricDirectorySchemaEntry,
		subType?: EMetricValueTypeEntry
	): MetricFormatterBase<MetricValue> {
		switch (schemaEntry.valueType) {
			case EMetricValueType.NUMERIC.name:
				return new NumericValueFormatter(schemaEntry);
			case EMetricValueType.ASSESSMENT.name:
				return new AssessmentFormatter(schemaEntry);
			case EMetricValueType.TIMESTAMP.name:
				return new TimestampMetricFormatter(schemaEntry);
			case EMetricValueType.COUNTER_SET.name:
				if (subType === EMetricValueType.NUMERIC.name) {
					return new NumericValueFormatter(schemaEntry);
				}
				return new CounterSetFormatter(schemaEntry);
			case EMetricValueType.STRING.name:
				return new StringValueFormatter(schemaEntry);
			default:
				MetricFormatterFactory.failForUnsupportedMetricType(schemaEntry.valueType);
		}
	}

	/**
	 * Fails with an assertion for the given unsupported value type. This is used for metric types that are only used in
	 * hidden metrics and shouldn't occur in the UI.
	 *
	 * @param valueType - The metric value type to fail for.
	 */
	private static failForUnsupportedMetricType(valueType: string): never {
		Assertions.fail('Formatting metrics of type ' + valueType + ' is currently not supported.');
	}
}
