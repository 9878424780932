import { EMetricProperty } from 'typedefs/EMetricProperty';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';

/** Utility functions related to properties of metrics. */
export class MetricProperties {
	/** Returns whether the metric described by the schema entry is a size metric. */
	public static isSizeMetric(schemaEntry: MetricDirectorySchemaEntry): boolean {
		return MetricProperties.hasProperty(schemaEntry, EMetricProperty.SIZE_METRIC);
	}

	/** Returns whether the metric described by the schema entry is a ratio ('percentage) metric, e.g. clone coverage */
	public static isRatioMetric(schemaEntry: MetricDirectorySchemaEntry): boolean {
		return MetricProperties.hasProperty(schemaEntry, EMetricProperty.RATIO_METRIC);
	}

	/** Returns whether a low value of the given metric is worse that a high value. */
	public static isLowIsBadMetric(schemaEntry: MetricDirectorySchemaEntry): boolean {
		return MetricProperties.hasProperty(schemaEntry, EMetricProperty.LOW_IS_BAD);
	}

	/** Returns whether a low value of the given metric is worse that a high value. */
	public static isQualityNeutralMetric(schemaEntry: MetricDirectorySchemaEntry): boolean {
		return MetricProperties.hasProperty(schemaEntry, EMetricProperty.QUALITY_NEUTRAL);
	}

	/** Returns whether an empty assessment should be treated as quality neutral (gray) instead of positive (default). */
	public static isEmptyAssessmentQualityNeutral(schemaEntry: MetricDirectorySchemaEntry): boolean {
		return MetricProperties.hasProperty(schemaEntry, EMetricProperty.EMPTY_ASSESSMENT_IS_NEUTRAL);
	}

	/** Returns whether the metric described by the schema entry has a given property. */
	public static hasProperty(schemaEntry: MetricDirectorySchemaEntry, expectedProperty: EMetricProperty): boolean {
		return schemaEntry.properties.includes(expectedProperty.name);
	}
}
