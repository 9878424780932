import type { SafeHtml } from 'ts-closure-library/lib/html/safehtml';
import { UIUtils } from './UIUtils';

/** Utility methods for dealing with texts. TextUtils differs from StringUtils, because we consider the texts layout */
export class TextUtils {
	/** Regex pattern that matches on URLs beginning with http(s), ftp or file protocol. */
	public static URL_MATCHING_PATTERN =
		/(https?|ftp|file):\/\/[-a-zA-Z0-9+&@#\\/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#\\/%=~_|]/gi;

	/** Pattern to substitute a text link with a HTML link. */
	public static URL_SUBSTITUTION_PATTERN = '<a href="$&">$&</a>';

	/**
	 * Gets the width in pixels of a given text, which is needed to draw the text with the given font family and font
	 * size
	 *
	 * @param text Text whose width should be calculated
	 * @param fontFamily The font family which should be used to layout the text
	 * @param fontSize The font size in px which should be used to layout the text
	 * @returns Width of the text in pixels
	 */
	public static getTextWidthUsingFont(text: string, fontFamily: string, fontSize: number): number {
		const canvas = document.createElement('canvas');
		const canvasContext = canvas.getContext('2d')!;
		canvasContext.font = fontSize + 'px ' + fontFamily;
		return canvasContext.measureText(text).width;
	}

	/**
	 * Replaces link texts with actual HTML links.
	 *
	 * @param text Link texts of this should be replaced with actual HTML links.
	 * @returns Formatted text, which includes links as HTML links.
	 */
	public static replaceLinkTextsByHtmlLinks(text: string): SafeHtml {
		return UIUtils.asSafeHtml(text.replace(TextUtils.URL_MATCHING_PATTERN, TextUtils.URL_SUBSTITUTION_PATTERN));
	}
}
