/** Generated from EIssueReferenceType.java. Do not modify this file! */


export type EIssueReferenceTypeEntry = 'ISSUE' | 'SPEC_ITEM';

export class EIssueReferenceType {
	static readonly ISSUE = new EIssueReferenceType(0, 'ISSUE', "Issue");
	static readonly SPEC_ITEM = new EIssueReferenceType(1, 'SPEC_ITEM', "Specification item");
	static readonly values = [
		EIssueReferenceType.ISSUE,
		EIssueReferenceType.SPEC_ITEM
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EIssueReferenceTypeEntry,
		public readonly readableName: string
	){
	}

	toString() {
		return this.name;
	}
}

