import clsx from 'clsx';
import { type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import type { SemanticCOLORS, SemanticTEXTALIGNMENTS, SemanticVERTICALALIGNMENTS, SemanticWIDTHS } from '../Generic';
import {
	getComponentType,
	getUnhandledProps,
	keyOnly,
	multipleProp,
	textAlignProp,
	verticalAlignProp,
	widthProp
} from '../lib';
import type { GridReversedProp } from './Grid';
import type { GridOnlyProp } from './GridColumn';

/** Props for {@link GridRow}. */
export type GridRowProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** A row can have its columns centered. */
	centered?: boolean;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** A grid row can be colored. */
	color?: SemanticCOLORS;

	/** Represents column count per line in Row. */
	columns?: SemanticWIDTHS | 'equal';

	/** A row can have dividers between its columns. */
	divided?: boolean;

	/** A row can appear only for a specific device, or screen sizes. */
	only?: GridOnlyProp;

	/** A row can specify that its columns should reverse order at different device sizes. */
	reversed?: GridReversedProp;

	/** A row can stretch its contents to take up the entire column height. */
	stretched?: boolean;

	/** A row can specify its text alignment. */
	textAlign?: SemanticTEXTALIGNMENTS;

	/** A row can specify its vertical alignment to have all its columns vertically centered. */
	verticalAlign?: SemanticVERTICALALIGNMENTS;
};

/** A row sub-component for Grid. */
export const GridRow = forwardRef(function GridRow(props: GridRowProps, ref: ForwardedRef<HTMLDivElement>) {
	const {
		centered,
		children,
		className,
		color,
		columns,
		divided,
		only,
		reversed,
		stretched,
		textAlign,
		verticalAlign
	} = props;

	const classes = clsx(
		color,
		keyOnly(centered, 'centered'),
		keyOnly(divided, 'divided'),
		keyOnly(stretched, 'stretched'),
		multipleProp(only, 'only'),
		multipleProp(reversed, 'reversed'),
		textAlignProp(textAlign),
		verticalAlignProp(verticalAlign),
		widthProp(columns, 'column', true),
		'row',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{children}
		</ElementType>
	);
});
const handledProps = [
	'as',
	'centered',
	'children',
	'className',
	'color',
	'columns',
	'divided',
	'only',
	'reversed',
	'stretched',
	'textAlign',
	'verticalAlign'
];
