/**
 * This file has been generated. Do not edit! 
 */

import type { WhatsNewAcknowledgeOption } from 'typedefs/WhatsNewAcknowledgeOption';
import type { UserLimitExceededOption } from 'typedefs/UserLimitExceededOption';
import type { TestGapContainerVisibilityOption } from 'typedefs/TestGapContainerVisibilityOption';
import type { TestExecutionsContainerVisibleMetricsOption } from 'typedefs/TestExecutionsContainerVisibleMetricsOption';
import type { TestCoverageVisibilityOption } from 'typedefs/TestCoverageVisibilityOption';
import type { TaskNotificationOption } from 'typedefs/TaskNotificationOption';
import type { NonCodeContainerVisibleMetricsOption } from 'typedefs/NonCodeContainerVisibleMetricsOption';
import type { LastDashboardOpenedByUserOption } from 'typedefs/LastDashboardOpenedByUserOption';
import type { IssueContainerVisibleMetricsOption } from 'typedefs/IssueContainerVisibleMetricsOption';
import type { FindingsVisibilityOption } from 'typedefs/FindingsVisibilityOption';
import type { FindingsBaseliningOption } from 'typedefs/FindingsBaseliningOption';
import type { DeltaPerspectiveVisibleMetricsOption } from 'typedefs/DeltaPerspectiveVisibleMetricsOption';
import type { ColorblindModeOption } from 'typedefs/ColorblindModeOption';
import type { CodeSidebarVisibleMetricsOption } from 'typedefs/CodeSidebarVisibleMetricsOption';
import type { CodeContainerVisibleMetricsOption } from 'typedefs/CodeContainerVisibleMetricsOption';
import type { ArchitectureContainerVisibleMetricsOption } from 'typedefs/ArchitectureContainerVisibleMetricsOption';
import type { ActivityPerspectiveVisibleMetricsOption } from 'typedefs/ActivityPerspectiveVisibleMetricsOption';

/** All known user options. */
export type UserOptions = {
	'activity.metrics.visibility': ActivityPerspectiveVisibleMetricsOption;
	'architecture.container.metrics.visibility': ArchitectureContainerVisibleMetricsOption;
	'code.container.metrics.visibility': CodeContainerVisibleMetricsOption;
	'code.sidebar.metrics.visibility': CodeSidebarVisibleMetricsOption;
	'accessibility.colorblind': ColorblindModeOption;
	'delta.metrics.visibility': DeltaPerspectiveVisibleMetricsOption;
	'findings.baselining': FindingsBaseliningOption;
	'findings.visibility': FindingsVisibilityOption;
	'issue.container.metrics.visibility': IssueContainerVisibleMetricsOption;
	'dashboard.lastAccessed': LastDashboardOpenedByUserOption;
	'noncode.container.metrics.visibility': NonCodeContainerVisibleMetricsOption;
	'tasks.mail.notifications': TaskNotificationOption;
	'testcoverage.visibility': TestCoverageVisibilityOption;
	'testexecutions.container.metrics.visibility': TestExecutionsContainerVisibleMetricsOption;
	'code.container.testgap.visibility': TestGapContainerVisibilityOption;
	'license.user.limit': UserLimitExceededOption;
	'whatsnew.acknowledged.version': WhatsNewAcknowledgeOption;

	[key: `findings.baselining/${string}`]: FindingsBaseliningOption;
};

/** All known user option names. */
export enum EUserOptions {
	ACTIVITY_PERSPECTIVE_VISIBLE_METRICS = 'activity.metrics.visibility',
	ARCHITECTURE_CONTAINER_VISIBLE_METRICS = 'architecture.container.metrics.visibility',
	CODE_CONTAINER_VISIBLE_METRICS = 'code.container.metrics.visibility',
	CODE_SIDEBAR_VISIBLE_METRICS = 'code.sidebar.metrics.visibility',
	COLORBLIND_MODE = 'accessibility.colorblind',
	DELTA_PERSPECTIVE_VISIBLE_METRICS = 'delta.metrics.visibility',
	FINDINGS_BASELINING = 'findings.baselining',
	FINDINGS_VISIBILITY = 'findings.visibility',
	ISSUE_CONTAINER_VISIBLE_METRICS = 'issue.container.metrics.visibility',
	LAST_DASHBOARD_OPENED_BY_USER = 'dashboard.lastAccessed',
	NON_CODE_CONTAINER_VISIBLE_METRICS = 'noncode.container.metrics.visibility',
	TASK_NOTIFICATION = 'tasks.mail.notifications',
	TEST_COVERAGE_VISIBILITY = 'testcoverage.visibility',
	TEST_EXECUTIONS_CONTAINER_VISIBLE_METRICS = 'testexecutions.container.metrics.visibility',
	TEST_GAP_CONTAINER_VISIBILITY = 'code.container.testgap.visibility',
	USER_LIMIT_EXCEEDED = 'license.user.limit',
	WHATS_NEW_ACKNOWLEDGE = 'whatsnew.acknowledged.version'
}
