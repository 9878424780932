import type { JSX } from 'react';
import { Button } from 'ts/components/Button';
import { Grid, GridColumn, GridRow } from 'ts/components/Grid';
import { Icon } from 'ts/components/Icon';
import type { Callback } from 'ts/base/Callback';
import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import { useBaselines } from 'ts/base/services/BaselineServices';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { PermissionUtils } from 'ts/commons/permission/PermissionUtils';
import { useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import {
	convertDefinedPointInTimeToOption,
	DefinedPointInTimeDropdown,
	useControlledSelectValue
} from 'ts/commons/time/components/TimePickerUtils';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import {
	openCreateBaselineDialog,
	openEditBaselineDialog
} from 'ts/perspectives/findings/baselines/BaselineEditDialog';
import type { ProjectSpecificBaselineInfo } from 'ts/perspectives/findings/baselines/ProjectSpecificBaselineInfo';

function extractPointInTime(value: string): TypedPointInTime | null {
	const { name, project } = JSON.parse(value);
	if (name == null || project == null) {
		return null;
	}
	return TimeUtils.baseline(name, project);
}

function deriveInitialValue(defaultValue: TypedPointInTime | null): string | undefined {
	if (defaultValue != null && defaultValue.type === EPointInTimeType.BASELINE) {
		return JSON.stringify({
			name: defaultValue.value.name,
			project: defaultValue.value.project
		});
	}
	return undefined;
}

/** Props for BaselineEditButton. */
type BaselineEditButtonProps = {
	selectedBaseline: ProjectSpecificBaselineInfo | undefined;
	baselines: ProjectSpecificBaselineInfo[];
	onBaselineSaved: Callback<ProjectSpecificBaselineInfo>;
};

/** Provides a button to be able to edit the currently selected baseline by opening an edit baseline dialog. */
function BaselineEditButton({ selectedBaseline, baselines, onBaselineSaved }: BaselineEditButtonProps): JSX.Element {
	const { projects } = useTimePickerContext();
	return (
		<Button
			basic
			type="button"
			disabled={selectedBaseline == null}
			fluid
			icon={<Icon name="edit" />}
			content="Edit"
			data-testid="edit-baseline-button"
			onClick={() => openEditBaselineDialog(projects!, baselines, selectedBaseline!, onBaselineSaved)}
		/>
	);
}

function getSelectedBaseline(
	baselines: ProjectSpecificBaselineInfo[],
	selectedValue?: string
): ProjectSpecificBaselineInfo | undefined {
	if (selectedValue != null) {
		const { project, name } = JSON.parse(selectedValue);
		return baselines.find(baseline => baseline.project === project && baseline.name === name);
	}
	return undefined;
}

/** Props for BaselineSelector. */
type BaselineSelectorProps = {
	baselines: ProjectSpecificBaselineInfo[] | undefined;
	onBaselineSaved: Callback<ProjectSpecificBaselineInfo>;
	selectedValue?: string;
	setSelectedValue: Callback<string>;
};

/** Provides a selector for the baseline array if it's not null or undefined. */
function BaselineSelector({
	baselines,
	onBaselineSaved,
	selectedValue,
	setSelectedValue
}: BaselineSelectorProps): JSX.Element {
	if (ArrayUtils.isEmptyOrUndefined(baselines)) {
		return <GridColumn>There are no baselines defined in the selected projects.</GridColumn>;
	}

	ArrayUtils.sortBy(baselines, 'timestamp', ArrayUtils.inverseDefaultCompare);
	return (
		<>
			<GridColumn>
				<DefinedPointInTimeDropdown
					testId="baseline-select"
					selectedValue={selectedValue}
					setSelectedValue={setSelectedValue}
					displayObjects={baselines}
					optionMapper={convertDefinedPointInTimeToOption}
				/>
			</GridColumn>
			<GridColumn width={4}>
				<BaselineEditButton
					selectedBaseline={getSelectedBaseline(baselines, selectedValue)}
					baselines={baselines}
					onBaselineSaved={onBaselineSaved}
				/>
			</GridColumn>
		</>
	);
}

/** A component for selecting a baseline. */
export function BaselinePicker(): JSX.Element | null {
	const { projects } = useTimePickerContext();
	const perspectiveContext = usePerspectiveContext();
	const [selectedValue, setSelectedValue] = useControlledSelectValue(
		'baseline',
		extractPointInTime,
		deriveInitialValue
	);
	const baselines = useBaselines(projects);
	const onBaselineSaved = (baseline: ProjectSpecificBaselineInfo) =>
		// Ensure that the last added/edited baseline is selected
		setSelectedValue(convertDefinedPointInTimeToOption(baseline).value!);
	if (projects == null || baselines === undefined) {
		return null;
	}

	return (
		<Grid columns="equal">
			<GridRow stretched>
				<BaselineSelector
					baselines={baselines}
					onBaselineSaved={onBaselineSaved}
					selectedValue={selectedValue}
					setSelectedValue={setSelectedValue}
				/>
			</GridRow>
			{PermissionUtils.mayDefineBaselines(perspectiveContext.userInfo.permissionSummary, projects) ? (
				<GridRow>
					<GridColumn>
						<Button
							type="button"
							id="add-baseline-button"
							basic
							fluid
							icon={<Icon name="add circle" />}
							content="Add baseline"
							data-testid="add-baseline"
							onClick={() => openCreateBaselineDialog(projects, baselines, onBaselineSaved)}
						/>
					</GridColumn>
				</GridRow>
			) : null}
		</Grid>
	);
}
