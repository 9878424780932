/** Generated from EAggregation.java. Do not modify this file! */


export type EAggregationEntry = 'SUM' | 'MIN' | 'MAX' | 'UNION' | 'NONE';

export class EAggregation {
	static readonly SUM = new EAggregation(0, 'SUM');
	static readonly MIN = new EAggregation(1, 'MIN');
	static readonly MAX = new EAggregation(2, 'MAX');
	static readonly UNION = new EAggregation(3, 'UNION');
	static readonly NONE = new EAggregation(4, 'NONE');
	static readonly values = [
		EAggregation.SUM,
		EAggregation.MIN,
		EAggregation.MAX,
		EAggregation.UNION,
		EAggregation.NONE
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EAggregationEntry,

	){
	}

	toString() {
		return this.name;
	}
}

