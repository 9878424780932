import { ServiceCallError } from 'api/ServiceCallError';
import type { ComponentType, JSX } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { Message } from 'ts/components/Message';
import {
	BaseErrorTemplate,
	ServiceErrorComponent as DefaultServiceErrorComponent
} from 'ts/base/components/ServiceErrorComponent';

/** Props for ErrorFallback. */
type ErrorFallbackProps = FallbackProps & {
	/** An optional component that will be used to render service call errors. */
	serviceErrorComponent?: ComponentType<ServiceErrorFallbackProps>;
};

/** The props that the serviceErrorComponent will receive. */
export type ServiceErrorFallbackProps = {
	error: ServiceCallError;
	resetErrorBoundary: (...args: unknown[]) => void;
};

/** Shows an error with special handling for ServiceCallError. */
export function ErrorFallback({
	error,
	resetErrorBoundary,
	serviceErrorComponent: ServiceErrorComponent = DefaultServiceErrorComponent
}: ErrorFallbackProps): JSX.Element {
	if (error instanceof ServiceCallError) {
		return <ServiceErrorComponent error={error} resetErrorBoundary={resetErrorBoundary} />;
	}
	// We want to log errors to the console to get the full stacktrace and to be able to catch errors in the UI tests
	// that are not caught by explicit assertions (TeamscaleUITestBase.failOnUnexpectedErrors)
	console.error(error);
	if (
		error.message &&
		(error.message.startsWith('Failed to fetch dynamically imported module') ||
			error.message.startsWith('error loading dynamically imported module') ||
			error.message.includes('Unable to preload'))
	) {
		// The import.meta.env.DEV constant comes from vite https://vitejs.dev/guide/env-and-mode.html
		if (import.meta.env.DEV) {
			return (
				<Message error>
					It seems you are using some kind of ad-blocker (e.g. Brave browser, uBlock Origin, AdBlock Ultimate,
					or similar). To make the Teamscale UI Dev server work you need to disable it for this site.
					<br />
					<strong>Brave:</strong> Click on the lion icon next to the address bar and disable the toggle.
					<br />
					<strong>uBlock Origin / AdBlock Ultimate:</strong> Click on the extension symbol and on the power
					icon afterwards.
					<p>
						<strong>
							Alternatively, this error can also occur if in your TypeScript code you try to import a file
							that doesn{"'"}t exist.
						</strong>
					</p>
				</Message>
			);
		}
		return (
			<BaseErrorTemplate
				message="Teamscale could not load all of its dependencies. This can usually happen when Teamscale was recently updated. Please refresh the page or clear the
						browser cache."
				resetErrorBoundary={() => window.location.reload()}
			/>
		);
	}
	return <BaseErrorTemplate message={error.message ?? ''} resetErrorBoundary={resetErrorBoundary} />;
}
