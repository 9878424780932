import { QUERY } from 'api/Query';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { FormProvider, useController, useForm, useWatch } from 'react-hook-form';
import type { Callback } from 'ts/base/Callback';
import { DateUtils } from 'ts/commons/DateUtils';
import { optionsGenerator } from 'ts/commons/DropdownUtils';
import { HookFormDropdown } from 'ts/commons/forms/HookFormDropdown';
import { openModal } from 'ts/commons/modal/ModalUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import { ETimePickerType } from 'ts/commons/time/ETimePickerType';
import { PointInTimePicker } from 'ts/commons/time/PointInTimePicker';
import { RevisionFormatter } from 'ts/commons/time/RevisionFormatter';
import { TimeContext } from 'ts/commons/time/TimeContext';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import { Button } from 'ts/components/Button';
import { Form, FormInput } from 'ts/components/Form';
import { Loader } from 'ts/components/Loader';
import { ModalActionButtons } from 'ts/components/Modal';
import { ToastNotification } from 'ts/components/Toast';
import type { ProjectSpecificBaselineInfo } from 'ts/perspectives/findings/baselines/ProjectSpecificBaselineInfo';

/** Edits the given baseline by opening a dialog. */
export function openEditBaselineDialog(
	projects: string[],
	allBaselines: ProjectSpecificBaselineInfo[],
	oldBaseline: ProjectSpecificBaselineInfo,
	onSaved?: Callback<ProjectSpecificBaselineInfo>
): void {
	openModal({
		title: 'Edit baseline',
		id: 'baseline-edit-modal',
		contentRenderer: close => (
			<BaselineEditDialogContent
				close={close}
				projects={projects}
				baseline={oldBaseline}
				onSaved={onSaved}
				allBaselines={allBaselines}
			/>
		)
	});
}

/** Adds a new baseline by opening a dialog */
export function openCreateBaselineDialog(
	projects: string[],
	allBaselines: ProjectSpecificBaselineInfo[],
	onSaved?: Callback<ProjectSpecificBaselineInfo>
): void {
	openModal({
		title: 'Add baseline',
		'data-testid': 'baseline-add-modal',
		contentRenderer: close => {
			const baseline = {
				project: projects[0]!,
				name: '',
				description: '',
				timestamp: new Date().getTime(),
				baselineRevision: ''
			};
			return (
				<BaselineEditDialogContent
					close={close}
					projects={projects}
					baseline={baseline}
					onSaved={onSaved}
					allBaselines={allBaselines}
				/>
			);
		}
	});
}

type BaselineEditDialogProps = {
	projects: string[];
	baseline: ProjectSpecificBaselineInfo;
	allBaselines: ProjectSpecificBaselineInfo[];
	onSaved?: Callback<ProjectSpecificBaselineInfo>;
};

type BaselineEditDialogContentProps = BaselineEditDialogProps & {
	close: () => void;
};

function BaselineEditDialogContent({
	projects,
	baseline,
	onSaved,
	allBaselines,
	close
}: BaselineEditDialogContentProps) {
	const form = useForm({ defaultValues: baseline });
	return (
		<FormProvider {...form}>
			<Form
				onSubmit={form.handleSubmit((newBaseline: ProjectSpecificBaselineInfo) => {
					void saveBaseline(newBaseline, baseline, close, onSaved);
				})}
				error={!form.formState.isValid}
			>
				{projects.length === 1 ? (
					<FormInput label="Project" value={projects[0]} input={{ disabled: true }} />
				) : (
					<HookFormDropdown
						selection
						id="project"
						label="Project"
						name="project"
						options={optionsGenerator(projects)}
					/>
				)}
				<FormInput
					label="Name"
					id="name-text"
					input={{
						...form.register('name', { validate: validateBaselineName(allBaselines, baseline) }),
						autoFocus: true
					}}
					error={form.formState.errors.name?.message}
				/>
				<FormInput label="Description" id="description-text" input={form.register('description')} />
				<BaselineDateInput projects={projects} />
				<ModalActionButtons>
					<Button type="submit" primary content="Save" />
					<Button type="button" content="Cancel" onClick={close} />
				</ModalActionButtons>
			</Form>
		</FormProvider>
	);
}

function validateBaselineName(allBaselines: ProjectSpecificBaselineInfo[], oldBaseline: ProjectSpecificBaselineInfo) {
	return (name: string, { project }: ProjectSpecificBaselineInfo) => {
		if (StringUtils.isEmptyOrWhitespace(name)) {
			return 'Baseline name must not be empty';
		}
		const baselineNameExists = allBaselines.some(
			existingBaseline => existingBaseline.project === project && existingBaseline.name === name
		);
		if (name !== oldBaseline.name && baselineNameExists) {
			return 'A baseline named "' + name + '" already exists for project ' + project;
		}
		return undefined;
	};
}

function BaselineDateInput({ projects }: { projects: string[] }) {
	const project = useWatch<ProjectSpecificBaselineInfo, 'project'>({ name: 'project' });
	const timestampOfFirstCommit = useFirstRepositoryTimestamp(project);
	const baselineRevisionController = useController<ProjectSpecificBaselineInfo, 'baselineRevision'>({
		name: 'baselineRevision'
	});
	const [isQueryLoading, setIsQueryLoading] = useState(false);
	const timestampController = useController<ProjectSpecificBaselineInfo, 'timestamp'>({
		name: 'timestamp',
		rules: {
			validate: timestamp => {
				if (timestamp < timestampOfFirstCommit) {
					return (
						'Please select a date after the first commit on ' +
						DateUtils.formatTimestamp(timestampOfFirstCommit) +
						'.'
					);
				}
				return undefined;
			}
		}
	});
	const timestamp = timestampController.field.value;
	const formattedTimestamp = DateUtils.formatTimestamp(timestamp);
	const baselineRevision = baselineRevisionController.field.value;
	if (isQueryLoading) {
		return (
			<Loader active inline="centered">
				Resolving date on server, please wait...
			</Loader>
		);
	}
	return (
		<FormInput
			label="Date"
			error={timestampController.fieldState.error?.message}
			action={
				<Button
					type="button"
					id="choose-date"
					size="mini"
					content="..."
					onClick={() => {
						promptChooseDate(
							projects,
							{
								baselineRevision,
								timestamp
							},
							setIsQueryLoading
						).then(result => {
							timestampController.field.onChange(result.timestamp);
							baselineRevisionController.field.onChange(result.baselineRevision);
							setIsQueryLoading(false);
						});
					}}
				/>
			}
			input={{ disabled: true }}
			/* Add a timestamp tooltip as it is not possible to read the whole timestamp*/
			/* in case of the baseline revision in which the date text is (Revision: REVISION... (TIMESTAMP)) */
			title={formattedTimestamp}
			id="date-text"
			value={
				baselineRevision
					? new RevisionFormatter().format({
							revision: baselineRevision,
							timestamp
						})
					: formattedTimestamp
			}
		/>
	);
}

/** Opens a time/revision picker for choosing the timestamp for the baseline. */
async function promptChooseDate(
	projects: string[],
	baseline: Pick<ProjectSpecificBaselineInfo, 'baselineRevision' | 'timestamp'>,
	setQueryLoading: Dispatch<SetStateAction<boolean>>
): Promise<Pick<ProjectSpecificBaselineInfo, 'baselineRevision' | 'timestamp'>> {
	let presetPointInTime: TypedPointInTime;
	if (baseline.baselineRevision) {
		presetPointInTime = TimeUtils.revision(baseline.baselineRevision, baseline.timestamp);
	} else {
		presetPointInTime = TimeUtils.timestamp(baseline.timestamp);
	}
	const pointInTime = await PointInTimePicker.showDialog(
		projects,
		[ETimePickerType.BASELINE, ETimePickerType.SYSTEM_VERSION, ETimePickerType.TIMESPAN],
		false,
		presetPointInTime,
		'Select time...'
	);

	setQueryLoading(true);
	// The time context used to resolve timestamps for the baseline definitions.
	const timeContext = new TimeContext();
	let timestamp = await timeContext.resolveToTimestamp(pointInTime);
	if (timestamp == null) {
		timestamp = Date.now();
	}
	return {
		timestamp,
		baselineRevision: pointInTime.type === EPointInTimeType.REVISION ? pointInTime.value.revision : ''
	};
}

/**
 * Returns the timestamp of the first commit that happened in the project to ensure baselines are only created after
 * that point in time. To avoid unnecessarily blocking the first render the hook returns 0 while the data is loading.
 */
function useFirstRepositoryTimestamp(project: string): number {
	return QUERY.getRepositorySummary(project, {}).useQuery().data?.firstCommit ?? 0;
}

async function saveBaseline(
	newBaseline: ProjectSpecificBaselineInfo,
	baseline: ProjectSpecificBaselineInfo,
	close: () => void,
	onSaved?: (result: ProjectSpecificBaselineInfo) => void
) {
	await QUERY.createOrUpdateBaseline(
		newBaseline.project,
		newBaseline.name,
		{ 'old-name': baseline.name || undefined },
		newBaseline
	)
		.fetch()
		.catch(ToastNotification.showIfServiceError);
	void QUERY.getBaselines({}).invalidate({ ignoreQueryParamsAndBody: true });
	void QUERY.getAllBaselines(newBaseline.project).invalidate();
	onSaved?.(newBaseline);
	close();
}
