import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { EFindingsPerspectiveView } from 'ts/perspectives/findings/EFindingsPerspectiveView';
import type { EBlacklistingOptionEntry } from 'typedefs/EBlacklistingOption';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Returns a URL to the details of a finding. */
export function findingDetails(
	project: string,
	findingId: string,
	params?: {
		commit?: UnresolvedCommitDescriptor | null;
		searchParamsId?: string;
	}
) {
	return linkTo(ETeamscalePerspective.FINDINGS, EFindingsPerspectiveView.FINDING_DETAIL, project, {
		id: findingId,
		searchParamsId: params?.searchParamsId,
		t: params?.commit
	});
}

/** Returns a URL to the finding list. */
export function findingsList(
	project: string,
	isSpecItemCheck: boolean,
	params?: {
		filter?: string;
		tolerate?: EBlacklistingOptionEntry;
		invert: boolean;
		commit?: UnresolvedCommitDescriptor | null;
	}
) {
	return linkTo(
		ETeamscalePerspective.FINDINGS,
		isSpecItemCheck
			? EFindingsPerspectiveView.SPEC_ITEM_FINDINGS_LIST
			: EFindingsPerspectiveView.CODE_FINDINGS_LIST,
		project,
		{
			filter: params?.filter,
			tolerate: params?.tolerate,
			invert: params?.invert,
			t: params?.commit
		}
	);
}
