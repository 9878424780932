import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ESnippetAnalysisPerspectiveView } from 'ts/perspectives/snippet_anaylsis/ESnippetAnalysisPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the Snippet Analysis Perspective. */
export class SnippetAnalysisPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.SNIPPET_ANALYSIS, ESnippetAnalysisPerspectiveView);
	}
}
