import type { OnChangeFn, SortingState } from '@tanstack/react-table';
import type { Callback } from 'ts/base/Callback';
import { Assertions } from 'ts/commons/Assertions';
import { ESortOrder } from 'typedefs/ESortOrder';
import type { SortOptions } from './SortableTable';

/** Converts our own sort options into the format Tanstack Table expects to receive as initialState. */
export function convertToReactTableSortBy(id: string | undefined, sortOrder: ESortOrder | undefined): SortingState {
	if (id === undefined || sortOrder === undefined) {
		return [];
	}
	const desc = sortOrder === ESortOrder.DESCENDING;
	return [{ id, desc }];
}

/** Converts the Tanstack Table sorting state to our own sort options format. */
export function convertFromReactTableSortingState(sortState: SortingState): SortOptions {
	Assertions.assertBoolean(sortState.length > 0, 'sort state length was zero');
	const sortData = sortState[0]!;
	return {
		sortByField: sortData.id,
		sortOrder: sortData.desc ? ESortOrder.DESCENDING : ESortOrder.ASCENDING
	};
}

/**
 * Creates a function that takes an updater that evaluates a potential given update function before calling our callback
 * with the updated value.
 */
export function getUpdaterHandler<T extends object>(currentValue: T, onChanged: Callback<T>): OnChangeFn<T> {
	return valueOrUpdater => {
		let newSortState: T;
		if (typeof valueOrUpdater === 'function') {
			newSortState = valueOrUpdater(currentValue);
		} else {
			newSortState = valueOrUpdater;
		}
		onChanged(newSortState);
	};
}
