import type { BaselineInfo } from 'typedefs/BaselineInfo';
import type { MetricDirectorySchema } from 'typedefs/MetricDirectorySchema';

/** Wraps options common to metric charts. */
export class MetricChartOptions {
	/**
	 * @param metricIndices The indices in the metric schema of the metrics to display.
	 * @param baselines The baselines to display in the chart
	 * @param showZoom Whether to show a zoom control under the chart. Defaults to true.
	 * @param hideYAxes Whether to hide all y-axes from the plot.
	 * @param showActionMenu Whether to show the action menu.
	 * @param minTimestamp An optional minimum timestamp to include in the chart.
	 * @param maxTimestamp An optional maximum timestamp to include in the chart.
	 */
	public constructor(
		public project: string | null,
		public branchName: string | null,
		public path: string,
		public metricIndices: number[],
		public metricSchema: MetricDirectorySchema,
		public baselines: BaselineInfo[],
		public showZoom: boolean,
		public hideYAxes: boolean,
		public showActionMenu: boolean,
		public minTimestamp: number | null = null,
		public maxTimestamp: number | null = null
	) {}
}
