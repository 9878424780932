import type { UseQueryResult } from '@tanstack/react-query';
import { Header } from 'ts/components/Header';
import { Message } from 'ts/components/Message';
import type { Callback } from 'ts/base/Callback';
import {
	createGitTagPreFilterDropdownOptions,
	GitTagPreFilterDropdown
} from 'ts/commons/time/components/git_tags/GitTagPreFilterDropdown';

type GitTagRepositoryDropdownProps = {
	repositories: UseQueryResult<string[] | null>;
	selectedRepository: string | null;
	setSelectedRepository: Callback<string | null>;
};

/** Dropdown for selecting the repository to fetch git tags for. */
export function GitTagRepositoryDropdown({
	repositories,
	selectedRepository,
	setSelectedRepository
}: GitTagRepositoryDropdownProps) {
	return (
		<>
			<Header size="tiny" content="Repository:" />
			{!repositories.isFetching && repositories.isSuccess && repositories.data?.length === 0 ? (
				<Message>No Git repositories where found in this project.</Message>
			) : (
				<GitTagPreFilterDropdown<string>
					testId="git-tag-repository-select"
					selectedValue={selectedRepository}
					setSelectedValue={setSelectedRepository}
					displayObjects={repositories.data ?? []}
					loading={repositories.isFetching}
					optionMapper={createGitTagPreFilterDropdownOptions}
				/>
			)}
			{repositories.isError ? <Message error>{repositories.error.message}</Message> : null}
		</>
	);
}
