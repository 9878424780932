/** Generated from ETaskResolution.java. Do not modify this file! */


export type ETaskResolutionEntry = 'NONE' | 'FIXED' | 'INFEASIBLE' | 'TOO_MUCH_EFFORT';

export class ETaskResolution {
	static readonly NONE = new ETaskResolution(0, 'NONE', "None");
	static readonly FIXED = new ETaskResolution(1, 'FIXED', "Fixed");
	static readonly INFEASIBLE = new ETaskResolution(2, 'INFEASIBLE', "Infeasible");
	static readonly TOO_MUCH_EFFORT = new ETaskResolution(3, 'TOO_MUCH_EFFORT', "Too much effort");
	static readonly values = [
		ETaskResolution.NONE,
		ETaskResolution.FIXED,
		ETaskResolution.INFEASIBLE,
		ETaskResolution.TOO_MUCH_EFFORT
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: ETaskResolutionEntry,
		public readonly readableName: string
	){
	}

	toString() {
		return this.name;
	}
}

