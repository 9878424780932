import { type ElementType, type ForwardedRef, forwardRef } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { getComponentType, getUnhandledProps } from '../lib';
import type { TextAreaProps } from '../TextArea';
import { TextArea } from '../TextArea';
import { type BasicFormFieldProps, FormField } from './FormField';

/** Props for {@link FormTextArea}. */
export type FormTextAreaProps = ExtendTypeWith<
	ExtendTypeWith<TextAreaProps, BasicFormFieldProps>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** A FormField control prop. */
		control?: ElementType;
	}
>;

/**
 * Sugar for <Form.Field control={TextArea} />.
 *
 * @see Form
 * @see TextArea
 */
export const FormTextArea = forwardRef(function FormTextArea(
	props: FormTextAreaProps,
	ref: ForwardedRef<HTMLTextAreaElement>
) {
	const { control = TextArea } = props;

	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props, { defaultAs: FormField });

	return <ElementType {...rest} control={control} ref={ref} />;
});
const handledProps = ['as', 'control'];
