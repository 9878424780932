/** Custom rectangle type. */
export type IRect = {
	left: number;
	top: number;
	width: number;
	height: number;
};

/** Custom coordinate type. */
export type ICoordinate = {
	x: number;
	y: number;
};

/** Custom rectangle class that can handle basic transformations. */
export class Rect implements IRect {
	public constructor(
		public readonly left: number,
		public readonly top: number,
		public readonly width: number,
		public readonly height: number
	) {}

	/** Gets the x-coordinate of the center of the rectangle. */
	public get centerX(): number {
		return this.left + this.width / 2;
	}

	/** Gets the y-coordinate of the center of the rectangle. */
	public get centerY(): number {
		return this.top + this.height / 2;
	}

	/** Scales this rectangle by the given scale factor. */
	public scale(scaleFactor: number): Rect {
		return new Rect(
			this.left * scaleFactor,
			this.top * scaleFactor,
			this.width * scaleFactor,
			this.height * scaleFactor
		);
	}

	/** Tests whether this rectangle contains a given coordinate. */
	public contains(coordinate: ICoordinate): boolean {
		return (
			this.left <= coordinate.x &&
			coordinate.x <= this.left + this.width &&
			this.top <= coordinate.y &&
			coordinate.y <= this.top + this.height
		);
	}

	/** Returns a rectangle that is located within this rectangle with the specified margins. */
	public inset(leftMargin: number, topMargin: number, rightMargin: number, bottomMargin: number): Rect {
		return new Rect(
			this.left + leftMargin,
			this.top + topMargin,
			this.width - leftMargin - rightMargin,
			this.height - topMargin - bottomMargin
		);
	}

	/**
	 * Calculates a rectangle that is centered in this rectangle. Tries to ensure that the rectangle is horizontally
	 * within the boundaries of [0, maxRight] and vertically within [0, maxBottom].
	 */
	public centerInside(width: number, height: number, maxRight: number, maxBottom: number): Rect {
		const rectCenterY = this.top + this.height / 2;
		let boxYPosition = Math.min(rectCenterY - height / 2, maxBottom - height);
		boxYPosition = Math.max(boxYPosition, 0);

		const rectCenterX = this.left + this.width / 2;
		let boxXPosition = Math.min(rectCenterX - width / 2, maxRight - width);
		boxXPosition = Math.max(boxXPosition, 0);
		return new Rect(boxXPosition, boxYPosition, width, height);
	}

	/** Calculates a rectangle that is translated by the given offsets. */
	public translate(x: number, y: number): Rect {
		return new Rect(this.left + x, this.top + y, this.width, this.height);
	}
}
