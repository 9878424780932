import type { JSX, ReactNode } from 'react';
import { CounterLabel } from 'ts/commons/components/CounterLabel';

/** Props for ViewTitle. */
type ViewTitleProps = {
	title: ReactNode;
	count?: number;
	buttons?: ReactNode;
};

/** A simple section title for detail views with a title and count label. */
export function ViewTitle({ title, count, buttons }: ViewTitleProps): JSX.Element {
	return (
		<div className="page-header">
			<h1 className="ts-headline">
				{title}
				{count !== undefined ? <CounterLabel count={count} /> : null}
			</h1>
			<div className="header-buttons">{buttons}</div>
		</div>
	);
}
