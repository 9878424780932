import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { EProjectPerspectiveView } from './EProjectPerspectiveView';

/** Descriptor for the projects perspective. */
export class ProjectPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView);
	}
}
