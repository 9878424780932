import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { ProjectInfo } from 'typedefs/ProjectInfo';

/** Client-specific extension of the ProjectInfo class. */
export type ExtendedProjectInfo = ExtendTypeWith<
	ProjectInfo,
	{
		primaryId: string;
		uniqueReadableName: string;
	}
>;

/**
 * Adds a primaryId field for convenience and an uniqueReadableName field, which is the project name with the primary
 * project ID in parenthesis iff the project name is not unique within the given project infos.
 */
export function wrapProjectInfos(projectInfos: ProjectInfo[]): ExtendedProjectInfo[] {
	const projectCountsByName: Record<string, number> = {};
	for (const projectInfo of projectInfos) {
		projectCountsByName[projectInfo.name] = (projectCountsByName[projectInfo.name]! || 0) + 1;
	}
	return projectInfos.map(projectInfo => {
		const primaryId = projectInfo.publicIds[0]!;
		let uniqueReadableName = projectInfo.name;
		const isNameUnique = projectCountsByName[projectInfo.name] === 1;
		if (!isNameUnique) {
			uniqueReadableName += ` (${primaryId})`;
		}
		return Object.assign(projectInfo, { primaryId, uniqueReadableName });
	});
}
