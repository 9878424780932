import { useEffect } from 'react';
import { type CUSTOM_LOCAL_STORAGE_EVENT_NAME } from 'ts/commons/hooks/UseLocalStorage';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface WindowEventMap {
		[CUSTOM_LOCAL_STORAGE_EVENT_NAME]: StorageEvent;
	}
}

/** Hook that adds a change event listener to the window, and remove it on un-mount. */
export function useEventListener<K extends keyof WindowEventMap>(
	eventType: K,
	eventHandler: (this: Window, ev: WindowEventMap[K]) => unknown
): void;
export function useEventListener(eventType: string, eventHandler: EventListener): void;
export function useEventListener(eventType: string, eventHandler: EventListener): void {
	useEffect(() => {
		window.addEventListener(eventType, eventHandler);
		return () => window.removeEventListener(eventType, eventHandler);
	}, [eventType, eventHandler]);
}
