import { useEffect } from 'react';
import { PerspectiveProgress } from 'ts/commons/PerspectiveProgress';

/**
 * This component causes the perspective progress indicator to show a loading state as long as the component stays
 * mounted.
 */
export function PerspectiveLoadingIndicator(): null {
	useEffect(() => {
		PerspectiveProgress.start();
		return () => PerspectiveProgress.stop();
	}, []);
	return null;
}
