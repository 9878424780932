/** Generated from EMetricProperty.java. Do not modify this file! */


export type EMetricPropertyEntry = 'SIZE_METRIC' | 'RATIO_METRIC' | 'HIDDEN' | 'LOW_IS_BAD' | 'QUALITY_NEUTRAL' | 'ARCHITECTURE_RELEVANT' | 'EMPTY_ASSESSMENT_IS_NEUTRAL';

export class EMetricProperty {
	static readonly SIZE_METRIC = new EMetricProperty(0, 'SIZE_METRIC');
	static readonly RATIO_METRIC = new EMetricProperty(1, 'RATIO_METRIC');
	static readonly HIDDEN = new EMetricProperty(2, 'HIDDEN');
	static readonly LOW_IS_BAD = new EMetricProperty(3, 'LOW_IS_BAD');
	static readonly QUALITY_NEUTRAL = new EMetricProperty(4, 'QUALITY_NEUTRAL');
	static readonly ARCHITECTURE_RELEVANT = new EMetricProperty(5, 'ARCHITECTURE_RELEVANT');
	static readonly EMPTY_ASSESSMENT_IS_NEUTRAL = new EMetricProperty(6, 'EMPTY_ASSESSMENT_IS_NEUTRAL');
	static readonly values = [
		EMetricProperty.SIZE_METRIC,
		EMetricProperty.RATIO_METRIC,
		EMetricProperty.HIDDEN,
		EMetricProperty.LOW_IS_BAD,
		EMetricProperty.QUALITY_NEUTRAL,
		EMetricProperty.ARCHITECTURE_RELEVANT,
		EMetricProperty.EMPTY_ASSESSMENT_IS_NEUTRAL
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EMetricPropertyEntry,

	){
	}

	toString() {
		return this.name;
	}
}

