import clsx from 'clsx';
import { type JSX, useMemo } from 'react';
import { useColorBlindModeEnabled } from 'ts/base/hooks/ColorBlindModeHook';
import type { ColorUtils } from 'ts/commons/ColorUtils';
import type { MetricFormatterOptions } from 'ts/commons/formatter/MetricFormatterBase';
import { NumericValueFormatter } from 'ts/commons/formatter/NumericValueFormatter';
import { MetricProperties } from 'ts/commons/MetricProperties';
import { MetricUtils } from 'ts/commons/MetricUtils';
import { Icon } from 'ts/components/Icon';
import { Popup } from 'ts/components/Popup';
import type { SemanticColor } from 'ts/perspectives/tests/pareto/data/ColorSemantics';
import {
	getColorRepresentationForSemanticColor,
	TRAFFIC_LIGHT_GREEN,
	TRAFFIC_LIGHT_ORANGE,
	TRAFFIC_LIGHT_RED,
	TRAFFIC_LIGHT_UNKNOWN,
	TRAFFIC_LIGHT_YELLOW
} from 'ts/perspectives/tests/pareto/data/ColorSemantics';
import type { Assessment } from 'typedefs/Assessment';
import { EMetricValueType } from 'typedefs/EMetricValueType';
import { ETrafficLightColor, type ETrafficLightColorEntry } from 'typedefs/ETrafficLightColor';
import type { MetricAssessment } from 'typedefs/MetricAssessment';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';
import type { MetricValue } from 'typedefs/MetricValue';
import styles from './MetricFormatters.module.less';

/** Renders an indicator that no data is available. */
export function MissingData(): JSX.Element {
	return (
		<span
			data-testid="metrics-table-cell-label"
			className="no-data"
			title="No data available. The metric might be disabled in the analysis profile of the code scope that includes this file or folder."
		>
			N/A
		</span>
	);
}

type StringValuedMetricProps = {
	value: string;
	tooltip?: string;
	rating?: ETrafficLightColorEntry;
};

/** Renders a metric value with its specified color. */
export function StringValuedMetric({ value, tooltip, rating }: StringValuedMetricProps): JSX.Element {
	let ariaDescription;
	const colorBlindModeEnabled = useColorBlindModeEnabled();
	let color: string | undefined;
	if (rating != null) {
		color = NumericValueFormatter.getMetricColor(rating, colorBlindModeEnabled);
		switch (rating) {
			case 'GREEN':
				ariaDescription = 'Assessment: Optimal. Value ' + value + ' does not exceed threshold.';
				break;
			case 'YELLOW':
				ariaDescription = 'Assessment: Warning. Value ' + value + ' exceeds warning threshold.';
				break;
			case 'RED':
				ariaDescription = 'Assessment: Critical. Value ' + value + ' exceeds critical threshold.';
				break;
			default:
				ariaDescription = 'Assessment: Neutral. No Threshold defined for this metric.';
				break;
		}
	}

	const contentToShow = truncateValue(value);

	let element = (
		<span
			data-testid="metrics-table-cell-label"
			className={clsx('metric-rating', { 'font-semibold': color != null })}
			title={tooltip}
			style={{ color }}
			aria-description={ariaDescription}
		>
			{contentToShow}
		</span>
	);
	if (rating != null) {
		const icon = getIconFromMetricRating(rating, colorBlindModeEnabled);
		element = (
			<>
				{icon}
				{element}
			</>
		);
	}
	if (contentToShow === value) {
		return element;
	}
	return <Popup trigger={element} content={tooltip} hideOnScroll />;
}

/**
 * Truncates the value to a size of roughly 40 characters with special handling for comma separated values so that we
 * don't show half-values.
 */
function truncateValue(value: string) {
	let contentToShow = value;
	if (contentToShow.length > 40) {
		const truncatedContent = contentToShow.substring(0, 40);
		const lastCommaIndex = truncatedContent.lastIndexOf(',');
		if (lastCommaIndex !== -1) {
			contentToShow = truncatedContent.substring(0, lastCommaIndex) + '...';
		} else {
			contentToShow = truncatedContent + '...';
		}
	}
	return contentToShow;
}

/** The names of all colors that can be part of a test gap bar. */
type ColorNames = keyof typeof ColorUtils.METRICS_COLOR_NAMES;

function useBarColors(assessment: Assessment, metricSchemaEntry: MetricDirectorySchemaEntry) {
	return useMemo(() => {
		const assessmentCounts = assessment.mapping;
		const colors: ColorNames[] = [];
		const valueMap: Partial<Record<ColorNames, number>> = {};
		let totalCount = 0;
		for (let i = 0; i < ETrafficLightColor.values.length; ++i) {
			if (assessmentCounts[i]! > 0) {
				valueMap[ETrafficLightColor.values[i]!.name as ColorNames] = assessmentCounts[i]!;
				colors.push(ETrafficLightColor.values[i]!.name as ColorNames);
			}
			totalCount += assessmentCounts[i]!;
		}
		if (colors.length === 0) {
			if (MetricProperties.isEmptyAssessmentQualityNeutral(metricSchemaEntry)) {
				colors.push('GRAY');
				valueMap['GRAY'] = 1;
			} else {
				colors.push('GREEN');
				valueMap['GREEN'] = 1;
			}
			totalCount = 1;
		}
		return { colors, valueMap, totalCount };
	}, [assessment, metricSchemaEntry]);
}

type AssessmentMetricProps = {
	assessmentMetric: Assessment;
	metricSchemaEntry: MetricDirectorySchemaEntry;
	tooltip: string;
};

/** Renders a metric assessment. */
export function AssessmentMetric({ assessmentMetric, metricSchemaEntry, tooltip }: AssessmentMetricProps): JSX.Element {
	const { colors, valueMap, totalCount } = useBarColors(assessmentMetric, metricSchemaEntry);

	if (isNonNeutralEmptyMetricAssessment(assessmentMetric, metricSchemaEntry)) {
		return <MissingData />;
	}

	return (
		<PercentageBar
			colors={colors}
			valueMap={valueMap}
			totalCount={totalCount}
			tooltip={tooltip}
			getBarColor={getBarColor}
		/>
	);
}

type PercentageBarProps<Color extends string> = {
	colors: Color[];
	valueMap: Partial<Record<Color, number>>;
	totalCount: number;
	tooltip?: string;
	headerText?: string;
	// Mapping color names to hex
	getBarColor: (color: Color, colorBlindModeEnabled: boolean) => string;
};

/** A colorful bar for the TGA summaries or assessments using the prepared valueMap. */
export function PercentageBar<Color extends string>({
	colors,
	valueMap,
	totalCount,
	tooltip,
	headerText,
	getBarColor
}: PercentageBarProps<Color>): JSX.Element {
	const colorBlindModeEnabled = useColorBlindModeEnabled();
	return (
		<span className={styles.ratingBarWrapper}>
			{headerText ? (
				<div data-testid="metrics-table-cell-label" className="text-center text-[12px]">
					{headerText}
				</div>
			) : null}
			<div className={clsx(styles.ratingBar, 'metric-rating')} title={tooltip}>
				{colors.map(colorName => {
					const colorCount = valueMap[colorName]!;
					if (!colorCount || colorCount <= 0) {
						return null;
					}
					return (
						<div
							key={colorName}
							className="rating-child"
							style={{
								background: getBarColor(colorName, colorBlindModeEnabled),
								width: (100 * colorCount) / totalCount + '%'
							}}
						/>
					);
				})}
			</div>
		</span>
	);
}

/** Maps a metric rating to a colored icon for rendering. */
export function getIconFromMetricRating(
	rating: ETrafficLightColorEntry | undefined,
	colorBlindModeEnabled: boolean,
	showUnknownAndHidden = false
): React.JSX.Element | undefined {
	const color = NumericValueFormatter.getMetricColor(rating, colorBlindModeEnabled);
	if (rating != null) {
		switch (rating) {
			case 'GREEN':
				return <Icon name="check circle" className="trend-green" style={{ color }} />;
			case 'YELLOW':
				return <Icon name="minus circle" className="trend-yellow" style={{ color }} />;
			case 'RED':
				return <Icon name="remove circle" className="trend-red" style={{ color }} />;
			case 'UNKNOWN':
				if (showUnknownAndHidden) {
					return <Icon name="circle outline" className="trend-neutral" />;
				}
				return undefined;
			default:
				// No icon should be rendered
				return undefined;
		}
	} else if (showUnknownAndHidden) {
		return <Icon className="placeholder" />;
	}
	return undefined;
}

/** Maps an assessment color to a color for rendering. Handles enabled colorblind mode. */
function getBarColor(colorName: ColorNames, colorBlindModeEnabled: boolean): string {
	let semanticColor: SemanticColor;
	switch (colorName) {
		case 'RED':
			semanticColor = TRAFFIC_LIGHT_RED;
			break;
		case 'GREEN':
			semanticColor = TRAFFIC_LIGHT_GREEN;
			break;
		case 'YELLOW':
			semanticColor = TRAFFIC_LIGHT_YELLOW;
			break;
		case 'ORANGE':
			semanticColor = TRAFFIC_LIGHT_ORANGE;
			break;
		case 'GRAY':
		default:
			semanticColor = TRAFFIC_LIGHT_UNKNOWN;
			break;
	}

	return getColorRepresentationForSemanticColor(semanticColor, colorBlindModeEnabled);
}

type RenderedMetricAssessmentProps = {
	isContentExcluded?: boolean;
	metricAssessment: MetricAssessment | undefined;
	abbreviateLargeNumbers?: boolean;
};

/** The base component to render a formatted metric. */
export function RenderedMetricAssessment({
	isContentExcluded = false,
	metricAssessment,
	abbreviateLargeNumbers = true
}: RenderedMetricAssessmentProps) {
	if (
		metricAssessment?.value == null ||
		!metricAssessment.availableInProject ||
		(isContentExcluded && metricAssessment.schemaEntry.name !== 'Files')
	) {
		return <MissingData />;
	}

	return MetricUtils.formatMetricAssessmentAsJsx(metricAssessment, {
		abbreviateValues: abbreviateLargeNumbers
	});
}

type RenderedMetricProps = {
	isContentExcluded?: boolean;
	schemaEntry: MetricDirectorySchemaEntry;
	metricValue: MetricValue | undefined;
	options?: MetricFormatterOptions;
};

/** Renders an (un-assessed) metric value. */
export function RenderedMetric({ isContentExcluded = false, schemaEntry, metricValue, options }: RenderedMetricProps) {
	if (isContentExcluded && schemaEntry.name !== 'Files') {
		return <MissingData />;
	}

	return MetricUtils.formatMetricAsJsx(metricValue, schemaEntry, options);
}

/**
 * Checks whether all values of an assessment are 0 (empty assessment) and the assessment is not considered to be
 * neutral.
 */
function isNonNeutralEmptyMetricAssessment(value: Assessment, metricSchemaEntry: MetricDirectorySchemaEntry): boolean {
	return (
		!MetricProperties.isEmptyAssessmentQualityNeutral(metricSchemaEntry) &&
		metricSchemaEntry.valueType === EMetricValueType.ASSESSMENT.name &&
		value.mapping.every(mappingValue => mappingValue === 0)
	);
}
