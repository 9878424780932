import {
	cloneElement,
	type FocusEvent,
	Fragment,
	type MutableRefObject,
	type SyntheticEvent,
	useCallback,
	useEffect,
	useRef
} from 'react';
import { doesNodeContainClick, useAutoControlledValue, useMergedRefs } from '..';
import { PortalInner } from './PortalInner';

/** Props for {@link Portal}. */
export type PortalProps = {
	/** Primary content. */
	children: JSX.Element;

	/** Controls whether or not the portal should close on a click outside. */
	closeOnDocumentClick?: boolean;

	/** Controls whether or not the portal should close when escape is pressed is displayed. */
	closeOnEscape?: boolean;

	/**
	 * Controls whether or not the portal should close when mousing out of the portal. NOTE: This will prevent
	 * `closeOnTriggerMouseLeave` when mousing over the gap from the trigger to the portal.
	 */
	closeOnPortalMouseLeave?: boolean;

	/** Controls whether or not the portal should close on blur of the trigger. */
	closeOnTriggerBlur?: boolean;

	/** Controls whether or not the portal should close on click of the trigger. */
	closeOnTriggerClick?: boolean;

	/** Controls whether or not the portal should close when mousing out of the trigger. */
	closeOnTriggerMouseLeave?: boolean;

	/** Initial value of open. */
	defaultOpen?: boolean;

	/** The node where the portal should mount. */
	mountNode?: HTMLElement;

	/** Milliseconds to wait before opening on mouse over */
	mouseEnterDelay?: number;

	/** Milliseconds to wait before closing on mouse leave */
	mouseLeaveDelay?: number;

	/** Called when a close event happens */
	onClose?: (event: SyntheticEvent<HTMLElement> | Event, data: PortalProps) => void;

	/** Called when the portal is mounted on the DOM */
	onMount?: (nothing: null, data: PortalProps) => void;

	/** Called when an open event happens */
	onOpen?: (event: SyntheticEvent<HTMLElement>, data: PortalProps) => void;

	/** Called when the portal is unmounted from the DOM */
	onUnmount?: (nothing: null, data: PortalProps) => void;

	/** Controls whether or not the portal is displayed. */
	open?: boolean;

	/** Controls whether or not the portal should open when the trigger is clicked. */
	openOnTriggerClick?: boolean;

	/** Controls whether or not the portal should open on focus of the trigger. */
	openOnTriggerFocus?: boolean;

	/** Controls whether or not the portal should open when mousing over the trigger. */
	openOnTriggerMouseEnter?: boolean;

	/** Element to be rendered in-place where the portal is defined. */
	trigger?: JSX.Element;

	/** Called with a ref to the trigger node. */
	triggerRef?: MutableRefObject<HTMLElement | undefined>;
};

/**
 * A component that allows you to render children outside their parent.
 *
 * @see Modal
 * @see Popup
 * @see Dimmer
 * @see Confirm
 */
export function Portal(props: PortalProps) {
	const {
		children,
		closeOnDocumentClick = true,
		closeOnEscape = true,
		closeOnPortalMouseLeave,
		closeOnTriggerBlur,
		closeOnTriggerClick,
		closeOnTriggerMouseLeave,
		mountNode,
		mouseEnterDelay,
		mouseLeaveDelay,
		openOnTriggerClick = true,
		openOnTriggerFocus,
		openOnTriggerMouseEnter
	} = props;

	const [open, setOpen] = useAutoControlledValue({
		state: props.open,
		defaultState: props.defaultOpen,
		initialState: false
	});

	const contentRef = useRef<HTMLElement>(null);
	const [triggerRef, trigger] = useTrigger(props.trigger, props.triggerRef!);

	const mouseEnterTimer = useRef<number>();
	const mouseLeaveTimer = useRef<number>();
	const latestDocumentMouseDownEvent = useRef<MouseEvent | null>(null);

	// ----------------------------------------
	// Behavior
	// ----------------------------------------

	const openPortal = (e: SyntheticEvent<HTMLElement>) => {
		setOpen(true);
		props.onOpen?.(e, { ...props, open: true });
	};

	const openPortalWithTimeout = (e: React.MouseEvent<HTMLElement>, delay: number | undefined) => {
		// React wipes the entire event object and suggests using e.persist() if
		// you need the event for async access. However, even with e.persist
		// certain required props (e.g. currentTarget) are null so we're forced to clone.
		const eventClone = { ...e };
		return setTimeout(() => openPortal(eventClone), delay || 0);
	};

	const closePortal = useCallback(
		(e: SyntheticEvent<HTMLElement> | Event) => {
			setOpen(false);
			props.onClose?.(e, { ...props, open: false });
		},
		[props, setOpen]
	);

	const closePortalWithTimeout = useCallback(
		(e: React.MouseEvent<HTMLElement> | MouseEvent, delay: number | undefined) => {
			// React wipes the entire event object and suggests using e.persist() if
			// you need the event for async access. However, even with e.persist
			// certain required props (e.g. currentTarget) are null so we're forced to clone.
			const eventClone = { ...e };
			return setTimeout(() => closePortal(eventClone), delay || 0);
		},
		[closePortal]
	);

	// ----------------------------------------
	// Document Event Handlers
	// ----------------------------------------

	useEffect(() => {
		// Clean up timers
		clearTimeout(mouseEnterTimer.current);
		clearTimeout(mouseLeaveTimer.current);
	}, []);

	useEffect(() => {
		const handleDocumentMouseDown = (e: MouseEvent) => {
			latestDocumentMouseDownEvent.current = e;
		};

		const handleDocumentClick = (e: MouseEvent) => {
			const currentMouseDownEvent = latestDocumentMouseDownEvent.current;
			latestDocumentMouseDownEvent.current = null;

			// event happened in trigger (delegate to trigger handlers)
			const isInsideTrigger = doesNodeContainClick(triggerRef.current, e);
			// event originated in the portal but was ended outside
			const isOriginatedFromPortal =
				currentMouseDownEvent && doesNodeContainClick(contentRef.current, currentMouseDownEvent);
			// event happened in the portal
			const isInsidePortal = doesNodeContainClick(contentRef.current, e);

			if (
				!contentRef.current?.contains || // no portal
				isInsideTrigger ||
				isOriginatedFromPortal ||
				isInsidePortal
			) {
				return;
			} // ignore the click

			if (closeOnDocumentClick) {
				closePortal(e);
			}
		};

		const handleEscape = (e: KeyboardEvent) => {
			if (!closeOnEscape) {
				return;
			}
			if (e.key !== 'Escape') {
				return;
			}

			closePortal(e);
		};

		window.addEventListener('mousedown', handleDocumentMouseDown);
		window.addEventListener('click', handleDocumentClick);
		window.addEventListener('keydown', handleEscape);
		return () => {
			window.removeEventListener('mousedown', handleDocumentMouseDown);
			window.removeEventListener('click', handleDocumentClick);
			window.removeEventListener('keydown', handleEscape);
		};
	}, [closeOnDocumentClick, closeOnEscape, closePortal, triggerRef]);

	useEffect(() => {
		const handlePortalMouseLeave = (e: MouseEvent) => {
			if (!closeOnPortalMouseLeave) {
				return;
			}

			// Do not close the portal when 'mouseleave' is triggered by children
			if (e.target !== contentRef.current) {
				return;
			}

			mouseLeaveTimer.current = closePortalWithTimeout(e, mouseLeaveDelay);
		};

		const handlePortalMouseEnter = () => {
			// In order to enable mousing from the trigger to the portal, we need to
			// clear the mouseleave timer that was set when leaving the trigger.
			if (!closeOnPortalMouseLeave) {
				return;
			}

			clearTimeout(mouseLeaveTimer.current);
		};

		if (!open) {
			return;
		}
		const content = contentRef.current;
		content?.addEventListener('mouseleave', handlePortalMouseLeave);
		content?.addEventListener('mouseenter', handlePortalMouseEnter);
		return () => {
			content?.removeEventListener('mouseleave', handlePortalMouseLeave);
			content?.removeEventListener('mouseenter', handlePortalMouseEnter);
		};
	}, [closeOnPortalMouseLeave, closePortalWithTimeout, mouseLeaveDelay, open]);

	const handleTriggerBlur = (e: FocusEvent<HTMLElement>, ...rest: unknown[]) => {
		// Call original event handler
		trigger?.props.onBlur?.(e, ...rest);

		// IE 11 doesn't work with relatedTarget in blur events
		const target = e.relatedTarget || document.activeElement;
		// do not close if focus is given to the portal
		const didFocusPortal = contentRef.current?.contains(target);

		if (!closeOnTriggerBlur || didFocusPortal) {
			return;
		}

		closePortal(e);
	};

	const handleTriggerClick = (e: React.MouseEvent<HTMLElement>, ...rest: unknown[]) => {
		// Call original event handler
		trigger?.props.onClick?.(e, ...rest);

		if (open && closeOnTriggerClick) {
			closePortal(e);
		} else if (!open && openOnTriggerClick) {
			openPortal(e);
		}
	};

	const handleTriggerFocus = (e: FocusEvent<HTMLElement>, ...rest: unknown[]) => {
		// Call original event handler
		trigger?.props.onFocus?.(e, ...rest);

		if (!openOnTriggerFocus) {
			return;
		}

		openPortal(e);
	};

	const handleTriggerMouseLeave = (e: React.MouseEvent<HTMLElement>, ...rest: unknown[]) => {
		clearTimeout(mouseEnterTimer.current);

		// Call original event handler
		trigger?.props.onMouseLeave?.(e, ...rest);

		if (!closeOnTriggerMouseLeave) {
			return;
		}

		mouseLeaveTimer.current = closePortalWithTimeout(e, mouseLeaveDelay);
	};

	const handleTriggerMouseEnter = (e: React.MouseEvent<HTMLElement>, ...rest: unknown[]) => {
		clearTimeout(mouseLeaveTimer.current);

		// Call original event handler
		trigger?.props.onMouseEnter?.(e, ...rest);

		if (!openOnTriggerMouseEnter) {
			return;
		}

		mouseEnterTimer.current = openPortalWithTimeout(e, mouseEnterDelay);
	};

	return (
		<>
			{open ? (
				<PortalInner
					mountNode={mountNode}
					onMount={() => props.onMount?.(null, props)}
					onUnmount={() => props.onUnmount?.(null, props)}
					ref={contentRef}
				>
					{children}
				</PortalInner>
			) : null}
			{trigger
				? cloneElement(trigger, {
						onBlur: handleTriggerBlur,
						onClick: handleTriggerClick,
						onFocus: handleTriggerFocus,
						onMouseLeave: handleTriggerMouseLeave,
						onMouseEnter: handleTriggerMouseEnter,
						ref: triggerRef
					})
				: null}
		</>
	);
}

function useTrigger(
	trigger: JSX.Element | null | undefined,
	triggerRef: MutableRefObject<HTMLElement | undefined>
): [ref: MutableRefObject<HTMLElement | null>, element: JSX.Element | null] {
	// @ts-ignore
	const ref = useMergedRefs(trigger?.ref, triggerRef);

	if (trigger) {
		/* istanbul ignore else */
		if (import.meta.env.DEV) {
			validateTrigger(trigger);
		}

		return [ref, cloneElement(trigger, { ref })];
	}

	return [ref, null];
}

function validateTrigger(element: JSX.Element) {
	if (element.type === Fragment) {
		throw new Error('An "Fragment" cannot be used as a `trigger`.');
	}
}

export const handledPortalProps = [
	'children',
	'closeOnDocumentClick',
	'closeOnEscape',
	'closeOnPortalMouseLeave',
	'closeOnTriggerBlur',
	'closeOnTriggerClick',
	'closeOnTriggerMouseLeave',
	'defaultOpen',
	'eventPool',
	'mountNode',
	'mouseEnterDelay',
	'mouseLeaveDelay',
	'onClose',
	'onMount',
	'onOpen',
	'onUnmount',
	'open',
	'openOnTriggerClick',
	'openOnTriggerFocus',
	'openOnTriggerMouseEnter',
	'trigger',
	'triggerRef'
];
