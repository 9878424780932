import type { Task } from 'typedefs/Task';
import { MarkdownUtils } from './MarkdownUtils';

/**
 * Utility methods for HTML-rendering, e.g. for markup. In contrast to the UIUtils, this class is supposed to have a
 * dependency to the marked library.
 */
export class TaskMarkdownUtils {
	/**
	 * Renders markdown in subjects and descriptions of tasks by setting the 'renderedSubject' & 'renderedDescription'
	 * property
	 */
	public static renderMarkdownInTaskFields(tasks: Task[]): void {
		for (const task of tasks) {
			Object.assign(task, {
				renderedSubject: MarkdownUtils.renderToSafeHtml(task.subject, {}, true),
				renderedDescription: MarkdownUtils.renderToSafeHtml(task.description, {}, true)
			});
		}
	}
}
