// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/base/scaffolding/PerspectiveBaseTemplate.soy

/**
 * @fileoverview Templates in namespace ts.base.scaffolding.PerspectiveBaseTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';


/**
 * @param {!$infoText.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $infoText = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $infoText$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.message, opt_data.iconClass, opt_data.severity);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} message
 * @param {?=} iconClass
 * @param {?=} severity
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $infoText$ = function($$areYouAnInternalCaller, $ijData, message, iconClass, severity) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.infoText']) {
    return soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.infoText']({message: message, iconClass: iconClass, severity: severity}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="no-data-available" class="ui ' + (severity ? soy.$$escapeHtmlAttribute(severity) : 'info') + ' message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.scaffolding.PerspectiveBaseTemplate.infoText ts/base/scaffolding/PerspectiveBaseTemplate.soy:22"' : '') + '>' + (iconClass ? '<i class="' + soy.$$escapeHtmlAttribute(iconClass) + '"></i>' : '') + '<span>' + soy.$$escapeHtml(message) + '</span></div>');
};
export { $infoText as infoText };
export { $infoText$ as infoText$ };
/**
 * @typedef {{
 *  message: ?,
 *  iconClass?: (?|undefined),
 *  severity?: (?|undefined),
 * }}
 */
$infoText.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $infoText.soyTemplateName = 'ts.base.scaffolding.PerspectiveBaseTemplate.infoText';
}
