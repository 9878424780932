import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { EModelComparePerspectiveView } from 'ts/perspectives/model_compare/EModelComparePerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the model compare perspective. */
export class ModelComparePerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.MODEL_COMPARE, EModelComparePerspectiveView);
	}
}
