import type { ETeamscalePerspectiveEntry } from 'typedefs/ETeamscalePerspective';
import type { EventAnnouncement } from 'typedefs/EventAnnouncement';
import type { PermissionSummary } from 'typedefs/PermissionSummary';
import type { PerspectiveContextUserInfo } from 'typedefs/PerspectiveContextUserInfo';
import type { User } from 'typedefs/User';
import { EUserOptions, type UserOptions } from 'typedefs/UserOptions';

/** Client-specific extension of the PerspectiveContextUserInfo class. */
export class ExtendedPerspectiveContextUserInfo {
	/** See {@link PerspectiveContextUserInfo#currentUser} */
	public readonly currentUser: User;

	/** See {@link PerspectiveContextUserInfo#canChangePassword} */
	public readonly canChangePassword: boolean;

	/** See {@link PerspectiveContextUserInfo#isAdmin}. */
	public readonly isAdmin: boolean;

	/** See {@link PerspectiveContextUserInfo#userOptions}. */
	public readonly userOptions: UserOptions;

	/** See {@link PerspectiveContextUserInfo#permissionSummary}. */
	public readonly permissionSummary: PermissionSummary;

	/** See {@link PerspectiveContextUserInfo#allowedPerspectives}. */
	public readonly allowedPerspectives: ETeamscalePerspectiveEntry[];

	/** See {@link PerspectiveContextUserInfo#eventAnnouncement}. */
	public readonly eventAnnouncement?: EventAnnouncement;

	public constructor(userInfo: PerspectiveContextUserInfo) {
		this.currentUser = userInfo.currentUser;
		this.canChangePassword = userInfo.canChangePassword;
		this.isAdmin = userInfo.isAdmin;
		this.userOptions = userInfo.userOptions as UserOptions;
		this.permissionSummary = userInfo.permissionSummary;
		this.allowedPerspectives = userInfo.allowedPerspectives;
		this.eventAnnouncement = userInfo.eventAnnouncement;
	}

	public isColorBlindModeEnabled(): boolean {
		return this.userOptions[EUserOptions.COLORBLIND_MODE].colorBlindModeEnabled;
	}
}
