import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { EAuditPerspectiveView } from './EAuditPerspectiveView';

/** Descriptor for the audit perspective. */
export class AuditPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.AUDIT, EAuditPerspectiveView);
	}
}
