import { QUERY } from 'api/Query';
import { type JSX, useMemo } from 'react';
import { useUserPermissionInfo } from 'ts/base/hooks/PermissionInfoHook';
import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';
import styles from './SystemHealthWarning.module.less';

/** Checks the health state after 30secs and emits a warning if the system state is critical. */
export function useSystemHealthWarning(): JSX.Element | null {
	const permissionInfo = useUserPermissionInfo();
	const teamscaleInfo = useTeamscaleInfo();

	const warning = useHealthStateWithoutPermissionCheck();

	if (
		permissionInfo.hasGlobalPermission(EGlobalPermission.ACCESS_ADMINISTRATIVE_SERVICES) &&
		teamscaleInfo.licenseInfo.license
	) {
		return warning;
	}
	return null;
}

/** The actual warning about the health state of Teamscale, which refreshes every 30 seconds. */
function useHealthStateWithoutPermissionCheck() {
	const {
		isSuccess,
		data: statusLine,
		isError,
		error
	} = QUERY.getHealthStatus({ 'critical-only': true }).useQuery({
		refetchInterval: 30000,
		throwOnError: false
	});
	const systemHealthState = useMemo(() => {
		if (statusLine == null) {
			return null;
		}
		// The health check service guarantees that the output contains a
		// numerical return code (RC) which reflects the health state of the
		// system
		const match = statusLine.match('RC: (\\d)\\)[\\n\\r]*(.+)');
		if (match !== null && match.length === 3) {
			// A return code of "2" indicates a critical health state
			return { healthy: match[1] !== '2', statusMessage: match[2]! };
		}
		return { healthy: true, statusMessage: '' };
	}, [statusLine]);
	if (isSuccess && !systemHealthState!.healthy) {
		return (
			<GlobalWarning
				// The status message may contain HTML
				message={
					<span
						className={styles.healthCheck}
						dangerouslySetInnerHTML={{ __html: systemHealthState!.statusMessage }}
					/>
				}
				severity="error"
				id="system-health-warning"
				tooltip="Teamscale appears to be in an error state."
			/>
		);
	} else if (isError) {
		return <GlobalWarning message={error.message} severity="error" />;
	}
	return null;
}
