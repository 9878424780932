import { QUERY } from 'api/Query';
import { type JSX, useMemo, useRef, useState } from 'react';
import { useProjectInfos } from 'ts/base/hooks/ProjectsInfosHook';
import { MetricChartOptions } from 'ts/commons/charts/MetricChartOptions';
import type { MetricTrendChartData } from 'ts/commons/charts/MetricTrendChartData';
import { MultiMetricTrendChart } from 'ts/commons/charts/MultiMetricTrendChart';
import { TrendChartComponent } from 'ts/commons/charts/TrendChartComponent';
import { Message } from 'ts/components/Message';
import { Select } from 'ts/components/Select';

/** How far back to show the project activity. Currently set to one year. */
const PROJECT_ACTIVITY_TREND = 365 * 24 * 3600 * 1000;

/** The project history dialog shows active Teamscale users for a certain project. */
export function ProjectHistoryDialog(): JSX.Element {
	const projects = useProjectInfos().projects;
	const selectedProject = projects[0] || null;
	const [projectId, setProject] = useState<string | null>(selectedProject);
	if (!projectId) {
		return <Message> No projects configured</Message>;
	}
	return (
		<>
			<Select
				options={projects.map(option => {
					return {
						text: option,
						key: option,
						value: option
					};
				})}
				data-testid="projectActivityChooser"
				onChange={(_, { value }) => {
					setProject(value as string);
				}}
				value={projectId}
			/>
			<ProjectActivityChartComponent projectId={projectId} />
		</>
	);
}

/** Props for ProjectActivityChartComponent. */
type ProjectActivityChartComponentProps = {
	projectId: string;
};

/** The project activity chart component. */
function ProjectActivityChartComponent({ projectId }: ProjectActivityChartComponentProps): JSX.Element {
	// We need a ref as otherwise Date.now() is continuously evaluated, which leads to an infinite query loop.
	const now = useRef(Date.now());
	const { data: activityTrend } = QUERY.getProjectUserActivityTrend({
		'project-ids': [projectId],
		baseline: now.current - PROJECT_ACTIVITY_TREND,
		end: now.current
	}).useQuery();
	const chart = useMemo(() => {
		const trendLabel = 'Unique users for ' + projectId;
		const trendData = [{ data: activityTrend, label: trendLabel, color: '#0088cc' }] as MetricTrendChartData[];
		const options = new MetricChartOptions(projectId, null, '', [], { entries: [] }, [], false, false, false);
		return new MultiMetricTrendChart(options, trendData, undefined, true, true, true, null, true);
	}, [activityTrend, projectId]);
	return <TrendChartComponent style={{ width: '800px', height: '300px' }} trendChart={chart} />;
}
