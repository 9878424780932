import { QUERY } from 'api/Query';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import type { Callback } from 'ts/base/Callback';
import type { AntPatternIncludeExcludeSupport } from 'ts/commons/AntPatternIncludeExcludeSupport';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { PathUtils } from 'ts/commons/PathUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import type { DetachedFinding } from 'ts/data/DetachedFinding';
import type { ExtendedTrackedFindingVoting } from 'ts/data/ExtendedTrackedFinding';
import { wrapFindings } from 'ts/data/ExtendedTrackedFinding';
import { TaskDataContainer } from 'ts/data/TaskDataContainer';
import { TaskUtils } from 'ts/perspectives/quality_control/tasks/utils/TaskUtils';
import type {
	TrackedIssueFieldFinding,
	TrackedIssueFinding,
	TrackedManualTestCaseFinding
} from 'ts/perspectives/requirement_tracing/spec_item_detail/components/ManualTestCaseStepsTable.services';
import { EFindingBlacklistOperation } from 'typedefs/EFindingBlacklistOperation';
import type { EFindingBlacklistType } from 'typedefs/EFindingBlacklistType';
import type { ElementLocationSubtype } from 'typedefs/ElementLocationSubtype';
import type { FindingBlacklistInfo } from 'typedefs/FindingBlacklistInfo';
import type { FindingChurnList } from 'typedefs/FindingChurnList';
import type { FindingContent } from 'typedefs/FindingContent';
import type { FindingTypeDescription } from 'typedefs/FindingTypeDescription';
import type { FormattedTokenElementInfo } from 'typedefs/FormattedTokenElementInfo';
import type { ManualTestCaseTextRegionLocation } from 'typedefs/ManualTestCaseTextRegionLocation';
import type { Task } from 'typedefs/Task';
import type { TeamscaleIssueFieldLocation } from 'typedefs/TeamscaleIssueFieldLocation';
import type { TeamscaleIssueLocation } from 'typedefs/TeamscaleIssueLocation';
import type { TokenElementInfo } from 'typedefs/TokenElementInfo';
import type { TrackedFinding } from 'typedefs/TrackedFinding';
import type { FindingLikeType } from './FindingLikeType';

export type ExtendedFindingChurnList = {
	commit: UnresolvedCommitDescriptor;
	addedFindings: ExtendedTrackedFindingVoting[];
	addedFindingsCount: number;
	findingsAddedInBranch: ExtendedTrackedFindingVoting[];
	findingsAddedInBranchCount: number;
	findingsInChangedCode: ExtendedTrackedFindingVoting[];
	findingsInChangedCodeCount: number;
	removedFindings: ExtendedTrackedFindingVoting[];
	removedFindingsCount: number;
	findingsRemovedInBranch: ExtendedTrackedFindingVoting[];
	findingsRemovedInBranchCount: number;
};

/** Possible location of a SpecItemFinding. */
export type SpecItemFindingLocation =
	| TeamscaleIssueLocation
	| TeamscaleIssueFieldLocation
	| ManualTestCaseTextRegionLocation;

/** SpecItem specific DetachedFinding */
export type SpecItemFinding = Omit<DetachedFinding, 'location'> & {
	location: SpecItemFindingLocation;
};

/** Utility methods for dealing with findings */
export class FindingsUtils {
	/**
	 * The number of lines of context for a code snippet if the snipped is expanded (appended both before and after
	 * affected line).
	 */
	public static SNIPPET_CONTEXT_LINES = 50;

	/**
	 * The number of lines of context for a code snippet if the snippet size is reduced (appended both before and after
	 * affected line).
	 */
	public static SNIPPET_SMALL_CONTEXT_LINES = 10;

	/**
	 * Returns the first line of the code snippet to be shown
	 *
	 * @param contextLines The number of lines of context for a code snippet
	 */
	public static getFirstLine(location: ElementLocationSubtype, contextLines: number): number {
		if (PathUtils.isTextRegionLocation(location)) {
			return Math.max(1, location.rawStartLine - contextLines);
		}
		return 1;
	}

	/**
	 * Returns the first line of the code snippet to be shown in the finding detail slide. If the optional start line is
	 * provided, the method returns it. Otherwise, the method calculates the first line depending on the size of the
	 * default context lines.
	 *
	 * @param codeSnippetStart Optional start line of the code snippet
	 */
	public static getFirstLineForFindingDetailSlide(
		location: ElementLocationSubtype,
		codeSnippetStart?: string
	): number {
		let firstLine;
		if (StringUtils.isEmptyOrWhitespace(codeSnippetStart)) {
			firstLine = FindingsUtils.getFirstLine(location, FindingsUtils.SNIPPET_SMALL_CONTEXT_LINES);
		} else {
			firstLine = Number(codeSnippetStart);
		}

		// This is needed to return 1 in case opt_codeSnippetStart is set to 0.
		return Math.max(1, firstLine);
	}

	/**
	 * Returns the last line of the code snippet to be shown.
	 *
	 * @param tokenElementInfo Has to be present in case the 'location' parameter is no TextRegionLocation
	 * @param location Used to determine whether ElementLocation is TextRegionLocation
	 * @param contextLines The number of lines of context for a code snippet
	 */
	public static getLastLine(
		tokenElementInfo: FormattedTokenElementInfo | TokenElementInfo | FindingContent | null,
		location: ElementLocationSubtype,
		contextLines: number
	): number {
		if (PathUtils.isTextRegionLocation(location)) {
			return location.rawEndLine + contextLines;
		}
		return tokenElementInfo?.text.split('\n').length ?? 0;
	}

	/**
	 * Returns the last line of the code snippet to be shown in the finding detail slide. If the optional end line is
	 * provided, the method returns it. Otherwise, the method calculates the last line depending on the size of the
	 * default context lines.
	 *
	 * @param tokenElementInfo Has to be present in case the 'location' parameter is no TextRegionLocation
	 * @param codeSnippetEnd Optional end line of the code snippet
	 */
	public static getLastLineForFindingDetailSlide(
		tokenElementInfo: FormattedTokenElementInfo | TokenElementInfo | FindingContent,
		location: ElementLocationSubtype,
		codeSnippetEnd?: string
	): number {
		let lastLine;
		if (StringUtils.isEmptyOrWhitespace(codeSnippetEnd)) {
			lastLine = FindingsUtils.getLastLine(tokenElementInfo, location, FindingsUtils.SNIPPET_SMALL_CONTEXT_LINES);
		} else {
			lastLine = Number(codeSnippetEnd);
		}
		return lastLine;
	}

	/**
	 * Filters out the source and target properties of architecture violation findings. These two properties do not need
	 * to be displayed either in the detail or list views, as they are already indicated in the finding's message.
	 * Optionally filters the check name, too.
	 *
	 * @param filterCheckName Whether to filter the "check name" property (default is false)
	 */
	public static filterRelevantFindingProperties(
		findingProperties: string[] | null,
		filterCheckName?: boolean
	): string[] {
		if (findingProperties == null) {
			return [];
		}
		const filteredProperties = new Set([
			'architecture-dependency-violation-target',
			'architecture-dependency-violation-source'
		]);
		if (filterCheckName) {
			filteredProperties.add('Check');
		}
		return findingProperties.filter(property => !filteredProperties.has(property));
	}

	/**
	 * Adds the given findings to the task specified by the id.
	 *
	 * @param project
	 * @param commit The commit to extract the branch name
	 * @param taskId The id of the new task
	 * @param findings Findings which should be added
	 * @param blacklistedFindingIds The IDs of the blacklisted findings amongst the given ones
	 * @param postSaveCallback Called after task is created or saved with findings.
	 */
	public static async addFindingsToTask(
		project: string,
		commit: UnresolvedCommitDescriptor | null | undefined,
		taskId: number,
		findings: TrackedFinding[],
		blacklistedFindingIds: string[],
		postSaveCallback?: Callback<Task>
	): Promise<void> {
		const subject = FindingsUtils.getTaskSubject(findings);
		const description = FindingsUtils.getTaskDescription(findings);
		let branch = null;
		if (commit != null) {
			branch = commit.branchName;
		}
		const taskDetails = new TaskDataContainer(subject, description, findings, blacklistedFindingIds, branch, null);
		await TaskUtils.addFindingsToTask(project, taskId, taskDetails, postSaveCallback);
	}

	/** Gets the task subject based on the given findings. */
	private static getTaskSubject(findings: TrackedFinding[]): string {
		if (findings.length === 0) {
			return '';
		}
		if (findings.every(finding => finding.categoryName === 'Code Duplication')) {
			return FindingsUtils.getCloneFindingTaskSubject(findings);
		} else if (findings.length <= 1) {
			const finding = findings[0]!;
			return (
				'Resolve ' +
				finding.categoryName +
				' / ' +
				finding.groupName +
				' in ' +
				PathUtils.getFileName(finding.location.uniformPath)
			);
		}
		return 'Resolve ' + findings.length + ' findings';
	}

	/** Gets the task subject for clone findings. */
	private static getCloneFindingTaskSubject(findings: TrackedFinding[]): string {
		let subject = '';
		const locations = Array.from(
			new Set(findings.map(finding => PathUtils.getFileName(finding.location.uniformPath)))
		);
		if (locations.length === 1) {
			subject += 'Redundant code within ';
		} else {
			subject += 'Redundant code between ';
		}
		locations.forEach((location, index) => {
			if (index === 0) {
				subject += '`' + location + '`';
			} else if (index === locations.length - 1) {
				subject += ' and `' + location + '`';
			} else {
				subject += ', `' + location + '`';
			}
		});
		return subject;
	}

	/** Gets the task description based on the given findings. */
	private static getTaskDescription(findings: TrackedFinding[]): string {
		let description = 'Resolve the following findings:  \n  ';
		for (let i = 0; i < findings.length; i++) {
			const finding = findings[i]!;
			description +=
				'[' + (i + 1) + '] *' + finding.message + '* in `' + finding.location.uniformPath + '`  \n  ';
		}
		return description;
	}

	/**
	 * Converts the contained findings to ExtendedTrackedFinding objects.
	 *
	 * @param commitForAddedAndChangedFindings If non-null, added findings and "findings in changed code" will link to
	 *   this commit. Otherwise, they link to findingChurnList.commit.
	 */
	public static wrapFindingChurnList(
		findingChurnList: FindingChurnList,
		blacklistInfos: FindingBlacklistInfo[],
		findingTypeIdToTypeName: Map<string, string>,
		commitForAddedAndChangedFindings?: UnresolvedCommitDescriptor,
		includeExcludePattern?: AntPatternIncludeExcludeSupport
	): ExtendedFindingChurnList {
		const commit = UnresolvedCommitDescriptor.wrap(findingChurnList.commit);
		const commitForAddedAndChanged = commitForAddedAndChangedFindings ?? commit;
		const blacklistInfosLookup = FindingsUtils.toBlacklistInfoLookup(blacklistInfos);
		return {
			commit,
			addedFindings: wrapFindings(
				findingChurnList.addedFindings,
				findingTypeIdToTypeName,
				blacklistInfosLookup,
				commitForAddedAndChanged,
				includeExcludePattern
			),
			addedFindingsCount: findingChurnList.addedFindingsCount,
			findingsInChangedCode: wrapFindings(
				findingChurnList.findingsInChangedCode,
				findingTypeIdToTypeName,
				blacklistInfosLookup,
				commitForAddedAndChanged,
				includeExcludePattern
			),
			findingsInChangedCodeCount: findingChurnList.findingsInChangedCodeCount,
			findingsAddedInBranch: wrapFindings(
				findingChurnList.findingsAddedInBranch,
				findingTypeIdToTypeName,
				blacklistInfosLookup,
				commit,
				includeExcludePattern
			),
			findingsAddedInBranchCount: findingChurnList.findingsAddedInBranchCount,
			removedFindings: wrapFindings(
				findingChurnList.removedFindings,
				findingTypeIdToTypeName,
				blacklistInfosLookup,
				commit,
				includeExcludePattern
			),
			removedFindingsCount: findingChurnList.removedFindingsCount,
			findingsRemovedInBranch: wrapFindings(
				findingChurnList.findingsRemovedInBranch,
				findingTypeIdToTypeName,
				blacklistInfosLookup,
				commit,
				includeExcludePattern
			),
			findingsRemovedInBranchCount: findingChurnList.findingsRemovedInBranchCount
		};
	}

	/** Returns the commit to use for code links and the code snippet. */
	public static getCodeSnippetFindingCommit(
		commitSetForPerspective: UnresolvedCommitDescriptor | null,
		finding: FindingLikeType
	): UnresolvedCommitDescriptor {
		const isHeadCommit = commitSetForPerspective === null || commitSetForPerspective.isLatestRevision();
		if (isHeadCommit && finding.death != null && finding.analysisTimestamp > 0) {
			return new UnresolvedCommitDescriptor(finding.analysisTimestamp);
		}
		if (finding.death != null) {
			return new UnresolvedCommitDescriptor(finding.death.timestamp, finding.death.branchName, 1);
		}
		return commitSetForPerspective || UnresolvedCommitDescriptor.createLatestOnDefaultBranch();
	}

	/** Unblacklists the given findings. */
	public static async unblacklistFindings(
		project: string,
		findingsToBlacklist: TrackedFinding[],
		commit: UnresolvedCommitDescriptor | null,
		blacklistType: EFindingBlacklistType
	): Promise<void> {
		await this.performOnGroupedFindings(findingsToBlacklist, project, commit, (findings, commit) =>
			QUERY.flagFindings(
				project,
				{ t: commit ?? undefined, operation: EFindingBlacklistOperation.REMOVE.name, type: blacklistType.name },
				{
					findingIds: findings.map(finding => finding.id)
				}
			).fetch()
		);
	}

	private static async performOnGroupedFindings(
		findings: TrackedFinding[],
		project: string,
		baseCommit: UnresolvedCommitDescriptor | null,
		callback: (findings: TrackedFinding[], commit: UnresolvedCommitDescriptor | null) => Promise<void>
	) {
		const openRequests: Array<Promise<void>> = [];
		const findingsByBranch = await this.groupFindingsByCommit(findings, project, baseCommit);
		findingsByBranch.forEach((findings, commit) => openRequests.push(callback(findings, commit)));

		return Promise.all(openRequests);
	}

	private static async groupFindingsByCommit(
		findings: TrackedFinding[],
		project: string,
		baseCommit: UnresolvedCommitDescriptor | null
	): Promise<Map<UnresolvedCommitDescriptor | null, TrackedFinding[]>> {
		// we have to group the findings by their branch and unmark for each branch separately
		const findingsByCommit = new Map<UnresolvedCommitDescriptor | null, TrackedFinding[]>();

		function addFindingWithCommit(finding: TrackedFinding, commit: UnresolvedCommitDescriptor | null): void {
			const existingFindings = findingsByCommit.get(commit) ?? [];
			existingFindings.push(finding);
			findingsByCommit.set(commit, existingFindings);
		}

		const branchRequests: Array<Promise<void>> = [];
		for (const finding of findings) {
			if (FindingsUtils.isSpecItemFindingLocation(finding.location)) {
				branchRequests.push(
					QUERY.getBranchForSpecItem(project, finding.location.issueId, { t: baseCommit ?? undefined })
						.fetch()
						.then(branch =>
							addFindingWithCommit(
								finding,
								new UnresolvedCommitDescriptor(baseCommit?.getTimestamp(), branch)
							)
						)
				);
			} else {
				addFindingWithCommit(finding, baseCommit);
			}
		}
		return Promise.all(branchRequests).then(() => findingsByCommit);
	}

	/**
	 * Checks whether the provided finding is a finding that references a spec item, i.e. some custom branch handling
	 * has to be performed.
	 */
	public static isSpecItemFindingLocation(
		location: DetachedFinding['location']
	): location is SpecItemFinding['location'] {
		return PathUtils.isSpecItemPath(location.uniformPath) && 'issueId' in location;
	}

	/** Navigates to the finding detail view of the given finding in the specified project */
	public static navigateToFindingDetailView(
		project: string,
		findingId: string,
		searchParamsId: string,
		commit: UnresolvedCommitDescriptor
	): void {
		NavigationUtils.updateLocation(
			LinkTemplate.findingDetails({
				project,
				id: findingId,
				commit,
				searchParamsId
			})
		);
	}

	/** Collects the distinct set of finding type IDs */
	public static collectTypeIds(findings: TrackedFinding[]): string[] {
		const allTypeIds = new Set<string>();
		for (const finding of findings) {
			allTypeIds.add(finding.typeId);
		}
		return Array.from(allTypeIds);
	}

	/** Builds a map from type IDs to type names. */
	public static buildTypeIdToTypeNameMap(
		allTypeIds: string[],
		findingTypeDescriptions: FindingTypeDescription[]
	): Map<string, string> {
		const findingTypeIdToTypeName = new Map<string, string>();
		for (let i = 0; i < allTypeIds.length; i++) {
			findingTypeIdToTypeName.set(allTypeIds[i]!, findingTypeDescriptions[i]!.name);
		}
		return findingTypeIdToTypeName;
	}

	/** Creates the blacklist infos to a lookup by the finding ID to the blacklist info. */
	public static toBlacklistInfoLookup(blacklistInfos: FindingBlacklistInfo[]): Map<string, FindingBlacklistInfo> {
		const blacklistedFindingsLookup = new Map<string, FindingBlacklistInfo>();
		for (const blacklistedFinding of blacklistInfos) {
			blacklistedFindingsLookup.set(blacklistedFinding.findingId, blacklistedFinding);
		}
		return blacklistedFindingsLookup;
	}

	/** Whether the provided finding is a spec item specific finding without a specific affected field. */
	public static isIssueFinding(finding: DetachedFinding): finding is TrackedIssueFinding {
		return 'issueId' in finding.location && !('affectedField' in finding.location);
	}

	/** Whether the provided finding is a spec item specific finding with a specific affected field. */
	public static isIssueFieldFinding(finding: DetachedFinding): finding is TrackedIssueFieldFinding {
		return 'issueId' in finding.location && 'affectedField' in finding.location;
	}

	/** Whether the provided finding is a test case step specific finding. */
	public static isManualTestCaseFinding(finding: DetachedFinding): finding is TrackedManualTestCaseFinding {
		return 'startTestStepIdentifier' in finding.location;
	}
}
