/** Generated from EExtendedResourceType.java. Do not modify this file! */


export type EExtendedResourceTypeEntry = 'CONTENT_EXCLUDED' | 'TEST_CODE' | 'SIMULINK_MODEL' | 'SIMULINK_DATA_DICTIONARY';

export class EExtendedResourceType {
	static readonly CONTENT_EXCLUDED = new EExtendedResourceType(0, 'CONTENT_EXCLUDED');
	static readonly TEST_CODE = new EExtendedResourceType(1, 'TEST_CODE');
	static readonly SIMULINK_MODEL = new EExtendedResourceType(2, 'SIMULINK_MODEL');
	static readonly SIMULINK_DATA_DICTIONARY = new EExtendedResourceType(3, 'SIMULINK_DATA_DICTIONARY');
	static readonly values = [
		EExtendedResourceType.CONTENT_EXCLUDED,
		EExtendedResourceType.TEST_CODE,
		EExtendedResourceType.SIMULINK_MODEL,
		EExtendedResourceType.SIMULINK_DATA_DICTIONARY
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EExtendedResourceTypeEntry,

	){
	}

	toString() {
		return this.name;
	}
}

