import type { DefinedPointInTime } from './DefinedPointInTime';
import { PointInTimeFormatter } from './PointInTimeFormatter';

/** Formatter for defined points in time. */
export class DefinedPointInTimeFormatter extends PointInTimeFormatter<DefinedPointInTime> {
	public constructor(private readonly readableName: string) {
		super();
	}

	public override format(definedPointInTime: DefinedPointInTime): string {
		return this.readableName + ': ' + definedPointInTime.name + ' [' + definedPointInTime.project + ']';
	}
}
