import { QUERY } from 'api/Query';
import { Message } from 'ts/components/Message';
import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell } from 'ts/components/Table';

/** The user history dialog that shows how many Teamscale users and committers were active in a project. */
export function UserHistoryDialog() {
	const userActivities = QUERY.calculateActiveUsersStatistics().useSuspendingQuery();
	const committerActivities = QUERY.calculateCommitterStatistics().useSuspendingQuery();
	if (userActivities.length === 0 && committerActivities.length === 0) {
		return <Message>No activities found.</Message>;
	}
	return (
		<Table unstackable striped compact selectable className="body-code-table">
			<TableHeader>
				<TableRow>
					<TableHeaderCell>Timespan</TableHeaderCell>
					<TableHeaderCell>Teamscale Users</TableHeaderCell>
					<TableHeaderCell>Committers</TableHeaderCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				{userActivities.map((entry, index) => (
					<TableRow key={entry.since + index}>
						<TableCell>{entry.since}</TableCell>
						<TableCell className="right aligned">{entry.count}</TableCell>
						<TableCell className="right aligned">{committerActivities[index]!.count}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}
