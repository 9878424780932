import clsx from 'clsx';
import { type ElementType, type ForwardedRef, type MouseEvent, type ReactNode } from 'react';
import {
	type DataAttributes,
	fixedForwardRef,
	type PolymorphicComponentPropWithRef,
	type SemanticCOLORS,
	type SemanticICONS,
	type SemanticShorthandContent,
	type SemanticShorthandItem,
	type SemanticSIZES
} from '../Generic';
import type { IconProps } from '../Icon';
import { createIcon } from '../Icon/Icon';
import { createImage } from '../Image/Image';
import {
	childrenUtils,
	createShorthandFactory,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	useEventCallback,
	valueAndKey
} from '../lib';
import { createLabelDetail, type LabelDetailProps } from './LabelDetail';

type BasicLabelProps = {
	/** A label can be active. */
	active?: boolean;

	/** A label can attach to a content segment. */
	attached?: 'top' | 'bottom' | 'top right' | 'top left' | 'bottom left' | 'bottom right';

	/** A label can reduce its complexity. */
	basic?: boolean;

	/** Primary content. */
	children?: ReactNode;

	/** A label can be circular. */
	circular?: boolean;

	/** Additional classes. */
	className?: string;

	/** Color of the label. */
	color?: SemanticCOLORS;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** A label can position itself in the corner of an element. */
	corner?: boolean | 'left' | 'right';

	/** Shorthand for LabelDetail. */
	detail?: SemanticShorthandItem<LabelDetailProps>;

	/** Formats the label as a dot. */
	empty?: boolean;

	/** Float above another element in the upper right corner. */
	floating?: boolean;

	/** A horizontal label is formatted to label content along-side it horizontally. */
	horizontal?: boolean;

	/** Add an icon by icon name or pass an <Icon /.> */
	icon?: SemanticShorthandItem<IconProps>;

	/** A label can be formatted to emphasize an image or prop can be used as shorthand for Image. */
	image?: boolean;

	/** Called on click. */
	onClick?: (event: MouseEvent<HTMLElement>, data: BasicLabelProps) => void;

	/** Adds an "x" icon, called when "x" is clicked. */
	onRemove?: (event: MouseEvent<HTMLElement>, data: BasicLabelProps) => void;

	/** A label can point to content next to it. */
	pointing?: boolean | 'above' | 'below' | 'left' | 'right';

	/** A label can prompt for an error in your forms. */
	prompt?: boolean;

	/** Shorthand for Icon to appear as the last child and trigger onRemove. */
	removeIcon?: SemanticShorthandItem<IconProps>;

	/** A label can appear as a ribbon attaching itself to an element. */
	ribbon?: boolean | 'right';

	/** A label can have different sizes. */
	size?: SemanticSIZES;

	/** A label can appear as a tag. */
	tag?: boolean;

	value?: string;
};

type GenericLabelProps<C extends ElementType> = PolymorphicComponentPropWithRef<C, BasicLabelProps>;
export type LabelProps<C extends ElementType = 'div'> = GenericLabelProps<C> & DataAttributes;

/** A label displays content classification. */
export const Label = fixedForwardRef(function Label<C extends ElementType = 'div'>(
	props: LabelProps<C>,
	ref: ForwardedRef<HTMLDivElement>
) {
	const {
		active,
		attached,
		basic,
		children,
		circular,
		className,
		color,
		content,
		corner,
		detail,
		empty,
		floating,
		horizontal,
		icon,
		image,
		onRemove,
		pointing,
		prompt,
		removeIcon,
		ribbon,
		size,
		tag
	} = props;

	const pointingClass =
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		(pointing === true && 'pointing') ||
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		((pointing === 'left' || pointing === 'right') && `${pointing} pointing`) ||
		((pointing === 'above' || pointing === 'below') && `pointing ${pointing}`);

	const classes = clsx(
		'ui',
		color,
		pointingClass,
		size,
		keyOnly(active, 'active'),
		keyOnly(basic, 'basic'),
		keyOnly(circular, 'circular'),
		keyOnly(empty, 'empty'),
		keyOnly(floating, 'floating'),
		keyOnly(horizontal, 'horizontal'),
		keyOnly(image === true, 'image'),
		keyOnly(prompt, 'prompt'),
		keyOnly(tag, 'tag'),
		keyOrValueAndKey(corner, 'corner'),
		keyOrValueAndKey(ribbon, 'ribbon'),
		valueAndKey(attached, 'attached'),
		'label',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	const handleClick = useEventCallback((e: MouseEvent<HTMLElement>) => {
		props.onClick?.(e, props);
	});

	if (!childrenUtils.isNil(children)) {
		return (
			<ElementType {...rest} className={classes} onClick={handleClick} ref={ref}>
				{children}
			</ElementType>
		);
	}

	const removeIconShorthand: SemanticShorthandItem<IconProps> | SemanticICONS =
		removeIcon === undefined ? 'delete' : removeIcon;

	return (
		<ElementType {...rest} className={classes} onClick={handleClick} ref={ref}>
			{createIcon(icon, { autoGenerateKey: false })}
			{typeof image !== 'boolean' && createImage(image, { autoGenerateKey: false })}
			{content}
			{createLabelDetail(detail, { autoGenerateKey: false })}
			{onRemove
				? createIcon(removeIconShorthand, {
						autoGenerateKey: false,
						overrideProps: (predefinedProps: IconProps) => ({
							onClick: (e: MouseEvent<HTMLElement>) => {
								predefinedProps.onClick?.(e);
								props.onRemove?.(e, props);
							}
						})
					})
				: null}
		</ElementType>
	);
});

export const createLabel = createShorthandFactory(Label, value => ({ content: value }));
const handledProps = [
	'active',
	'as',
	'attached',
	'basic',
	'children',
	'circular',
	'className',
	'color',
	'content',
	'corner',
	'detail',
	'empty',
	'floating',
	'horizontal',
	'icon',
	'image',
	'onClick',
	'onRemove',
	'pointing',
	'prompt',
	'removeIcon',
	'ribbon',
	'size',
	'tag'
];
