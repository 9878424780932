import clsx from 'clsx';
import * as _ from 'es-toolkit/compat';
import { type ElementType, type ForwardedRef, type MouseEvent, type ReactNode } from 'react';
import {
	fixedForwardRef,
	type PolymorphicComponentPropWithRef,
	type SemanticCOLORS,
	type SemanticShorthandContent,
	type SemanticShorthandItem
} from '../Generic';
import { createIcon, type IconProps } from '../Icon/Icon';
import {
	childrenUtils,
	createShorthandFactory,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	useEventCallback
} from '../lib';

/** Props for {@link BasicMenuItem}. */
export type BasicMenuItemProps = {
	/** A menu item can be active. */
	active?: boolean;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Additional colors can be specified. */
	color?: SemanticCOLORS;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** A menu item can be disabled. */
	disabled?: boolean;

	/** A menu item or menu can remove element padding, vertically or horizontally. */
	fitted?: boolean | 'horizontally' | 'vertically';

	/** A menu item may include a header or may itself be a header. */
	header?: boolean;

	/** MenuItem can be only icon. */
	icon?: boolean | SemanticShorthandItem<IconProps>;

	/** MenuItem index inside Menu. */
	index?: number;

	/** A menu item can be link. */
	link?: boolean;

	/** Internal name of the MenuItem. */
	name?: string;

	/** Called on click. When passed, the component will render as an `a` tag by default instead of a `div`. */
	onClick?: (event: MouseEvent<HTMLAnchorElement>, data: BasicMenuItemProps) => void;

	/** A menu item can take left or right position. */
	position?: 'left' | 'right';
};

type GenericMenuItemProps<C extends ElementType> = PolymorphicComponentPropWithRef<C, BasicMenuItemProps>;
export type MenuItemProps<C extends ElementType = 'div'> = GenericMenuItemProps<C> & Record<string, unknown>;
/** A menu can contain an item. */
export const MenuItem = fixedForwardRef(function MenuItem<C extends ElementType = 'div'>(
	props: MenuItemProps<C>,
	ref: ForwardedRef<HTMLDivElement>
) {
	const {
		active,
		children,
		className,
		color,
		content,
		disabled,
		fitted,
		header,
		icon,
		link,
		name,
		onClick,
		position
	} = props;

	const classes = clsx(
		color,
		position,
		keyOnly(active, 'active'),
		keyOnly(disabled, 'disabled'),
		keyOnly(icon === true || (icon && !(name || content)), 'icon'),
		keyOnly(header, 'header'),
		keyOnly(link, 'link'),
		keyOrValueAndKey(fitted, 'fitted'),
		'item',
		className
	);
	const ElementType = getComponentType(props, {
		getDefault: () => {
			if (onClick) {
				return 'a';
			}
			return undefined;
		}
	});
	const rest = getUnhandledProps(handledProps, props);

	const handleClick = useEventCallback((e: MouseEvent<HTMLAnchorElement>) => {
		if (!disabled) {
			props.onClick?.(e, props);
		}
	});

	if (!childrenUtils.isNil(children)) {
		return (
			<ElementType {...rest} className={classes} onClick={handleClick} ref={ref}>
				{children}
			</ElementType>
		);
	}

	return (
		<ElementType {...rest} className={classes} onClick={handleClick} ref={ref}>
			{createIcon(icon, { autoGenerateKey: false })}
			{childrenUtils.isNil(content) ? _.startCase(name) : content}
		</ElementType>
	);
});

export const createMenuItem = createShorthandFactory(MenuItem, val => ({
	content: val,
	name: val
}));
const handledProps = [
	'active',
	'as',
	'children',
	'className',
	'color',
	'content',
	'disabled',
	'fitted',
	'header',
	'icon',
	'index',
	'link',
	'name',
	'onClick',
	'position'
];
