import { useDisclosure } from '@mantine/hooks';
import { QUERY } from 'api/Query';
import { type JSX } from 'react';
import * as TeamscaleDashboardPerspectiveTemplate from 'soy/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy.generated';
import { ButtonSet, Dialog } from 'ts-closure-library/lib/ui/dialog';
import type { Callback } from 'ts/base/Callback';
import { useCommit } from 'ts/base/hooks/UseCommit';
import { useKeyboardShortcut } from 'ts/base/hooks/UseKeyboardShortcut';
import { useProjectIfExists } from 'ts/base/hooks/UseProject';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import * as soy from 'ts/base/soy/SoyRenderer';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import type { DropdownItemOptions } from 'ts/commons/InMenuSearchableDropdown';
import { convertToDropdownItemProps } from 'ts/commons/InMenuSearchableDropdown';
import { Links } from 'ts/commons/links/Links';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { Dropdown } from 'ts/components/Dropdown';
import { Icon } from 'ts/components/Icon';
import { ToastNotification } from 'ts/components/Toast';
import { CreateFromTemplateModal } from 'ts/perspectives/dashboard/templates/CreateFromTemplateModal';
import { DashboardUploadModal } from 'ts/perspectives/dashboard/templates/DashboardUploadModal';

/**
 * The dropdown which is shown in the dashboard perspective that allows adding new/importing dashboards and managing
 * existing templates.
 */
export function DashboardAddDropdown(): JSX.Element {
	const [
		isCreateFromTemplateModalVisible,
		{ open: openCreateFromTemplateModal, close: closeCreateFromTemplateModal }
	] = useDisclosure(false);
	const [isDashboardUploadModalVisible, { open: openDashboardUploadModal, close: closeDashboardUploadModal }] =
		useDisclosure(false);
	const itemOptions: DropdownItemOptions[] = [];
	const projectId = useProjectIfExists()?.primaryId;
	const commitDescriptor = useCommit();
	const onCreateFromTemplate = () =>
		void showImportTemplateDialog(openCreateFromTemplateModal).catch(ToastNotification.showIfServiceError);
	const addLink = Links.newDashboard(projectId, {
		commit: commitDescriptor
	});
	useKeyboardShortcut('N', 'New Dashboard', () => NavigationUtils.updateLocation(addLink));
	itemOptions.push({
		description: 'N',
		text: 'Add a new Dashboard',
		icon: <Icon name="add" color="grey" />,
		id: 'add-a-new-dashboard',
		as: TeamscaleLink,
		to: addLink
	});
	useKeyboardShortcut('T', 'Create from template', onCreateFromTemplate);
	itemOptions.push({
		description: 'T',
		text: 'Create from template',
		icon: <Icon name="file outline" color="grey" />,
		id: 'create-with-template',
		onClick: onCreateFromTemplate
	});
	itemOptions.push({
		kind: 'divider',
		key: 'divider'
	});

	itemOptions.push({
		text: 'Import dashboards/templates',
		icon: <Icon name="upload" color="grey" />,
		id: 'import-dashboard',
		onClick: openDashboardUploadModal
	});
	itemOptions.push({
		text: 'Manage templates',
		icon: <Icon name="cog" color="grey" />,
		id: 'edit-templates',
		as: TeamscaleLink,
		to: Links.dashboardTemplates()
	});
	return (
		<>
			<Dropdown
				button
				icon={false}
				value=""
				id="add-dashboard-dropdown"
				className="icon"
				trigger={<Icon name="add" />}
				options={itemOptions.map(convertToDropdownItemProps)}
			/>
			{isCreateFromTemplateModalVisible ? (
				<CreateFromTemplateModal closeModal={closeCreateFromTemplateModal} />
			) : null}
			{isDashboardUploadModalVisible ? <DashboardUploadModal onClose={closeDashboardUploadModal} /> : null}
		</>
	);
}

/** Show a dialog for importing a dashboard from a template */
async function showImportTemplateDialog(openModal: Callback<void>): Promise<void> {
	const dialog = new Dialog();
	dialog.setTitle('Create from Template');
	const buttonSet = ButtonSet.createOkCancel();
	dialog.setButtonSet(buttonSet);
	dialog.setDisposeOnHide(true);
	const templates = await QUERY.getAllDashboardTemplates().fetch();
	if (ArrayUtils.isEmpty(templates)) {
		dialog.setButtonSet(ButtonSet.createOk());
		const errorMessage = soy.renderAsElement(TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage);
		dialog.getContentElement()!.appendChild(errorMessage);
		dialog.setVisible(true);
	} else {
		openModal();
	}
}
