import { type ForwardedRef, type MutableRefObject, useCallback } from 'react';

/** Assigns a value to a React ref. */
export function setRef<T extends HTMLElement>(ref: ForwardedRef<T> | undefined | null, value: T) {
	if (typeof ref === 'function') {
		ref(value);
	} else if (ref) {
		ref.current = value;
	}
}

/**
 * React hook to merge multiple React refs (either MutableRefObjects or ref callbacks) into a single ref callback that
 * updates all provided refs.
 *
 * @returns A function with an attached "current" prop, so that it can be treated like a RefObject.
 */
export function useMergedRefs<T extends HTMLElement>(
	refA: ForwardedRef<T> | null | undefined,
	refB?: ForwardedRef<T> | null
): ((instance: T | null) => void) & MutableRefObject<T | null> {
	// @ts-ignore
	const mergedCallback: ((instance: T | null) => void) & MutableRefObject<T | null> = useCallback(
		(value: T) => {
			// Update the "current" prop hanging on the function.
			mergedCallback.current = value;

			setRef(refA, value);
			setRef(refB, value);
		},
		[refA, refB]
	);

	return mergedCallback;
}
