import { useEffect } from 'react';
import { UserOptionUtils } from 'ts/commons/UserOptionUtils';
import { ToastNotification } from 'ts/components/Toast';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import { EFeatureToggle } from 'typedefs/EFeatureToggle';
import { EUserOptions } from 'typedefs/UserOptions';
import type { WhatsNewAcknowledgeOption } from 'typedefs/WhatsNewAcknowledgeOption';

/**
 * We need to store this in global state as the perspective context can get temporarily out-of sync with the user
 * options when navigating very fast (i.e. in the testWhatsNewNotification UI test).
 */
let SHOW_WHATS_NEW_NOTIFICATION = true;

/** Shows a What's New notification unless it has already been acknowledged by the current user */
export function useWhatsNewNotification(perspectiveContext: ExtendedPerspectiveContext | undefined) {
	useEffect(() => {
		if (perspectiveContext && SHOW_WHATS_NEW_NOTIFICATION) {
			const lastAcknowledgedVersionOption =
				perspectiveContext.userInfo.userOptions[EUserOptions.WHATS_NEW_ACKNOWLEDGE];
			const currentVersion = parseMinorVersion(perspectiveContext.teamscaleInfo.version);
			if (shouldShowWhatsNewNotification(lastAcknowledgedVersionOption, currentVersion, perspectiveContext)) {
				showWhatsNewNotification(currentVersion.replace('_', '.'));
				acknowledgeCurrentVersionForWhatsNew(perspectiveContext);
			}
		}
	}, [perspectiveContext]);
}

function shouldShowWhatsNewNotification(
	lastAcknowledgedVersionOption: WhatsNewAcknowledgeOption,
	currentVersion: string,
	perspectiveContext: ExtendedPerspectiveContext
): boolean {
	const isDevMode = perspectiveContext.teamscaleInfo.enabledFeatureToggles.includes(
		EFeatureToggle.ENABLE_DEV_MODE.name
	);
	const isTestMode = perspectiveContext.teamscaleInfo.enabledFeatureToggles.includes(
		EFeatureToggle.ENABLE_TEST_MODE.name
	);
	if (isDevMode && !isTestMode) {
		return false;
	}
	if (!lastAcknowledgedVersionOption.acknowledgedVersion) {
		// we also show the notification for first time users who might as well be interested in the latest features
		return true;
	}
	const lastAcknowledgedVersion = parseMinorVersion(lastAcknowledgedVersionOption.acknowledgedVersion);
	return currentVersion !== lastAcknowledgedVersion;
}

function showWhatsNewNotification(version: string) {
	SHOW_WHATS_NEW_NOTIFICATION = false;
	ToastNotification.info(
		<>
			Discover What&apos;s New in{' '}
			<a href="documentation/changelog" target="_blank">
				Teamscale {version}
			</a>
			.
		</>,
		ToastNotification.STICKY_TOAST_OPTIONS
	);
}

function acknowledgeCurrentVersionForWhatsNew(perspectiveContext: ExtendedPerspectiveContext) {
	UserOptionUtils.setCurrentUserOption(perspectiveContext, EUserOptions.WHATS_NEW_ACKNOWLEDGE, {
		acknowledgedVersion: perspectiveContext.teamscaleInfo.version
	});
}

function parseMinorVersion(fullVersionString: string): string {
	const regex = /VERSION_(\d+_\d+)_\d+/;
	return fullVersionString.match(regex)![1]!;
}
