import { QUERY } from 'api/Query';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { ExtendedFlotOptions } from 'ts/commons/charts/data_types/ExtendedFlotOptions';
import type { MetricChartOptions } from 'ts/commons/charts/MetricChartOptions';
import { Links } from 'ts/commons/links/Links';
import { EDeltaView } from 'ts/perspectives/delta/EDeltaView';

/**
 * Updates the delta perspective link
 *
 * @param margins The margin timestamps
 * @param deltaPerspectiveLink The link to navigate to delta perspective view
 * @param options Basic chart options
 * @param targetViewName The target view within the delta perspective
 */
export async function updateDeltaPerspectiveLink(
	margins: { from: number; to: number },
	deltaPerspectiveLink: HTMLAnchorElement | null,
	options: MetricChartOptions,
	targetViewName?: ViewDescriptor
): Promise<void> {
	if (!deltaPerspectiveLink) {
		// No need to update if we are not displaying the link
		return;
	}
	if (options.project === null) {
		return;
	}
	const view = getTargetView(targetViewName ?? null, margins.from, margins.to);
	const timestamps: number[] = [];
	if (margins.from !== -1) {
		timestamps.push(margins.from);
	}
	if (margins.to !== -1) {
		timestamps.push(margins.to);
	}
	if (timestamps[0] === undefined || timestamps[1] === undefined) {
		return;
	}
	const commits = await QUERY.getMetricHistoryCommits(options.project, {
		branch: options.branchName ?? undefined,
		timestamp: timestamps
	}).fetch();
	let from;
	if (margins.from !== -1) {
		from = UnresolvedCommitDescriptor.wrap(commits.shift());
	}
	let to;
	if (margins.to !== -1) {
		to = UnresolvedCommitDescriptor.wrap(commits.shift());
	}
	deltaPerspectiveLink.href = Links.delta(options.project, options.path, {
		viewName: view,
		startTimestamp: from,
		endTimestamp: to
	});
}

/**
 * Calculates the margin timestamps of flot plot
 *
 * @param plot
 * @param flotOptions
 */
export function calculateMarginTimestamps(
	plot: jquery.flot.plot,
	flotOptions: ExtendedFlotOptions
): { from: number; to: number } {
	const plotData = plot.getData();
	if (plotData.length === 0 || plotData[0]!.data.length === 0) {
		return { from: -1, to: -1 };
	}
	const data = plotData[0]!.data;
	return {
		from: Math.round(flotOptions.xaxis!.min || data[0]![0]!),
		to: Math.round(flotOptions.xaxis!.max || data[data.length - 1]![0]!)
	};
}

/**
 * Configures a navigation hash to navigate to the delta perspective for a given interval (from, to)
 *
 * @param targetViewName The target view within the delta perspective.
 * @param from The start timestamp.
 * @param to The end timestamp.
 */
function getTargetView(targetView: ViewDescriptor | null, from: number, to: number): ViewDescriptor {
	if (targetView === null) {
		if (from !== -1 && to !== -1) {
			targetView = EDeltaView.FILE;
		} else {
			targetView = EDeltaView.PARAMETER;
		}
	}
	return targetView;
}
