import { useQuery } from '@tanstack/react-query';
import { QUERY } from 'api/Query';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import type { ProjectSpecificBaselineInfo } from 'ts/perspectives/findings/baselines/ProjectSpecificBaselineInfo';

/** Provides a list of all baselines of the specified projects. */
export function useBaselines(projects: string[] | null): ProjectSpecificBaselineInfo[] | undefined {
	const baselinesQuery = QUERY.getBaselines({ project: projects! });
	const { data } = useQuery({
		queryKey: baselinesQuery.queryKey,
		queryFn: () =>
			baselinesQuery.fetch().then(baselinesByProjects => {
				return Object.keys(baselinesByProjects).flatMap(project => {
					return baselinesByProjects[project]!.map(baselineInfo => {
						return {
							...baselineInfo,
							project
						};
					});
				});
			}),
		enabled: !ArrayUtils.isEmptyOrUndefined(projects)
	});
	return data;
}
