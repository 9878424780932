import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { ESystemPerspectiveView } from './ESystemPerspectiveView';

/** Descriptor for the system perspective. */
export class SystemPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.SYSTEM, ESystemPerspectiveView);
	}
}
