import type { Rgb } from 'ts-closure-library/lib/color/color';
import { darken, hexToRgb, parse, rgbToHex } from 'ts-closure-library/lib/color/color';
import { Assertions } from 'ts/commons/Assertions';
import { StringUtils } from 'ts/commons/StringUtils';
import type { SemanticCOLORS } from 'ts/components/Generic';
import type { EFindingEnablementEntry } from 'typedefs/EFindingEnablement';
import { EFindingEnablement } from 'typedefs/EFindingEnablement';
import { ELanguage } from 'typedefs/ELanguage';

/** Utility methods for colors */
export class ColorUtils {
	/** 'Blue' as hex string. */
	public static BLUE = '#0000ff';

	/** Teamscale's main orange color as hex string. Should match the one in teamscale_variables.less */
	public static TEAMSCALE_ORANGE = '#fd7b34';

	/** TsGreen defined in teamscale_variables.less */
	public static TEAMSCALE_GREEN = '#44bf70';

	/** TsYellow defined in teamscale_variables.less */
	public static TEAMSCALE_YELLOW = '#fde725';

	/** Yellow defined in teamscale_variables.less */
	public static TEAMSCALE_DARK_YELLOW = '#e6d222';

	/** Red defined in teamscale_variables.less */
	public static TEAMSCALE_RED = '#dd513a';

	/** Dark blue defined in teamscale_variables.less */
	public static TEAMSCALE_DARK_BLUE = '#2a586f';

	/** White as hex string. */
	public static WHITE = '#ffffff';

	/** Black as hex string. */
	public static BLACK = '#000000';

	/** A very light gray as hex string. */
	public static LIGHT_GRAY = '#aaaaaa';

	/** A gray as hex string. */
	public static GRAY = '#cccccc';

	/** Muted blue colour used for displaying infos as hex string. */
	public static MUTED_BLUE = '#2185d0';

	/** Plotly's safety orange colour as hex string. */
	public static SAFETY_ORANGE = '#ff7f0e';

	/** Plotly's cooked asparagus green colour as hex string. */
	public static COOKED_ASPARAGUS_GREEN = '#2ca02c';

	/** Plotly's brick-red colour as hex string. */
	public static BRICK_RED = '#d62728';

	/** Plotly's muted purple colour as hex string. */
	public static MUTED_PURPLE = '#9467bd';

	/** Plotly's chestnut brown colour as hex string. */
	public static CHESTNUT_BROWN = '#8c564b';

	/** Plotly's raspberry yogurt pink colour as hex string. */
	public static RASPBERRY_YOGURT_PINK = '#e377c2';

	/** Plotly's middle gray colour as hex string. */
	public static MIDDLE_GRAY = '#7f7f7f';

	/** Plotly's curry yellow-green colour as hex string. */
	public static CURRY_YELLOW_GREEN = '#bcbd22';

	/** Plotly's blue-teal colour as hex string. */
	public static BLUE_TEAL = '#17becf';

	/** Greenish blue colour as hex string. */
	public static GREEN_BLUE = '#0aaf90';

	/** Cyan */
	public static CYAN = '#00ffff';

	/** A version of the traffic light yellow, which is used in the Metrics table. */
	public static YELLOW_READABLE_ON_BRIGHT_BACKGROUND = '#FBBC05';

	/** Light blue for test coverage annotation in code views */
	public static readonly LIGHT_BLUE = '#dbebff';

	/** Light green for test coverage annotation in code views */
	public static readonly LIGHT_GREEN = '#ded';

	/** Light yellow for the test coverage annotation in code views */
	public static readonly LIGHT_YELLOW = '#fffbda';

	/** Light red for the test coverage annotation in code views */
	public static readonly LIGHT_RED = '#fee';

	/** Color names applicable to traffic light and test gap colors. */
	public static readonly TGA_COLOR_NAMES = {
		GREEN: 'green',
		ORANGE: 'orange',
		RED: 'red',
		GRAY: 'gray'
	};

	/** Color names applicable to metrics. */
	public static readonly METRICS_COLOR_NAMES = {
		GREEN: 'green',
		YELLOW: 'yellow',
		RED: 'red',
		ORANGE: 'orange',
		GRAY: 'gray'
	};

	/** The default colors for the color chooser. */
	private static readonly DEFAULT_COLORS = [
		'#639CCE',
		ColorUtils.TEAMSCALE_RED,
		ColorUtils.TEAMSCALE_YELLOW,
		ColorUtils.TEAMSCALE_GREEN,
		'#666666'
	];

	/** Returns a copy of the default colors for the color chooser. */
	public static getDefaultColorChooserColors(): string[] {
		// Copied to avoid accidental changes from somewhere else.
		return Array.from(ColorUtils.DEFAULT_COLORS);
	}

	/**
	 * @param hexColor
	 * @param factor A number between 0 and 1, where 0 will not alter the input and 1 will return black.
	 */
	public static darken(hexColor: string, factor: number): string {
		const darkerRgb = darken(hexToRgb(hexColor), factor);
		return rgbToHex(darkerRgb[0]!, darkerRgb[1]!, darkerRgb[2]!);
	}

	/**
	 * @param rgbColor The color as string in rgb representation e.g. rgb(10,20,30)
	 * @param opacity The required opacity of the color, should be a value between 0 and 1
	 * @returns The rgba color code
	 */
	public static rgbToRgba(rgbColor: string, opacity: number): string {
		return rgbColor.slice(0, 3) + 'a' + rgbColor.slice(3, rgbColor.length - 1) + ', ' + opacity + ')';
	}

	/** Converts the given hex value to RGB entries and wrap them to use in the UI. */
	public static hexToRgb(hexColor: string) {
		return 'rgb(' + hexToRgb(hexColor).toString() + ')';
	}

	/** Converts a hex or rgb color to a rgb color. */
	public static toRgb(color: string) {
		if (color.trim().startsWith('rgb')) {
			return color;
		}
		return ColorUtils.hexToRgb(color);
	}

	/**
	 * Calculates the complementary color for a given color. Complementary colors are two colors which cancel each other
	 * out if mixed. {@see https://en.wikipedia.org/wiki/Complementary_colors}. Calculation is done by inverting the rgb
	 * color representation.
	 *
	 * @param color The color. Can be any css compatible color code.
	 * @returns The hex color code of the complementary color
	 */
	public static calculateComplementaryColor(color: string): string {
		const rgbColor: Rgb = hexToRgb(parse(color).hex);
		return rgbToHex(rgbColor[0]! ^ 0xff, rgbColor[1]! ^ 0xff, rgbColor[2]! ^ 0xff);
	}

	/** Determines whether the given RGB value is exactly white. */
	public static isWhite(color: Rgb) {
		return color[0] === 0xff && color[1] === 0xff && color[2] === 0xff;
	}

	/**
	 * Returns a SemanticUI color name for the given language, which can e.g. be used for colored language labels. The
	 * mapping is not distinct, i.e. some languages can lead to the same color. As default, 'grey' is returned.
	 */
	public static getColorForLanguage(language: ELanguage): SemanticCOLORS {
		switch (language.name) {
			case ELanguage.JAVASCRIPT.name:
			case ELanguage.PYTHON.name:
				return 'yellow';
			case ELanguage.CS.name:
				return 'purple';
			case ELanguage.JAVA.name:
			case ELanguage.RUST.name:
			case ELanguage.SWIFT.name:
				return 'red';
			case ELanguage.CPP.name:
			case ELanguage.ABAP.name:
			case ELanguage.GROOVY.name:
			case ELanguage.DELPHI.name:
				return 'blue';
			case ELanguage.GO.name:
				return 'teal';
			case ELanguage.KOTLIN.name:
			case ELanguage.SIMULINK.name:
			case ELanguage.MATLAB.name:
				return 'orange';
			default:
				return 'grey';
		}
	}

	/**
	 * Returns a SemanticUI color name for the given enablement, which can e.g. be used for colored enablement labels.
	 * As default, 'grey' is returned.
	 */
	public static getColorForFindingEnablement(findingEnablement: EFindingEnablementEntry): SemanticCOLORS {
		switch (findingEnablement) {
			case EFindingEnablement.YELLOW.name:
				return 'yellow';
			case EFindingEnablement.AUTO.name:
				return 'blue';
			case EFindingEnablement.RED.name:
				return 'red';
			default:
				return 'grey';
		}
	}

	/**
	 * Extends a 3-digit hex color code to a 6-digit hex color code. E.g. #123 becomes #112233.
	 *
	 * @param hexColor The 3-digit hex color code to extend. (e.g. #123)
	 * @returns The 6-digit hex color code.
	 */
	public static extendHexColorCode(hexColor: string): string {
		const color = StringUtils.stripPrefix(hexColor, '#');

		Assertions.assert(color.length === 3, 'Expected a 3-digit hex color code');

		const convertedColor = color
			.split('')
			.map(c => c + c)
			.join('');

		return '#' + convertedColor;
	}

	/**
	 * Returns the text color (lightColor or darkColor) that should be used on the given background color.
	 *
	 * @param bgColor The background color in hex format.
	 * @param lightColor The color to use when the background color is dark.
	 * @param darkColor The color to use when the background color is light.
	 * @returns The text color that should be used on the given background color.
	 * @see https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color/41491220#41491220
	 */
	public static pickTextColorBasedOnBgColor(
		bgColor: string,
		lightColor = this.WHITE,
		darkColor = this.BLACK
	): string {
		let color = StringUtils.ensureStartsWith(bgColor, '#');
		if (color.length === 4) {
			color = this.extendHexColorCode(color);
		}

		const [r, g, b] = hexToRgb(color);

		const uiColors = [r! / 255, g! / 255, b! / 255];
		const c = uiColors.map(col => {
			if (col <= 0.03928) {
				return col / 12.92;
			}
			return Math.pow((col + 0.055) / 1.055, 2.4);
		});
		const l = 0.2126 * c[0]! + 0.7152 * c[1]! + 0.0722 * c[2]!;
		if (l > 0.179) {
			return darkColor;
		}
		return lightColor;
	}
}
