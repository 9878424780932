/// <reference types="flot" />
import $ from 'jquery';
import { JsLibraryLoader } from 'ts/base/JsLibraryLoader';
import type { FlotSeriesObject } from 'ts/commons/charts/data_types/FlotSeriesObject';

Object.assign(window, { $, jQuery: $ });

/** Utility methods for dealing with the deprecated JQuery.Flot charting library. */
export class FlotUtils {
	/**
	 * Delegates the given call to the Flot library while making sure the library was loaded. Due to this fact, don't
	 * call $.plot() directly.
	 *
	 * @deprecated Note that whenever possible, the Plotly.JS library should be used instead, because Flot is no longer
	 *   maintained.
	 */
	public static async renderFlotChart(
		chartContainer: HTMLElement,
		dataEntries: FlotSeriesObject[],
		flotOptions: jquery.flot.plotOptions
	): Promise<jquery.flot.plot> {
		await JsLibraryLoader.loadFlot();
		return $.plot($(chartContainer), dataEntries, flotOptions);
	}

	/**
	 * Dynamically sets the values of the flotOptions of the plot
	 *
	 * @param leftLabel Content of the label for the right y axis
	 * @param rightLabel Content of the label for the left y axis
	 * @returns, grid: {borderColor: string, borderWidth: number}, legend: {show: boolean, backgroundOpacity: number,
	 *   position: string, labelBoxBorderColor: string}, yaxes: [{axisLabel: *, min: *, position: string}, {axisLabel:
	 *   *, position: string}]}}
	 */
	public static setOptions(leftLabel: string, rightLabel: string): Record<string, unknown> {
		return {
			xaxis: { mode: 'time', timezone: 'browser', tickLength: 5 },
			grid: { borderWidth: 0, borderColor: '#CCC' },
			legend: { show: true, position: 'nw', backgroundOpacity: 0.5, labelBoxBorderColor: '#FFF0' },
			yaxes: [
				{ position: 'left', axisLabel: leftLabel, min: 0.0 },
				{ position: 'right', axisLabel: rightLabel }
			]
		};
	}

	/** Dynamically sets the values of the series properties. */
	public static setSeries(
		label: string,
		showLines: boolean,
		showDashes: boolean,
		yaxis: number,
		data: unknown[],
		color: string
	): FlotSeriesObject {
		return {
			label,
			lines: { show: showLines },
			dashes: { show: showDashes, dashLength: 5 },
			data,
			shadowSize: 0,
			color,
			yaxis
		};
	}
}
