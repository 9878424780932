import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { DateUtils } from 'ts/commons/DateUtils';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { CommitAssociatedObjectBase } from 'typedefs/CommitAssociatedObjectBase';
import type { RepositoryLogEntry } from 'typedefs/RepositoryLogEntry';
import type { WrappedLogEntry } from 'typedefs/WrappedLogEntry';

/** The properties that we want to append/overwrite in CommitAssociatedObjectBase objects. */
type CommitAssociatedObjectBaseExtension = {
	commit: UnresolvedCommitDescriptor;
	formattedDate: string;
};

/** The RepositoryLogEntry extended with the above properties. */
export type RepositoryLogEntryWithCommit = ExtendTypeWith<RepositoryLogEntry, CommitAssociatedObjectBaseExtension>;

export function wrapRepositoryLogEntry(
	logEntry: RepositoryLogEntry | WrappedLogEntry | RepositoryLogEntryWithCommit
): RepositoryLogEntryWithCommit;
export function wrapRepositoryLogEntry<T extends CommitAssociatedObjectBase>(
	commitAssociatedObjectBase: T
): ExtendTypeWith<T, CommitAssociatedObjectBaseExtension> {
	return Object.assign(commitAssociatedObjectBase, {
		commit: UnresolvedCommitDescriptor.wrap(commitAssociatedObjectBase.commit),
		formattedDate: DateUtils.formatTimestamp(commitAssociatedObjectBase.commit.timestamp)
	});
}
