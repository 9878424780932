import { type ForwardedRef, forwardRef } from 'react';
import { Dropdown, type DropdownProps } from 'ts/components/Dropdown';
import type { DropdownItemProps } from 'ts/components/Dropdown/DropdownItem';

/** Props for {@link Select}. */
export type SelectProps = DropdownProps & {
	/** Array of Dropdown.Item props e.g. `{ text: '', value: '' }` */
	options: DropdownItemProps[];
};

/**
 * A Select is sugar for <Dropdown selection />.
 *
 * @see Dropdown
 * @see Form
 */
export const Select = forwardRef(function Select(props: SelectProps, ref: ForwardedRef<HTMLDivElement>) {
	return <Dropdown {...props} selection ref={ref} />;
});
