import { DateUtils } from './../DateUtils';
import { PointInTimeFormatter } from './PointInTimeFormatter';
import type { Timestamp } from './Timestamp';

/** Formatter for a date time. */
export class DateTimeFormatter extends PointInTimeFormatter<Timestamp> {
	public override format(dateTime: Timestamp): string {
		return DateUtils.formatTimestamp(dateTime.timestamp);
	}
}
