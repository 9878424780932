import { contains } from 'ts-closure-library/lib/string/string';
import { Assertions } from 'ts/commons/Assertions';
import { StringUtils } from 'ts/commons/StringUtils';
import { UniformPath } from './UniformPath';

/** Represents a uniform path which consists of a project and a path within that project. This class is immutable. */
export class ProjectAndUniformPath {
	/** An empty instance of ProjectAndUniformPath with no project and path set. */
	public static EMPTY: ProjectAndUniformPath = ProjectAndUniformPath.of('', '');

	/** The project part of the path. */
	public readonly project: string;

	protected constructor(
		project: string,
		public readonly path: UniformPath,
		private readonly isPartialProject: boolean
	) {
		Assertions.assert(
			!StringUtils.isEmptyOrWhitespace(project) || path.isEmpty(),
			'Cannot create a ProjectAndPath with path but no project. Use UniformPath in such a case!'
		);
		Assertions.assert(!contains(project, '/'), 'Project may not contain a slash!');
		this.project = project;
	}

	/** @returns The project part of the uniform path. */
	public getProject(): string {
		return this.project;
	}

	/** @returns <code>true</code> iff this uniform path is empty, i.e. has no project and no path component. */
	public isEmpty(): boolean {
		// We don't have to specifically check for the path component as it is only set in the constructor iff a project is set.
		return StringUtils.isEmptyOrWhitespace(this.project);
	}

	/** @returns The path within the project. If the project is the empty string, this will also be the empty string. */
	public getPath(): string {
		return this.path.getPath();
	}

	/** @returns The uniform path within the project. If the project is the empty this will be null. */
	public getUniformPath(): UniformPath {
		return this.path;
	}

	/**
	 * @returns A new uniform path to the parent container of this path. If the path already points to the root path an
	 *   AssertionError is thrown. Check with isProjectRoot() before calling.
	 */
	public getParentPath(): ProjectAndUniformPath {
		const parentPath = this.path.getParentPath();
		return new ProjectAndUniformPath(this.project, parentPath, this.isPartialProject);
	}

	/**
	 * @returns The basename of the current path (without trailing slash). If the path is empty, returns the empty
	 *   string.
	 */
	public getBasename(): string {
		return this.path.getBasename();
	}

	/** @returns <code>true</code> iff this path is the root folder of a project. */
	public isProjectRoot(): boolean {
		return !this.isEmpty() && this.path.isEmpty();
	}

	/** @returns A string representation of the uniform path. */
	public toString(): string {
		if (this.isEmpty()) {
			return '';
		}
		let message = this.project;
		if (!this.isPartialProject) {
			message += '/';
		}
		message += this.path.getPath();
		return message;
	}

	/** Creates a ProjectAndUniformPath object for the given project and path. */
	public static of(project: string | null, path: string | null): ProjectAndUniformPath {
		if (project == null) {
			project = '';
		}
		if (path == null) {
			path = '';
		}
		return new ProjectAndUniformPath(project, new UniformPath(path), false);
	}

	/**
	 * Creates a ProjectAndUniformPath object for the given project and path.
	 *
	 * @param projectAndPath The project and path, separated by at least one slash.
	 */
	public static parse(projectAndPath: string | null): ProjectAndUniformPath {
		if (projectAndPath == null) {
			return ProjectAndUniformPath.EMPTY;
		}

		// Remove leading dots and leading/repeated slashes
		projectAndPath = UniformPath.normalizePath(projectAndPath);
		let i = projectAndPath.indexOf('/');
		if (i === -1) {
			// No slash, so it's only a project.
			i = projectAndPath.length;
		}
		const project = projectAndPath.slice(0, i);
		const path = projectAndPath.slice(i + 1);
		return new ProjectAndUniformPath(project, new UniformPath(path), i === projectAndPath.length);
	}
}
