import { type JSX, useCallback, useEffect } from 'react';
import type { RegisterOptions, ValidateResult } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import type { Callback } from 'ts/base/Callback';
import { DateUtils } from 'ts/commons/DateUtils';
import { useLocalStorage } from 'ts/commons/hooks/UseLocalStorage';
import { useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import type { DropdownItemProps } from 'ts/components/Dropdown';
import type { FormDropdownProps } from 'ts/components/Form';
import { FormDropdown } from 'ts/components/Form';

/**
 * Provides a default way to handle form inputs for the time picker tabs: handles setting the initial value, registers
 * the corresponding form field, and validates and extracts the TypedPointInTime.
 */
export function useInputWithDefault<InputValueType, ExtractedValueType>(
	fieldName: string,
	validateAndExtractPointInTime: (
		value: ExtractedValueType,
		setTypedPointInTime: Callback<TypedPointInTime>
	) => Promise<ValidateResult>,
	deriveInitialValue: (defaultValue: TypedPointInTime | null) => InputValueType | undefined,
	fallbackValue?: InputValueType,
	additionalOptions?: RegisterOptions
): [InputValueType | undefined, Callback<InputValueType>] {
	const { register, unregister, setValue } = useFormContext();
	const { setTypedPointInTime, id, projects, defaultValue } = useTimePickerContext();
	const initialValue = deriveInitialValue(defaultValue);
	const [selectedValue, setSelectedValue] = useLocalStorage(
		'timepicker-' + id + '-' + fieldName,
		initialValue ?? fallbackValue,
		initialValue
	);
	useEffect(() => {
		register(fieldName, {
			validate: value => validateAndExtractPointInTime(value, setTypedPointInTime),
			...additionalOptions
		});
		return () => unregister(fieldName);
	}, [
		fieldName,
		projects,
		register,
		unregister,
		setTypedPointInTime,
		validateAndExtractPointInTime,
		additionalOptions
	]);
	useEffect(() => {
		setValue(fieldName, selectedValue);
	}, [fieldName, selectedValue, setValue]);
	return [selectedValue, setSelectedValue];
}

/**
 * Default function for validating items of a DefinedPointInTimeDropdown, and extracting the selected items as a
 * TypedPointInTime.
 */
function validateAndExtractDropdownItem(
	value: string | null,
	setTypedPointInTime: Callback<TypedPointInTime>,
	valueMapper: (value: string) => TypedPointInTime | null
): Promise<string | undefined> {
	if (value == null) {
		return Promise.resolve('No item was selected.');
	}
	const typedPointInTime = valueMapper(value);
	if (typedPointInTime == null) {
		return Promise.resolve('The selected item is invalid. Please select an item from the list.');
	}
	setTypedPointInTime(typedPointInTime);

	return Promise.resolve(undefined);
}

/** Default function for converting point in time objects to dropdown options. */
export function convertDefinedPointInTimeToOption(item: {
	name: string;
	project: string | null;
	timestamp: number;
}): DropdownItemProps {
	const key = item.name + '-' + item.project;
	let text = `${item.name} (${DateUtils.formatTimestamp(item.timestamp)})`;
	if (item.project != null) {
		text += ` [${item.project}]`;
	}
	return {
		key,
		text,
		value: JSON.stringify({ name: item.name, project: item.project })
	};
}

/** Provides a default way to handle form inputs for the time picker tabs with dropdown menus. */
export function useControlledSelectValue(
	fieldName: string,
	valueMapper: (value: string) => TypedPointInTime | null,
	deriveInitialValue: (defaultValue: TypedPointInTime | null) => string | undefined
): [string | undefined, Callback<string>] {
	const validateAndExtract = useCallback(
		(value: string | null, setTypedPointInTime: Callback<TypedPointInTime>) =>
			validateAndExtractDropdownItem(value, setTypedPointInTime, valueMapper),
		[valueMapper]
	);
	return useInputWithDefault(fieldName, validateAndExtract, deriveInitialValue);
}

/** Props for DefinedPointInTimeDropdown. */
type DefinedPointInTimeDropdownProps<T> = FormDropdownProps & {
	testId: string;
	selectedValue: string | undefined;
	setSelectedValue: Callback<string>;
	displayObjects: T[];
	optionMapper: (item: T) => DropdownItemProps;
};

/** A dropdown for selecting a DefinedPointInTime. */
export function DefinedPointInTimeDropdown<T>({
	testId,
	selectedValue,
	setSelectedValue,
	displayObjects,
	optionMapper,
	...dropdownProps
}: DefinedPointInTimeDropdownProps<T>): JSX.Element | null {
	const options = displayObjects.map(optionMapper);
	return (
		<FormDropdown
			fluid
			search
			selection
			data-testid={testId}
			options={options}
			onChange={(event, { value }) => {
				setSelectedValue(value as string);
			}}
			value={selectedValue}
			{...dropdownProps}
		/>
	);
}
