import clsx from 'clsx';
import { type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import type { SemanticShorthandContent } from '../Generic';
import { childrenUtils, createShorthandFactory, getComponentType, getUnhandledProps } from '../lib';

/** Props for {@link PopupHeader}. */
export type PopupHeaderProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;
};

/** A PopupHeader displays a header in a Popover. */
export const PopupHeader = forwardRef(function PopupHeader(props: PopupHeaderProps, ref: ForwardedRef<HTMLDivElement>) {
	const { children, className, content } = props;

	const classes = clsx('header', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
});

export const createPopupHeader = createShorthandFactory(PopupHeader, children => ({ children }));
const handledProps = ['as', 'children', 'className', 'content'];
