import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { CodeLineSelection } from './CodeLineSelection';
import { CodeSelection } from './CodeSelection';

/** Describes a selection of code based on offset. */
export class CodeOffsetSelection extends CodeSelection {
	/**
	 * @param startOffset The start offset of which the containing line should be highlighted
	 * @param endOffset The end offset of which the containing line should be highlighted
	 */
	public constructor(
		public readonly startOffset: number,
		public readonly endOffset: number
	) {
		super();
	}

	public override resolve(lineOffsets: number[]): CodeLineSelection {
		return new CodeLineSelection(
			CodeOffsetSelection.getLineFromOffset(lineOffsets, this.startOffset),
			CodeOffsetSelection.getLineFromOffset(lineOffsets, this.endOffset)
		);
	}

	/**
	 * Converts the specified character based offset to a line-based offset.
	 *
	 * @param lineOffsets An array that contains for each line the offset of its line ending
	 * @param offset The character based offset to convert to line based offset
	 */
	public static getLineFromOffset(lineOffsets: number[], offset: number): number {
		const index = ArrayUtils.binarySearch(lineOffsets, offset, ArrayUtils.defaultCompare);
		if (index < 0) {
			return -index - 1;
		}
		return index;
	}
}
