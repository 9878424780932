import clsx from 'clsx';
import { type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';

import type {
	SemanticFLOATS,
	SemanticShorthandContent,
	SemanticShorthandItem,
	SemanticVERTICALALIGNMENTS
} from '../Generic';
import {
	childrenUtils,
	createShorthandFactory,
	getComponentType,
	getUnhandledProps,
	valueAndKey,
	verticalAlignProp
} from '../lib';
import type { ListDescriptionProps } from './ListDescription';
import { createListDescription } from './ListDescription';
import type { ListHeaderProps } from './ListHeader';
import { createListHeader } from './ListHeader';

/** Props for {@link ListContent}. */
export type ListContentProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** Shorthand for ListDescription. */
	description?: SemanticShorthandItem<ListDescriptionProps>;

	/** An list content can be floated left or right. */
	floated?: SemanticFLOATS;

	/** Shorthand for ListHeader. */
	header?: SemanticShorthandItem<ListHeaderProps>;

	/** An element inside a list can be vertically aligned. */
	verticalAlign?: SemanticVERTICALALIGNMENTS;
};

/** A list item can contain a content. */
export const ListContent = forwardRef(function ListContent(props: ListContentProps, ref: ForwardedRef<HTMLDivElement>) {
	const { children, className, content, description, floated, header, verticalAlign } = props;

	const classes = clsx(valueAndKey(floated, 'floated'), verticalAlignProp(verticalAlign), 'content', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	if (!childrenUtils.isNil(children)) {
		return (
			<ElementType {...rest} className={classes} ref={ref}>
				{children}
			</ElementType>
		);
	}

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{createListHeader(header)}
			{createListDescription(description)}
			{content}
		</ElementType>
	);
});

export const createListContent = createShorthandFactory(ListContent, content => ({ content }));
const handledProps = ['as', 'children', 'className', 'content', 'description', 'floated', 'header', 'verticalAlign'];
