import { ArrayUtils } from 'ts/commons/ArrayUtils';

/** Utility methods for object handling. */
export class ObjectUtils {
	/**
	 * Converts an object to a map.
	 *
	 * @param object Array of objects from which some objects should be removed.
	 */
	public static toMap<V>(object: Record<string, V> | null): Map<string, V> {
		if (object === null) {
			return new Map<string, V>();
		}
		const keys = Object.keys(object);
		return new Map<string, V>(keys.map(key => [key, object[key]!]));
	}

	/** Applies the mapping to the values of the record. */
	public static mapValues<V, R>(obj: Record<string, V>, mapping: (value: V, key: string) => R): Record<string, R> {
		return Object.keys(obj).reduce(
			(result, key) => {
				result[key] = mapping(obj[key]!, key);
				return result;
			},
			{} as Record<string, R>
		);
	}

	/**
	 * Performs a deep equals comparison amongst two objects recursively comparing each field's value for equality.
	 *
	 * @param x First element to compare
	 * @param y Second element to compare
	 * @param nonStrict Defaults to false, only if set to true, the comparison is performed with ==
	 * @param ignoredKeys Defaults to empty array, if set, the given keys will not be compared
	 * @returns True if equal
	 */
	public static deepEqual(x: unknown, y: unknown, nonStrict = false, ignoredKeys: string[] = []): boolean {
		if (x && y && Array.isArray(x) && Array.isArray(y)) {
			return ArrayUtils.equals(x, y, (ele1, ele2) => ObjectUtils.deepEqual(ele1, ele2, nonStrict, ignoredKeys));
		}
		if (x && y && typeof x === 'object' && typeof y === 'object') {
			return (
				Object.keys(x).length === Object.keys(y).length &&
				Object.keys(x)
					.filter(key => !ignoredKeys.includes(key))
					.every(key =>
						ObjectUtils.deepEqual(
							(x as Record<string, unknown>)[key],
							(y as Record<string, unknown>)[key],
							nonStrict,
							ignoredKeys
						)
					)
			);
		}
		// Only if explicitly given as parameter, strict equality is softened to ==
		// eslint-disable-next-line eqeqeq
		return x === y || (nonStrict && x == y);
	}

	/** Returns the keys of the given record. */
	public static numberKeys(record: Record<number, unknown>): number[] {
		return Object.keys(record).map(key => Number(key));
	}

	/**
	 * Returns a deep copy of an object. This supports a wide range of data types, but does NOT support functions.
	 *
	 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm#supported_types
	 */
	public static deepClone<T>(object: T): T {
		return structuredClone(object);
	}

	/**
	 * Clones a value. The input may be an Object, Array, or basic type. Objects and arrays will be cloned recursively.
	 * WARNINGS: <code>unsafeClone</code> does not detect reference loops. Objects that refer to themselves will cause
	 * infinite recursion. Set and Map objects will also only be shallow-cloned.
	 *
	 * @deprecated Use ObjectUtils.deepClone where possible i.e., when obj does not contain functions
	 * @param obj The value to clone.
	 * @returns A clone of the input value.
	 */
	public static unsafeClone<T>(obj: T): T {
		if (!obj || typeof obj !== 'object') {
			return obj;
		}
		if (typeof Map !== 'undefined' && obj instanceof Map) {
			return new Map(obj) as T;
		} else if (typeof Set !== 'undefined' && obj instanceof Set) {
			return new Set(obj) as T;
		}
		let clone: T;
		if (Array.isArray(obj)) {
			clone = [] as T;
		} else if (
			typeof ArrayBuffer === 'function' &&
			typeof ArrayBuffer.isView === 'function' &&
			ArrayBuffer.isView(obj) &&
			!(obj instanceof DataView)
		) {
			// @ts-ignore
			clone = new obj.constructor(obj.length);
		} else {
			clone = {} as T;
		}
		for (const key in obj) {
			clone[key] = ObjectUtils.unsafeClone(obj[key]);
		}
		return clone;
	}

	/** Takes an array of objects and group them based on the property of the return value of the given function */
	public static groupBy<T, R extends string | number>(list: T[], func: (item: T) => R) {
		return list.reduce(
			(obj, item) => {
				const existingItems = obj[func(item)] ?? [];
				return { ...obj, [func(item)]: [...existingItems, item] };
			},
			{} as Partial<Record<R, T[]>>
		);
	}
}
