import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { EChecksPerspectiveView } from 'ts/perspectives/checks/EChecksPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the checks perspective. */
export class ChecksPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.CHECKS, EChecksPerspectiveView);
	}
}
