import type { JSX, ReactNode } from 'react';
import { useMemo } from 'react';
import type { FieldError, FieldValues, UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import type { Callback } from 'ts/base/Callback';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { AmbiguousRevisionPicker } from 'ts/commons/time/components/AmbiguousRevisionPicker';
import { BaselinePicker } from 'ts/commons/time/components/BaselinePicker';
import DateTimePicker from 'ts/commons/time/components/DateTimePicker';
import { GitTagPicker } from 'ts/commons/time/components/git_tags/GitTagPicker';
import { RevisionPicker } from 'ts/commons/time/components/RevisionPicker';
import { SystemVersionPicker } from 'ts/commons/time/components/SystemVersionPicker';
import { TimePickerContextProvider, useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import { TimespanPicker } from 'ts/commons/time/components/TimespanPicker';
import { ETimePickerType } from 'ts/commons/time/ETimePickerType';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import { Button } from 'ts/components/Button';
import { Form } from 'ts/components/Form';
import { Message } from 'ts/components/Message';
import { ModalActionButtons } from 'ts/components/Modal';
import { Tab, TabPane } from 'ts/components/Tab';

/** Props for FormWithErrorMessages. */
type FormWithErrorMessagesProps = {
	children: JSX.Element;
};

/** Provides a form that displays the error messages in a message. Must be nested within a FormProvider. */
function FormWithErrorMessages({ children }: FormWithErrorMessagesProps): JSX.Element {
	const {
		formState: { errors }
	} = useFormContext();
	return (
		<>
			{children}
			{Object.keys(errors).length > 0 ? (
				<Message negative>
					{Object.keys(errors).map(key => {
						return <div key={key}>{(errors[key] as FieldError).message}</div>;
					})}
				</Message>
			) : null}
		</>
	);
}

/** Pane type used for the {@link Tab}. */
type TimePickerTab = {
	menuItem: ETimePickerType;
	render: () => ReactNode;
};

/** Determines which tabs must be rendered and returns them as an array of panes for a {@link Tab}. */
function useTimePickerTabs(dateOnly: boolean, disabledTabs: ETimePickerType[]): TimePickerTab[] {
	const { projects, systemVersions, ambiguousRevision } = useTimePickerContext();
	return useMemo(() => {
		const components = [<DateTimePicker key={ETimePickerType.TIMESTAMP} dateOnly={dateOnly} />];
		if (!ArrayUtils.isEmptyOrUndefined(projects)) {
			components.push(<RevisionPicker key={ETimePickerType.REVISION} />);
			if (ambiguousRevision != null) {
				components.push(<AmbiguousRevisionPicker key={ETimePickerType.AMBIGUOUS_REVISION} />);
			}
			components.push(<GitTagPicker key={ETimePickerType.GIT_TAG} />);
			components.push(<BaselinePicker key={ETimePickerType.BASELINE} />);
			if (!ArrayUtils.isEmptyOrUndefined(systemVersions)) {
				components.push(<SystemVersionPicker key={ETimePickerType.SYSTEM_VERSION} />);
			}
		}
		components.push(<TimespanPicker key={ETimePickerType.TIMESPAN} />);
		return components
			.filter(component => !disabledTabs.includes(component.key as ETimePickerType))
			.map(component => {
				return {
					menuItem: component.key as ETimePickerType,
					render() {
						return (
							<TabPane key={component.key} className="tab-content">
								<FormWithErrorMessages>{component}</FormWithErrorMessages>
							</TabPane>
						);
					}
				};
			});
	}, [dateOnly, disabledTabs, projects, systemVersions, ambiguousRevision]);
}

/** Determines which tab index must be selected as active. */
function useActiveTabIndex(panes: TimePickerTab[]): number {
	const { activeTabKey } = useTimePickerContext();
	let activeTabIndex;
	if (activeTabKey != null) {
		activeTabIndex = Math.max(
			0,
			panes.findIndex(pane => pane.menuItem === activeTabKey)
		);
	} else {
		activeTabIndex = 0;
	}

	return activeTabIndex;
}

/** Props for TimePickerDialog. */
type TimePickerDialogProps = TimePickerDialogTabsProps & {
	/** An ID that can be used to store the state of one instance of the time picker dialog. */
	id: string;
	/**
	 * An array of projects from which to retrieve baselines or revisions for selection. This may be null to indicate
	 * the selection of a project-independent date. In this case only a basic date/time picker will be shown.
	 */
	projects: string[] | null;
	/** The given value will be shown on startup of the dialog if it is set to a valid value. */
	defaultValue: TypedPointInTime | null;
	/** A function that is called if setting the value is successful. */
	onChange: Callback<TypedPointInTime>;
	/** A function that is called when the dialog is closed. */
	onClose: () => void;
};

/** Provides a dialog content for picking the time. */
export function TimePickerDialogContent({
	dateOnly,
	disabledTabs,
	onChange,
	id,
	projects,
	onClose,
	defaultValue
}: TimePickerDialogProps): JSX.Element {
	const formMethods = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' });
	return (
		<FormProvider {...formMethods}>
			<Form
				error
				onSubmit={event => {
					formMethods.clearErrors();
					// If everything was valid, the dialog can be closed, otherwise the error is handled by the FormWithErrorMessages
					formMethods.handleSubmit(onClose)(event);
				}}
			>
				<TimePickerContextProvider onChange={onChange} id={id} projects={projects} defaultValue={defaultValue}>
					<SuspendingErrorBoundary>
						<TimePickerDialogTabs dateOnly={dateOnly} disabledTabs={disabledTabs} />
					</SuspendingErrorBoundary>
					<TimePickerActionButtons disabledTabs={disabledTabs} onClose={onClose} />
				</TimePickerContextProvider>
			</Form>
		</FormProvider>
	);
}

type TimePickerActionButtonsProps = {
	disabledTabs: ETimePickerType[];
	onClose: () => void;
};

function TimePickerActionButtons({ disabledTabs, onClose }: TimePickerActionButtonsProps) {
	const { setError, clearErrors } = useFormContext();
	const { setTypedPointInTime } = useTimePickerContext();

	let nowButton: JSX.Element | null = null;
	if (!disabledTabs.includes(ETimePickerType.TIMESPAN) || !disabledTabs.includes(ETimePickerType.TIMESTAMP)) {
		nowButton = (
			<Button
				type="button"
				content="Now"
				data-testid="nowButton"
				onClick={() => {
					clearErrors();
					onClickNowButton(setError, disabledTabs, setTypedPointInTime);
					onClose();
				}}
			/>
		);
	}
	return (
		<ModalActionButtons>
			<Button type="submit" primary content="OK" data-testid="okButton" />
			<Button type="button" content="Cancel" data-testid="cancelButton" onClick={onClose} />
			{nowButton}
		</ModalActionButtons>
	);
}

type TimePickerDialogTabsProps = {
	/** Optional tabs that should be disabled. */
	disabledTabs: ETimePickerType[];
	/** If true, setting the time is hidden and defaults to 00:00. */
	dateOnly: boolean;
};

/** Provides a dialog for picking the time. Must be nested within a FormProvider and a TimePickerContextProvider. */
function TimePickerDialogTabs({ dateOnly, disabledTabs }: TimePickerDialogTabsProps): JSX.Element {
	const { reset } = useFormContext();
	const { activeTabKey, setActiveTabKey } = useTimePickerContext();
	const panes = useTimePickerTabs(dateOnly, disabledTabs);
	const activeTabIndex = useActiveTabIndex(panes);

	return (
		<div data-testid="time-revision-picker-content" className="min-w-[400px]">
			<Tab
				onTabChange={(event, { panes, activeIndex }) => {
					if (
						panes != null &&
						activeIndex != null &&
						typeof activeIndex === 'number' &&
						activeIndex < panes.length &&
						activeTabKey !== panes[activeIndex]!.menuItem
					) {
						setActiveTabKey(panes[activeIndex]!.menuItem as ETimePickerType);
						reset();
					}
				}}
				menu={{ secondary: true, pointing: true }}
				panes={panes}
				activeIndex={activeTabIndex}
			/>
		</div>
	);
}

/** Handles the onClick event of the "Now" button. */
function onClickNowButton(
	setError: UseFormSetError<FieldValues>,
	disabledTabs: ETimePickerType[],
	setTypedPointInTime: Callback<TypedPointInTime>
): void {
	if (!disabledTabs.includes(ETimePickerType.TIMESPAN)) {
		setTypedPointInTime(TimeUtils.fullHistory());
		return;
	}
	if (!disabledTabs.includes(ETimePickerType.TIMESTAMP)) {
		setTypedPointInTime(TimeUtils.timestamp(new Date().getTime()));
		return;
	}
	setError('date', { message: 'Cannot set "Now" value when timespan and timestamp tabs are hidden' });
}
