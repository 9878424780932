import { useDisclosure } from '@mantine/hooks';
import { type ReactNode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BaseProviders, ReactUtils } from 'ts/base/ReactUtils';
import { Modal, type ModalProps } from 'ts/components/Modal';

const MODAL_UNMOUNT_CALLS = new Set<() => void>();

/** The options that can be passed to openModal. */
export type ModalOptions = Omit<ModalProps, 'onClose' | 'opened' | 'children'> & {
	/**
	 * Receives a function that will close the modal when called and returns the content that should be rendered in the
	 * modal.
	 */
	contentRenderer: (close: () => void) => ReactNode;
	onClose?: () => void;
	'data-testid'?: string;
};

/** Opens a new modal with the given options. */
export function openModal(modalProps: ModalOptions) {
	const wrapper = document.createElement('div');
	document.body.appendChild(wrapper);
	const modalRoot = createRoot(wrapper);
	const unmount = () => {
		MODAL_UNMOUNT_CALLS.delete(unmount);
		setTimeout(() => {
			modalRoot.unmount();
			if (Array.from(document.body.children).includes(wrapper)) {
				document.body.removeChild(wrapper);
			}
		});
	};
	MODAL_UNMOUNT_CALLS.add(unmount);
	ReactUtils.replace(<ManagedModal unmount={unmount} {...modalProps} />, modalRoot);
}

type ManagedModalProps = ModalOptions & {
	unmount: () => void;
};

function ManagedModal({ contentRenderer, unmount, onClose, ...modalProps }: ManagedModalProps) {
	const [opened, { open, close }] = useDisclosure(false);
	// Open after first render to get the transition
	useEffect(open, [open]);
	const onCloseInternal = () => {
		close();
		onClose?.();
		// Unmount delayed to get the transition
		setTimeout(unmount, 300);
	};
	return (
		<Modal onClose={onCloseInternal} opened={opened} size="xl" closeOnClickOutside={false} {...modalProps}>
			<BaseProviders>{contentRenderer(onCloseInternal)}</BaseProviders>
		</Modal>
	);
}

/** Disposes all currently shown modals. */
export function disposeAllOpenModals() {
	for (const unmount of MODAL_UNMOUNT_CALLS) {
		unmount();
	}
	MODAL_UNMOUNT_CALLS.clear();
}
