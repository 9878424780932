import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { ColorUtils } from 'ts/commons/ColorUtils';
import { ObjectUtils } from 'ts/commons/ObjectUtils';
import { type IRect, Rect } from 'ts/commons/Rect';
import type { NodeInfo, TreeMapOutlineOptions } from 'ts/commons/treemap/TreeMap';
import type { TreeMapNodeBase } from 'typedefs/TreeMapNodeBase';

/** Renderer for a flat tree map. */
export class FlatTreeMapRenderer<Node extends TreeMapNodeBase> {
	/**
	 * The ratio of the resolution in physical pixels to the resolution in CSS pixels for the current display device.
	 *
	 * This value could also be interpreted as the ratio of pixel sizes: the size of one CSS pixel to the size of one
	 * physical pixel. In simpler terms, this tells the browser how many of the screen's actual pixels should be used to
	 * draw a single CSS pixel.
	 *
	 * This is useful when dealing with the difference between rendering on a standard display versus a HiDPI or Retina
	 * display, which use more screen pixels to draw the same objects, resulting in a sharper image.
	 *
	 * See https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
	 */
	public static readonly DEVICE_PIXEL_RATIO = window.devicePixelRatio;

	/** The padding to add around the treemap to ensure the treemap des not stretch to the edge of the canvas in pixels. */
	private static readonly TREEMAP_INSET = 1;

	private static readonly CHILD_NODE_HIGHLIGHT_COLOR = 'rgba(255, 255, 255, 0.35)';

	private static readonly ANNOTATED_NODE_HIGHLIGHT_COLOR = 'rgba(255, 255, 255, 0.5)';

	/** The color used to draw the highlight around the hovered treemap nodes. */
	private static readonly STROKE_HIGHLIGHT_COLOR = '#639CCE';

	/** The rect within the canvas that the top-most node of the treemap should be rendered into. */
	private readonly targetRect: Rect;

	private static readonly REGULAR_NODE_OUTLINE_WIDTH = 1;

	private static readonly CURRENT_PACKAGE_OUTLINE_WIDTH = 2;

	private rootNode: Node | undefined;

	/**
	 * @param outlineOptions Options defining the outline which should be applied to the treemap.
	 * @param isMethodBasedTreeMap Determines if the package outline is rendered on the lowest level of the treemap. In
	 *   contrast, file based treemaps method based treemaps will never draw an outline around the leaf nodes.
	 * @param getColor Lookup for the node color
	 * @param checkNodeMatchesSearch A function that determines if a node matches a given search criteria.
	 * @param drillDownEnabled Whether the drill-down mode is active on the treemap. This slightly changes the behavior
	 *   (e.g. rendering annotations in a different position, hiding annotations if they don't fit, showing zoom icon)
	 */
	public constructor(
		width: number,
		height: number,
		private readonly outlineOptions: TreeMapOutlineOptions,
		private readonly isMethodBasedTreeMap: boolean,
		private readonly getColor: (node: Node) => string,
		private readonly checkNodeMatchesSearch: (node: Node) => boolean = () => true
	) {
		this.targetRect = new Rect(
			FlatTreeMapRenderer.TREEMAP_INSET,
			FlatTreeMapRenderer.TREEMAP_INSET,
			width - FlatTreeMapRenderer.TREEMAP_INSET * 2,
			height - FlatTreeMapRenderer.TREEMAP_INSET * 2
		).scale(FlatTreeMapRenderer.DEVICE_PIXEL_RATIO);
	}

	/**
	 * Renders leaf nodes of the tree map and outlines packages if needed.
	 *
	 * @param node The treemap/node to render.
	 * @param currentDepth The current depth within the children hierarchy.
	 */
	public render(context: CanvasRenderingContext2D, node: Node): void {
		context.save();
		this.rootNode = node;

		// Translates the context by half a pixel so the edges are right in the middle of
		// edges and thus appear sharp  (see https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Applying_styles_and_colors#a_linewidth_example)
		context.translate(0.5, 0.5);
		this.renderNode(context, node, 0, this.targetRect);
		context.restore();
	}

	/**
	 * Renders leaf nodes of the tree map and outlines packages if needed.
	 *
	 * @param node The treemap/node to render.
	 * @param currentDepth The current depth within the children hierarchy.
	 */
	private renderNode(context: CanvasRenderingContext2D, node: Node, currentDepth: number, targetRect: IRect): void {
		if (!ArrayUtils.isEmptyOrUndefined(node.children)) {
			for (const child of node.children) {
				const rect = FlatTreeMapRenderer.getChildRect(node, child, targetRect);
				this.renderNode(context, child as Node, currentDepth + 1, rect);
			}
		} else {
			this.fillRectangle(context, node, targetRect);
		}
		this.renderPackageOutline(context, node, targetRect, currentDepth);
	}

	/**
	 * Renders an outline around the specified package node. Note that this does not care if the node is really a
	 * package or a file-leaf.
	 *
	 * @param node The treemap/node to render.
	 */
	private renderPackageOutline(
		context: CanvasRenderingContext2D,
		node: Node,
		targetRect: IRect,
		currentDepth: number
	): void {
		if (currentDepth !== this.outlineOptions.outlineDepth || this.outlineOptions.outlineDepth === 0) {
			return;
		}
		// Do not draw outlines around methods
		if (this.isMethodBasedTreeMap && ArrayUtils.isEmptyOrUndefined(node.children)) {
			return;
		}
		const rect = FlatTreeMapRenderer.getRoundedRect(targetRect);
		this.renderStroke(
			context,
			rect,
			FlatTreeMapRenderer.CURRENT_PACKAGE_OUTLINE_WIDTH,
			this.outlineOptions.outlineColor
		);
	}

	/**
	 * Fills the tree map rectangle with its color. Also considers {@link #checkNodeMatchesSearch}: if there is an active
	 * search, the matching nodes are displayed with the regular node color, while the non-matching nodes are displayed
	 * with darkened color.
	 *
	 * @param node The treemap/node to render.
	 */
	private fillRectangle(context: CanvasRenderingContext2D, node: Node, targetRect: IRect): void {
		let fillStyle;
		if (!this.checkNodeMatchesSearch(node)) {
			fillStyle = ColorUtils.darken(this.getColor(node), 0.6);
		} else {
			fillStyle = this.getColor(node);
		}
		const rect = FlatTreeMapRenderer.getRoundedRect(targetRect);
		this.renderFilledRect(context, rect, fillStyle);

		const strokeStyle = ColorUtils.darken(fillStyle, 0.3);
		this.renderStroke(context, rect, FlatTreeMapRenderer.REGULAR_NODE_OUTLINE_WIDTH, strokeStyle);
	}

	/** Renders the given rect. */
	public renderFilledRect(context: CanvasRenderingContext2D, rect: IRect, fillStyle: string) {
		context.fillStyle = fillStyle;
		context.fillRect(rect.left, rect.top, rect.width, rect.height);
	}

	/**
	 * Renders a stroke around the given rect.
	 *
	 * @param rect The rectangle to render a stroke for.
	 * @param lineWidth The line width in px.
	 * @param strokeStyle The stroke style.
	 */
	public renderStroke(context: CanvasRenderingContext2D, rect: IRect, lineWidth: number, strokeStyle: string): void {
		context.lineWidth = lineWidth * FlatTreeMapRenderer.DEVICE_PIXEL_RATIO;
		context.strokeStyle = strokeStyle;
		context.strokeRect(rect.left, rect.top, rect.width, rect.height);
	}

	/**
	 * Render the highlight(s) for the given node to context.
	 *
	 * @param node The node to highlight inside the tree map.
	 */
	public renderHighlight(context: CanvasRenderingContext2D, nodeInfo: NodeInfo<Node> | undefined): void {
		if (nodeInfo === undefined || this.rootNode === undefined) {
			return;
		}

		const annotationNodeIsHovered = nodeInfo.isDrillDownNodeHovered;

		let rect: Rect = this.targetRect;
		for (let i = 0; i < nodeInfo.parents.length; i++) {
			const node = nodeInfo.parents[i]!;

			// Brighten selected node a bit
			if (!annotationNodeIsHovered && i === nodeInfo.parents.length - 1) {
				this.renderFilledRect(context, rect, FlatTreeMapRenderer.CHILD_NODE_HIGHLIGHT_COLOR);
			} else if (annotationNodeIsHovered && ObjectUtils.deepEqual(node, nodeInfo.node, false, ['children'])) {
				this.renderFilledRect(context, rect, FlatTreeMapRenderer.ANNOTATED_NODE_HIGHLIGHT_COLOR);
			}
			if (!annotationNodeIsHovered) {
				const outlineRect = new Rect(rect.left + 1, rect.top + 1, rect.width - 1, rect.height - 1);
				this.renderStroke(context, outlineRect, 3, FlatTreeMapRenderer.STROKE_HIGHLIGHT_COLOR);
			}

			if (i < nodeInfo.parents.length - 1) {
				rect = FlatTreeMapRenderer.getChildRect(node, nodeInfo.parents[i + 1]!, rect);
			}
		}
	}

	/**
	 * Returns a rectangle that represents the area of <code>node</code> with crisp edges.
	 *
	 * @param node The node to highlight inside the tree map.
	 */
	public static getRoundedRect(rect: IRect): IRect {
		const left = Math.round(rect.left);
		const top = Math.round(rect.top);
		const width = Math.round(rect.left + rect.width) - left;
		const height = Math.round(rect.top + rect.height) - top;

		return new Rect(left, top, width, height);
	}

	/**
	 * Returns a rectangle that represents the child node when assuming that the parent node is projected into the given
	 * rect.
	 */
	public static getChildRect(parent: TreeMapNodeBase, child: TreeMapNodeBase, rect: IRect): Rect {
		const xScale = rect.width / parent.width;
		const yScale = rect.height / parent.height;
		const left = rect.left + (child.x - parent.x) * xScale;
		const top = rect.top + (child.y - parent.y) * yScale;
		const width = child.width * xScale;
		const height = child.height * yScale;
		return new Rect(left, top, width, height);
	}

	public getTargetRect(): Rect {
		return this.targetRect;
	}

	/** Returns the root node of the last rendered treemap. */
	public getRootNode(): Node {
		return this.rootNode!;
	}
}
