import clsx from 'clsx';
import {
	type ComponentPropsWithoutRef,
	type ElementType,
	type ForwardedRef,
	forwardRef,
	type MouseEvent,
	type ReactNode
} from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type {
	IconNameOrElement,
	SemanticCOLORS,
	SemanticShorthandCollection,
	SemanticShorthandContent,
	SemanticShorthandItem
} from '../Generic';
import { Icon } from '../Icon';
import { createIcon } from '../Icon/Icon';
import {
	childrenUtils,
	createHTMLParagraph,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	useEventCallback
} from '../lib';
import { MessageContent } from './MessageContent';
import { createMessageHeader, type MessageHeaderProps } from './MessageHeader';
import type { MessageItemProps } from './MessageItem';
import { createMessageList } from './MessageList';

export type MessageSizeProp = 'mini' | 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive';

/** Props for {@link Message}. */
export type MessageProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'div'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** A message can be formatted to attach itself to other content. */
		attached?: boolean | 'bottom' | 'top';

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** A message can be formatted to be different colors. */
		color?: SemanticCOLORS;

		/** A message can only take up the width of its content. */
		compact?: boolean;

		/** Shorthand for primary content. */
		content?: SemanticShorthandContent;

		/** A message may be formatted to display a negative message. Same as `negative`. */
		error?: boolean;

		/** A message can float above content that it is related to. */
		floating?: boolean;

		/** Shorthand for MessageHeader. */
		header?: SemanticShorthandItem<MessageHeaderProps>;

		/** A message can be hidden. */
		hidden?: boolean;

		/** Add an icon by icon name or pass an <Icon /.> */
		icon?: IconNameOrElement | boolean;

		/** A message may be formatted to display information. */
		info?: boolean;

		/** Array shorthand items for the MessageList. Mutually exclusive with children. */
		list?: SemanticShorthandCollection<MessageItemProps>;

		/** A message may be formatted to display a negative message. Same as `error`. */
		negative?: boolean;

		/**
		 * A message that the user can choose to hide. Called when the user clicks the "x" icon. This also adds the "x"
		 * icon.
		 */
		onDismiss?: (event: MouseEvent<HTMLElement>, data: MessageProps) => void;

		/** A message may be formatted to display a positive message. Same as `success`. */
		positive?: boolean;

		/** A message can have different sizes. */
		size?: MessageSizeProp;

		/** A message may be formatted to display a positive message. Same as `positive`. */
		success?: boolean;

		/** A message can be set to visible to force itself to be shown. */
		visible?: boolean;

		/** A message may be formatted to display warning messages. */
		warning?: boolean;
	}
>;

/**
 * A message displays information that explains nearby content.
 *
 * @see Form
 */
export const Message = forwardRef(function Message(props: MessageProps, ref: ForwardedRef<HTMLDivElement>) {
	const {
		attached,
		children,
		className,
		color,
		compact,
		content,
		error,
		floating,
		header,
		hidden,
		icon,
		info,
		list,
		negative,
		onDismiss,
		positive,
		size,
		success,
		visible,
		warning
	} = props;

	const classes = clsx(
		'ui',
		color,
		size,
		keyOnly(compact, 'compact'),
		keyOnly(error, 'error'),
		keyOnly(floating, 'floating'),
		keyOnly(hidden, 'hidden'),
		keyOnly(icon, 'icon'),
		keyOnly(info, 'info'),
		keyOnly(negative, 'negative'),
		keyOnly(positive, 'positive'),
		keyOnly(success, 'success'),
		keyOnly(visible, 'visible'),
		keyOnly(warning, 'warning'),
		keyOrValueAndKey(attached, 'attached'),
		'message',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	const handleDismiss = useEventCallback((e: MouseEvent<HTMLElement>) => {
		props.onDismiss?.(e, props);
	});
	const dismissIcon = onDismiss && <Icon name="close" onClick={handleDismiss} />;

	if (!childrenUtils.isNil(children)) {
		return (
			<ElementType {...rest} className={classes} ref={ref}>
				{dismissIcon}
				{children}
			</ElementType>
		);
	}

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{dismissIcon}
			{createIcon(icon, { autoGenerateKey: false })}
			{(header != null || content != null || list != null) && (
				<MessageContent>
					{createMessageHeader(header, { autoGenerateKey: false })}
					{createMessageList(list, { autoGenerateKey: false })}
					{createHTMLParagraph(content, { autoGenerateKey: false })}
				</MessageContent>
			)}
		</ElementType>
	);
});
const handledProps = [
	'as',
	'attached',
	'children',
	'className',
	'color',
	'compact',
	'content',
	'error',
	'floating',
	'header',
	'hidden',
	'icon',
	'info',
	'list',
	'negative',
	'onDismiss',
	'positive',
	'size',
	'success',
	'visible',
	'warning'
];
