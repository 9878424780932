import type { JSX } from 'react';
import type { PlaceholderProps } from 'ts/components/Placeholder';
import { Segment } from 'ts/components/Segment';

import {
	Placeholder,
	PlaceholderImage,
	PlaceholderHeader,
	PlaceholderLine,
	PlaceholderParagraph
} from 'ts/components/Placeholder';

/** Properties for {@link PlaceholderList} */
type PlaceholderListProps = PlaceholderProps & {
	/** Amount of placeholder lines in the list */
	numberOfLines: number;
	/** Whether to render image placeholders */
	useImagePlaceholder?: boolean;
};

/** Creates a list of placeholders. */
export function PlaceholderList({
	numberOfLines,
	useImagePlaceholder = false,
	...placeholderProps
}: PlaceholderListProps): JSX.Element {
	return (
		<Placeholder {...placeholderProps}>
			{Array(numberOfLines)
				.fill(1)
				.map((element, index: number) => (
					<PlaceholderLineOrImage key={index} useImagePlaceholder={useImagePlaceholder} />
				))}
		</Placeholder>
	);
}

type PlaceholderTreemapProps = {
	height?: string;
};

/** Creates a placeholder for a treemap, using the full available width */
export function PlaceholderTreemap({ height }: PlaceholderTreemapProps): JSX.Element {
	return (
		<div style={{ width: '100%', height }}>
			<Placeholder style={{ maxWidth: '100%', height }}>
				<PlaceholderImage />
			</Placeholder>
		</div>
	);
}

/** A placeholder that looks similar to a commit entry. */
export function CommitEntryLikePlaceholder(): JSX.Element {
	return (
		<Segment>
			<Placeholder>
				<PlaceholderHeader image>
					<PlaceholderLine />
					<PlaceholderLine />
				</PlaceholderHeader>
				<PlaceholderParagraph>
					<PlaceholderLine />
					<PlaceholderLine />
				</PlaceholderParagraph>
			</Placeholder>
		</Segment>
	);
}

/** Properties for {@link PlaceholderLineOrImage} */
type PlaceholderLineOrImageProps = {
	useImagePlaceholder: boolean;
};

/** Creates a single placeholder line. */
function PlaceholderLineOrImage({ useImagePlaceholder }: PlaceholderLineOrImageProps): JSX.Element {
	if (useImagePlaceholder) {
		return <PlaceholderImage />;
	}
	return <PlaceholderLine />;
}
