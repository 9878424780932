import { EApiPerspectiveView } from 'ts/perspectives/api/EApiPerspectiveView';
import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** For the API perspective. */
export class ApiPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.API_DOCUMENTATION, EApiPerspectiveView);
	}
}
