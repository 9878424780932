import clsx from 'clsx';
import { type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import type { SemanticShorthandContent } from '../Generic';
import { childrenUtils, getComponentType, getUnhandledProps } from '../lib';

/** Props for {@link HeaderContent}. */
export type HeaderContentProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;
};

/** Header content wraps the main content when there is an adjacent Icon or Image. */
export const HeaderContent = forwardRef(function HeaderContent(
	props: HeaderContentProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const { children, className, content } = props;

	const classes = clsx('content', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
});
const handledProps = ['as', 'children', 'className', 'content'];
