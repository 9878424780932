import { DateUtils } from './../DateUtils';
import { PointInTimeFormatter } from './PointInTimeFormatter';
import type { Revision } from './Revision';

/** Formatter for revisions. */
export class RevisionFormatter extends PointInTimeFormatter<Revision> {
	public override format(revision: Revision): string {
		return (
			'Revision: ' +
			revision.revision.substring(0, 10) +
			'... (' +
			DateUtils.formatTimestamp(revision.timestamp) +
			')'
		);
	}
}
