import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import type { SemanticTEXTALIGNMENTS, SemanticVERTICALALIGNMENTS, SemanticWIDTHS } from '../Generic';
import {
	getComponentType,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	multipleProp,
	textAlignProp,
	verticalAlignProp,
	widthProp
} from '../lib';

export type GridReversedProp =
	| string
	| 'computer'
	| 'computer vertically'
	| 'mobile'
	| 'mobile vertically'
	| 'tablet'
	| 'tablet vertically';

/** Props for {@link Grid}. */
export type GridProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** A grid can have rows divided into cells. */
	celled?: boolean | 'internally';

	/** A grid can have its columns centered. */
	centered?: boolean;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Represents column count per row in Grid. */
	columns?: SemanticWIDTHS | 'equal';

	/** A grid can be combined with a container to use available layout and alignment. */
	container?: boolean;

	/** A grid can have dividers between its columns. */
	divided?: boolean | 'vertically';

	/** A grid can double its column width on tablet and mobile sizes. */
	doubling?: boolean;

	/** A grid's colors can be inverted. */
	inverted?: boolean;

	/** A grid can preserve its vertical and horizontal gutters on first and last columns. */
	padded?: boolean | 'horizontally' | 'vertically';

	/** A grid can increase its gutters to allow for more negative space. */
	relaxed?: boolean | 'very';

	/** A grid can specify that its columns should reverse order at different device sizes. */
	reversed?: GridReversedProp;

	/** A grid can have its columns stack on-top of each other after reaching mobile breakpoints. */
	stackable?: boolean;

	/** A grid can stretch its contents to take up the entire grid height. */
	stretched?: boolean;

	/** A grid can specify its text alignment. */
	textAlign?: SemanticTEXTALIGNMENTS;

	/** A grid can specify its vertical alignment to have all its columns vertically centered. */
	verticalAlign?: SemanticVERTICALALIGNMENTS;
};

/** A grid is used to harmonize negative space in a layout. */
export const Grid = forwardRef(function Grid(props: GridProps, ref: ForwardedRef<HTMLDivElement>) {
	const {
		celled,
		centered,
		children,
		className,
		columns,
		container,
		divided,
		doubling,
		inverted,
		padded,
		relaxed,
		reversed,
		stackable,
		stretched,
		textAlign,
		verticalAlign
	} = props;

	const classes = clsx(
		'ui',
		keyOnly(centered, 'centered'),
		keyOnly(container, 'container'),
		keyOnly(doubling, 'doubling'),
		keyOnly(inverted, 'inverted'),
		keyOnly(stackable, 'stackable'),
		keyOnly(stretched, 'stretched'),
		keyOrValueAndKey(celled, 'celled'),
		keyOrValueAndKey(divided, 'divided'),
		keyOrValueAndKey(padded, 'padded'),
		keyOrValueAndKey(relaxed, 'relaxed'),
		multipleProp(reversed, 'reversed'),
		textAlignProp(textAlign),
		verticalAlignProp(verticalAlign),
		widthProp(columns, 'column', true),
		'grid',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{children}
		</ElementType>
	);
});
const handledProps = [
	'as',
	'celled',
	'centered',
	'children',
	'className',
	'columns',
	'container',
	'divided',
	'doubling',
	'inverted',
	'padded',
	'relaxed',
	'reversed',
	'stackable',
	'stretched',
	'textAlign',
	'verticalAlign'
];
