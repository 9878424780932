import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import { getComponentType, getUnhandledProps } from 'ts/components/lib';
import type { SemanticShorthandContent, SemanticSIZES } from '../Generic';
import { childrenUtils, keyOnly, keyOrValueAndKey } from '../lib';

/** Props for {@link Loader}. */
export type LoaderProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** A loader can be active or visible. */
	active?: boolean;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** A loader can be disabled or hidden. */
	disabled?: boolean;

	/** A loader can show it's unsure of how long a task will take. */
	indeterminate?: boolean;

	/** Loaders can appear inline with content. */
	inline?: boolean | 'centered';

	/** Loaders can have their colors inverted. */
	inverted?: boolean;

	/** Loaders can have different sizes. */
	size?: SemanticSIZES;
};

/**
 * A loader alerts a user to wait for an activity to complete.
 *
 * @see Dimmer
 */
export const Loader = forwardRef(function Loader(props: LoaderProps, ref: ForwardedRef<HTMLDivElement>) {
	const { active, children, className, content, disabled, indeterminate, inline, inverted, size } = props;

	const classes = clsx(
		'ui',
		size,
		keyOnly(active, 'active'),
		keyOnly(disabled, 'disabled'),
		keyOnly(indeterminate, 'indeterminate'),
		keyOnly(inverted, 'inverted'),
		keyOnly(children || content, 'text'),
		keyOrValueAndKey(inline, 'inline'),
		'loader',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
});
const handledProps = [
	'active',
	'as',
	'children',
	'className',
	'content',
	'disabled',
	'indeterminate',
	'inline',
	'inverted',
	'size'
];
