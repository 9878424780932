import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

export const ETestGapsPerspectiveView = {
	TEST_GAPS: {
		name: 'Files',
		anchor: 'code',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./files/TestGapsOverviewView'),
		timeTravel: {
			contentName: ETeamscalePerspective.TEST_GAPS.displayName
		}
	} as ViewDescriptor,
	ARCHITECTURE_COMPONENTS: {
		name: 'Architectures',
		anchor: 'architecture',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./files/TestGapsOverviewView'),
		timeTravel: {
			contentName: ETeamscalePerspective.TEST_GAPS.displayName
		}
	} as ViewDescriptor,
	ISSUE_TEST_GAP: {
		name: 'Issues',
		anchor: 'issues',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./issues/IssueTestGapView')
	} as ViewDescriptor,
	UNLINKED_CHANGES_TEST_GAP: {
		name: 'Unlinked Changes',
		anchor: 'unlinked-changes',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./UnlinkedChangesTestGapView'),
		timeTravel: {
			contentName: ETeamscalePerspective.TEST_GAPS.displayName
		}
	} as ViewDescriptor,
	TEST_SELECTION_TEST_GAP: {
		name: 'Test Selection',
		anchor: 'pareto',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./pareto/TestSelectionView'),
		timeTravel: {
			contentName: ETeamscalePerspective.TEST_GAPS.displayName
		}
	} as ViewDescriptor
} as const;
