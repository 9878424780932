import { QUERY } from 'api/Query';
import { useEffect, useRef, useState } from 'react';
import { Checkbox } from 'ts/components/Checkbox';
import { FlotUtils } from 'ts/commons/charts/FlotUtils';
import { tsdom } from 'ts/commons/tsdom';

/** Options used for plot rendering */
const PLOT_OPTIONS = {
	series: { bars: { show: true } },
	bars: { barWidth: 1 },
	grid: { hoverable: true },
	axisLabels: { show: true },
	xaxis: { tickFormatter: formatXAxisTick },
	xaxes: [{ axisLabel: 'Time of Day' }],
	yaxes: [{ axisLabel: '#Service Calls' }]
};

/** The service history dialog that shows the amount of service calls in a project for a certain timeframe. */
export function ServiceHistoryDialog() {
	const loadProfiles = QUERY.getServiceLoad().useSuspendingQuery();
	const [maxCalls, setMaxCalls] = useState(true);
	const chartContainer = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		const chartContainerCurrent = chartContainer.current;
		loadProfiles.forEach(profile => {
			const placeholder = document.createElement('div');
			placeholder.style.width = '500px';
			placeholder.style.height = '180px';
			const plotData = [
				{
					label: 'Average calls per minute',
					data: profile.hourAverage.map((element, index) => [index, element]),
					color: 'orange'
				}
			];
			if (maxCalls) {
				plotData.unshift({
					label: 'Maximum calls per minute',
					data: profile.hourMaximum.map((element, index) => [index, element]),
					color: 'grey'
				});
			}
			//@ts-ignore
			FlotUtils.renderFlotChart(placeholder, plotData, PLOT_OPTIONS);
			if (chartContainerCurrent) {
				chartContainerCurrent.style.paddingTop = '0.75rem';
				const paragraphElement = document.createElement('p');
				paragraphElement.innerHTML = '<b>' + profile.description + '</b>';
				chartContainerCurrent.append(paragraphElement);
				chartContainerCurrent.append(placeholder);
			}
		});
		return () => {
			if (chartContainerCurrent) {
				tsdom.removeAllChildren(chartContainerCurrent);
			}
		};
	}, [loadProfiles, maxCalls]);

	return (
		<>
			<Checkbox
				label="Maximum calls per minute"
				checked={maxCalls}
				toggle
				id="max-calls-toggle"
				onChange={(_, value) => setMaxCalls(value.checked!)}
			/>
			<div ref={chartContainer} />
		</>
	);
}
/** Formatter for converting the x-axis hour values as integer to the time format 'h:mm' (e.g. 1:00). */
function formatXAxisTick(tick: number): string {
	return tick + ':00';
}
