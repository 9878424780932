import { QUERY } from 'api/Query';
import { Message } from 'ts/components/Message';
import { Tab } from 'ts/components/Tab';
import { Table, TableBody, TableHeader, TableHeaderCell, TableRow } from 'ts/components/Table';
import type { CommitterDisplayName } from 'typedefs/CommitterDisplayName';

/** The active committers dialog shows committers from the last 90 and 180 days and maps them to Teamscale users. */
export function ActiveCommittersDialog() {
	const activeCommitters90Days: CommitterDisplayName[] = QUERY.getListOfActiveCommitters({
		'number-of-days': 90
	}).useSuspendingQuery();
	const activeCommitters180Days = QUERY.getListOfActiveCommitters({ 'number-of-days': 180 }).useSuspendingQuery();
	const PANES = [
		{ menuItem: 'Last 90 days', render: () => <ActiveCommittersTable activeCommitters={activeCommitters90Days} /> },
		{
			menuItem: 'Last 180 days',
			render: () => <ActiveCommittersTable activeCommitters={activeCommitters180Days} />
		}
	];
	return (
		<div className="max-w-[500px] !px-[15px]">
			<div>
				Lists everyone that has committed code that is analyzed by Teamscale in the selected timespan and the
				mapping between Teamscale users and commit authors in the version control system.
			</div>
			<br />
			<Tab panes={PANES} />
		</div>
	);
}

function ActiveCommittersTable({ activeCommitters }: { activeCommitters: CommitterDisplayName[] }): JSX.Element {
	if (activeCommitters.length === 0) {
		return <Message>No active committers found.</Message>;
	}
	return (
		<div className="max-w-[500px]">
			<Table selectable striped compact unstackable className="borderless framed body-code-table">
				<TableHeader>
					<TableRow>
						<TableHeaderCell>Teamscale User</TableHeaderCell>
						<TableHeaderCell>Aliases</TableHeaderCell>
					</TableRow>
				</TableHeader>
				<TableBody>
					{activeCommitters.map((committer, index) => (
						<tr key={committer.userName + index}>
							<td>
								{committer.userName ? (
									committer.userName + ' (' + committer.fullName + ')'
								) : (
									<i>None</i>
								)}
							</td>
							<td>{committer.commaSeparatedAliases ? committer.commaSeparatedAliases : <i>None</i>}</td>
						</tr>
					))}
				</TableBody>
			</Table>
		</div>
	);
}
