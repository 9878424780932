import { EActivityPerspectiveView } from 'ts/perspectives/activity/EActivityPerspectiveView';
import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the activity perspective. */
export class ActivityPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.ACTIVITY, EActivityPerspectiveView);
	}
}
