import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ForwardedRef, forwardRef, type ReactNode } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { createImage, type ImageProps } from 'ts/components/Image/Image';
import type { SemanticCOLORS, SemanticFLOATS, SemanticShorthandItem, SemanticTEXTALIGNMENTS } from '../Generic';
import { createIcon, type IconProps } from '../Icon/Icon';
import {
	childrenUtils,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	textAlignProp,
	valueAndKey
} from '../lib';
import { HeaderContent } from './HeaderContent';
import { createHeaderSubheader, type HeaderSubheaderProps } from './HeaderSubheader';

/** Props for {@link Header}. */
export type HeaderProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'h1'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** Attach header to other content, like a segment. */
		attached?: boolean | 'top' | 'bottom';

		/** Format header to appear inside a content block. */
		block?: boolean;

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** Color of the header. */
		color?: SemanticCOLORS;

		/** Shorthand for primary content. */
		content?: ReactNode;

		/** Show that the header is inactive. */
		disabled?: boolean;

		/** Divide header from the content below it. */
		dividing?: boolean;

		/** Header can sit to the left or right of other content. */
		floated?: SemanticFLOATS;

		/** Add an icon by icon name or pass an Icon. */
		icon?: SemanticShorthandItem<IconProps>;

		/** Add an image by img src or pass an Image. */
		image?: SemanticShorthandItem<ImageProps>;

		/** Inverts the color of the header for dark backgrounds. */
		inverted?: boolean;

		/** Content headings are sized with em and are based on the font-size of their container. */
		size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge';

		/** Headers may be formatted to label smaller or de-emphasized content. */
		sub?: boolean;

		/** Shorthand for Header.Subheader. */
		subheader?: SemanticShorthandItem<HeaderSubheaderProps>;

		/** Align header content. */
		textAlign?: SemanticTEXTALIGNMENTS;
	}
>;

/** A header provides a short summary of content */
export const Header = forwardRef(function Header(props: HeaderProps, ref: ForwardedRef<HTMLDivElement>) {
	const {
		attached,
		block,
		children,
		className,
		color,
		content,
		disabled,
		dividing,
		floated,
		icon,
		image,
		inverted,
		size,
		sub,
		subheader,
		textAlign
	} = props;

	const classes = clsx(
		'ui',
		color,
		size,
		keyOnly(block, 'block'),
		keyOnly(disabled, 'disabled'),
		keyOnly(dividing, 'dividing'),
		valueAndKey(floated, 'floated'),
		keyOnly(icon === true, 'icon'),
		keyOnly(image === true, 'image'),
		keyOnly(inverted, 'inverted'),
		keyOnly(sub, 'sub'),
		keyOrValueAndKey(attached, 'attached'),
		textAlignProp(textAlign),
		'header',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	if (!childrenUtils.isNil(children)) {
		return (
			<ElementType {...rest} className={classes} ref={ref}>
				{children}
			</ElementType>
		);
	}

	const iconElement = createIcon(icon, { autoGenerateKey: false });
	const imageElement = createImage(image, { autoGenerateKey: false });
	const subheaderElement = createHeaderSubheader(subheader, { autoGenerateKey: false });

	if (iconElement || imageElement) {
		return (
			<ElementType {...rest} className={classes} ref={ref}>
				{iconElement || imageElement}
				{content || subheaderElement ? (
					<HeaderContent>
						{content}
						{subheaderElement}
					</HeaderContent>
				) : null}
			</ElementType>
		);
	}

	return (
		<ElementType {...rest} className={classes} ref={ref}>
			{content}
			{subheaderElement}
		</ElementType>
	);
});
const handledProps = [
	'as',
	'attached',
	'block',
	'children',
	'className',
	'color',
	'content',
	'disabled',
	'dividing',
	'floated',
	'icon',
	'image',
	'inverted',
	'size',
	'sub',
	'subheader',
	'textAlign'
];
