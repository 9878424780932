import type { SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { type StateArray } from 'ts/base/hooks/UseStateSupport';

type DebouncedAndLiveState<S> = {
	/**
	 * Live state, which will update immediately, i.e., should be used for e.g., the underlying input field.
	 *
	 * Setting this state will update the debounced state after the configured timeout.
	 */
	live: StateArray<S>;
	/**
	 * Debounced state, which is updated after the timeout, i.e., should be used for the expensive debounced operation.
	 *
	 * Setting this state will skip any timeout.
	 */
	debounced: StateArray<S>;
};

/**
 * Provides a state, which can be either manipulated directly or used via debouncing, meaning that the state will only
 * be updated to the latest change if multiple changes happened before the timeout.
 *
 * Debouncing should be used for cases where the state changes rapidly, but you only want to re-render everything on the
 * latest change, not on all changes in a row, i.e., a search bar, when typing a query.
 *
 * @deprecated Debouncing is
 *   [discouraged]{@link https://react.dev/reference/react/useDeferredValue#how-is-deferring-a-value-different-from-debouncing-and-throttling}
 *   in newer React versions. [useDeferredValue]{@link https://react.dev/reference/react/useDeferredValue} was introduced
 *   as a better way to deal with this kind of problem.
 */
export function useDebouncedAndLiveState<S>(initialState: S, timeout: number): DebouncedAndLiveState<S> {
	const [state, setState] = useState(initialState);
	const [liveState, setLiveState] = useState(state);

	useEffect(() => {
		const handler = setTimeout(() => setState(liveState), timeout);

		return () => clearTimeout(handler);
	}, [setState, timeout, liveState]);

	const setDebouncedState = useCallback(
		(newState: SetStateAction<S>) => {
			setLiveState(newState);
			setState(newState);
		},
		[setLiveState, setState]
	);
	return {
		live: [liveState, setLiveState],
		debounced: [state, setDebouncedState]
	};
}
