import clsx from 'clsx';
import {
	type ComponentPropsWithoutRef,
	type ElementType,
	type FormEvent,
	type ForwardedRef,
	forwardRef,
	type ReactNode
} from 'react';
import { getComponentType, getUnhandledProps, keyOnly, widthProp } from '../lib';

/** Props for {@link Form}. */
export type FormProps = ComponentPropsWithoutRef<'form'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The HTML form action */
	action?: string;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Automatically show any error Message children. */
	error?: boolean;

	/** A form can have its color inverted for contrast. */
	inverted?: boolean;

	/** Automatically show a loading indicator. */
	loading?: boolean;

	/** The HTML form submit handler. */
	onSubmit?: (event: FormEvent<HTMLFormElement>, data: FormProps, ...additionalArgs: unknown[]) => void;

	/** A comment can contain a form to reply to a comment. This may have arbitrary content. */
	reply?: boolean;

	/** A form can vary in size. */
	size?: string;

	/** Automatically show any success Message children. */
	success?: boolean;

	/** A form can prevent itself from stacking on mobile. */
	unstackable?: boolean;

	/** Automatically show any warning Message children. */
	warning?: boolean;

	/** Forms can automatically divide fields to be equal width. */
	widths?: 'equal';
};

/**
 * A Form displays a set of related user input fields in a structured way.
 *
 * @see Button
 * @see Checkbox
 * @see Dropdown
 * @see Input
 * @see Message
 * @see Radio
 * @see Select
 */
export const Form = forwardRef(function Form(props: FormProps, ref: ForwardedRef<HTMLDivElement>) {
	const {
		action,
		children,
		className,
		error,
		inverted,
		loading,
		reply,
		size,
		success,
		unstackable,
		warning,
		widths
	} = props;

	const handleSubmit = (e: FormEvent<HTMLFormElement>, ...args: unknown[]) => {
		// Heads up! Third party libs can pass own data as first argument, we need to check that it has preventDefault()
		// method.
		if (typeof action !== 'string') {
			e.preventDefault();
		}
		props.onSubmit?.(e, props, ...args);
	};

	const classes = clsx(
		'ui',
		size,
		keyOnly(error, 'error'),
		keyOnly(inverted, 'inverted'),
		keyOnly(loading, 'loading'),
		keyOnly(reply, 'reply'),
		keyOnly(success, 'success'),
		keyOnly(unstackable, 'unstackable'),
		keyOnly(warning, 'warning'),
		widthProp(widths, null, true),
		'form',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props, { defaultAs: 'form' });

	return (
		<ElementType {...rest} action={action} className={classes} onSubmit={handleSubmit} ref={ref}>
			{children}
		</ElementType>
	);
});
const handledProps = [
	'action',
	'as',
	'children',
	'className',
	'error',
	'inverted',
	'loading',
	'onSubmit',
	'reply',
	'size',
	'success',
	'unstackable',
	'warning',
	'widths'
];
