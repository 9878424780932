import { EnumUtils } from 'ts/commons/EnumUtils';
import { linkTo } from 'ts/commons/links/LinkTo';
import { EQualityPerspectiveView } from 'ts/perspectives/quality_control/EQualityPerspectiveView';
import type { ETaskStatus } from 'typedefs/ETaskStatus';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/**
 * Returns a URL to the view showing all configured baselines.
 *
 * @param project The project.
 */
export function baselines(project: string) {
	return linkTo(ETeamscalePerspective.QUALITY_CONTROL, EQualityPerspectiveView.BASELINES, project);
}

/** Returns a link to the quality reports list. */
export function showQualityReportList() {
	return linkTo(ETeamscalePerspective.QUALITY_CONTROL, EQualityPerspectiveView.REPORTS);
}

/** Returns a link to report editor in view mode. */
export function viewQualityReport(qualityReportId: string) {
	return linkTo(ETeamscalePerspective.QUALITY_CONTROL, EQualityPerspectiveView.REPORT_SHOW, {
		id: qualityReportId
	});
}

/** Returns a URL for the report perspective in presentation mode for the given report id. */
export function presentReport(id: string | number, startIndex = 0) {
	return linkTo(ETeamscalePerspective.QUALITY_CONTROL, EQualityPerspectiveView.REPORT_PRESENT, {
		id,
		startIndex
	});
}

/** Returns a URL for editing the roles of a quality report. */
export function editQualityReportRolesLink(qualityReportId: string, qualityReportName: string) {
	return linkTo(ETeamscalePerspective.QUALITY_CONTROL, EQualityPerspectiveView.REPORT_ROLES, {
		id: qualityReportId,
		name: qualityReportName
	});
}

/** Returns a URL to report editor. */
export function editQualityReport(qualityReportId: string, slideIndex?: number) {
	return linkTo(ETeamscalePerspective.QUALITY_CONTROL, EQualityPerspectiveView.REPORT_EDIT, {
		id: qualityReportId,
		index: slideIndex
	});
}

/** Returns a link to retrospective editor. */
export function editQualityRetrospective(qualityRetrospectiveId: string) {
	return linkTo(ETeamscalePerspective.QUALITY_CONTROL, EQualityPerspectiveView.RETROSPECTIVE_EDIT, {
		id: qualityRetrospectiveId
	});
}

/** Filter options for tasks. */
export type TaskFilters = {
	statuses: ETaskStatus[];
	assignees: string[];
	authors: string[];
	tags: string[] | null;
};

/** Returns a URL for editing a task. */
export function taskDetails(project: string, id: number, taskFilters?: TaskFilters) {
	return taskViewLink(project, id, 'details', taskFilters);
}

/** Returns a URL for creating/editing/viewing a task. */
export function taskViewLink(
	project: string,
	id: number,
	action: 'new' | 'edit' | 'details',
	taskFilters?: Partial<TaskFilters>,
	createFromStoredData?: boolean
) {
	return linkTo(ETeamscalePerspective.QUALITY_CONTROL, EQualityPerspectiveView.TASKS, project, {
		id,
		action,
		author: taskFilters?.authors,
		assignee: taskFilters?.assignees,
		statuses: taskFilters?.statuses ? EnumUtils.getValues(taskFilters.statuses) : undefined,
		tags: taskFilters?.tags,
		stored: createFromStoredData
	});
}
