import { useEffect, useState } from 'react';

/**
 * Methods of the HubSopt chat widget we use. This is not the full list, but only the subset that we use. The full
 * documentation can be found here: Documentation for HubSpot's chat widget can be found here:
 * https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
 */
type HubSpotWidget = {
	/** Loads the chat widget. */
	load: () => void;

	/** Opens the chat widget. */
	open: () => void;

	/** Removes the chat widget. After this, we have to call load() if we want to show it again. */
	remove: () => void;
};

/**
 * Methods of the HubSpot conversations API we use. This is not the full list, but only the subset that we use. The full
 * documentation can be found here: Documentation for HubSpot's chat widget can be found here:
 * https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
 */
type HubSpotConversations = {
	/** The currently installed chat widget. */
	widget?: HubSpotWidget;

	/** Method used to register events. */
	on: (event: string, handler: (event: object) => void) => void;

	/** Method used to deregister an event handler. */
	off: (event: string, handler: (event: object) => void) => void;
};

declare global {
	/**
	 * Declares additional properties for "window", which are used by the HubSpot chat widget. More information can be
	 * found on https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
	 */
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		_hsq?: Array<Array<object | string>>;
		hsConversationsSettings?: object;
		hsConversationsOnReady?: Array<() => void>;
		HubSpotConversations?: HubSpotConversations;
	}
}

/** Return type for the useHubSpotChat() hook, used to control the chat widget. */
type HubSpotChatControl = {
	show: () => void;
};

/**
 * Hook for integrating and accessing the HubSopt chat widget.
 *
 * Ideas for integrating with React are taken from here:
 * https://community.hubspot.com/t5/CMS-Development/Embed-Chat-Into-A-ReactJS-SPA/m-p/275754
 *
 * @param portalId The id of the chat portal. If this is null, the chat will not be loaded.
 * @param userEmail The email of the user accessing the portal.
 */
export function useHubSpotChat({
	portalId,
	userEmail
}: {
	portalId?: number;
	userEmail?: string;
}): HubSpotChatControl | null {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!loaded) {
			return;
		}

		registerEmailAddress(userEmail);

		const widgetClosedHandler = () => {
			// we completely remove the widget on close, to also make HubSpot's chat button in the bottom right disappear
			window.HubSpotConversations?.widget?.remove();
		};
		preConfigureChatWidget(widgetClosedHandler);

		const script = document.createElement('script');
		script.src = `https://js.hs-scripts.com/${portalId}.js`;
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
			window._hsq = [];
			window.hsConversationsOnReady = [];
			window.hsConversationsSettings = {};
			window.HubSpotConversations?.off('widgetClosed', widgetClosedHandler);
		};
	}, [loaded, portalId, userEmail]);

	if (!portalId) {
		return null;
	}

	return {
		show: () => {
			setLoaded(true);
			window.HubSpotConversations?.widget?.load();
			window.HubSpotConversations?.widget?.open();
		}
	};
}

/** This function adds code that ensures the chat widget is automatically loaded and shown after the API is ready. */
function preConfigureChatWidget(widgetCloseHandler: () => void) {
	window.hsConversationsSettings = {
		loadImmediately: false,
		enableWidgetCookieBanner: false
	};

	window.hsConversationsOnReady = [
		() => {
			window.HubSpotConversations?.widget?.load();
			window.HubSpotConversations?.widget?.open();
			window.HubSpotConversations?.on('widgetClosed', widgetCloseHandler);
		}
	];
}

/**
 * Registers the email address with the HubSpot API. For details see
 * https://developers.hubspot.com/docs/api/events/tracking-code
 */
function registerEmailAddress(emailAddress?: string) {
	if (!emailAddress || emailAddress.trim().length === 0) {
		return;
	}

	const identityEntry = [
		'identify',
		{
			email: emailAddress
		}
	];

	window._hsq ??= [];
	const hubspotQueue = window._hsq;
	if (!hubspotQueue.includes(identityEntry)) {
		hubspotQueue.push(identityEntry);
	}
}
