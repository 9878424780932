import { type JSX } from 'react';
import { useAccessibleViews, useFilterVisibleInSidebar } from 'ts/base/hooks/UseAccessibleViews';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import {
	ActivePerspectiveSubViewMenu,
	PerspectiveSubViewPopupMenu
} from 'ts/base/perspective/sidebar/left/SubViewMenu';
import type { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Props for LeftSidebarEntry. */
type LeftSidebarEntryProps = {
	perspective: ETeamscalePerspective;
	iconOnlySidebar: boolean;
	perspectiveDescriptor: PerspectiveViewDescriptorBase;
};

/** Represents a single perspective entry in the left sidebar. Takes care of attaching the floating submenus. */
export function LeftSidebarEntry({
	perspective,
	iconOnlySidebar,
	perspectiveDescriptor
}: LeftSidebarEntryProps): JSX.Element {
	const hash = useNavigationHash();
	const activePerspective = hash.getPerspective();
	const isActive = perspective.name === activePerspective.name;

	const accessibleViewDescriptors = useAccessibleViews(perspectiveDescriptor);

	// We use the first view here because the perspective link will lead to the first view (may not necessarily be visible in the sidebar)
	const defaultView = accessibleViewDescriptors[0]!;

	const accessibleSubviewsInSidebar = useFilterVisibleInSidebar(accessibleViewDescriptors);
	if (isActive && !iconOnlySidebar) {
		return (
			<ActivePerspectiveSubViewMenu
				perspectiveDescriptor={perspectiveDescriptor}
				defaultView={defaultView}
				views={accessibleSubviewsInSidebar}
			/>
		);
	}
	return (
		<PerspectiveSubViewPopupMenu
			perspectiveDescriptor={perspectiveDescriptor}
			views={accessibleSubviewsInSidebar}
			defaultView={defaultView}
		/>
	);
}
