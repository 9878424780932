import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { EUserPerspectiveView } from './EUserPerspectiveView';

/**
 * Descriptor for the user perspective. This perspective is not shown in the sidebar, but is shown as a user avatar
 * dropdown in the right side of the perspective settings bar.
 */
export class UserPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.USER, EUserPerspectiveView);
	}
}
