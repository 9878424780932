import { QUERY } from 'api/Query';
import type { JSX } from 'react';
import { useBasicPermissionLookup } from 'ts/base/hooks/PermissionInfoHook';
import { useProjectInfos } from 'ts/base/hooks/ProjectsInfosHook';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { MenuItem } from 'ts/components/Menu';
import { Message } from 'ts/components/Message';
import { EBasicPermission } from 'typedefs/EBasicPermission';
import { EBasicPermissionScope } from 'typedefs/EBasicPermissionScope';
import type { QualityReport } from 'typedefs/QualityReport';

/** The ID of the DOM element that will show an inaccessible slide warning. */
export const INACCESSIBLE_SLIDE_WARNING_ID = 'inaccessible-slide-warning';

type ReportWarningProps = {
	addSlideInaccessiblePlaceholder?: boolean;
};

/** Shows warnings regarding the given report if the user is missing critical permissions to edit or view the report. */
export function ReportWarning({ addSlideInaccessiblePlaceholder }: ReportWarningProps): JSX.Element | null {
	const report = useQualityReport();
	const accessibleMetricThresholdConfigurations = QUERY.getAllMetricThresholdConfigurationNames({
		'include-default-configurations': true
	}).useSuspendingQuery();
	const permissionLookup = useBasicPermissionLookup(EBasicPermissionScope.REPORTS);
	const projectInfos = useProjectInfos();
	const isPermittedToEditReport = permissionLookup.hasBasicPermission(report.metaInfo.id!, EBasicPermission.EDIT);
	const isPermittedToViewThresholdConfig = accessibleMetricThresholdConfigurations.includes(
		report.qualityArtifactProfile.thresholdProfileName
	);
	const isPermittedToViewDefaultProject = projectInfos.projectExists(
		report.qualityArtifactProfile.defaultProjectSetting.projectOrAlias
	);
	return (
		<>
			{!isPermittedToEditReport ? (
				<GlobalWarning severity="warning" message="No report edit permission granted" />
			) : null}
			{isPermittedToEditReport && !isPermittedToViewDefaultProject ? (
				<GlobalWarning severity="warning" message="Default project not accessible" />
			) : null}
			{isPermittedToEditReport && !isPermittedToViewThresholdConfig ? (
				<GlobalWarning severity="warning" message="Default threshold configuration not accessible" />
			) : null}
			{addSlideInaccessiblePlaceholder ? (
				<MenuItem fitted>
					<Message warning id={INACCESSIBLE_SLIDE_WARNING_ID} style={{ display: 'none' }}>
						<b>Project used in this slide is inaccessible</b>
					</Message>
				</MenuItem>
			) : null}
		</>
	);
}

function useQualityReport(): QualityReport {
	const reportId = useNavigationHash().getId()!;
	return QUERY.getReport(reportId).useSuspendingQuery();
}
