import { useMemo } from 'react';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';

/** Hook that provides the current commit from the time travel feature. */
export function useCommit(): UnresolvedCommitDescriptor {
	const hash = useNavigationHash();
	const commitDescriptor = hash.getCommit() ?? UnresolvedCommitDescriptor.createLatestOnDefaultBranch();
	return useMemo(
		() =>
			new UnresolvedCommitDescriptor(
				commitDescriptor.timestamp,
				commitDescriptor.branchName,
				commitDescriptor.previous
			),
		[commitDescriptor.branchName, commitDescriptor.previous, commitDescriptor.timestamp]
	);
}
