// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$UIUtilsTemplate from './../../commons/UIUtilsTemplate.soy.generated.js';


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $noTemplatesAvailableMessage = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<h3' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:13"' : '') + '>No templates available</h3><p' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:14"' : '') + '>Use an existing dashboard\'s menu to save it as a template.</p>');
};
export { $noTemplatesAvailableMessage as noTemplatesAvailableMessage };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $noTemplatesAvailableMessage.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $toggleIcons = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.toggleIcons']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.toggleIcons'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="toggle-icons" class="greyLBBorder hoverDim" style="position: absolute; top: -3px; right: 0; background: white; padding-left: 2px; padding-top: 2px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.toggleIcons ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:22"' : '') + '><button id="exit-kiosk-button" class="ts-dropdown"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Exit fullscreen mode')) + '><i class="compress icon"></i></button></div>');
};
export { $toggleIcons as toggleIcons };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $toggleIcons.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.toggleIcons';
}
