import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { Assertions } from 'ts/commons/Assertions';
import { SourceFormatter } from 'ts/commons/formatter/SourceFormatter';
import { StyleRegion } from 'ts/commons/formatter/StyleRegion';
import type { FindingContent } from 'typedefs/FindingContent';
import type { FormattedTokenElementInfo } from 'typedefs/FormattedTokenElementInfo';

export class DiffSourceFormatter extends SourceFormatter {
	/**
	 * The source formatter used for both sides of the diff. In addition to the plain source formatter, this also
	 * supports highlighting of matched regions.
	 *
	 * @param tokenElementInfo The json data to render.
	 * @param project String denoting the current project.
	 * @param commit The commit. May be null to indicate the latest revision on the default branch.
	 * @param changedLines The array describing the changed lines in the diff. See Java class DiffDescriptor for
	 *   details.
	 * @param changedRegions The array describing the changed regions in the diff. See Java class DiffDescriptor for
	 *   details.
	 * @param otherChangedLines The array describing the changed lines in the other side of the diff. See Java class
	 *   DiffDescriptor for details.
	 * @param isLeft Whether this is the left or right source of the compare.
	 * @param inLastCommitChangedRegions The regions which have been changed in the last commit
	 */
	public constructor(
		tokenElementInfo: FormattedTokenElementInfo | FindingContent,
		project: string,
		commit: UnresolvedCommitDescriptor,
		private readonly changedLines: number[],
		changedRegions: number[],
		private readonly otherChangedLines: number[],
		private readonly isLeft: boolean,
		inLastCommitChangedRegions: number[] | null = null
	) {
		super(tokenElementInfo, null, project, commit, undefined, undefined, undefined);
		this.insertChangedRegions(changedRegions);
		if (inLastCommitChangedRegions !== null) {
			this.insertChangedRegions(inLastCommitChangedRegions, true);
		}
	}

	/**
	 * Merges the change regions into the styleRegions array so the highlighted regions are visible. Note that token end
	 * index in inclusive while region end index is exclusive.
	 *
	 * @param highlightInYellow Indicates if the highlight color should be the default one or yellow
	 */
	private insertChangedRegions(changedRegions: number[], highlightInYellow = false): void {
		const oldStyleRegions = this.formatterContext.getTokenStyleRegions();
		const highlightClassOffset = this.formatterContext.getCSSClassNamesCount();
		this.addNewCssClasses(highlightClassOffset, highlightInYellow);

		// Add empty token at end, to ensure we do not need range checking below
		oldStyleRegions.push(this.createDummyEntry());
		this.formatterContext.clearStyleRegions();
		let tokenIndex = 0;
		for (let regionIndex = 0; regionIndex < changedRegions.length; regionIndex += 2) {
			const regionStart = changedRegions[regionIndex]!;
			const regionEnd = changedRegions[regionIndex + 1]!;
			tokenIndex = this.advanceIntoRegion(oldStyleRegions, regionStart, tokenIndex);
			tokenIndex = this.completeRegion(oldStyleRegions, regionStart, regionEnd, tokenIndex, highlightClassOffset);
		}
		while (tokenIndex < oldStyleRegions.length - 1) {
			this.formatterContext.insertStyleRegion(oldStyleRegions[tokenIndex]!);
			tokenIndex += 1;
		}
	}

	/** Add new CSS classes to the formatterContext, which are used for the highlighting. */
	private addNewCssClasses(highlightClassOffset: number, highlightInYellow: boolean): void {
		for (let i = 0; i < highlightClassOffset; ++i) {
			const className = this.formatterContext.getCSSClassName(i);
			if (!highlightInYellow) {
				this.formatterContext.insertCSSClassName(className + ' diff-highlight');
			} else {
				this.formatterContext.insertCSSClassName(className + ' diff-highlight-secondary');
			}
		}
	}

	/** Creates a dummy style region entry. */
	private createDummyEntry(): StyleRegion {
		return new StyleRegion(1e9, 1e9, '0');
	}

	/**
	 * Advances the regionIndex into the current region and inserts the styleRegions encountered so far into the
	 * formatterContext. Afterwards, we can assume that start of the oldStyleRegions at the regionIndex is equal to our
	 * regionStart.
	 *
	 * @param regionStart Start offset of the current region
	 * @param regionIndex The current style-region index
	 * @returns The new style-region index
	 */
	private advanceIntoRegion(oldStyleRegions: StyleRegion[], regionStart: number, regionIndex: number): number {
		while (oldStyleRegions[regionIndex]!.endOffset < regionStart) {
			this.formatterContext.insertStyleRegion(oldStyleRegions[regionIndex]!);
			regionIndex += 1;
		}
		if (oldStyleRegions[regionIndex]!.startOffset < regionStart) {
			const styleRegion = new StyleRegion(
				oldStyleRegions[regionIndex]!.startOffset,
				regionStart - 1,
				oldStyleRegions[regionIndex]!.styleCssClassName
			);
			this.formatterContext.insertStyleRegion(styleRegion);
			oldStyleRegions[regionIndex]!.startOffset = regionStart;
		}
		return regionIndex;
	}

	/**
	 * Advances the token index to the end of the current region and copies the tokens encountered so far into the
	 * tokenData array, modifying the style used (highlighting).
	 *
	 * @param regionStart Start offset of the current region
	 * @param regionEnd End offset of the current region
	 * @param regionIndex The current style-region index
	 * @param highlightClassOffset The offset inside the formatterContext's cssClassNames
	 * @returns The new style-region index
	 */
	private completeRegion(
		oldStyleRegion: StyleRegion[],
		regionStart: number,
		regionEnd: number,
		regionIndex: number,
		highlightClassOffset: number
	): number {
		let styleRegion: StyleRegion;
		while (oldStyleRegion[regionIndex]!.startOffset < regionEnd) {
			const oldClassIndex = this.formatterContext.getCSSClassIndex(
				oldStyleRegion[regionIndex]!.styleCssClassName
			);
			const newClassIndex = oldClassIndex + highlightClassOffset;
			const newCssClass = this.formatterContext.getCSSClassName(newClassIndex);
			if (oldStyleRegion[regionIndex]!.endOffset < regionEnd) {
				styleRegion = new StyleRegion(
					oldStyleRegion[regionIndex]!.startOffset,
					oldStyleRegion[regionIndex]!.endOffset,
					newCssClass
				);
				this.formatterContext.insertStyleRegion(styleRegion);
				regionIndex += 1;
			} else {
				styleRegion = new StyleRegion(oldStyleRegion[regionIndex]!.startOffset, regionEnd - 1, newCssClass);
				this.formatterContext.insertStyleRegion(styleRegion);
				oldStyleRegion[regionIndex]!.startOffset = regionEnd;
			}
		}
		return regionIndex;
	}

	/** Returns the element representing the given (1-based) line. */
	public getLineElement(line: number): Element {
		const index = line - this.firstLine;
		Assertions.assert(index >= 0 && index < this.lineElements.length);
		return this.lineElements[index]!;
	}

	/**
	 * Determines the color to be used for a given changed line. The colors are computed for one file (shown left or
	 * right in the compare view).
	 *
	 * @param hasChanges Whether the current file (left or right) has changes to the line
	 * @param hasOtherChanges Whether the other file (left or right) has changes to the line
	 */
	private determineLineColor(hasChanges: boolean, hasOtherChanges: boolean): string {
		if (hasChanges === hasOtherChanges) {
			return 'blue';
		} else if (hasChanges) {
			if (this.isLeft) {
				return 'red';
			}
			return 'green';
		} else if (this.isLeft) {
			return 'green';
		}
		return 'red';
	}

	protected override augmentLineClasses(lineClasses: string[], colorBlindModeEnabled: boolean | undefined): void {
		for (let i = 0; i < this.changedLines.length; i += 2) {
			let start = this.changedLines[i]! - this.firstLine;
			let end = this.changedLines[i + 1]! - this.firstLine;
			if (start < 0) {
				start = 0;
			}
			if (end > lineClasses.length) {
				end = lineClasses.length;
			}
			const hasChanges = this.changedLines[i]! < this.changedLines[i + 1]!;
			const hasOtherChanges = this.otherChangedLines[i]! < this.otherChangedLines[i + 1]!;
			const color = this.determineLineColor(hasChanges, hasOtherChanges);
			if (start < lineClasses.length) {
				if (color === 'red' && colorBlindModeEnabled) {
					lineClasses[start] += ' diff-lines-start-color-blind-mode-' + color;
				} else {
					lineClasses[start] += ' diff-lines-start-' + color;
				}
			}
			if (end > 0 && end > start) {
				if (color === 'red' && colorBlindModeEnabled) {
					lineClasses[end - 1] += ' diff-lines-end-color-blind-mode-' + color;
				} else {
					lineClasses[end - 1] += ' diff-lines-end-' + color;
				}
			}
			for (let j = start; j < end; ++j) {
				lineClasses[j] += ' diff-marked-' + color;
			}
		}
	}
}
