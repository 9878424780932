import { useSuspenseQuery } from '@tanstack/react-query';
import { QUERY } from 'api/Query';
import type { JSX, ReactNode } from 'react';
import React, { useContext, useState } from 'react';
import type { Callback } from 'ts/base/Callback';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import type { AmbiguousRevision } from 'ts/commons/time/components/AmbiguousRevisionPicker';
import type { ETimePickerType } from 'ts/commons/time/ETimePickerType';
import { getDefaultTimePickerType } from 'ts/commons/time/ETimePickerType';
import type { SystemVersionDisplayObject } from 'ts/commons/time/SystemVersionDisplayObject';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import type { DotNetVersionInfo } from 'typedefs/DotNetVersionInfo';

function createVersionDisplayObject(
	project: string,
	timestampedNamedObject: DotNetVersionInfo
): SystemVersionDisplayObject {
	return {
		name: timestampedNamedObject.name,
		timestamp: timestampedNamedObject.commit.timestamp,
		project
	};
}

function useSystemVersions(projects: string[] | null): SystemVersionDisplayObject[] | null {
	const { data: systemVersions } = useSuspenseQuery({
		queryKey: ['systemVersions', projects],
		queryFn: () => {
			if (ArrayUtils.isEmptyOrUndefined(projects)) {
				return null;
			}
			return QUERY.getDotNetVersions({ project: projects })
				.fetch()
				.then(systemVersionsByProjects => {
					return Object.keys(systemVersionsByProjects).flatMap(project => {
						return systemVersionsByProjects[project]!.map(systemVersion =>
							createVersionDisplayObject(project, systemVersion)
						);
					});
				});
		}
	});
	if (systemVersions != null) {
		ArrayUtils.sortBy(systemVersions, 'timestamp', ArrayUtils.inverseDefaultCompare);
	}
	return systemVersions;
}

/** Context type definition for TimePickerContext */
type ExposedTimePickerContext = {
	setTypedPointInTime: Callback<TypedPointInTime>;
	defaultValue: TypedPointInTime | null;
	id: string;
	projects: string[] | null;
	/**
	 * System versions of all projects in the context. They have to be queried in the context to avoid rendering the tab
	 * if no system versions are available.
	 */
	systemVersions: SystemVersionDisplayObject[] | null;
	ambiguousRevision: AmbiguousRevision | undefined;
	setAmbiguousRevision: Callback<AmbiguousRevision>;
	activeTabKey: ETimePickerType | undefined;
	setActiveTabKey: Callback<ETimePickerType>;
};

/** Context that holds the current details of the time picker component. */
const TimePickerContext = React.createContext<ExposedTimePickerContext | undefined>(undefined);

/** Props for TimePickerContextProvider. */
type TimePickerContextProviderProps = {
	onChange: Callback<TypedPointInTime>;
	id: string;
	projects: string[] | null;
	defaultValue: TypedPointInTime | null;
	children: ReactNode;
};

/** Context provider for a time picker component */
export function TimePickerContextProvider({
	id,
	projects,
	defaultValue,
	onChange,
	children
}: TimePickerContextProviderProps): JSX.Element {
	const [ambiguousRevision, setAmbiguousRevision] = useState<AmbiguousRevision>();
	const [activeTabKey, setActiveTabKey] = useState(getDefaultTimePickerType(defaultValue));
	const systemVersions = useSystemVersions(projects);

	const value: ExposedTimePickerContext = {
		setTypedPointInTime: onChange,
		id,
		projects,
		systemVersions,
		ambiguousRevision,
		setAmbiguousRevision,
		activeTabKey,
		setActiveTabKey,
		defaultValue
	};
	return <TimePickerContext.Provider value={value}>{children}</TimePickerContext.Provider>;
}

/**
 * Returns the details of the current comparison to be displayed. Must only be used in components nested within a
 * TimePickerContextProvider.
 */
export function useTimePickerContext(): ExposedTimePickerContext {
	const context = useContext(TimePickerContext);
	if (context === undefined) {
		throw new Error('useTimePickerContext must be used within a TimePickerContextProvider');
	}
	return context;
}
