import clsx from 'clsx';
import * as _ from 'es-toolkit/compat';
import {
	type ChangeEvent,
	Children,
	cloneElement,
	type ComponentPropsWithoutRef,
	createRef,
	type ElementType,
	type FocusEvent,
	type ForwardedRef,
	forwardRef,
	type Key,
	type ReactNode,
	type Ref,
	type SyntheticEvent
} from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { SemanticShorthandItem } from 'ts/components/Generic';
import { createLabel, type LabelProps } from 'ts/components/Label/Label';
import {
	ModernAutoControlledComponent,
	type ModernAutoControlledComponentState
} from 'ts/components/lib/ModernAutoControlledComponent';
import { createIcon, type IconProps } from '../Icon/Icon';
import { createImage } from '../Image/Image';
import {
	childrenUtils,
	doesNodeContainClick,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	setRef
} from '../lib';
import { createDropdownHeader } from './DropdownHeader';
import { createDropdownItem, type DropdownItemProps } from './DropdownItem';
import { DropdownMenu } from './DropdownMenu';
import { createDropdownSearchInput, type DropdownSearchInputProps } from './DropdownSearchInput';
import { createDropdownText } from './DropdownText';
import { getMenuOptions } from './utils/getMenuOptions';
import getSelectedIndex from './utils/getSelectedIndex';

/** Props for {@link Dropdown}. */
export type DropdownProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'div'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** Label prefixed to an option added by a user. */
		additionLabel?: number | string | ReactNode;

		/** Position of the `Add: ...` option in the dropdown list ('top' or 'bottom'). */
		additionPosition?: 'top' | 'bottom';

		/**
		 * Allow user additions to the list of options (boolean). Requires the use of `selection`, `options` and
		 * `search`.
		 */
		allowAdditions?: boolean;

		/** A Dropdown can reduce its complexity. */
		basic?: boolean;

		/** Format the Dropdown to appear as a button. */
		button?: boolean;

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** Using the clearable setting will let users remove their selection from a dropdown. */
		clearable?: boolean;

		/** Whether or not the menu should close when the dropdown is blurred. */
		closeOnBlur?: boolean;

		/** Whether or not the dropdown should close when the escape key is pressed. */
		closeOnEscape?: boolean;

		/**
		 * Whether or not the menu should close when a value is selected from the dropdown. By default, multiple
		 * selection dropdowns will remain open on change, while single selection dropdowns will close on change.
		 */
		closeOnChange?: boolean;

		/** A compact dropdown has no minimum width. */
		compact?: boolean;

		/** Whether or not the dropdown should strip diacritics in options and input search */
		deburr?: boolean;

		/** Initial value of open. */
		defaultOpen?: boolean;

		/** Initial value of searchQuery. */
		defaultSearchQuery?: string;

		/** Currently selected label in multi-select. */
		defaultSelectedLabel?: number | string;

		/** Initial value of upward. */
		defaultUpward?: boolean;

		/** Initial value or value array if multiple. */
		defaultValue?: string | number | boolean | Array<number | string | boolean>;

		/** A dropdown menu can open to the left or to the right. */
		direction?: 'left' | 'right';

		/** A disabled dropdown menu or item does not allow user interaction. */
		disabled?: boolean;

		/** An errored dropdown can alert a user to a problem. */
		error?: boolean;

		/** A dropdown menu can contain floated content. */
		floating?: boolean;

		/** A dropdown can take the full width of its parent */
		fluid?: boolean;

		/** A dropdown menu can contain a header. */
		header?: ReactNode;

		/** Shorthand for Icon. */
		icon?: SemanticShorthandItem<IconProps>;

		/** A dropdown can be formatted to appear inline in other content. */
		inline?: boolean;

		/** A dropdown can be formatted as a Menu item. */
		item?: boolean;

		/** A dropdown can be labeled. */
		labeled?: boolean;

		/** A dropdown can defer rendering its options until it is open. */
		lazyLoad?: boolean;

		/** A dropdown can show that it is currently loading data. */
		loading?: boolean;

		/** The minimum characters for a search to begin showing results. */
		minCharacters?: number;

		/** A selection dropdown can allow multiple selections. */
		multiple?: boolean;

		/** Message to display when there are no results. */
		noResultsMessage?: ReactNode;

		/** Called when a user adds a new item. Use this to update the options list. */
		onAddItem?: (event: SyntheticEvent, data: DropdownProps) => void;

		/** Called on blur. */
		onBlur?: (event: FocusEvent<HTMLElement>, data: DropdownProps) => void;

		/** Called when the user attempts to change the value. */
		onChange?: (event: KeyboardEvent | MouseEvent, data: DropdownProps) => void;

		/** Called on click. */
		onClick?: (event: React.MouseEvent<HTMLElement>, data: DropdownProps) => void;

		/** Called when a close event happens. */
		onClose?: (event: SyntheticEvent | Event | undefined, data: DropdownProps) => void;

		/** Called on focus. */
		onFocus?: (event: FocusEvent<HTMLElement>, data: DropdownProps) => void;

		/** Called when a multi-select label is clicked. */
		onLabelClick?: (event: React.MouseEvent<HTMLElement>, data: LabelProps) => void;

		/** Called on mousedown. */
		onMouseDown?: (event: React.MouseEvent<HTMLElement>, data: DropdownProps) => void;

		/** Called when an open event happens. */
		onOpen?: (event: SyntheticEvent<HTMLElement> | null, data: DropdownProps) => void;

		/** Called on search input change. */
		onSearchChange?: (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => void;

		/** Controls whether or not the dropdown menu is displayed. */
		open?: boolean;

		/** Whether or not the menu should open when the dropdown is focused. */
		openOnFocus?: boolean;

		/** Array of Dropdown.Item props e.g. `{ text: '', value: '' }` */
		options?: DropdownItemProps[];

		/** Placeholder text. */
		placeholder?: string;

		/** A dropdown can be formatted so that its menu is pointing. */
		pointing?:
			| boolean
			| 'left'
			| 'right'
			| 'top'
			| 'top left'
			| 'top right'
			| 'bottom'
			| 'bottom left'
			| 'bottom right';

		/**
		 * Mapped over the active items and returns shorthand for the active item Labels. Only applies to `multiple`
		 * Dropdowns.
		 *
		 * @param item - A currently active dropdown item.
		 * @param index - The current index.
		 * @param defaultLabelProps - The default props for an active item Label.
		 * @returns Shorthand for a Label.
		 */
		renderLabel?: (
			item: DropdownItemProps,
			index: number,
			defaultLabelProps: LabelProps
		) => SemanticShorthandItem<LabelProps>;

		/** A dropdown can have its menu scroll. */
		scrolling?: boolean;

		/**
		 * A selection dropdown can allow a user to search through a large list of choices. Pass a function here to
		 * replace the default search.
		 */
		search?: boolean | ((options: DropdownItemProps[], value: string) => DropdownItemProps[]);

		/** A shorthand for a search input. */
		searchInput?: string;

		/** Current value of searchQuery. Creates a controlled component. */
		searchQuery?: string;

		/** Define whether the highlighted item should be selected on blur. */
		selectOnBlur?: boolean;

		/**
		 * Whether dropdown should select new option when using keyboard shortcuts. Setting to false will require enter
		 * or left click to confirm a choice.
		 */
		selectOnNavigation?: boolean;

		/** Currently selected label in multi-select. */
		selectedLabel?: number | string;

		/** A dropdown can be used to select between choices in a form. */
		selection?: boolean;

		/** A simple dropdown can open without Javascript. */
		simple?: boolean;

		/** A dropdown can receive focus. */
		tabIndex?: number | string;

		/** The text displayed in the dropdown, usually for the active item. */
		text?: string;

		/** Custom element to trigger the menu to become visible. Takes place of 'text'. */
		trigger?: ReactNode;

		/** Current value or value array if multiple. Creates a controlled component. */
		value?: boolean | number | string | Array<boolean | number | string>;

		/** Controls whether the dropdown will open upward. */
		upward?: boolean;

		/**
		 * A dropdown will go to the last element when ArrowUp is pressed on the first, or go to the first when
		 * ArrowDown is pressed on the last( aka infinite selection )
		 */
		wrapSelection?: boolean;
	}
>;

const getKeyOrValue = (key: Key | null | undefined, value: string | undefined) => (key == null ? value : key);
const getKeyAndValues = (options: DropdownItemProps[] | undefined) =>
	options ? options.map(({ key, value }) => ({ key, value })) : options;

function renderItemContent(item: DropdownItemProps) {
	const { image, text } = item;

	return {
		content: (
			<>
				{createImage(image)}

				{text}
			</>
		)
	};
}

/**
 * A dropdown allows a user to select a value from a series of options.
 *
 * @see Form
 * @see Select
 * @see Menu
 */
export const Dropdown = forwardRef(function Dropdown(props: DropdownProps, ref: ForwardedRef<HTMLDivElement>) {
	const {
		additionLabel = 'Add ',
		additionPosition = 'top',
		closeOnBlur = true,
		closeOnEscape = true,
		deburr = false,
		icon = 'dropdown',
		minCharacters = 1,
		noResultsMessage = 'No results found.',
		openOnFocus = true,
		renderLabel = renderItemContent,
		searchInput = 'text',
		selectOnBlur = true,
		selectOnNavigation = true,
		wrapSelection = true,
		...rest
	} = props;

	return (
		<DropdownInner
			additionLabel={additionLabel}
			additionPosition={additionPosition}
			closeOnBlur={closeOnBlur}
			closeOnEscape={closeOnEscape}
			deburr={deburr}
			icon={icon}
			minCharacters={minCharacters}
			noResultsMessage={noResultsMessage}
			openOnFocus={openOnFocus}
			renderLabel={renderLabel}
			searchInput={searchInput}
			selectOnBlur={selectOnBlur}
			selectOnNavigation={selectOnNavigation}
			wrapSelection={wrapSelection}
			{...rest}
			innerRef={ref}
		/>
	);
});

type DropdownInnerProps = DropdownProps &
	Required<
		Pick<
			DropdownProps,
			| 'renderLabel'
			| 'minCharacters'
			| 'additionLabel'
			| 'additionPosition'
			| 'closeOnBlur'
			| 'closeOnEscape'
			| 'selectOnBlur'
			| 'selectOnNavigation'
		>
	> & {
		innerRef: Ref<HTMLElement>;
		clearable?: boolean;
		onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
	};

type DropdownInnerState = ModernAutoControlledComponentState<DropdownInnerProps> & {
	upward?: boolean;
	value: string | string[];
	focus?: boolean;
	open?: boolean;
	selectedLabel?: string;
	searchQuery: string;
	__value?: string | string[];
	__options?: DropdownItemProps[];
	selectedIndex: number;
};

class DropdownInner extends ModernAutoControlledComponent<DropdownInnerProps, DropdownInnerState> {
	private readonly searchRef = createRef<HTMLInputElement>();
	private readonly sizerRef = createRef<HTMLElement>();
	private readonly ref = createRef<HTMLElement>();
	private isMouseDown = false;

	public constructor(props: DropdownInnerProps) {
		super(
			props,
			['open', 'searchQuery', 'selectedLabel', 'value', 'upward'],
			DropdownInner.getAutoControlledStateFromProps
		);
	}

	private readonly handleRef = (el: HTMLElement) => {
		// @ts-ignore
		this.ref.current = el;
		setRef(this.props.innerRef, el);
	};

	protected override getInitialAutoControlledState() {
		return { focus: false, searchQuery: '' };
	}

	public static getAutoControlledStateFromProps(
		this: void,
		nextProps: DropdownInnerProps,
		computedState: DropdownInnerState,
		prevState: DropdownInnerState
	): Partial<DropdownInnerState> {
		// These values are stored only for a comparison on next getAutoControlledStateFromProps()
		const derivedState: Partial<DropdownInnerState> = {
			__options: nextProps.options,
			__value: computedState.value
		};

		// The selected index is only dependent:
		const shouldComputeSelectedIndex =
			// On value change
			!_.isEqual(prevState.__value, computedState.value) ||
			// On option keys/values, we only check those properties to avoid recursive performance impacts.
			// https://github.com/Semantic-Org/Semantic-UI-React/issues/3000
			!_.isEqual(getKeyAndValues(nextProps.options), getKeyAndValues(prevState.__options));

		if (shouldComputeSelectedIndex) {
			derivedState.selectedIndex = getSelectedIndex({
				additionLabel: nextProps.additionLabel,
				additionPosition: nextProps.additionPosition,
				allowAdditions: nextProps.allowAdditions,
				deburr: nextProps.deburr,
				multiple: nextProps.multiple,
				search: nextProps.search,
				selectedIndex: computedState.selectedIndex,

				value: computedState.value,
				options: nextProps.options,
				searchQuery: computedState.searchQuery
			})!;
		}

		return derivedState;
	}

	public override componentDidMount() {
		const { open } = this.state;

		if (open) {
			this.open(null, false);
		}
		this.updateEventListeners();
	}

	public override componentWillUnmount() {
		// Clean up all listeners when component unmounts
		this.removeEventListeners();
	}

	private updateEventListeners() {
		// Remove any previously added listeners
		this.removeEventListeners();

		// Add listeners based on current state
		if (this.state.open) {
			window.addEventListener('keydown', this.closeOnEscape, { capture: true });
			window.addEventListener('click', this.closeOnDocumentClick, { capture: true });
		}
		if (this.state.focus) {
			window.addEventListener('keydown', this.removeItemOnBackspace, { capture: true });
		}
	}

	private removeEventListeners() {
		// Remove all listeners to avoid duplicates
		window.removeEventListener('keydown', this.closeOnEscape, { capture: true });
		window.removeEventListener('click', this.closeOnDocumentClick, { capture: true });
		window.removeEventListener('keydown', this.removeItemOnBackspace, { capture: true });
	}

	public override shouldComponentUpdate(nextProps: DropdownInnerProps, nextState: DropdownInnerState) {
		return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
	}

	public override componentDidUpdate(prevProps: DropdownInnerProps, prevState: DropdownInnerState) {
		const { closeOnBlur, minCharacters, openOnFocus, search } = this.props;

		if (import.meta.env.DEV) {
			// in development, validate value type matches dropdown type
			const isNextValueArray = Array.isArray(this.props.value);
			const hasValue = _.has(this.props, 'value');

			if (hasValue && this.props.multiple && !isNextValueArray) {
				console.error(
					'Dropdown `value` must be an array when `multiple` is set.' +
						` Received type: \`${Object.prototype.toString.call(this.props.value)}\`.`
				);
			} else if (hasValue && !this.props.multiple && isNextValueArray) {
				console.error(
					'Dropdown `value` must not be an array when `multiple` is not set.' +
						' Either set `multiple={true}` or use a string or number value.'
				);
			}
		}

		// focused / blurred
		if (!prevState.focus && this.state.focus) {
			if (!this.isMouseDown) {
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				const openable = !search || (search && minCharacters === 1 && !this.state.open);

				if (openOnFocus && openable) {
					this.open();
				}
			}
		} else if (prevState.focus && !this.state.focus) {
			if (!this.isMouseDown && closeOnBlur) {
				this.close();
			}
		}

		// opened / closed
		if (!prevState.open && this.state.open) {
			this.setOpenDirection();
			this.scrollSelectedItemIntoView();
		}

		if (prevState.selectedIndex !== this.state.selectedIndex) {
			this.scrollSelectedItemIntoView();
		}
		if (prevState.open !== this.state.open || prevState.focus !== this.state.focus) {
			this.updateEventListeners();
		}
	}

	// ----------------------------------------
	// Document Event Handlers
	// ----------------------------------------

	// onChange needs to receive a value
	// can't rely on props.value if we are controlled
	private readonly handleChange = (
		e: React.SyntheticEvent | KeyboardEvent | MouseEvent,
		value: string | string[]
	) => {
		const newProps = { ...this.props, value };
		if ('nativeEvent' in e) {
			this.props.onChange?.(e.nativeEvent as KeyboardEvent | MouseEvent, newProps);
		} else {
			this.props.onChange?.(e, newProps);
		}
	};

	private readonly closeOnChange = (e: SyntheticEvent) => {
		const { closeOnChange, multiple } = this.props;
		const shouldClose = closeOnChange === undefined ? !multiple : closeOnChange;

		if (shouldClose) {
			this.close(e, _.noop);
		}
	};

	private readonly closeOnEscape = (e: Event) => {
		if (!this.props.closeOnEscape) {
			return;
		}
		if ((e as KeyboardEvent).key !== 'Escape') {
			return;
		}
		e.preventDefault();

		this.close(e);
	};

	private readonly moveSelectionOnKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		const { multiple, selectOnNavigation } = this.props;
		const { open } = this.state;

		if (!open) {
			return;
		}

		const moves: Record<string, number> = {
			ArrowDown: 1,
			ArrowUp: -1
		};
		const move = moves[e.key];

		if (move === undefined) {
			return;
		}

		e.preventDefault();
		const nextIndex = this.getSelectedIndexAfterMove(move);

		if (!multiple && selectOnNavigation) {
			this.makeSelectedItemActive(e, nextIndex!);
		}

		this.setState({ selectedIndex: nextIndex! });
	};

	private readonly openOnSpace = (e: React.KeyboardEvent<HTMLElement>) => {
		const shouldHandleEvent = this.state.focus && !this.state.open && e.key === ' ';
		const target = e.target as HTMLElement | undefined;
		const shouldPreventDefault =
			target?.tagName !== 'INPUT' && target?.tagName !== 'TEXTAREA' && target?.isContentEditable !== true;

		if (shouldHandleEvent) {
			if (shouldPreventDefault) {
				e.preventDefault();
			}

			this.open(e);
		}
	};

	private readonly openOnArrow = (e: React.KeyboardEvent<HTMLElement>) => {
		const { focus, open } = this.state;

		if (focus && !open) {
			const key = e.key;

			if (key === 'ArrowDown' || key === 'ArrowUp') {
				e.preventDefault();
				this.open(e);
			}
		}
	};

	private readonly makeSelectedItemActive = (e: SyntheticEvent<HTMLElement>, selectedIndex: number) => {
		const { open, value } = this.state;
		const { multiple } = this.props;

		const item = this.getSelectedItem(selectedIndex);
		const selectedValue = item?.value;
		const disabled = item?.disabled;

		// prevent selecting null if there was no selected item value
		// prevent selecting duplicate items when the dropdown is closed
		// prevent selecting disabled items
		if (selectedValue == null || !open || disabled) {
			return value;
		}

		// state value may be undefined
		const newValue = multiple ? _.union(value as string[], [selectedValue]) : selectedValue;
		const valueHasChanged = multiple ? Boolean(_.difference(newValue, value).length) : newValue !== value;

		if (valueHasChanged) {
			// notify the onChange prop that the user is trying to change value
			this.setState({ value: newValue });
			this.handleChange(e, newValue);

			// Heads up! This event handler should be called after `onChange`
			// Notify the onAddItem prop if this is a new value
			if (item?.['data-additional']) {
				this.props.onAddItem?.(e, { ...this.props, value: selectedValue });
			}
		}

		return value;
	};

	private readonly selectItemOnEnter = (e: React.KeyboardEvent<HTMLElement>) => {
		const { search } = this.props;
		const { open, selectedIndex } = this.state;

		if (!open) {
			return;
		}

		const shouldSelect =
			e.key === 'Enter' ||
			// https://github.com/Semantic-Org/Semantic-UI-React/pull/3766
			(!search && e.key === ' ');

		if (!shouldSelect) {
			return;
		}

		e.preventDefault();

		const optionSize = _.size(
			getMenuOptions({
				value: this.state.value,
				options: this.props.options,
				searchQuery: this.state.searchQuery,

				additionLabel: this.props.additionLabel,
				additionPosition: this.props.additionPosition,
				allowAdditions: this.props.allowAdditions,
				deburr: this.props.deburr,
				multiple: this.props.multiple,
				search: this.props.search
			})
		);

		if (search && optionSize === 0) {
			return;
		}

		const nextValue = this.makeSelectedItemActive(e, selectedIndex);

		// This is required as selected value may be the same
		this.setState({
			selectedIndex: getSelectedIndex({
				additionLabel: this.props.additionLabel,
				additionPosition: this.props.additionPosition,
				allowAdditions: this.props.allowAdditions,
				deburr: this.props.deburr,
				multiple: this.props.multiple,
				search: this.props.search,
				selectedIndex,

				value: nextValue,
				options: this.props.options,
				searchQuery: ''
			})!
		});

		this.closeOnChange(e);
		this.clearSearchQuery();

		if (search) {
			this.searchRef.current?.focus();
		}
	};

	private readonly removeItemOnBackspace = (event: Event) => {
		const e = event as KeyboardEvent;

		const { multiple, search } = this.props;
		const { searchQuery, value } = this.state;

		if (e.key !== 'Backspace') {
			return;
		}
		if (searchQuery || !search || !multiple || value.length === 0) {
			return;
		}
		e.preventDefault();

		// remove most recent value
		const newValue = _.dropRight(value);

		this.setState({ value: newValue });
		this.handleChange(e, newValue);
	};

	private readonly closeOnDocumentClick = (e: MouseEvent) => {
		if (!this.props.closeOnBlur) {
			return;
		}

		// If event happened in the dropdown, ignore it
		if (this.ref.current && doesNodeContainClick(this.ref.current, e)) {
			return;
		}

		this.close();
	};

	// ----------------------------------------
	// Component Event Handlers
	// ----------------------------------------

	private readonly handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
		this.isMouseDown = true;
		this.props.onMouseDown?.(e, this.props);
		document.addEventListener('mouseup', this.handleDocumentMouseUp);
	};

	private readonly handleDocumentMouseUp = () => {
		this.isMouseDown = false;
		document.removeEventListener('mouseup', this.handleDocumentMouseUp);
	};

	private readonly handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const { minCharacters, search } = this.props;
		const { open, searchQuery } = this.state;

		this.props.onClick?.(e, this.props);
		// prevent closeOnDocumentClick()
		e.stopPropagation();

		if (!search) {
			return this.toggle(e);
		}
		if (open) {
			this.searchRef.current?.focus();
			return;
		}
		if (searchQuery.length >= minCharacters || minCharacters === 1) {
			this.open(e);
			return;
		}
		this.searchRef.current?.focus();
	};

	private readonly handleIconClick = (e: React.MouseEvent<HTMLElement>) => {
		const { clearable } = this.props;
		const hasValue = this.hasValue();

		this.props.onClick?.(e, this.props);
		// prevent handleClick()
		e.stopPropagation();

		if (clearable && hasValue) {
			this.clearValue(e);
		} else {
			this.toggle(e);
		}
	};

	private readonly handleItemClick = (e: React.MouseEvent, item: DropdownItemProps) => {
		const { multiple, search } = this.props;
		const { value: currentValue } = this.state;
		const { value } = item;

		// prevent toggle() in handleClick()
		e.stopPropagation();

		// prevent closeOnDocumentClick() if multiple or item is disabled
		if (multiple || item.disabled) {
			e.nativeEvent.stopImmediatePropagation();
		}
		if (item.disabled) {
			return;
		}

		const isAdditionItem = item['data-additional'];
		const newValue = multiple ? _.union(this.state.value as string[], [value!]) : value!;
		const valueHasChanged = multiple
			? Boolean(_.difference(newValue, currentValue).length)
			: newValue !== currentValue;

		// notify the onChange prop that the user is trying to change value
		if (valueHasChanged) {
			this.setState({ value: newValue });
			this.handleChange(e, newValue);
		}

		this.clearSearchQuery();

		if (search) {
			this.searchRef.current?.focus();
		} else {
			this.ref.current?.focus();
		}

		this.closeOnChange(e);

		// Heads up! This event handler should be called after `onChange`
		// Notify the onAddItem prop if this is a new value
		if (isAdditionItem) {
			this.props.onAddItem?.(e, { ...this.props, value });
		}
	};

	private readonly handleFocus = (e: FocusEvent<HTMLElement>) => {
		const { focus } = this.state;

		if (focus) {
			return;
		}

		this.props.onFocus?.(e, this.props);
		this.setState({ focus: true });
	};

	private readonly handleBlur = (e: FocusEvent<HTMLElement>) => {
		// Heads up! Don't remove this.
		// https://github.com/Semantic-Org/Semantic-UI-React/issues/1315
		const currentTarget = e.currentTarget;
		if (currentTarget.contains(document.activeElement)) {
			return;
		}

		const { closeOnBlur, multiple, selectOnBlur } = this.props;
		// do not "blur" when the mouse is down inside of the Dropdown
		if (this.isMouseDown) {
			return;
		}

		this.props.onBlur?.(e, this.props);

		if (selectOnBlur && !multiple) {
			this.makeSelectedItemActive(e, this.state.selectedIndex);
			if (closeOnBlur) {
				this.close();
			}
		}

		this.setState({ focus: false });
		this.clearSearchQuery();
	};

	private readonly handleSearchChange = (e: ChangeEvent<HTMLInputElement>, { value }: DropdownSearchInputProps) => {
		// prevent propagating to this.props.onChange()
		e.stopPropagation();

		const { minCharacters } = this.props;
		const { open } = this.state;
		const newQuery = value!;

		this.props.onSearchChange?.(e, { ...this.props, searchQuery: newQuery });
		this.setState({ searchQuery: newQuery, selectedIndex: 0 });

		// open search dropdown on search query
		if (!open && newQuery.length >= minCharacters) {
			this.open();
			return;
		}
		// close search dropdown if search query is too small
		if (open && minCharacters !== 1 && newQuery.length < minCharacters) {
			this.close();
		}
	};

	private readonly handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		this.moveSelectionOnKeyDown(e);
		this.openOnArrow(e);
		this.openOnSpace(e);
		this.selectItemOnEnter(e);

		this.props.onKeyDown?.(e);
	};

	// ----------------------------------------
	// Getters
	// ----------------------------------------

	private readonly getSelectedItem = (selectedIndex: number): DropdownItemProps | undefined => {
		const options = getMenuOptions({
			value: this.state.value,
			options: this.props.options,
			searchQuery: this.state.searchQuery,

			additionLabel: this.props.additionLabel,
			additionPosition: this.props.additionPosition,
			allowAdditions: this.props.allowAdditions,
			deburr: this.props.deburr,
			multiple: this.props.multiple,
			search: this.props.search
		});

		return options?.[selectedIndex];
	};

	private readonly getItemByValue = (value: string | string[]): DropdownItemProps | undefined => {
		const { options } = this.props;

		return options?.find(item => item.value === value);
	};

	private readonly getDropdownAriaOptions = () => {
		const { loading, disabled, search, multiple } = this.props;
		const { open } = this.state;
		const ariaOptions: Record<string, string | boolean | undefined> = {
			role: search ? 'combobox' : 'listbox',
			'aria-busy': loading,
			'aria-disabled': disabled,
			'aria-expanded': Boolean(open)
		};
		if (ariaOptions.role === 'listbox') {
			ariaOptions['aria-multiselectable'] = multiple;
		}
		return ariaOptions;
	};

	private getDropdownMenuAriaOptions() {
		const { search, multiple } = this.props;
		const ariaOptions: Record<string, string | boolean | undefined> = {};

		if (search) {
			ariaOptions['aria-multiselectable'] = multiple;
			ariaOptions.role = 'listbox';
		}
		return ariaOptions;
	}

	// ----------------------------------------
	// Setters
	// ----------------------------------------

	private readonly clearSearchQuery = () => {
		const { searchQuery } = this.state;
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (searchQuery === undefined || searchQuery === '') {
			return;
		}

		this.setState({ searchQuery: '' });
	};

	private readonly handleLabelClick = (e: React.MouseEvent<HTMLElement>, labelProps: LabelProps) => {
		// prevent focusing search input on click
		e.stopPropagation();

		this.setState({ selectedLabel: labelProps.value });
		this.props.onLabelClick?.(e, labelProps);
	};

	private readonly handleLabelRemove = (e: React.MouseEvent<HTMLElement>, labelProps: LabelProps) => {
		// prevent focusing search input on click
		e.stopPropagation();
		const { value } = this.state;
		const newValue = _.without(value, labelProps.value!);

		this.setState({ value: newValue });
		this.handleChange(e, newValue);
	};

	private readonly getSelectedIndexAfterMove = (
		offset: number,
		startIndex = this.state.selectedIndex
	): number | undefined => {
		const options = getMenuOptions({
			value: this.state.value,
			options: this.props.options,
			searchQuery: this.state.searchQuery,

			additionLabel: this.props.additionLabel,
			additionPosition: this.props.additionPosition,
			allowAdditions: this.props.allowAdditions,
			deburr: this.props.deburr,
			multiple: this.props.multiple,
			search: this.props.search
		});

		// Prevent infinite loop
		if (options === undefined || options.every(item => item.disabled)) {
			return undefined;
		}

		const lastIndex = options.length - 1;
		const { wrapSelection } = this.props;
		// next is after last, wrap to beginning
		// next is before first, wrap to end
		let nextIndex = startIndex + offset;

		// if 'wrapSelection' is set to false and selection is after last or before first, it just does not change
		if (!wrapSelection && (nextIndex > lastIndex || nextIndex < 0)) {
			nextIndex = startIndex;
		} else if (nextIndex > lastIndex) {
			nextIndex = 0;
		} else if (nextIndex < 0) {
			nextIndex = lastIndex;
		}

		if (options[nextIndex]!.disabled) {
			return this.getSelectedIndexAfterMove(offset, nextIndex);
		}

		return nextIndex;
	};

	// ----------------------------------------
	// Overrides
	// ----------------------------------------

	private readonly handleIconOverrides = (predefinedProps: IconProps): IconProps => {
		const { clearable } = this.props;
		const classes = clsx(clearable && this.hasValue() && 'clear', predefinedProps.className);

		return {
			className: classes,
			onClick: (e: React.MouseEvent<HTMLElement>) => {
				predefinedProps.onClick?.(e);
				this.handleIconClick(e);
			}
		};
	};

	// ----------------------------------------
	// Helpers
	// ----------------------------------------

	private readonly clearValue = (e: React.MouseEvent<HTMLElement>) => {
		const { multiple } = this.props;
		const newValue = multiple ? [] : '';

		this.setState({ value: newValue });
		this.handleChange(e, newValue);
	};

	private readonly computeSearchInputTabIndex = () => {
		const { disabled, tabIndex } = this.props;

		if (tabIndex != null) {
			return tabIndex;
		}
		return disabled ? -1 : 0;
	};

	private readonly computeSearchInputWidth = () => {
		const { searchQuery } = this.state;

		if (this.sizerRef.current && searchQuery) {
			// resize the search input, temporarily show the sizer so we can measure it

			this.sizerRef.current.style.display = 'inline';
			this.sizerRef.current.textContent = searchQuery;
			const searchWidth = Math.ceil(this.sizerRef.current.getBoundingClientRect().width);
			this.sizerRef.current.style.removeProperty('display');

			return searchWidth;
		}
		return undefined;
	};

	private readonly computeTabIndex = () => {
		const { disabled, search, tabIndex } = this.props;

		// don't set a root node tabIndex as the search input has its own tabIndex
		if (search) {
			return undefined;
		}
		if (disabled) {
			return -1;
		}
		return tabIndex == null ? 0 : tabIndex;
	};

	private readonly handleSearchInputOverrides = (predefinedProps: DropdownSearchInputProps) => ({
		onChange: (e: ChangeEvent<HTMLInputElement>, inputProps: DropdownSearchInputProps) => {
			predefinedProps.onChange?.(e, inputProps);
			this.handleSearchChange(e, inputProps);
		},
		ref: this.searchRef
	});

	private readonly hasValue = () => {
		const { multiple } = this.props;
		const { value } = this.state;

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		return multiple ? value.length > 0 : value != null && value !== '';
	};

	// ----------------------------------------
	// Behavior
	// ----------------------------------------

	private readonly scrollSelectedItemIntoView = () => {
		if (!this.ref.current) {
			return;
		}
		const menu = this.ref.current.querySelector('.menu.visible');
		if (!menu) {
			return;
		}
		const item = menu.querySelector('.item.selected') as HTMLElement | undefined;
		if (!item) {
			return;
		}
		const isOutOfUpperView = item.offsetTop < menu.scrollTop;
		const isOutOfLowerView = item.offsetTop + item.clientHeight > menu.scrollTop + menu.clientHeight;

		if (isOutOfUpperView) {
			menu.scrollTop = item.offsetTop;
		} else if (isOutOfLowerView) {
			menu.scrollTop = item.offsetTop + item.clientHeight - menu.clientHeight;
		}
	};

	private readonly setOpenDirection = () => {
		if (!this.ref.current) {
			return;
		}

		const menu = this.ref.current.querySelector('.menu.visible');

		if (!menu) {
			return;
		}

		const dropdownRect = this.ref.current.getBoundingClientRect();
		const menuHeight = menu.clientHeight;
		const spaceAtTheBottom =
			document.documentElement.clientHeight - dropdownRect.top - dropdownRect.height - menuHeight;
		const spaceAtTheTop = dropdownRect.top - menuHeight;

		const upward = spaceAtTheBottom < 0 && spaceAtTheTop > spaceAtTheBottom;

		// set state only if there's a relevant difference
		if (!upward !== !this.state.upward) {
			this.setState({ upward });
		}
	};

	private readonly open = (e: SyntheticEvent<HTMLElement> | null = null, triggerSetState = true) => {
		const { disabled, search } = this.props;

		if (disabled) {
			return;
		}
		if (search) {
			this.searchRef.current?.focus();
		}

		this.props.onOpen?.(e, this.props);

		if (triggerSetState) {
			this.setState({ open: true });
		}
		this.scrollSelectedItemIntoView();
	};

	private readonly close = (e?: SyntheticEvent | Event, callback = this.handleClose) => {
		if (this.state.open) {
			this.props.onClose?.(e, this.props);
			this.setState({ open: false }, callback);
		}
	};

	private readonly handleClose = () => {
		const hasSearchFocus = document.activeElement === this.searchRef.current;
		// https://github.com/Semantic-Org/Semantic-UI-React/issues/627
		// Blur the Dropdown on close so it is blurred after selecting an item.
		// This is to prevent it from re-opening when switching tabs after selecting an item.
		if (!hasSearchFocus && this.ref.current) {
			this.ref.current.blur();
		}

		const hasDropdownFocus = document.activeElement === this.ref.current;
		const hasFocus = hasSearchFocus || hasDropdownFocus;

		// We need to keep the virtual model in sync with the browser focus change
		// https://github.com/Semantic-Org/Semantic-UI-React/issues/692
		this.setState({ focus: hasFocus });
	};

	private readonly toggle = (e: SyntheticEvent<HTMLElement>) => (this.state.open ? this.close(e) : this.open(e));

	// ----------------------------------------
	// Render
	// ----------------------------------------

	private readonly renderText = () => {
		const { multiple, placeholder, search, text } = this.props;
		const { searchQuery, selectedIndex, value, open } = this.state;
		const hasValue = this.hasValue();

		const classes = clsx(placeholder && !hasValue && 'default', 'text', search && searchQuery && 'filtered');
		let _text = placeholder;
		let selectedItem;

		if (text) {
			_text = text;
		} else if (open && !multiple) {
			selectedItem = this.getSelectedItem(selectedIndex);
		} else if (hasValue) {
			selectedItem = this.getItemByValue(value);
		}

		return createDropdownText(selectedItem ? renderItemContent(selectedItem) : _text, {
			defaultProps: {
				className: classes
			}
		});
	};

	private readonly renderSearchInput = () => {
		const { search, searchInput } = this.props;
		const { searchQuery } = this.state;

		return (
			search &&
			createDropdownSearchInput(searchInput, {
				defaultProps: {
					style: { width: this.computeSearchInputWidth() },
					tabIndex: this.computeSearchInputTabIndex(),
					value: searchQuery
				},
				overrideProps: this.handleSearchInputOverrides
			})
		);
	};

	private readonly renderSearchSizer = () => {
		const { search, multiple } = this.props;

		return search && multiple && <span className="sizer" ref={this.sizerRef} />;
	};

	private readonly renderLabels = () => {
		const { multiple, renderLabel } = this.props;
		const { selectedLabel, value } = this.state;
		if (!multiple || value.length === 0) {
			return;
		}
		const selectedItems = (value as string[]).map(this.getItemByValue);

		// if no item could be found for a given state value the selected item will be undefined
		// compact the selectedItems so we only have actual objects left
		return _.compact(selectedItems).map((item, index) => {
			const defaultProps: LabelProps<'a'> = {
				active: item.value === selectedLabel,
				as: 'a',
				key: getKeyOrValue(item.key, item.value),
				onClick: this.handleLabelClick,
				onRemove: this.handleLabelRemove,
				value: item.value
			};

			return createLabel(renderLabel(item, index, defaultProps as LabelProps), { defaultProps });
		});
	};

	private readonly renderOptions = () => {
		const { lazyLoad, multiple, search, noResultsMessage } = this.props;
		const { open, selectedIndex, value } = this.state;

		// lazy load, only render options when open
		if (lazyLoad && !open) {
			return null;
		}

		const options = getMenuOptions({
			value: this.state.value,
			options: this.props.options,
			searchQuery: this.state.searchQuery,

			additionLabel: this.props.additionLabel,
			additionPosition: this.props.additionPosition,
			allowAdditions: this.props.allowAdditions,
			deburr: this.props.deburr,
			multiple: this.props.multiple,
			search: this.props.search
		});

		if (noResultsMessage !== null && search && (options == null || options.length === 0)) {
			return <div className="message">{noResultsMessage}</div>;
		}

		const isActive = multiple
			? (optValue: string | undefined) => value.includes(optValue!)
			: (optValue: string | undefined) => optValue === value;

		return options?.map((opt, i) =>
			createDropdownItem(
				{
					active: isActive(opt.value),
					selected: selectedIndex === i,
					...opt,
					key: getKeyOrValue(opt.key, opt.value),
					// Needed for handling click events on disabled items
					style: { ...opt.style, pointerEvents: 'all' }
				},
				{
					autoGenerateKey: false,
					overrideProps: (predefinedProps: DropdownItemProps) => ({
						onClick: (e: React.MouseEvent, item: DropdownItemProps) => {
							predefinedProps.onClick?.(e, item);
							this.handleItemClick(e, item);
						}
					})
				}
			)
		);
	};

	private readonly renderMenu = () => {
		const { children, direction, header } = this.props;
		const { open } = this.state;
		const ariaOptions = this.getDropdownMenuAriaOptions();

		// single menu child
		if (!childrenUtils.isNil(children)) {
			const menuChild = Children.only(children) as JSX.Element;
			const className = clsx(direction, keyOnly(open, 'visible'), menuChild.props.className);

			return cloneElement(menuChild, { className, ...ariaOptions });
		}

		return (
			<DropdownMenu {...ariaOptions} direction={direction} open={open}>
				{createDropdownHeader(header, { autoGenerateKey: false })}
				{this.renderOptions()}
			</DropdownMenu>
		);
	};

	public override render() {
		const {
			basic,
			button,
			className,
			compact,
			disabled,
			error,
			fluid,
			floating,
			icon,
			inline,
			item,
			labeled,
			loading,
			multiple,
			pointing,
			search,
			selection,
			scrolling,
			simple,
			trigger
		} = this.props;
		const { open, upward } = this.state;

		// Classes
		const classes = clsx(
			'ui',
			keyOnly(open, 'active visible'),
			keyOnly(disabled, 'disabled'),
			keyOnly(error, 'error'),
			keyOnly(loading, 'loading'),

			keyOnly(basic, 'basic'),
			keyOnly(button, 'button'),
			keyOnly(compact, 'compact'),
			keyOnly(fluid, 'fluid'),
			keyOnly(floating, 'floating'),
			keyOnly(inline, 'inline'),
			// The icon class is only required when a dropdown is a button
			keyOnly(icon !== 'dropdown' && button, 'icon'),
			keyOnly(labeled, 'labeled'),
			keyOnly(item, 'item'),
			keyOnly(multiple, 'multiple'),
			keyOnly(search, 'search'),
			keyOnly(selection, 'selection'),
			keyOnly(simple, 'simple'),
			keyOnly(scrolling, 'scrolling'),
			keyOnly(upward, 'upward'),

			keyOrValueAndKey(pointing, 'pointing'),
			'dropdown',
			className
		);
		const rest = getUnhandledProps(handledProps, this.props);
		const ElementType = getComponentType(this.props);
		const ariaOptions = this.getDropdownAriaOptions();

		return (
			<ElementType
				{...rest}
				{...ariaOptions}
				className={classes}
				onBlur={this.handleBlur}
				onClick={this.handleClick}
				onKeyDown={this.handleKeyDown}
				onMouseDown={this.handleMouseDown}
				onFocus={this.handleFocus}
				onChange={this.handleChange}
				tabIndex={this.computeTabIndex()}
				ref={this.handleRef}
			>
				{this.renderLabels()}
				{this.renderSearchInput()}
				{this.renderSearchSizer()}
				{trigger || this.renderText()}
				{createIcon(icon, {
					overrideProps: this.handleIconOverrides,
					autoGenerateKey: false
				})}
				{this.renderMenu()}
			</ElementType>
		);
	}
}

const handledProps = [
	'additionLabel',
	'additionPosition',
	'allowAdditions',
	'as',
	'basic',
	'button',
	'children',
	'className',
	'clearable',
	'closeOnBlur',
	'closeOnChange',
	'closeOnEscape',
	'compact',
	'deburr',
	'defaultOpen',
	'defaultSearchQuery',
	'defaultSelectedLabel',
	'defaultUpward',
	'defaultValue',
	'direction',
	'disabled',
	'error',
	'floating',
	'fluid',
	'header',
	'icon',
	'inline',
	'item',
	'labeled',
	'lazyLoad',
	'loading',
	'minCharacters',
	'multiple',
	'noResultsMessage',
	'onAddItem',
	'onBlur',
	'onChange',
	'onClick',
	'onClose',
	'onFocus',
	'onLabelClick',
	'onMouseDown',
	'onOpen',
	'onSearchChange',
	'open',
	'openOnFocus',
	'options',
	'placeholder',
	'pointing',
	'renderLabel',
	'scrolling',
	'search',
	'searchInput',
	'searchQuery',
	'selectOnBlur',
	'selectOnNavigation',
	'selectedLabel',
	'selection',
	'simple',
	'tabIndex',
	'text',
	'trigger',
	'upward',
	'value',
	'wrapSelection'
];
