import * as _ from 'es-toolkit/compat';

/**
 * Determines if a click's coordinates are within the bounds of a node.
 *
 * @see https://github.com/Semantic-Org/Semantic-UI-React/pull/2384
 */
export const doesNodeContainClick = (node: HTMLElement | undefined | null, e: MouseEvent | undefined | null) => {
	if (e == null || node == null) {
		return false;
	}

	// if there is an e.target and it is in the document, use a simple node.contains() check
	if (e.target) {
		(e.target as HTMLElement).setAttribute('data-suir-click-target', 'true');

		if (document.querySelector('[data-suir-click-target=true]')) {
			(e.target as HTMLElement).removeAttribute('data-suir-click-target');

			if (typeof node.contains === 'function') {
				return node.contains(e.target as HTMLElement);
			}
		}
	}

	// Below logic handles cases where the e.target is no longer in the document.
	// The result of the click likely has removed the e.target node.
	// Instead of node.contains(), we'll identify the click by X/Y position.

	// return early if the event properties aren't available
	// prevent measuring the node and repainting if we don't need to
	const { clientX, clientY } = e;

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if ([clientX, clientY].some(item => item == null)) {
		return false;
	}

	if (typeof node.getClientRects !== 'function') {
		return false;
	}

	// false if the node is not visible
	const clientRects = node.getClientRects();

	// Heads Up!
	// getClientRects returns a DOMRectList, not an array nor a plain object
	// We explicitly avoid _.isEmpty and check .length to cover all possible shapes
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (!node.offsetWidth || !node.offsetHeight || !clientRects?.length) {
		return false;
	}

	// false if the node doesn't have a valid bounding rect
	const firstRect = clientRects.item(0);
	if (firstRect == null) {
		return false;
	}

	const { top, bottom, left, right } = firstRect;

	// we add a small decimal to the upper bound just to make it inclusive
	// don't add an whole pixel (1) as the event/node values may be decimal sensitive
	return _.inRange(clientY, top, bottom + 0.001) && _.inRange(clientX, left, right + 0.001);
};
