/** Generated from EBasicRole.java. Do not modify this file! */

import type { EBasicPermissionEntry } from 'typedefs/EBasicPermission';

export type EBasicRoleEntry = 'VIEWER' | 'EDITOR' | 'OWNER';

export class EBasicRole {
	static readonly VIEWER = new EBasicRole(0, 'VIEWER', "Viewer", ["VIEW"]);
	static readonly EDITOR = new EBasicRole(1, 'EDITOR', "Editor", ["VIEW","EDIT"]);
	static readonly OWNER = new EBasicRole(2, 'OWNER', "Owner", ["VIEW","EDIT","DELETE","EDIT_ROLES"]);
	static readonly values = [
		EBasicRole.VIEWER,
		EBasicRole.EDITOR,
		EBasicRole.OWNER
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EBasicRoleEntry,
		public readonly readableName: string,
		public readonly permissions: Array<EBasicPermissionEntry>
	){
	}

	toString() {
		return this.name;
	}
}

