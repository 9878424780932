/** Generated from EFindingBlacklistOperation.java. Do not modify this file! */


export type EFindingBlacklistOperationEntry = 'ADD' | 'REMOVE';

export class EFindingBlacklistOperation {
	static readonly ADD = new EFindingBlacklistOperation(0, 'ADD');
	static readonly REMOVE = new EFindingBlacklistOperation(1, 'REMOVE');
	static readonly values = [
		EFindingBlacklistOperation.ADD,
		EFindingBlacklistOperation.REMOVE
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EFindingBlacklistOperationEntry,

	){
	}

	toString() {
		return this.name;
	}
}

