import { PointInTimeFormatter } from './PointInTimeFormatter';
import type { TimeSpan } from './TimeSpan';

/** Formatter for a timespan. */
export class TimespanFormatter extends PointInTimeFormatter<TimeSpan> {
	public override format(timespan: TimeSpan): string {
		const days = timespan.days;
		if (days === 0) {
			return 'Now';
		}
		if (days !== 1) {
			return days + ' Days';
		}
		return days + ' Day';
	}

	public override formatAsTrend(timespan: TimeSpan): string {
		if (timespan.days === 0) {
			return 'No trend';
		}
		return 'Trend since ' + this.format(timespan) + ' ago';
	}

	public override formatAsTaskTrend(timespan: TimeSpan): string {
		if (timespan.days === 0) {
			return 'All tasks counted.';
		}
		return 'Non-open tasks counted since ' + this.format(timespan) + ' ago';
	}

	public override formatAsBaseline(timespan: TimeSpan): string {
		return 'introduced within the last ' + timespan.days + ' days';
	}
}
