import {
	Modal as MantineModal,
	type ModalProps as MantineModalProps,
	ScrollArea as MantineScrollArea
} from '@mantine/core';

/** Props for Modal */
export type ModalProps = MantineModalProps;

/** An accessible overlay dialog */
export const Modal = MantineModal;

/** Area with custom scrollbars */
export const ScrollArea = MantineScrollArea;
