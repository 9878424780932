import { useCallback, useLayoutEffect, useRef } from 'react';

/**
 * https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
 *
 * Modified `useCallback` that can be used when dependencies change too frequently. Can occur when: e.g. user props are
 * depedencies which could change on every render e.g. volatile values (i.e. useState/useDispatch) are dependencies
 * which could change frequently
 *
 * This should not be used often, but can be a useful re-render optimization since the callback is a ref and will not be
 * invalidated between rerenders.
 *
 * @param fn The callback function that will be used
 */
export function useEventCallback<T extends (...args: never[]) => unknown>(fn: T): T {
	const callbackRef = useRef<T>((() => {
		if (import.meta.env.DEV) {
			throw new Error('Cannot call an event handler while rendering...');
		}
	}) as T);

	useLayoutEffect(() => {
		callbackRef.current = fn;
	}, [fn]);

	return useCallback(
		((...args: Parameters<T>): ReturnType<T> => {
			const callback = callbackRef.current;

			return callback(...args) as ReturnType<T>;
		}) as T,
		[callbackRef]
	);
}
