import type { UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { useMutation as useReactMutation } from '@tanstack/react-query';
import type { UploadProgress } from 'api/ServiceClientImplementation';
import type { Mutations, Queries } from './ApiDefinition';
import { urlMapping } from './ApiDefinition';
import type { ServiceCallError } from './ServiceCallError';
import type { RequestParameters } from './ServiceCallOperationHandlerBase';
import { ServiceCallOperationHandlerBase } from './ServiceCallOperationHandlerBase';

/** Is called with the progress of the upload. */
export type UploadProgressCallback = { uploadProgressCallback?: UploadProgress };
type RequestParametersWithUploadProgressCallback = RequestParameters & UploadProgressCallback;

/** The mutation operations API. */
export type MutationOperation<TVariables extends RequestParametersWithUploadProgressCallback | void, TData> = {
	/** Calls the useMutation hook to which exposes the functions to call the service at a later point in time. */
	useMutation: (
		options?: MutationOptions<TData, TVariables>
	) => UseMutationResult<TData, ServiceCallError, TVariables & UploadProgressCallback>;
};

/** Options that can be passed to the useMutation hook. */
export type MutationOptions<TData, TVariables extends RequestParameters | void> = Omit<
	UseMutationOptions<TData, ServiceCallError, TVariables>,
	'mutationFn'
>;

/** Shorthand for the mutation result type given the operation ID. */
export type MutationResult<K extends keyof Mutations> =
	{
		[Property in keyof Mutations]: Mutations[Property];
	}[K] extends MutationOperation<
		infer TVariables extends RequestParametersWithUploadProgressCallback | void,
		infer TData
	>
		? UseMutationResult<TData, ServiceCallError, TVariables>
		: never;

/** Implements a proxy handler for the MUTATION object, which implements the MutationOperation API. */
class MutationHandler extends ServiceCallOperationHandlerBase implements ProxyHandler<object> {
	/**
	 * The proxy will call this method when code tries to access MUTATION.someOperation and it will return an
	 * implementation for that mutation operation.
	 */
	public get<TVariables extends RequestParametersWithUploadProgressCallback, TData>(
		target: object,
		operationId: keyof Queries
	): MutationOperation<TVariables, TData> {
		const operationInfo = urlMapping[operationId];

		const mutationFn = <TData>(parameters: TVariables): Promise<TData> => {
			return this.performServiceCall<TData>(operationInfo, parameters, parameters);
		};

		function useMutation(options?: MutationOptions<TData, TVariables>) {
			return useReactMutation({ mutationFn, ...options });
		}

		return { useMutation };
	}
}

/**
 * Provides access to all service calls either via Promise or useQuery based APIs. This should be used to update data or
 * query data conditionally.
 */
export const MUTATION: Mutations = new Proxy({}, new MutationHandler()) as Mutations;
