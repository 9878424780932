import type { JSX } from 'react';
import type { Callback } from 'ts/base/Callback';
import {
	BRANCH_SELECTOR_PAGINATION_LIMIT,
	getBranchesInfoForProjects,
	getDefaultBranchForProjects,
	listRecentBranchesForProjects
} from 'ts/base/components/branch-chooser/UseBranchInfos';
import { BranchDropdown } from 'ts/commons/BranchDropdown';
import { useSuspendingQueries } from 'ts/commons/hooks/UseSuspendingQueries';
import type { ValueAwareComponentType } from 'ts/commons/InMenuSearchableDropdown';

/** Props for BranchSelector. */
export type BranchSelectorProps = {
	selectorId: string;
	defaultSelectedBranch?: string | null;
	selectedBranch?: string | null;
	className?: string;
	projectIds: string[];
	disabled?: boolean;
	onBranchChanged?: Callback<string>;
	asItem?: ValueAwareComponentType;
	upward?: boolean;
	scrolling?: boolean;
	direction?: 'left' | 'right';
};

/** The default parameters used when requesting the available branches. */
const DEFAULT_BRANCHES_PARAMS = {
	'start-offset': 0,
	limit: BRANCH_SELECTOR_PAGINATION_LIMIT
};

/** A dropdown with all branch names of one or more projects. */
export function BranchSelector({
	selectorId,
	projectIds,
	defaultSelectedBranch,
	selectedBranch,
	className,
	disabled,
	onBranchChanged,
	asItem,
	upward,
	scrolling,
	direction
}: BranchSelectorProps): JSX.Element | null {
	const [defaultBranch, recentBranches, branchesInfo] = useSuspendingQueries(
		getDefaultBranchForProjects(projectIds),
		listRecentBranchesForProjects(projectIds),
		getBranchesInfoForProjects(projectIds, DEFAULT_BRANCHES_PARAMS)
	);
	return (
		<BranchDropdown
			id={selectorId}
			className={className}
			defaultActiveBranch={defaultSelectedBranch ?? defaultBranch}
			selectedBranch={(selectedBranch === undefined ? undefined : selectedBranch) ?? defaultBranch}
			defaultBranchName={defaultBranch}
			recentBranches={recentBranches}
			branchesInfo={branchesInfo}
			disabled={disabled}
			onBranchChanged={onBranchChanged}
			asItem={asItem}
			projectIds={projectIds}
			upward={upward}
			scrolling={scrolling}
			direction={direction}
		/>
	);
}
