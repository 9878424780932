import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ExtendedRepositoryLogEntry } from 'typedefs/ExtendedRepositoryLogEntry';
import type { FindingChurnCount } from 'typedefs/FindingChurnCount';
import type { ParentedCommitDescriptor } from 'typedefs/ParentedCommitDescriptor';
import type { RepositoryLogFileChurn } from 'typedefs/RepositoryLogFileChurn';
import type { TeamscaleIssueStatus } from 'typedefs/TeamscaleIssueStatus';
import type { UserResolvedRepositoryLogEntry } from 'typedefs/UserResolvedRepositoryLogEntry';
import { FormattedCommitMessage } from './FormattedCommitMessage';

/**
 * A repo log entry with a full CommitDescriptor as 'commit' field and fields 'project', 'formattedDate', and 'deleted'
 * (default to null or false, need to be set manually).
 */
export class CommitRepositoryLogEntry {
	/** A regex matching SHA-1 hashes as used by Git (e.g. e6c2530d4784e89d66f1452b5fb931ac9451864a). */
	public static GIT_COMMIT_HASH_REGEX = /[0-9a-f]{40}/i;

	/** The expected number of characters in a git commit hash. */
	public static GIT_COMMIT_HASH_LENGTH = 40;

	/** The original commit descriptor. */
	public readonly commit: ParentedCommitDescriptor;

	/** Here we use the wrapped commit descriptor instead. */
	public readonly unresolvedCommit: UnresolvedCommitDescriptor;

	/** A short commit hash if the revision is too long. */
	public readonly shortRevision: string;

	/** The full commit hash. */
	public readonly fullRevision: string;

	/** The commit message title and details parsed from the message string. */
	public readonly parsedCommitMessage: FormattedCommitMessage;

	/** The project ID. */
	public project: string | null = null;

	/** The number of affected files. */
	public readonly fileChurnCount: RepositoryLogFileChurn;

	/** The type(s) of this commit (see {@link ECommitType}). */
	public readonly commitTypes?: string[];

	/** Link to the commit at a CCP */
	public readonly externalLink: string | undefined;

	/** The number of alerts that have been produced by the commit/log entry. */
	public readonly alertsCount: number;

	/** The finding churn count aka the number needed to render a finding badge. */
	public readonly findingChurnCount: FindingChurnCount;

	/** The issues that are linked to this commit together with the issue's status and title. */
	public readonly linkedIssues: TeamscaleIssueStatus[];

	/** The actual repository log entry. */
	public readonly repositoryLogEntry: UserResolvedRepositoryLogEntry;

	public constructor(extendedLogEntry: ExtendedRepositoryLogEntry) {
		this.repositoryLogEntry = extendedLogEntry.logEntry;
		this.commit = this.repositoryLogEntry.commit;
		this.unresolvedCommit = UnresolvedCommitDescriptor.wrap(this.commit);
		this.fullRevision = this.repositoryLogEntry.revision;
		this.shortRevision = CommitRepositoryLogEntry.getShortRevision(this.repositoryLogEntry.revision);
		this.parsedCommitMessage = FormattedCommitMessage.fromPlainCommitMessage(
			this.repositoryLogEntry.message ?? null
		);
		this.commitTypes = this.repositoryLogEntry.commitTypes;
		this.linkedIssues = extendedLogEntry.linkedIssues;
		this.findingChurnCount = extendedLogEntry.findingChurnCount;
		this.fileChurnCount = extendedLogEntry.fileChurnCount;
		this.alertsCount = extendedLogEntry.alertsCount;
		this.externalLink = extendedLogEntry.externalLink;
	}

	/** Returns an UnresolvedCommitDescriptor for the commit before. */
	public getPreviousCommit(): UnresolvedCommitDescriptor {
		return UnresolvedCommitDescriptor.getPreviousCommit(this.unresolvedCommit);
	}

	/**
	 * If the revision of the given log entry is a SHA-1 hash (-> Git), the first 8 characters of the hash will be
	 * attached to the given logEntry as a 'shortedCommitHash'-field.
	 */
	public static getShortRevision(revision: string): string {
		if (
			revision.length !== CommitRepositoryLogEntry.GIT_COMMIT_HASH_LENGTH ||
			!revision.match(CommitRepositoryLogEntry.GIT_COMMIT_HASH_REGEX)
		) {
			return revision;
		}
		return revision.substring(0, 8);
	}

	/**
	 * Wraps the repository log entries into commit repository log entries. If a repository log entry is null, the
	 * returned array will contain null at the position as well.
	 */
	public static wrapRepositoryLogEntries(logEntries: ExtendedRepositoryLogEntry[]): CommitRepositoryLogEntry[] {
		return logEntries.map(logEntry => new CommitRepositoryLogEntry(logEntry));
	}
}
