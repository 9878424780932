import type { ReactNode } from 'react';
import { Suspense, type JSX } from 'react';
import { PerspectiveLoadingIndicator } from 'ts/base/components/PerspectiveLoadingIndicator';
import type { ErrorBoundaryProps } from 'ts/base/ErrorBoundary';
import { ErrorBoundary } from 'ts/base/ErrorBoundary';

/** Props for SuspendingErrorBoundary. */
type SuspendingErrorBoundaryProps = ErrorBoundaryProps & {
	/** The component to render in case data is still loading (the promise is not resolved) */
	suspenseFallback?: ReactNode;
};

/** Provides suspense handling, client and error boundary for React components in Teamscale. */
export function SuspendingErrorBoundary({
	children,
	suspenseFallback,
	errorFallback,
	serviceErrorFallback
}: SuspendingErrorBoundaryProps): JSX.Element {
	const suspenseFallbackToUse = suspenseFallback ?? <PerspectiveLoadingIndicator />;
	return (
		<Suspense fallback={suspenseFallbackToUse}>
			<ErrorBoundary serviceErrorFallback={serviceErrorFallback} errorFallback={errorFallback}>
				{children}
			</ErrorBoundary>
		</Suspense>
	);
}
