import { Children, type ReactNode } from 'react';

/**
 * Determine if child by type exists in children.
 *
 * @param children The children prop of a component.
 * @param type An html tag name string or React component.
 */
export const someByType = (children: ReactNode, type: string): boolean =>
	Children.toArray(children).some(child => typeof child === 'object' && 'type' in child && child.type === type);

/**
 * Tests if children are nil in React and Preact.
 *
 * @param children The children prop of a component.
 */
export const isNil = (children: ReactNode): boolean =>
	children === null || children === undefined || (Array.isArray(children) && children.length === 0);
