import { OptionsUtils } from 'ts/commons/OptionsUtils';
import type { DashboardDescriptor } from 'typedefs/DashboardDescriptor';
import type { DashboardEntryWithPermissions } from 'typedefs/DashboardEntryWithPermissions';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';
import type { LastDashboardOpenedByUserOption } from 'typedefs/LastDashboardOpenedByUserOption';

/** Utility functions related to dashboards. */
export class DashboardUtils {
	/** The special key under which the last accessed dashboard for 'all dashboards' (= across projects) is stored. */
	public static readonly LAST_ACCESSED_DASHBOARD_FOR_ALL_PROJECTS_KEY = '#-#all-projects#-#';

	/**
	 * Fetches the default dashboards per project from the server.
	 *
	 * @returns The projects (keys) with their respective default dashboard (values).
	 */
	public static loadLastAccessedDashboardIdsByProject(option: LastDashboardOpenedByUserOption): Map<string, string> {
		return OptionsUtils.createOptionMapFromString(option.projectDashboardMapping);
	}

	/** Returns all referenced projects in the given dashboard descriptor. */
	public static getReferencedProjects(
		dashboardDescriptor: DashboardDescriptor | DashboardTemplateDescriptor
	): string[] {
		const projects: string[] = [];
		// @ts-ignore
		dashboardDescriptor.descriptor.widgets.forEach(
			(widget: { Path: { project: string } | null; project: string }) => {
				// Includes call on list might be expensive, but should be ok because we don't expect dashboards with more
				// than a handful of referenced projects.
				if (widget.Path && !projects.includes(widget.Path.project)) {
					projects.push(widget.Path.project);
				}
				if (widget.project && !projects.includes(widget.project)) {
					projects.push(widget.project);
				}
			}
		);
		return projects;
	}

	/** Searches for the dashboard with the given ID. */
	public static findDashboardById<
		T extends DashboardDescriptor | DashboardTemplateDescriptor | DashboardEntryWithPermissions
	>(dashboards: T[], dashboardId: string): T | null {
		return dashboards.find(dashboard => dashboard.id === dashboardId) ?? null;
	}
}
