import '@mantine/core/styles.css';
import { createRoot } from 'react-dom/client';
import 'requestidlecallback-polyfill';
import { Component } from 'ts-closure-library/lib/ui/component';
import { App } from 'ts/App';
import Redirects from 'ts/Redirects';
import './../styles/main.less';

/** Redirects if the URL is rewritten for backwards compatibility. */
function redirectForBackwardsCompatibleURLs(): boolean {
	const maybeRedirect = Redirects.createRedirectOrNull(window.location.href);
	if (maybeRedirect !== null) {
		window.location.replace(maybeRedirect);
	}
	return Boolean(maybeRedirect);
}

/** Main entry point into Teamscale which parses the current path, loads and shows the corresponding perspective. */
function showPerspective(): void {
	if (redirectForBackwardsCompatibleURLs()) {
		// The current page may render too quickly before the redirection process starts.
		return;
	}

	// Make sure the Closure library knows about left to right order, as computing this is very expensive and occurs
	// frequently.
	Component.setDefaultRightToLeft(false);

	const appRoot = document.getElementById('app')!;
	const root = createRoot(appRoot);
	root.render(<App />);
}

document.addEventListener('DOMContentLoaded', showPerspective);
