import type { CallbackWithPromise } from 'ts/base/Callback';
import { KeyboardShortcutRegistry } from 'ts/base/scaffolding/KeyboardShortcutRegistry';

/**
 * Keyboard shortcut registry that registers all shortcuts against the global registry
 * ({@link KeyboardShortcutRegistry}), but keeps track of which shortcuts were added and removes them again when #dispose
 * is called.
 */
export class DisposableKeyboardShortcutRegistry {
	private readonly unregisterFunctions: Array<() => void> = [];

	/**
	 * Registers a shortcut to the registry.
	 *
	 * @param shortcut A shortcut description, consisting of the optional modifiers "Alt", "Ctrl", "Shift" and an
	 *   uppercase character. The modifiers must occur in this order (if they occur). All parts have to be separated by
	 *   a single space. A valid example is "Ctrl Shift H".
	 * @param label The label used in the help dialog.
	 * @param callback The callback triggered by the shortcut.
	 * @param preventDefault If this is set to <code>false</code>, event.preventDefault() will not be called, which
	 *   means the event can be also be caught somewhere else. (e.g. if multiple components use the same shortcut). The
	 *   default behavior is to 'swallow' the event.
	 * @returns Returns a function that allows to de-register the shortcut
	 */
	public registerShortcut(
		shortcut: string,
		label: string,
		callback: CallbackWithPromise<KeyboardEvent>,
		preventDefault = true
	): void {
		this.unregisterFunctions.push(
			KeyboardShortcutRegistry.INSTANCE.registerShortcut(shortcut, label, callback, preventDefault)
		);
	}

	/** Removes the registered shortcuts from the global registry. */
	public dispose() {
		for (const unregisterFunction of this.unregisterFunctions) {
			unregisterFunction();
		}
	}
}
