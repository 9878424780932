import clsx from 'clsx';
import { type ForwardedRef, forwardRef } from 'react';
import type { SemanticICONS, SemanticVERTICALALIGNMENTS } from '../Generic';
import { Icon, type IconProps } from '../Icon';
import { createShorthandFactory, getUnhandledProps, verticalAlignProp } from '../lib';

/** Props for {@link ListIcon}. */
export type ListIconProps = {
	/** Additional classes. */
	className?: string;

	/** An element inside a list can be vertically aligned. */
	verticalAlign?: SemanticVERTICALALIGNMENTS;
} & IconProps;

/** A list item can contain an icon. */
export const ListIcon = forwardRef(function ListIcon(props: ListIconProps, ref: ForwardedRef<HTMLElement>) {
	const { className, verticalAlign } = props;
	const classes = clsx(verticalAlignProp(verticalAlign), className);
	const rest = getUnhandledProps(handledProps, props);

	return <Icon {...rest} className={classes} ref={ref} />;
});

export const createListIcon = createShorthandFactory<typeof ListIcon, SemanticICONS>(ListIcon, name => ({ name }));
const handledProps = ['className', 'verticalAlign'];
