import type { UseMutateFunction } from '@tanstack/react-query';
import { MUTATION } from 'api/Mutation';
import { QUERY } from 'api/Query';
import { type JSX, useState } from 'react';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { DEFAULT_STALE_TIME } from 'ts/base/perspective/topbar/warnings/GlobalWarnings';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { Links } from 'ts/commons/links/Links';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { ToastNotification } from 'ts/components/Toast';
import { EServerOptions } from 'typedefs/ServerOptions';
import styles from './DefaultBackupSettingsWarning.module.less';

/** Informs the user that the default backup settings are active and have not yet been actively acknowledged by an admin. */
export function useDefaultBackupSettingsWarning(): JSX.Element | null {
	const { data: showDefaultBackupSettingsWarning } = QUERY.showDefaultBackupSettingsWarning().useQuery({
		staleTime: DEFAULT_STALE_TIME
	});
	const [userHasAcknowledgedDefaultSettings, setUserHasAcknowledgedDefaultSettings] = useState(false);
	const { mutate } = MUTATION.editServerOption.useMutation({
		onSuccess() {
			setUserHasAcknowledgedDefaultSettings(true);
			// Refresh the warning in the perspective settings bar
			QUERY.showDefaultBackupSettingsWarning().invalidate();
		},
		onError(e) {
			ToastNotification.error('We are sorry. Accepting the automatic backup settings failed. ' + e.errorSummary);
		}
	});
	const handleAcknowledgeDefaultSettings = () => {
		mutate({
			pathParams: { optionId: EServerOptions.DEFAULT_BACKUP_SETTINGS_ACKNOWLEDGED },
			queryParams: { 'save-if-validation-fails': false },
			body: { acknowledged: true }
		});
	};

	const showWarning = showDefaultBackupSettingsWarning && !userHasAcknowledgedDefaultSettings;
	if (!showWarning) {
		return null;
	}
	return <DefaultBackupSettingsWarning handleAcknowledgeDefaultSettings={handleAcknowledgeDefaultSettings} />;
}

type DefaultBackupSettingsWarningProps = {
	handleAcknowledgeDefaultSettings: UseMutateFunction;
};

function DefaultBackupSettingsWarning({
	handleAcknowledgeDefaultSettings
}: DefaultBackupSettingsWarningProps): JSX.Element {
	const linkToSettings: string = Links.showAdminSettings('Automatic Backup');
	return (
		<GlobalWarning
			message={
				<>
					Automatic Backups Are Enabled With Default <br /> Settings (
					<button
						className="teamscale-link-button"
						onClick={() =>
							handleAcknowledgeDefaultSettings(undefined, {
								onSuccess() {
									NavigationUtils.updateLocation(linkToSettings);
								}
							})
						}
						title="Review the default settings for periodic automatic backups."
					>
						Review Settings
					</button>
					<span className={styles.separator}>or</span>
					<button
						className="teamscale-link-button"
						onClick={() =>
							handleAcknowledgeDefaultSettings(undefined, {
								onSuccess() {
									ToastNotification.info(
										<>
											The default settings have been accepted. You can revisit the backup settings
											anytime in the{' '}
											<TeamscaleLink to={linkToSettings}>Admin perspective</TeamscaleLink>.
										</>
									);
								}
							})
						}
						title="Accept to keep the default settings for periodic automatic backups."
					>
						Accept
					</button>
					)
				</>
			}
			severity="warning"
			tooltip="Periodic automatic backups are enabled with default settings. Please review these settings to verify that they are in line with your preferences. Alternatively, you can accept to keep the default settings."
			multiline
		/>
	);
}
