import { QUERY } from 'api/Query';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { CodeReferences } from 'ts/commons/code/CodeReferences';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import type { CompareLayoutOptions } from 'ts/perspectives/compare/CompareCodeRenderComponent';
import { CompareCodeRenderComponent } from 'ts/perspectives/compare/CompareCodeRenderComponent';
import type { DiffDescription } from 'typedefs/DiffDescription';
import { CompareContent } from './CompareContent';

/** The available compare modes in the compare perspective. */
export enum ECompareMode {
	LINE_BASED = 0,
	LINE_BASED_IGNORE_WHITESPACE = 1,
	LINE_BASED_WITH_COVERAGE = 2,
	TOKEN_BASED = 3
}

/**
 * Displays a comparison between two files or methods. Delegates the actual rendering logic to
 * {@link CompareCodeRenderComponent}, which has no dependency on server calls.
 */
export class CompareCodeComponent {
	/** Storage key for remembering last selected diff mode. */
	public static COMPARISON_MODE = 'currentDiffModeIndex';

	/** Utility to create references to issues and spec items via popups. */
	private readonly codeReferences: CodeReferences;

	private readonly compareRenderComponent: CompareCodeRenderComponent;

	/**
	 * This component does asynchronous actions which means it could have already been disposed when the actions are
	 * finished. Therefore, we need to check after each asynchronous action whether it got disposed already to avoid
	 * manipulating non-existing DOM.
	 */
	private disposed = false;

	public constructor() {
		this.codeReferences = new CodeReferences();
		this.compareRenderComponent = new CompareCodeRenderComponent();
	}

	/**
	 * Handles loading content and appends it to the given container. The history token of this perspective consists of
	 * the two uniform paths of the resources being compared (including the leading project). Optionally, the uniform
	 * paths can each be followed by a '#@#' sequence and the commit to display followed by an optional start line
	 * number which is initially scrolled into view. The two paths are separated by the '&' sign. Additionally, a second
	 * & can be given followed by a region "1-2:2-3" to which the diff should be restricted.
	 *
	 * @param container In which the content will be appended.
	 * @param hash The url fragment that contains project, path and (optional) regions
	 * @param layoutOptions Optional: additional layout options for the compare view
	 * @param artificialDiffs Optional: artificial diffs which should be shown instead of actual diffs
	 * @param diffModeIndex Optional: if there are different diff options (e.g. line-based and token-based), the index
	 *   inside the diffs array.
	 */
	public async loadAndAppendContent(
		container: Element,
		hash: NavigationHash,
		isColorBlindModeEnabled: boolean,
		layoutOptions?: CompareLayoutOptions,
		artificialDiffs?: DiffDescription,
		diffModeIndex: ECompareMode = ECompareMode.LINE_BASED,
		resolveToLastChange?: boolean
	): Promise<void> {
		this.compareRenderComponent.prepareLayout(container, layoutOptions);
		const leftContent = CompareContent.fromHistoryToken('left', hash);
		const rightContent = CompareContent.fromHistoryToken('right', hash, resolveToLastChange);
		const region = hash.getString('region') ?? undefined;
		const isInconsistentClone = hash.getBoolean('inconsistent-clone', false);

		await this.parseParametersAndAppendContent(
			leftContent,
			rightContent,
			region,
			isInconsistentClone,
			artificialDiffs,
			diffModeIndex,
			isColorBlindModeEnabled
		);
		if (this.disposed) {
			return;
		}
		this.compareRenderComponent.postRender();
	}

	/** Parses the necessary parameters from the given {@code parts} and appends the content afterwards. */
	private async parseParametersAndAppendContent(
		leftContent: CompareContent,
		rightContent: CompareContent,
		region: string | undefined,
		isInconsistentClone: boolean,
		artificialDiffs: DiffDescription | undefined,
		diffModeIndex: ECompareMode,
		isColorBlindModeEnabled: boolean
	): Promise<void> {
		const path1 = this.createExtendedCommitPath(leftContent, leftContent.getCommit());
		const path2 = this.createExtendedCommitPath(rightContent, rightContent.getCommit());
		let showingInconsistentClone = false;
		if (isInconsistentClone) {
			showingInconsistentClone = true;
			let finalDiffs = (await this.getDiffPrevCommit(rightContent))[1]!.leftChangeRegions;

			if (path1 !== path2) {
				const inconsistentCloneDiff = (
					await QUERY.getDiffs({
						left: path1,
						right: path2,
						normalized: true
					}).fetch()
				)[0]!;
				finalDiffs = this.intersectChangeRegions(finalDiffs, inconsistentCloneDiff.rightChangeRegions);
			}
			if (this.disposed) {
				return;
			}
			rightContent.setPreviousCommitChangeRegions(finalDiffs);
		}

		let diffsLoader;
		if (artificialDiffs) {
			diffsLoader = Promise.resolve([artificialDiffs]);
		} else {
			diffsLoader = QUERY.getDiffs({
				left: path1,
				right: path2,
				region
			}).fetch();
		}
		await this.appendContent(
			leftContent,
			rightContent,
			diffsLoader,
			showingInconsistentClone,
			diffModeIndex,
			isColorBlindModeEnabled
		);
	}

	/** Computes the diff between the current commit and the previous one */
	private getDiffPrevCommit(compareContent: CompareContent): Promise<DiffDescription[]> {
		const currentCommitPath = this.createExtendedCommitPath(compareContent, compareContent.getCommit());
		const previousCommitPath = this.createExtendedCommitPath(
			compareContent,
			UnresolvedCommitDescriptor.getPreviousCommit(compareContent.getCommit())
		);
		return QUERY.getDiffs({
			left: currentCommitPath,
			right: previousCommitPath
		}).fetch();
	}

	/** Creates the extended commit path for a specific commit */
	private createExtendedCommitPath(
		compareContent: CompareContent,
		commit: UnresolvedCommitDescriptor | null
	): string {
		return compareContent.getProject() + '/' + compareContent.getUniformPath() + '#@#' + (commit ?? '');
	}

	/**
	 * Intersects two change regions. If two change regions intersect each other, the one from the first list will be
	 * added to array which is returned.
	 */
	private intersectChangeRegions(firstChangeRegions: number[], secondChangeRegions: number[]): number[] {
		const changes = [];
		for (let i = 0; i < firstChangeRegions.length; i += 2) {
			for (let j = 0; j < secondChangeRegions.length; j += 2) {
				const startFirst = firstChangeRegions[i]!;
				const endFirst = firstChangeRegions[i + 1]!;
				const startSecond = secondChangeRegions[j]!;
				const endSecond = secondChangeRegions[j + 1]!;

				const firstContainsSecond = startFirst <= startSecond && endFirst >= endSecond;
				const secondContainsFirst = startFirst >= startSecond && endFirst <= endSecond;
				const secondStartInFirstRange = startFirst <= startSecond && endFirst >= startSecond;
				const secondEndInFirstRange = startFirst <= endSecond && endFirst >= endSecond;

				const containsOtherRange = firstContainsSecond || secondContainsFirst;
				const overlap = secondStartInFirstRange || secondEndInFirstRange;

				if (containsOtherRange || overlap) {
					changes.push(startFirst, endFirst);
					break;
				}
			}
		}
		return changes;
	}

	/** Appends the content after the content is loaded. */
	private async appendContent(
		leftContent: CompareContent,
		rightContent: CompareContent,
		diffsLoader: Promise<DiffDescription[]>,
		showingInconsistentClone: boolean,
		diffModeIndex: ECompareMode,
		isColorBlindModeEnabled: boolean
	): Promise<void> {
		await Promise.all([rightContent.preload(), leftContent.preload()]);
		const shownDiffs = await diffsLoader;
		if (this.disposed) {
			return;
		}
		this.compareRenderComponent.render(
			leftContent,
			rightContent,
			shownDiffs,
			showingInconsistentClone,
			undefined,
			diffModeIndex,
			undefined,
			isColorBlindModeEnabled
		);

		this.appendIssueAndSpecReferences(leftContent, rightContent);
	}

	/**
	 * Scrolls to the first diff line on either the left or right code component, whichever has the higher starting line
	 * of the diff.
	 */
	public scrollSideWhereChangeIsHigher(): void {
		this.compareRenderComponent.scrollSideWhereChangeIsHigher();
	}

	/** Append the tooltips for spec items and issues to the code view for both the left and the right content. */
	private appendIssueAndSpecReferences(leftContent: CompareContent, rightContent: CompareContent): void {
		const leftContentFormattedToken = leftContent.getContent();
		const rightContentFormattedToken = rightContent.getContent();
		if (
			leftContentFormattedToken != null &&
			'issueReferences' in leftContentFormattedToken &&
			'issueReferencesByCommentOffset' in leftContentFormattedToken
		) {
			this.codeReferences.insertReferencePopups(
				leftContentFormattedToken.issueReferences,
				leftContentFormattedToken.issueReferencesByCommentOffset,
				leftContent.getProject(),
				leftContent.getCommit()?.branchName ?? undefined
			);
		}
		if (
			rightContentFormattedToken != null &&
			'issueReferences' in rightContentFormattedToken &&
			'issueReferencesByCommentOffset' in rightContentFormattedToken
		) {
			this.codeReferences.insertReferencePopups(
				rightContentFormattedToken.issueReferences,
				rightContentFormattedToken.issueReferencesByCommentOffset,
				rightContent.getProject(),
				rightContent.getCommit()?.branchName ?? undefined,
				1
			);
		}
	}

	/** Cleans up the reference popups. */
	public dispose(): void {
		this.codeReferences.dispose();
		this.compareRenderComponent.dispose();
		this.disposed = true;
	}
}
