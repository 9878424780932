import {
	cloneElement,
	type ComponentPropsWithoutRef,
	type ComponentPropsWithRef,
	type ElementType,
	forwardRef,
	type JSX,
	type ReactNode
} from 'react';
import { Icon, type IconProps } from 'ts/components/Icon';

// ======================================================
// Alignments
// ======================================================

export type SemanticFLOATS = 'left' | 'right';
export type SemanticTEXTALIGNMENTS = 'left' | 'center' | 'right' | 'justified';
export type SemanticVERTICALALIGNMENTS = 'top' | 'middle' | 'bottom';

// ======================================================
// Common element's props
// ======================================================

export type HtmlLabelProps = ComponentPropsWithoutRef<'label'>;

export type HtmlSpanProps = {
	children?: ReactNode;
};

// ======================================================
// Types
// ======================================================

export type SemanticShorthandCollection<TProps extends Record<string, unknown>> = Array<SemanticShorthandItem<TProps>>;
export type SemanticShorthandContent = ReactNode;
export type SemanticShorthandItem<TProps extends Record<string, unknown>> = ReactNode | TProps;

// ======================================================
// Styling
// ======================================================

export type SemanticCOLORS =
	| 'red'
	| 'orange'
	| 'yellow'
	| 'olive'
	| 'green'
	| 'teal'
	| 'blue'
	| 'violet'
	| 'purple'
	| 'pink'
	| 'brown'
	| 'grey'
	| 'black';
export type SemanticSIZES = 'mini' | 'tiny' | 'small' | 'medium' | 'large' | 'big' | 'huge' | 'massive';

// ======================================================
// Widths
// ======================================================

type SemanticWIDTHSNUMBER = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
type SemanticWIDTHSSTRING =
	| '1'
	| '2'
	| '3'
	| '4'
	| '5'
	| '6'
	| '7'
	| '8'
	| '9'
	| '10'
	| '11'
	| '12'
	| '13'
	| '14'
	| '15'
	| '16'
	| 'one'
	| 'two'
	| 'three'
	| 'four'
	| 'five'
	| 'six'
	| 'seven'
	| 'eight'
	| 'nine'
	| 'ten'
	| 'eleven'
	| 'twelve'
	| 'thirteen'
	| 'fourteen'
	| 'fifteen'
	| 'sixteen';

export type SemanticWIDTHS = SemanticWIDTHSNUMBER | SemanticWIDTHSSTRING;

// ======================================================
// Icon Names
// ======================================================

export type SemanticICONS =
	| '500px'
	| 'accessible'
	| 'accusoft'
	| 'add circle'
	| 'add square'
	| 'add to calendar'
	| 'add to cart'
	| 'add user'
	| 'add'
	| 'address book outline'
	| 'address book'
	| 'address card outline'
	| 'address card'
	| 'adjust'
	| 'adn'
	| 'adversal'
	| 'affiliatetheme'
	| 'alarm mute'
	| 'alarm'
	| 'ald'
	| 'algolia'
	| 'align center'
	| 'align justify'
	| 'align left'
	| 'align right'
	| 'als'
	| 'amazon pay'
	| 'amazon'
	| 'ambulance'
	| 'american sign language interpreting'
	| 'amilia'
	| 'anchor'
	| 'android'
	| 'angellist'
	| 'angle double down'
	| 'angle double left'
	| 'angle double right'
	| 'angle double up'
	| 'angle down'
	| 'angle left'
	| 'angle right'
	| 'angle up'
	| 'angrycreative'
	| 'angular'
	| 'announcement'
	| 'app store ios'
	| 'app store'
	| 'apper'
	| 'apple pay'
	| 'apple'
	| 'archive'
	| 'area chart'
	| 'area graph'
	| 'arrow alternate circle down outline'
	| 'arrow alternate circle down'
	| 'arrow alternate circle left outline'
	| 'arrow alternate circle left'
	| 'arrow alternate circle right outline'
	| 'arrow alternate circle right'
	| 'arrow alternate circle up outline'
	| 'arrow alternate circle up'
	| 'arrow circle down'
	| 'arrow circle left'
	| 'arrow circle right'
	| 'arrow circle up'
	| 'arrow down cart'
	| 'arrow down'
	| 'arrow left'
	| 'arrow right'
	| 'arrow up'
	| 'arrows alternate horizontal'
	| 'arrows alternate vertical'
	| 'arrows alternate'
	| 'asexual'
	| 'asl interpreting'
	| 'asl'
	| 'assistive listening devices'
	| 'assistive listening systems'
	| 'asterisk'
	| 'asymmetrik'
	| 'at'
	| 'attach'
	| 'attention'
	| 'audible'
	| 'audio description'
	| 'autoprefixer'
	| 'avianex'
	| 'aviato'
	| 'aws'
	| 'backward'
	| 'balance scale'
	| 'balance'
	| 'ban'
	| 'band aid'
	| 'bandcamp'
	| 'bar'
	| 'barcode'
	| 'bars'
	| 'baseball ball'
	| 'basketball ball'
	| 'bath'
	| 'bathtub'
	| 'battery empty'
	| 'battery four'
	| 'battery full'
	| 'battery half'
	| 'battery high'
	| 'battery low'
	| 'battery one'
	| 'battery quarter'
	| 'battery three quarters'
	| 'battery three'
	| 'battery two'
	| 'battery zero'
	| 'bed'
	| 'beer'
	| 'behance square'
	| 'behance'
	| 'bell outline'
	| 'bell slash outline'
	| 'bell slash'
	| 'bell'
	| 'bicycle'
	| 'bimobject'
	| 'binoculars'
	| 'birthday cake'
	| 'birthday'
	| 'bitbucket square'
	| 'bitbucket'
	| 'bitcoin'
	| 'bity'
	| 'black tie'
	| 'blackberry'
	| 'blind'
	| 'block layout'
	| 'blogger b'
	| 'blogger'
	| 'bluetooth alternative'
	| 'bluetooth b'
	| 'bluetooth'
	| 'bold'
	| 'bolt'
	| 'bomb'
	| 'book'
	| 'bookmark outline'
	| 'bookmark'
	| 'bowling ball'
	| 'box'
	| 'boxes'
	| 'braille'
	| 'briefcase'
	| 'broken chain'
	| 'browser'
	| 'btc'
	| 'bug'
	| 'building outline'
	| 'building'
	| 'bullhorn'
	| 'bullseye'
	| 'buromobelexperte'
	| 'bus'
	| 'buysellads'
	| 'calculator'
	| 'calendar alternate outline'
	| 'calendar alternate'
	| 'calendar check outline'
	| 'calendar check'
	| 'calendar minus outline'
	| 'calendar minus'
	| 'calendar outline'
	| 'calendar plus outline'
	| 'calendar plus'
	| 'calendar times outline'
	| 'calendar times'
	| 'calendar'
	| 'call square'
	| 'call'
	| 'camera retro'
	| 'camera'
	| 'cancel'
	| 'car'
	| 'caret down'
	| 'caret left'
	| 'caret right'
	| 'caret square down outline'
	| 'caret square down'
	| 'caret square left outline'
	| 'caret square left'
	| 'caret square right outline'
	| 'caret square right'
	| 'caret square up outline'
	| 'caret square up'
	| 'caret up'
	| 'cart arrow down'
	| 'cart plus'
	| 'cart'
	| 'cc amazon pay'
	| 'cc amex'
	| 'cc apple pay'
	| 'cc diners club'
	| 'cc discover'
	| 'cc jcb'
	| 'cc mastercard'
	| 'cc paypal'
	| 'cc stripe'
	| 'cc visa'
	| 'cc'
	| 'centercode'
	| 'certificate'
	| 'chain'
	| 'chart area'
	| 'chart bar outline'
	| 'chart bar'
	| 'chart line'
	| 'chart pie'
	| 'chat'
	| 'check circle outline'
	| 'check circle'
	| 'check square outline'
	| 'check square'
	| 'check'
	| 'checked calendar'
	| 'checkmark box'
	| 'checkmark'
	| 'chess bishop'
	| 'chess board'
	| 'chess king'
	| 'chess knight'
	| 'chess pawn'
	| 'chess queen'
	| 'chess rock'
	| 'chess rook'
	| 'chess'
	| 'chevron circle down'
	| 'chevron circle left'
	| 'chevron circle right'
	| 'chevron circle up'
	| 'chevron down'
	| 'chevron left'
	| 'chevron right'
	| 'chevron up'
	| 'child'
	| 'chrome'
	| 'circle notch'
	| 'circle notched'
	| 'circle outline'
	| 'circle thin'
	| 'circle'
	| 'clipboard check'
	| 'clipboard list'
	| 'clipboard outline'
	| 'clipboard'
	| 'clock outline'
	| 'clock'
	| 'clone outline'
	| 'clone'
	| 'close'
	| 'closed captioning outline'
	| 'closed captioning'
	| 'cloud download'
	| 'cloud upload'
	| 'cloud'
	| 'cloudscale'
	| 'cloudsmith'
	| 'cloudversify'
	| 'cny'
	| 'cocktail'
	| 'code branch'
	| 'code'
	| 'codepen'
	| 'codiepie'
	| 'coffee'
	| 'cog'
	| 'cogs'
	| 'columns'
	| 'comment alternate outline'
	| 'comment alternate'
	| 'comment outline'
	| 'comment'
	| 'commenting'
	| 'comments outline'
	| 'comments'
	| 'compass outline'
	| 'compass'
	| 'compose'
	| 'compress'
	| 'computer'
	| 'configure'
	| 'connectdevelop'
	| 'contao'
	| 'content'
	| 'conversation'
	| 'copy outline'
	| 'copy'
	| 'copyright outline'
	| 'copyright'
	| 'cpanel'
	| 'creative commons'
	| 'credit card alternative'
	| 'credit card outline'
	| 'credit card'
	| 'crop'
	| 'crosshairs'
	| 'css3 alternate'
	| 'css3'
	| 'cube'
	| 'cubes'
	| 'currency'
	| 'cut'
	| 'cuttlefish'
	| 'd and d'
	| 'dashboard'
	| 'dashcube'
	| 'database'
	| 'deaf'
	| 'deafness'
	| 'delete calendar'
	| 'delete'
	| 'delicious'
	| 'deploydog'
	| 'deskpro'
	| 'desktop'
	| 'detective'
	| 'deviantart'
	| 'diamond'
	| 'digg'
	| 'digital ocean'
	| 'discord'
	| 'discourse'
	| 'discussions'
	| 'disk'
	| 'dna'
	| 'dochub'
	| 'docker'
	| 'doctor'
	| 'dollar sign'
	| 'dollar'
	| 'dolly flatbed'
	| 'dolly'
	| 'dont'
	| 'dot circle outline'
	| 'dot circle'
	| 'download'
	| 'draft2digital'
	| 'dribbble square'
	| 'dribbble'
	| 'drivers license'
	| 'dropbox'
	| 'dropdown'
	| 'drupal'
	| 'dyalog'
	| 'earlybirds'
	| 'edge'
	| 'edit outline'
	| 'edit'
	| 'eject'
	| 'elementor'
	| 'ellipsis horizontal'
	| 'ellipsis vertical'
	| 'ember'
	| 'emergency'
	| 'empire'
	| 'envelope open outline'
	| 'envelope open'
	| 'envelope outline'
	| 'envelope square'
	| 'envelope'
	| 'envira gallery'
	| 'envira'
	| 'erase'
	| 'eraser'
	| 'erlang'
	| 'ethereum'
	| 'etsy'
	| 'eur'
	| 'euro sign'
	| 'euro'
	| 'exchange'
	| 'exclamation circle'
	| 'exclamation triangle'
	| 'exclamation'
	| 'expand arrows alternate'
	| 'expand'
	| 'expeditedssl'
	| 'external alternate'
	| 'external share'
	| 'external square alternate'
	| 'external square'
	| 'external'
	| 'eye dropper'
	| 'eye slash outline'
	| 'eye slash'
	| 'eye'
	| 'eyedropper'
	| 'facebook f'
	| 'facebook messenger'
	| 'facebook official'
	| 'facebook square'
	| 'facebook'
	| 'factory'
	| 'fast backward'
	| 'fast forward'
	| 'favorite'
	| 'fax'
	| 'feed'
	| 'female homosexual'
	| 'female'
	| 'fighter jet'
	| 'file alternate outline'
	| 'file alternate'
	| 'file archive outline'
	| 'file archive'
	| 'file audio outline'
	| 'file audio'
	| 'file code outline'
	| 'file code'
	| 'file excel outline'
	| 'file excel'
	| 'file image outline'
	| 'file image'
	| 'file outline'
	| 'file pdf outline'
	| 'file pdf'
	| 'file powerpoint outline'
	| 'file powerpoint'
	| 'file text outline'
	| 'file text'
	| 'file video outline'
	| 'file video'
	| 'file word outline'
	| 'file word'
	| 'file'
	| 'film'
	| 'filter'
	| 'find'
	| 'fire extinguisher'
	| 'fire'
	| 'firefox'
	| 'first aid'
	| 'first order'
	| 'firstdraft'
	| 'flag checkered'
	| 'flag outline'
	| 'flag'
	| 'flask'
	| 'flickr'
	| 'flipboard'
	| 'fly'
	| 'folder open outline'
	| 'folder open'
	| 'folder outline'
	| 'folder'
	| 'font awesome alternate'
	| 'font awesome flag'
	| 'font awesome'
	| 'font'
	| 'fonticons fi'
	| 'fonticons'
	| 'food'
	| 'football ball'
	| 'fork'
	| 'fort awesome alternate'
	| 'fort awesome'
	| 'forumbee'
	| 'forward'
	| 'foursquare'
	| 'free code camp'
	| 'freebsd'
	| 'frown outline'
	| 'frown'
	| 'futbol outline'
	| 'futbol'
	| 'game'
	| 'gamepad'
	| 'gavel'
	| 'gay'
	| 'gbp'
	| 'gem outline'
	| 'gem'
	| 'genderless'
	| 'get pocket'
	| 'gg circle'
	| 'gg'
	| 'gift'
	| 'git square'
	| 'git'
	| 'github alternate'
	| 'github square'
	| 'github'
	| 'gitkraken'
	| 'gitlab'
	| 'gitter'
	| 'glass martini'
	| 'glide g'
	| 'glide'
	| 'globe'
	| 'gofore'
	| 'golf ball'
	| 'goodreads g'
	| 'goodreads'
	| 'google drive'
	| 'google play'
	| 'google plus circle'
	| 'google plus g'
	| 'google plus official'
	| 'google plus square'
	| 'google plus'
	| 'google wallet'
	| 'google'
	| 'grab'
	| 'graduation cap'
	| 'graduation'
	| 'gratipay'
	| 'grav'
	| 'grid layout'
	| 'gripfire'
	| 'group'
	| 'grunt'
	| 'gulp'
	| 'h square'
	| 'h'
	| 'hacker news square'
	| 'hacker news'
	| 'hand lizard outline'
	| 'hand lizard'
	| 'hand paper outline'
	| 'hand paper'
	| 'hand peace outline'
	| 'hand peace'
	| 'hand point down outline'
	| 'hand point down'
	| 'hand point left outline'
	| 'hand point left'
	| 'hand point right outline'
	| 'hand point right'
	| 'hand point up outline'
	| 'hand point up'
	| 'hand pointer outline'
	| 'hand pointer'
	| 'hand rock outline'
	| 'hand rock'
	| 'hand scissors outline'
	| 'hand scissors'
	| 'hand spock outline'
	| 'hand spock'
	| 'hand victory'
	| 'handicap'
	| 'handshake outline'
	| 'handshake'
	| 'hard of hearing'
	| 'hashtag'
	| 'hdd outline'
	| 'hdd'
	| 'header'
	| 'heading'
	| 'headphones'
	| 'heart outline'
	| 'heart'
	| 'heartbeat'
	| 'help circle'
	| 'help'
	| 'heterosexual'
	| 'hide'
	| 'hips'
	| 'hire a helper'
	| 'history'
	| 'hockey puck'
	| 'home'
	| 'hooli'
	| 'hospital outline'
	| 'hospital symbol'
	| 'hospital'
	| 'hotel'
	| 'hotjar'
	| 'hourglass end'
	| 'hourglass four'
	| 'hourglass full'
	| 'hourglass half'
	| 'hourglass one'
	| 'hourglass outline'
	| 'hourglass start'
	| 'hourglass three'
	| 'hourglass two'
	| 'hourglass zero'
	| 'hourglass'
	| 'houzz'
	| 'html5'
	| 'hubspot'
	| 'i cursor'
	| 'id badge outline'
	| 'id badge'
	| 'id card outline'
	| 'id card'
	| 'idea'
	| 'ils'
	| 'image outline'
	| 'image'
	| 'images outline'
	| 'images'
	| 'imdb'
	| 'in cart'
	| 'inbox'
	| 'indent'
	| 'industry'
	| 'info circle'
	| 'info'
	| 'inr'
	| 'instagram'
	| 'intergender'
	| 'internet explorer'
	| 'intersex'
	| 'ioxhost'
	| 'italic'
	| 'itunes note'
	| 'itunes'
	| 'jenkins'
	| 'joget'
	| 'joomla'
	| 'jpy'
	| 'js square'
	| 'js'
	| 'jsfiddle'
	| 'key'
	| 'keyboard outline'
	| 'keyboard'
	| 'keycdn'
	| 'kickstarter k'
	| 'kickstarter'
	| 'korvue'
	| 'krw'
	| 'lab'
	| 'language'
	| 'laptop'
	| 'laravel'
	| 'lastfm square'
	| 'lastfm'
	| 'law'
	| 'leaf'
	| 'leanpub'
	| 'legal'
	| 'lemon outline'
	| 'lemon'
	| 'lesbian'
	| 'less'
	| 'level down alternate'
	| 'level down'
	| 'level up alternate'
	| 'level up'
	| 'life ring outline'
	| 'life ring'
	| 'lightbulb outline'
	| 'lightbulb'
	| 'lightning'
	| 'like'
	| 'line graph'
	| 'linechat'
	| 'linkedin alternate'
	| 'linkedin square'
	| 'linkedin'
	| 'linkify'
	| 'linode'
	| 'linux'
	| 'lira sign'
	| 'lira'
	| 'list alternate outline'
	| 'list alternate'
	| 'list layout'
	| 'list ol'
	| 'list ul'
	| 'list'
	| 'location arrow'
	| 'lock open'
	| 'lock'
	| 'log out'
	| 'long arrow alternate down'
	| 'long arrow alternate left'
	| 'long arrow alternate right'
	| 'long arrow alternate up'
	| 'low vision'
	| 'lyft'
	| 'magento'
	| 'magic'
	| 'magnet'
	| 'magnify'
	| 'mail forward'
	| 'mail outline'
	| 'mail square'
	| 'mail'
	| 'male homosexual'
	| 'male'
	| 'man'
	| 'map marker alternate'
	| 'map marker'
	| 'map outline'
	| 'map pin'
	| 'map signs'
	| 'map'
	| 'marker'
	| 'mars alternate'
	| 'mars double'
	| 'mars horizontal'
	| 'mars stroke horizontal'
	| 'mars stroke vertical'
	| 'mars stroke'
	| 'mars vertical'
	| 'mars'
	| 'maxcdn'
	| 'meanpath'
	| 'medapps'
	| 'medium m'
	| 'medium'
	| 'medkit'
	| 'medrt'
	| 'meetup'
	| 'meh outline'
	| 'meh'
	| 'mercury'
	| 'microchip'
	| 'microphone slash'
	| 'microphone'
	| 'microsoft edge'
	| 'microsoft'
	| 'military'
	| 'minus circle'
	| 'minus square outline'
	| 'minus square'
	| 'minus'
	| 'mix'
	| 'mixcloud'
	| 'mizuni'
	| 'mobile alternate'
	| 'mobile'
	| 'modx'
	| 'monero'
	| 'money bill alternate outline'
	| 'money bill alternate'
	| 'money'
	| 'moon outline'
	| 'moon'
	| 'motorcycle'
	| 'mouse pointer'
	| 'move'
	| 'ms edge'
	| 'music'
	| 'mute'
	| 'napster'
	| 'neuter'
	| 'new pied piper'
	| 'newspaper outline'
	| 'newspaper'
	| 'nintendo switch'
	| 'node js'
	| 'node'
	| 'non binary transgender'
	| 'npm'
	| 'ns8'
	| 'numbered list'
	| 'nutritionix'
	| 'object group outline'
	| 'object group'
	| 'object ungroup outline'
	| 'object ungroup'
	| 'odnoklassniki square'
	| 'odnoklassniki'
	| 'opencart'
	| 'openid'
	| 'opera'
	| 'optin monster'
	| 'options'
	| 'ordered list'
	| 'osi'
	| 'other gender horizontal'
	| 'other gender vertical'
	| 'other gender'
	| 'outdent'
	| 'page4'
	| 'pagelines'
	| 'paint brush'
	| 'palfed'
	| 'pallet'
	| 'paper plane outline'
	| 'paper plane'
	| 'paperclip'
	| 'paragraph'
	| 'paste'
	| 'patreon'
	| 'pause circle outline'
	| 'pause circle'
	| 'pause'
	| 'paw'
	| 'payment'
	| 'paypal card'
	| 'paypal'
	| 'pen square'
	| 'pencil alternate'
	| 'pencil square'
	| 'pencil'
	| 'percent'
	| 'periscope'
	| 'phabricator'
	| 'phoenix framework'
	| 'phone square'
	| 'phone volume'
	| 'phone'
	| 'photo'
	| 'php'
	| 'picture'
	| 'pie chart'
	| 'pie graph'
	| 'pied piper alternate'
	| 'pied piper hat'
	| 'pied piper pp'
	| 'pied piper'
	| 'pills'
	| 'pin'
	| 'pinterest p'
	| 'pinterest square'
	| 'pinterest'
	| 'plane'
	| 'play circle outline'
	| 'play circle'
	| 'play'
	| 'playstation'
	| 'plug'
	| 'plus cart'
	| 'plus circle'
	| 'plus square outline'
	| 'plus square'
	| 'plus'
	| 'podcast'
	| 'point'
	| 'pointing down'
	| 'pointing left'
	| 'pointing right'
	| 'pointing up'
	| 'pound sign'
	| 'pound'
	| 'power cord'
	| 'power off'
	| 'power'
	| 'print'
	| 'privacy'
	| 'product hunt'
	| 'protect'
	| 'pushed'
	| 'puzzle piece'
	| 'puzzle'
	| 'python'
	| 'qq'
	| 'qrcode'
	| 'question circle outline'
	| 'question circle'
	| 'question'
	| 'quidditch'
	| 'quinscape'
	| 'quora'
	| 'quote left'
	| 'quote right'
	| 'r circle'
	| 'radio'
	| 'rain'
	| 'random'
	| 'ravelry'
	| 'react'
	| 'rebel'
	| 'record'
	| 'recycle'
	| 'reddit alien'
	| 'reddit square'
	| 'reddit'
	| 'redo alternate'
	| 'redo'
	| 'redriver'
	| 'refresh'
	| 'registered outline'
	| 'registered'
	| 'remove bookmark'
	| 'remove circle'
	| 'remove from calendar'
	| 'remove user'
	| 'remove'
	| 'rendact'
	| 'renren'
	| 'repeat'
	| 'reply all'
	| 'reply'
	| 'replyd'
	| 'resize horizontal'
	| 'resize vertical'
	| 'resolving'
	| 'retweet'
	| 'rmb'
	| 'road'
	| 'rocket'
	| 'rocketchat'
	| 'rockrms'
	| 'rouble'
	| 'rss square'
	| 'rss'
	| 'rub'
	| 'ruble sign'
	| 'ruble'
	| 'rupee sign'
	| 'rupee'
	| 's15'
	| 'safari'
	| 'sass'
	| 'save outline'
	| 'save'
	| 'schlix'
	| 'scribd'
	| 'search minus'
	| 'search plus'
	| 'search'
	| 'searchengin'
	| 'selected radio'
	| 'sellcast'
	| 'sellsy'
	| 'send'
	| 'server'
	| 'servicestack'
	| 'setting'
	| 'settings'
	| 'share alternate square'
	| 'share alternate'
	| 'share square outline'
	| 'share square'
	| 'share'
	| 'shekel sign'
	| 'shekel'
	| 'sheqel'
	| 'shield alternate'
	| 'shield'
	| 'ship'
	| 'shipping fast'
	| 'shipping'
	| 'shirtsinbulk'
	| 'shop'
	| 'shopping bag'
	| 'shopping basket'
	| 'shopping cart'
	| 'shower'
	| 'shuffle'
	| 'shutdown'
	| 'sidebar'
	| 'sign in alternate'
	| 'sign in'
	| 'sign language'
	| 'sign out alternate'
	| 'sign out'
	| 'sign-in alternate'
	| 'sign-in'
	| 'sign-out alternate'
	| 'sign-out'
	| 'signal'
	| 'signing'
	| 'signup'
	| 'simplybuilt'
	| 'sistrix'
	| 'sitemap'
	| 'skyatlas'
	| 'skype'
	| 'slack hash'
	| 'slack'
	| 'sliders horizontal'
	| 'sliders'
	| 'slideshare'
	| 'smile outline'
	| 'smile'
	| 'snapchat ghost'
	| 'snapchat square'
	| 'snapchat'
	| 'snowflake outline'
	| 'snowflake'
	| 'soccer'
	| 'sort alphabet ascending'
	| 'sort alphabet descending'
	| 'sort alphabet down'
	| 'sort alphabet up'
	| 'sort amount down'
	| 'sort amount up'
	| 'sort ascending'
	| 'sort content ascending'
	| 'sort content descending'
	| 'sort descending'
	| 'sort down'
	| 'sort numeric ascending'
	| 'sort numeric descending'
	| 'sort numeric down'
	| 'sort numeric up'
	| 'sort up'
	| 'sort'
	| 'sound'
	| 'soundcloud'
	| 'space shuttle'
	| 'speakap'
	| 'spinner'
	| 'spoon'
	| 'spotify'
	| 'spy'
	| 'square full'
	| 'square outline'
	| 'square'
	| 'stack exchange'
	| 'stack overflow'
	| 'star half empty'
	| 'star half full'
	| 'star half outline'
	| 'star half'
	| 'star outline'
	| 'star'
	| 'staylinked'
	| 'steam square'
	| 'steam symbol'
	| 'steam'
	| 'step backward'
	| 'step forward'
	| 'stethoscope'
	| 'sticker mule'
	| 'sticky note outline'
	| 'sticky note'
	| 'stop circle outline'
	| 'stop circle'
	| 'stop'
	| 'stopwatch'
	| 'strava'
	| 'street view'
	| 'strikethrough'
	| 'stripe card'
	| 'stripe s'
	| 'stripe'
	| 'student'
	| 'studiovinari'
	| 'stumbleupon circle'
	| 'stumbleupon'
	| 'subscript'
	| 'subway'
	| 'suitcase'
	| 'sun outline'
	| 'sun'
	| 'superpowers'
	| 'superscript'
	| 'supple'
	| 'sync alternate'
	| 'sync'
	| 'syringe'
	| 'table tennis'
	| 'table'
	| 'tablet alternate'
	| 'tablet'
	| 'tachometer alternate'
	| 'tag'
	| 'tags'
	| 'talk'
	| 'target'
	| 'tasks'
	| 'taxi'
	| 'telegram plane'
	| 'telegram'
	| 'teletype'
	| 'television'
	| 'tencent weibo'
	| 'terminal'
	| 'text cursor'
	| 'text height'
	| 'text telephone'
	| 'text width'
	| 'th large'
	| 'th list'
	| 'th'
	| 'theme'
	| 'themeisle'
	| 'thermometer empty'
	| 'thermometer full'
	| 'thermometer half'
	| 'thermometer quarter'
	| 'thermometer three quarters'
	| 'thermometer'
	| 'thumb tack'
	| 'thumbs down outline'
	| 'thumbs down'
	| 'thumbs up outline'
	| 'thumbs up'
	| 'thumbtack'
	| 'ticket alternate'
	| 'ticket'
	| 'time'
	| 'times circle outline'
	| 'times circle'
	| 'times rectangle'
	| 'times'
	| 'tint'
	| 'tm'
	| 'toggle down'
	| 'toggle left'
	| 'toggle off'
	| 'toggle on'
	| 'toggle right'
	| 'toggle up'
	| 'trademark'
	| 'train'
	| 'transgender alternate'
	| 'transgender'
	| 'translate'
	| 'trash alternate outline'
	| 'trash alternate'
	| 'trash'
	| 'travel'
	| 'treatment'
	| 'tree'
	| 'trello'
	| 'triangle down'
	| 'triangle left'
	| 'triangle right'
	| 'triangle up'
	| 'tripadvisor'
	| 'trophy'
	| 'truck'
	| 'try'
	| 'tty'
	| 'tumblr square'
	| 'tumblr'
	| 'tv'
	| 'twitch'
	| 'twitter square'
	| 'twitter'
	| 'typo3'
	| 'uber'
	| 'uikit'
	| 'umbrella'
	| 'underline'
	| 'undo alternate'
	| 'undo'
	| 'unhide'
	| 'uniregistry'
	| 'universal access'
	| 'university'
	| 'unlink'
	| 'unlinkify'
	| 'unlock alternate'
	| 'unlock'
	| 'unmute'
	| 'unordered list'
	| 'untappd'
	| 'upload'
	| 'usb'
	| 'usd'
	| 'user cancel'
	| 'user circle outline'
	| 'user circle'
	| 'user close'
	| 'user delete'
	| 'user doctor'
	| 'user md'
	| 'user outline'
	| 'user plus'
	| 'user secret'
	| 'user times'
	| 'user x'
	| 'user'
	| 'users'
	| 'ussunnah'
	| 'utensil spoon'
	| 'utensils'
	| 'vaadin'
	| 'vcard'
	| 'venus double'
	| 'venus mars'
	| 'venus'
	| 'viacoin'
	| 'viadeo square'
	| 'viadeo'
	| 'viber'
	| 'video camera'
	| 'video play'
	| 'video'
	| 'vimeo square'
	| 'vimeo v'
	| 'vimeo'
	| 'vine'
	| 'vk'
	| 'vnv'
	| 'volleyball ball'
	| 'volume control phone'
	| 'volume down'
	| 'volume off'
	| 'volume up'
	| 'vuejs'
	| 'wait'
	| 'warehouse'
	| 'warning circle'
	| 'warning sign'
	| 'warning'
	| 'wechat'
	| 'weibo'
	| 'weight'
	| 'weixin'
	| 'whatsapp square'
	| 'whatsapp'
	| 'wheelchair'
	| 'whmcs'
	| 'wi-fi'
	| 'wifi'
	| 'wikipedia w'
	| 'window close outline'
	| 'window close'
	| 'window maximize outline'
	| 'window maximize'
	| 'window minimize outline'
	| 'window minimize'
	| 'window restore outline'
	| 'window restore'
	| 'windows'
	| 'winner'
	| 'wizard'
	| 'woman'
	| 'won sign'
	| 'won'
	| 'wordpress beginner'
	| 'wordpress forms'
	| 'wordpress simple'
	| 'wordpress'
	| 'world'
	| 'wpbeginner'
	| 'wpexplorer'
	| 'wpforms'
	| 'wrench'
	| 'write square'
	| 'write'
	| 'x'
	| 'xbox'
	| 'xing square'
	| 'xing'
	| 'y combinator'
	| 'yahoo'
	| 'yandex international'
	| 'yandex'
	| 'yelp'
	| 'yen sign'
	| 'yen'
	| 'yoast'
	| 'youtube play'
	| 'youtube square'
	| 'youtube'
	| 'zip'
	| 'zoom in'
	| 'zoom out'
	| 'zoom'
	| 'zoom-in'
	| 'zoom-out';

/** Either the name of an icon or an Icon component which might contain additional class names, a color or a size. */
export type IconNameOrElement = SemanticICONS | JSX.Element | null | undefined;

/** Returns an Icon component with the given props attached. */
export function getIcon(icon: IconNameOrElement | undefined, props?: IconProps) {
	if (typeof icon === 'string') {
		return <Icon {...props} name={icon} />;
	} else if (icon && props) {
		return cloneElement(icon, props);
	}
	return icon;
}

// https://blog.logrocket.com/build-strongly-typed-polymorphic-components-react-typescript/#making-component-reusable-props
type AsProp<C extends ElementType> = {
	as?: C;
};

type ComponentPropsWithoutRefFixed<T extends ElementType> = T extends 'a'
	? JSX.IntrinsicElements['a']
	: ComponentPropsWithoutRef<T>;

type ComponentPropsWithRefFixed<T extends ElementType> = T extends 'a'
	? JSX.IntrinsicElements['a']
	: ComponentPropsWithRef<T>;

type PropsToOmit<C extends ElementType, P> = keyof (AsProp<C> & P);

type PolymorphicComponentProp<C extends ElementType, Props = object> = Props &
	AsProp<C> &
	Omit<ComponentPropsWithoutRefFixed<C>, PropsToOmit<C, Props>>;

export type PolymorphicComponentPropWithRef<C extends ElementType, Props = object> = PolymorphicComponentProp<
	C,
	Props
> & { ref?: PolymorphicRef<C> };

// This is the type for the "ref" only
export type PolymorphicRef<C extends ElementType> = ComponentPropsWithRefFixed<C>['ref'];

// https://www.totaltypescript.com/forwardref-with-generic-components
export function fixedForwardRef<T, P = object>(
	render: (props: P, ref: React.Ref<T>) => React.ReactNode
): (props: P & React.RefAttributes<T>) => React.ReactNode {
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return forwardRef(render) as any;
}

export type DataAttributes = Record<`data-${string}`, string | boolean | undefined>;
