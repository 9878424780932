import type { ExtendedProjectInfo } from 'ts/data/ExtendedProjectInfo';
import { wrapProjectInfos } from 'ts/data/ExtendedProjectInfo';
import type { PerspectiveContextProjectInfos } from 'typedefs/PerspectiveContextProjectInfos';

/** Client-specific extension of the PerspectiveContextProjectInfos class. */
export class ExtendedPerspectiveContextProjectInfos {
	/**
	 * The projects that are visible for the user and have data. If a project has multiple public IDs all the IDs are
	 * contained in the list.
	 */
	public readonly projects: string[];

	/**
	 * The projects that are visible for the user and are still in the stage of initial analysis. If a project has
	 * multiple public IDs all the IDs are contained in the list.
	 */
	public readonly initialProjects: string[];

	/** Holds a list of all visible project infos with an additional unique readable project name. */
	public projectsInfo: ExtendedProjectInfo[];

	/** The projects the current user recently visited in Teamscale. */
	public recentProjects: ExtendedProjectInfo[];

	public constructor(projectsInfo: PerspectiveContextProjectInfos) {
		this.projects = projectsInfo.projects;
		this.initialProjects = projectsInfo.initialProjects;
		this.projectsInfo = wrapProjectInfos(projectsInfo.projectsInfo);
		this.recentProjects = wrapProjectInfos(projectsInfo.recentProjects);
	}

	/**
	 * Returns the analyzed projects form the local projects variable and the not yet fully analyzed initialProjects
	 * from the context in a sorted array.
	 *
	 * @returns All project IDs
	 */
	public getAllProjects(): string[] {
		const allProjects = [...this.projects, ...this.initialProjects];
		allProjects.sort();
		return allProjects;
	}

	/**
	 * Determines if a project with the given identifier exists in the system.
	 *
	 * @param projectId The ID of the project to test.
	 * @returns Whether the projects exists.
	 */
	public projectExists(projectId: string | null = null): boolean {
		if (projectId === null) {
			return false;
		}
		return this.getAllProjects().includes(projectId);
	}

	/** Returns the ExtendedProjectInfo for the project with the given ID. */
	public findProjectInfo(projectId: string | null): ExtendedProjectInfo | null {
		if (projectId === null) {
			return null;
		}
		return (
			this.projectsInfo.find(
				project => project.internalId === projectId || project.publicIds.includes(projectId)
			) ?? null
		);
	}

	/** Returns the ExtendedProjectInfo for the project with the given ID. */
	public getProjectName(projectId: string): string {
		return this.findProjectInfo(projectId)?.name ?? projectId;
	}
}
