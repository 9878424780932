/** Generated from ETaskStatus.java. Do not modify this file! */


export type ETaskStatusEntry = 'OPEN' | 'RESOLVED' | 'VERIFIED' | 'DISCARDED';

export class ETaskStatus {
	static readonly OPEN = new ETaskStatus(0, 'OPEN', "Open");
	static readonly RESOLVED = new ETaskStatus(1, 'RESOLVED', "Ready for review");
	static readonly VERIFIED = new ETaskStatus(2, 'VERIFIED', "Closed");
	static readonly DISCARDED = new ETaskStatus(3, 'DISCARDED', "Discarded");
	static readonly values = [
		ETaskStatus.OPEN,
		ETaskStatus.RESOLVED,
		ETaskStatus.VERIFIED,
		ETaskStatus.DISCARDED
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: ETaskStatusEntry,
		public readonly readableName: string
	){
	}

	toString() {
		return this.name;
	}
}

