import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as SourceFormatterTemplate from 'soy/perspectives/metrics/SourceFormatterTemplate.soy.generated';
import * as dom from 'ts-closure-library/lib/dom/dom';
import type { SafeHtml } from 'ts-closure-library/lib/html/safehtml';
import type { SanitizedHtml } from 'ts-closure-library/lib/soy/data';
import * as style from 'ts-closure-library/lib/style/style';
import { AdvancedTooltip } from 'ts-closure-library/lib/ui/advancedtooltip';
import * as soy from 'ts/base/soy/SoyRenderer';
import { Assertions } from 'ts/commons/Assertions';
import type { CodeSelection } from 'ts/commons/code/CodeSelection';
import type { ExpandedMacroElementInfo } from 'ts/commons/code/ExpandedMacroElementInfo';
import { PreprocessorExpansionHandler } from 'ts/commons/formatter/PreprocessorExpansionHandler';
import { SourceFormatterUtils } from 'ts/commons/formatter/SourceFormatterUtils';
import type { StyleRegion } from 'ts/commons/formatter/StyleRegion';
import { TokenStyleCssCache } from 'ts/commons/formatter/TokenStyleCssCache';
import { ObjectUtils } from 'ts/commons/ObjectUtils';
import { tsdom } from 'ts/commons/tsdom';
import type { DetachedFinding } from 'ts/data/DetachedFinding';
import { renderMarkdownInFindingMessages } from 'ts/data/ExtendedTrackedFinding';
import { CodeSelectionSupport } from 'ts/perspectives/metrics/code/CodeSelectionSupport';
import { FlexCodeContainer } from 'ts/perspectives/metrics/code/FlexCodeContainer';
import { SourceLineFormatter } from 'ts/perspectives/metrics/code/SourceLineFormatter';
import type { CodeResultInfo } from 'typedefs/CodeResultInfo';
import type { EExtendedResourceTypeEntry } from 'typedefs/EExtendedResourceType';
import { EMetricSchemaSource } from 'typedefs/EMetricSchemaSource';
import type { FindingContent } from 'typedefs/FindingContent';
import type { FormattedTokenElementInfo } from 'typedefs/FormattedTokenElementInfo';
import type { LineCoverageInfo } from 'typedefs/LineCoverageInfo';
import type { PreprocessorExpansionsTransport } from 'typedefs/PreprocessorExpansionsTransport';
import type { RefactoringSuggestion } from 'typedefs/RefactoringSuggestion';
import type { SelfContainedFinding } from 'typedefs/SelfContainedFinding';
import type { TokenStyle } from 'typedefs/TokenStyle';
import type { CodeLineSelection } from './../code/CodeLineSelection';
import { StringUtils } from './../StringUtils';
import { UIUtils } from './../UIUtils';
import { SourceFormatterContext } from './SourceFormatterContext';

/** Representations of code that can be rendered. */
export type RenderableCode =
	| FindingContent
	| FormattedTokenElementInfo
	| ExpandedMacroElementInfo
	| (CodeResultInfo & { uniformPath: string });

/** Simple type to describe a code region with a start and an end line. */
export type LineRegion = {
	start: number;
	end: number;
};

/**
 * Converts a list of tokens with an associated source text into highlighted HTML.
 *
 * @deprecated This is only used for legacy code snippets that aren't rendered by the {@link RenderedCode} React
 *   component
 */
export class SourceFormatter {
	/** The CSS class of an even code line that is covered by a refactoring suggestion. */
	private static readonly REFACTORING_SUGGESTION_LINE_CLASS = 'code-line-marked-refactoring';

	/**
	 * The class name for code snippets that were hidden due to the {@link SourceFormatter#maxCodeContainerCount}
	 * setting.
	 */
	public static readonly HIDDEN_SNIPPET_CLASS = 'hidden';

	/** Uniform path of the file that contains the currently formatted text. */
	protected uniformPath: string;

	/** The source code to format. */
	protected text: string;

	/** The index of the first line. This will actually skip lines of the provided code. */
	protected firstLine = 1;

	/**
	 * The number used for the very first line. This only affects the line numbers rendered on the left of the code
	 * snippet.
	 */
	private readonly firstLineNumber: number | null;

	/** Object holding all css styles for token rendering. */
	public readonly tokenStyleCssCache: TokenStyleCssCache;

	/** Data context providing information accessible during source code formatting. */
	protected formatterContext: SourceFormatterContext;

	/**
	 * The document elements containing the formatted and annotated source code.
	 *
	 * In case of dataflow findings, these line elements may have gaps (i.e., lineElements[x+1] is not necessarily the
	 * line directly after lineElements[x]).
	 */
	protected lineElements: Element[] = [];

	/** An array where every array item represents a single line and which findings are present on that particular line. */
	protected lineFilteredFindings: Array<Array<DetachedFinding & { blacklisted?: boolean }>> = [];

	/**
	 * The character offsets representing the start of each line. This is needed to be able to jump to a line based on
	 * character offsets rather than line.
	 */
	private lineOffsets: number[] = [];

	/** The document element that contains the code display. */
	protected codeElement: Element | null = null;

	/**
	 * An optional threshold for the maximal number of code lines after which to create separate container(s) with the
	 * rest of the code. The default behavior is to render all the code into one container.
	 *
	 * Example: If the code to render has 75 lines and maxLinesPerContainer = 30, three separate '.prettycode.code'
	 * elements will be rendered (30 + 30 + 15 lines).
	 */
	private readonly maxLinesPerContainer: number | null;

	/**
	 * The maximal number of code container elements for the finding, which is only useful if {@link
	 *
	 * # maxLinesPerContainer} was also set. In case this number is exceeded, a 'X more' element will be
	 *
	 * Shown, as well as the last code snippet.
	 *
	 * Example rendering for maxCodeContainerCount = 4 for a finding whose code fills 10 code containers (see
	 *
	 * # maxLinesPerContainer):
	 *
	 * [Snippet 1] [Snippet 2] [Snippet 3] [label: '6 more'] [Snippet 10]
	 */
	private readonly maxCodeContainerCount: number | null;

	/** Helper for selecting regions of code with a popup menu that provides actions on the selection. */
	public codeSelectionSupport: CodeSelectionSupport | null = null;

	/**
	 * Counter to create unique CSS styles for each element whose tokens are to be formatted. It only increments when a
	 * new @SourceFormatter instance is created.
	 */
	private static CSS_CLASS_COUNTER = 0;

	/** The flags of the ExtendedResourceTypeIndex on the current file. */
	private readonly extendedResourceTypes: EExtendedResourceTypeEntry[];

	/** Contains the AdvancedTooltip registered in this component for later removal on dispose() */
	private readonly tooltips: Set<AdvancedTooltip> = new Set<AdvancedTooltip>();

	/**
	 * @param tokenElement The json data to render.
	 * @param preprocessorExpansions Expansions generated by the preprocessor for the current code. If this is passed,
	 *   you need to call {@link activatePreprocessorExpansion} after {@link getFormattedSource} to enable the expansion
	 *   feature.
	 * @param project String denoting the current project.
	 * @param commit The commit for which the code is displayed. May be <code>null</code> to indicate the latest
	 *   revision on the main branch
	 * @param firstLineNumber If this is provided, the first line number will be set to this value. This only affects
	 *   the line numbers rendered at the left side of the code snippet.
	 */
	public constructor(
		tokenElement: RenderableCode,
		preprocessorExpansions: PreprocessorExpansionsTransport | null,
		public project: string,
		private readonly commit: UnresolvedCommitDescriptor | null,
		firstLineNumber: number | null = null,
		maxLinesPerContainer: number | null = null,
		maxCodeContainerCount: number | null = null
	) {
		this.uniformPath = tokenElement.uniformPath;
		this.text = tokenElement.text;
		this.firstLineNumber = firstLineNumber;
		this.extendedResourceTypes = [];
		if ('extendedResourceTypes' in tokenElement) {
			this.extendedResourceTypes = tokenElement.extendedResourceTypes;
		}

		SourceFormatter.CSS_CLASS_COUNTER++;
		const cssClassSuffix = '-' + SourceFormatter.CSS_CLASS_COUNTER;

		this.tokenStyleCssCache = new TokenStyleCssCache(cssClassSuffix);
		this.formatterContext = new SourceFormatterContext(
			SourceFormatter.extractStyleRegions(
				tokenElement,
				this.tokenStyleCssCache,
				SourceFormatter.determineRequiredPreprocessorOffsets(preprocessorExpansions)
			),
			preprocessorExpansions,
			project
		);
		this.maxLinesPerContainer = maxLinesPerContainer;
		this.maxCodeContainerCount = maxCodeContainerCount;

		this.setupCssClasses(tokenElement.styles);
	}

	/**
	 * Returns offsets that must not be merged with the preceding code because other parts of the Code view rely on the
	 * fact that these offsets can be referred to.
	 *
	 * For example, the start-offsets of preprocessor expansions must be available in span ids such that the
	 * corresponding listener can find these spans.
	 */
	public static determineRequiredPreprocessorOffsets(
		preprocessorExpansions?: PreprocessorExpansionsTransport | null
	): number[] {
		if (!preprocessorExpansions) {
			return [];
		}
		return ObjectUtils.numberKeys(preprocessorExpansions.offsetToExpansionGroup);
	}

	/** Setup the CSS classes. */
	public setupCssClasses(styles: TokenStyle[]): void {
		for (const style of styles) {
			const className = this.tokenStyleCssCache.getOrAddTokenStyleName(style);
			this.formatterContext.insertCSSClassName(className);
		}
	}

	/** Returns the CSS classes used for identifiers. */
	public getIdentifierCssClasses(): string[] {
		return this.tokenStyleCssCache.identifierClassNames;
	}

	/** Returns the CSS classes used for delimiters. */
	public getDelimiterCssClasses(): string[] {
		return this.tokenStyleCssCache.delimiterClassNames;
	}

	/**
	 * @returns The 'X more' message element that will be rendered if some code containers were hidden due to the {@link
	 *
	 *   # maxCodeContainerCount} setting. Will return <code>null</code> in case all available code
	 *
	 *   Containers are shown. Should only be called after {@link
	 *
	 *   # getFormattedSource} was
	 *
	 *   Invoked.
	 */
	public getMoreCodeMessageElement(): HTMLElement | null {
		return dom.getElementByClass('more-code-snippets-message', this.codeElement) as HTMLElement | null;
	}

	/**
	 * Returns a document element with the non-code text.
	 *
	 * @returns An HTML Element containing the non-code text. After inserting this element into the DOM, its resize()
	 *   method should be called in order to ensure proper sizing.
	 */
	private getFormattedSourceNonCode(): Element {
		const result = soy.renderAsElement(SourceFormatterTemplate.nonCode, { html: this.text });
		this.codeElement = result;
		this.lineElements = [result];
		return result;
	}

	/**
	 * Formats the source code for the element and returns a document element.
	 *
	 * @param firstLine If this optional parameter is provided, rendering will start with the given line number. This
	 *   will actually skip lines of the provided code.
	 * @param lastLine If this optional parameter is provided, rendering will end with the given line number.
	 * @param wrapNonTokens If text between tokens should be enclosed in <span> elements, defaults to false
	 * @param fileIndex The index of the current file (if more than one file is displayed). Default is 0.
	 * @returns An HTML Element containing the formatted source code.
	 */
	public getFormattedSource(
		firstLine?: number,
		lastLine?: number,
		wrapNonTokens?: boolean,
		fileIndex = 0,
		codeFontSize?: number,
		colorBlindModeEnabled?: boolean
	): Element {
		const isNonCode = this.uniformPath.startsWith(EMetricSchemaSource.NON_CODE_METRICS.pathPrefix);
		const hasKustomizeError = this.text.startsWith('Kustomize error');
		if (isNonCode || hasKustomizeError) {
			// Ignore opt_firstLine and opt_lastLine
			// all content is in this.text
			return this.getFormattedSourceNonCode();
		}
		if (firstLine != null) {
			this.firstLine = firstLine;
		}
		firstLine = firstLine ?? 1;
		const lines = StringUtils.splitLines(this.text);
		lastLine = lastLine ?? lines.length;
		const formattedLines: SanitizedHtml[] = [];
		const lineClasses: string[] = [];
		let offset = 0;
		codeFontSize = codeFontSize ?? 15;
		for (let i = 0; i < lines.length; ++i) {
			if (firstLine <= i + 1 && i + 1 <= lastLine) {
				formattedLines.push(this.formatLine(lines[i]!, offset, wrapNonTokens, fileIndex));
				lineClasses.push('code-line');
			}
			this.lineOffsets[i] = offset;
			offset += 1 + lines[i]!.length;
		}

		this.augmentLineClasses(lineClasses, colorBlindModeEnabled);
		this.codeElement = soy.renderAsElement(SourceFormatterTemplate.code, {
			lines: formattedLines,
			lineClasses,
			codeFontSize,
			firstUserVisibleLineNumber: this.firstLineNumber || this.firstLine,
			maxLinesPerContainer: this.maxLinesPerContainer,
			snippetIndex: null
		});
		this.applyMaxCodeContainerThreshold();
		this.lineElements = this.getLineElements();
		return this.codeElement;
	}

	public activatePreprocessorExpansion(fileIndex = 0): void {
		Assertions.assert(
			this.codeElement != null,
			'codeElement is not defined. getFormattedSource() needs to be called before activatePreprocessorExpansion()'
		);
		new PreprocessorExpansionHandler(
			this.project,
			this.uniformPath,
			this.tokenStyleCssCache,
			this.formatterContext
		).registerPreprocessorReplacementListeners(this.codeElement, fileIndex);
	}

	/** Enables code selection support. This is currently only supported for the code view. */
	public registerCodeSelectionSupport(): void {
		this.codeSelectionSupport = new CodeSelectionSupport(
			this.project,
			this.uniformPath,
			this.text,
			Assertions.assertIsHtmlElement(this.codeElement),
			selection => this.highlightNewSelection(selection),
			(firstLine, lastLine) => this.getFindingsRenderedOnSelectedLineRange(firstLine, lastLine)
		);
	}

	private applyMaxCodeContainerThreshold(): void {
		if (this.maxLinesPerContainer == null || this.maxCodeContainerCount == null) {
			return;
		}
		const codeContainers = this.getVisibleCodeSnippetElements();
		if (codeContainers.length <= this.maxCodeContainerCount) {
			return;
		}
		let moreElementInserted = false;
		for (let i = 0; i < codeContainers.length; i++) {
			const isLast = i + 1 === codeContainers.length;
			const thresholdExceeded = i + 2 > this.maxCodeContainerCount;
			if (isLast || !thresholdExceeded) {
				continue;
			}
			codeContainers[i]!.classList.add(SourceFormatter.HIDDEN_SNIPPET_CLASS);
			if (!moreElementInserted) {
				dom.insertSiblingAfter(
					soy.renderAsElement(SourceFormatterTemplate.moreSnippetsMessage, {
						moreCount: codeContainers.length - this.maxCodeContainerCount
					}),
					codeContainers[i]!
				);
				moreElementInserted = true;
			}
		}
	}

	/**
	 * Returns the code snippet elements visible for the current finding (there can be hidden snippets due to the {@link
	 *
	 * # maxCodeContainerCount} setting).
	 *
	 * Should only be called after {@link
	 *
	 * # getFormattedSource} was invoked. Otherwise, an empty array is
	 *
	 * Returned.
	 */
	public getVisibleCodeSnippetElements(): HTMLElement[] {
		if (this.codeElement == null) {
			return [];
		}
		return tsdom
			.getElementsByClass('code', this.codeElement)
			.filter(snippet => !snippet.classList.contains(SourceFormatter.HIDDEN_SNIPPET_CLASS));
	}

	/**
	 * Template method that allows modification or augmentation of the line classes used.
	 *
	 * @param lineClasses The CSS classes as a single string to be used for the displayed lines. Note that only visible
	 *   lines are represented in the array (i.e. starting with this.firstLine).
	 */
	// Used in subclasses
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	protected augmentLineClasses(lineClasses: string[], colorBlindModeEnabled: boolean | undefined): void {
		// Default implementation does nothing
	}

	/**
	 * Formats a single line. This is intended to be called for all lines in succession, as this method also updates the
	 * internal state of the formatter.
	 *
	 * @param lineContent Source line of code.
	 * @param lineStartOffset The offset of the line w.r.t. the start of the file.
	 * @param wrapNonTokens If text between tokens should be enclosed in <span> elements, defaults to false.
	 * @param fileIndex The index of the current file (if more than one file is displayed). Default is 0.
	 */
	public formatLine(
		lineContent: string,
		lineStartOffset: number,
		wrapNonTokens?: boolean,
		fileIndex = 0
	): SanitizedHtml {
		while (lineStartOffset > this.formatterContext.getCurrentTokenStyleRegion()!.endOffset) {
			this.formatterContext.incrementCurrentRegionIndex();
		}
		const length = lineContent.length;
		if (length === 0) {
			return UIUtils.sanitizedHtml(' ');
		}
		const formattedLine = SourceLineFormatter.formatLine(
			this.formatterContext,
			lineContent,
			lineStartOffset,
			wrapNonTokens,
			fileIndex
		);
		return UIUtils.sanitizedHtml(formattedLine);
	}

	/**
	 * Create a unique identifier for a file and offset.
	 *
	 * @returns A string id for the given file index and offset
	 */
	public static makeIdForFileAndOffset(fileIndex: number, offsetInFile: number): string {
		return SourceFormatterContext.makeIdForFileAndOffset(fileIndex, offsetInFile);
	}

	/**
	 * Adds finding bars for the given findings.
	 *
	 * @param findings
	 * @param project The project (used for links in tool tips)
	 */
	public addFindingBars(findings: DetachedFinding[] | SelfContainedFinding[], show: boolean, project: string): void {
		this.lineElements = this.getLineElements();
		this.lineFilteredFindings = [];
		while (this.lineFilteredFindings.length < this.lineElements.length) {
			this.lineFilteredFindings.push([]);
		}
		this.addAllFindingsToAffectedLines(findings, this.lineFilteredFindings);
		this.showFindings(show, project);
	}

	/** Returns the DOM elements of the code lines. */
	private getLineElements(): Element[] {
		return tsdom.getElementsByClass('line', this.codeElement);
	}

	/**
	 * Adds all findings in the given findings array to the affected lines
	 *
	 * @param findings
	 * @param lineFindings See {@see #lineFilteredFindings}
	 */
	public addAllFindingsToAffectedLines(
		findings: DetachedFinding[] | SelfContainedFinding[],
		lineFindings: DetachedFinding[][]
	): void {
		for (const finding of findings) {
			const findingRegion = SourceFormatterUtils.getStartAndEndLineNumberForFindingLocation(
				this.text,
				this.lineOffsets,
				finding.location,
				this.extendedResourceTypes
			);
			if (findingRegion) {
				this.addFindingToAffectedLines(finding, findingRegion.start, findingRegion.end, lineFindings);
			}
			if ('secondaryLocations' in finding && finding.secondaryLocations) {
				for (const secondaryLocation of finding.secondaryLocations) {
					const findingRegion = SourceFormatterUtils.getStartAndEndLineNumberForFindingLocation(
						this.text,
						this.lineOffsets,
						secondaryLocation,
						this.extendedResourceTypes
					);
					if (findingRegion) {
						this.addFindingToAffectedLines(finding, findingRegion.start, findingRegion.end, lineFindings);
					}
				}
			}
		}
	}

	/**
	 * Adds a finding to all lines between startLine and endLine.
	 *
	 * @param finding The finding to add.
	 * @param startLine The first line to add the finding to.
	 * @param endLine The last line to add the finding to.
	 * @param lineFindings The array representing the single lines, indicating which findings of the given kind are
	 *   present in each line.
	 */
	protected addFindingToAffectedLines<T = DetachedFinding | SelfContainedFinding>(
		finding: T,
		startLine: number,
		endLine: number,
		lineFindings: Record<number, T[]>
	): void {
		for (let i = startLine; i <= endLine; i++) {
			const lineFindingsIndex = i - this.firstLine;
			if (lineFindingsIndex >= 0 && lineFindingsIndex < this.lineFilteredFindings.length) {
				// Cope with 'invalid' line information in findings, such as
				// incorrect endLines
				lineFindings[lineFindingsIndex]!.push(finding);
			}
		}
	}

	/** Retrieves the finding ids of findings rendered on the lines in a code selection. */
	public getFindingsRenderedOnSelectedLineRange(firstLine: number, lastLine: number): string[] {
		const findings: Set<string> = new Set<string>();
		for (let line = firstLine; line <= lastLine; ++line) {
			this.lineFilteredFindings.forEach((detachedFindings, lineFindingIndex) => {
				if (line === lineFindingIndex + this.firstLine) {
					//@ts-ignore
					detachedFindings.forEach(detachedFinding => findings.add(detachedFinding.id));
				}
			});
		}

		return Array.from(findings.values());
	}

	/** Shows/hide code coverage highlighting. */
	public showTestCoverageHighlighting(
		show: boolean,
		testCoverageInfo: LineCoverageInfo | null,
		isColorBlindModeEnabled: boolean
	): void {
		const coverageTypes = ['fullyCoveredLines', 'partiallyCoveredLines', 'uncoveredLines'] as const;
		const coverageTypesCssClasses = ['fully-covered', 'partially-covered'];
		if (isColorBlindModeEnabled) {
			coverageTypesCssClasses.push('uncovered-color-blind-mode');
		} else {
			coverageTypesCssClasses.push('uncovered');
		}

		for (let j = 0; j < coverageTypes.length; j++) {
			const lines = testCoverageInfo?.[coverageTypes[j]!] ?? [];
			for (const item of lines) {
				const line = item - this.firstLine;
				const lineElement = this.lineElements[line]!;
				if (show) {
					lineElement.classList.add(coverageTypesCssClasses[j]!);
				} else {
					lineElement.classList.remove(coverageTypesCssClasses[j]!);
				}
			}
		}
	}

	/**
	 * Creates a tooltip.
	 *
	 * @param parentElement The parent element to add the tooltip to
	 * @param safeText The text of the tooltip as HTML
	 */
	public createTooltip(parentElement: Element, safeText: SafeHtml): void {
		const tooltip = new AdvancedTooltip(parentElement);
		tooltip.setCursorTracking(true);
		tooltip.setShowDelayMs(50);
		tooltip.setCursorTrackingHideDelayMs(500);
		tooltip.setSafeHtml(safeText);
		this.tooltips.add(tooltip);
	}

	/**
	 * Highlights a selection.
	 *
	 * @param selection The selection to be highlighted or null to remove the selection.
	 */
	public highlightNewSelection(selection: CodeLineSelection | null): void {
		if (selection == null) {
			this.resetLineMarkers();
			return;
		}
		this.removeCssClass(CodeSelectionSupport.MARKED_LINE_CLASS);
		// Indices of the lines to be highlighted based on this.firstLine
		const startIndex = selection.firstLine - this.firstLine;
		// Do not try to highlight lines which are not present anymore, e.g. after time travel
		const endIndex = Math.min(selection.lastLine - (this.firstLine - 1), this.lineElements.length);
		for (let index = startIndex; index < endIndex; ++index) {
			const lineElement = this.lineElements[index]!;
			this.markLine(lineElement, false);
		}
	}

	/**
	 * Shows / hides the finding bars.
	 *
	 * @param show
	 * @param project The project (used for links in tool tips)
	 */
	public showFindings(show: boolean, project: string): void {
		this.lineElements.forEach((lineElement, index) => {
			if (show) {
				this.addFindingMarkerToElementInLine(project, lineElement, index);
			} else {
				lineElement.classList.remove(...['finding', 'findings', 'mixed', 'blacklisted']);
			}
		});
	}

	/**
	 * Adds for a given element in the given line the markers corresponding to the findings.
	 *
	 * @param project The project name
	 * @param lineNumber The line number (used to find the corresponding findings).
	 */
	public addFindingMarkerToElementInLine(project: string, lineElement: Element, lineNumber: number): void {
		let actualFindings = 0;
		let blacklistedFindings = 0;
		const findingsInLine = this.lineFilteredFindings[lineNumber]!;
		for (const item of findingsInLine) {
			const finding = item;
			if ('blacklisted' in finding && finding.blacklisted) {
				blacklistedFindings++;
			} else {
				actualFindings++;
			}
		}
		if (actualFindings === 0 && blacklistedFindings === 0) {
			return;
		}
		const markerElement = tsdom.getElementByClass('markerArea', lineElement);
		if (actualFindings + blacklistedFindings > 1) {
			markerElement.classList.add('findings');
		} else {
			markerElement.classList.add('finding');
		}
		markerElement.classList.toggle('blacklisted', blacklistedFindings > 0);
		markerElement.classList.toggle('mixed', blacklistedFindings > 0 && actualFindings > 0);

		const toolTipContent = UIUtils.renderAsSafeHtml(SourceFormatterTemplate.findingTooltip, {
			project,
			findings: renderMarkdownInFindingMessages(findingsInLine),
			commit: this.commit
		});
		this.createTooltip(markerElement, toolTipContent);
	}

	/**
	 * Marks the given line element and removes the marked state from any other currently marked element.
	 *
	 * @param lineElement The element to mark.
	 * @param resetMarks Whether to reset the other marks
	 */
	public markLine(lineElement: Element, resetMarks = true): void {
		if (resetMarks) {
			this.removeCssClass(CodeSelectionSupport.MARKED_LINE_CLASS);
		}
		lineElement.classList.toggle(CodeSelectionSupport.MARKED_LINE_CLASS, true);
	}

	/** Jumps to an initial position within the code (if set). */
	public jumpToAndHighlight(selection: CodeSelection | null): void {
		if (selection != null) {
			const lineSelection = selection.resolve(this.lineOffsets);
			this.codeSelectionSupport!.setSelection(lineSelection);
			this.jumpToLine(lineSelection.firstLine);
		} else {
			this.codeSelectionSupport!.setSelection(null);
		}
	}

	/**
	 * Jumps to the specified line number (this.firstLine-based).
	 *
	 * @param lineNumber The line number to jump to (this.firstLine-based)
	 */
	public jumpToLine(lineNumber: number): void {
		// If the specified line number is too large for the current state of the file (e.g. due to time travel), jump to the last line
		const lineNumberToJumpTo = Math.min(lineNumber - this.firstLine, this.lineElements.length - 1);
		const element = this.lineElements[lineNumberToJumpTo]! as HTMLElement;
		const scrollingElement = document.querySelector('.' + FlexCodeContainer.SCROLL_CONTAINER_CLASS);
		if (!scrollingElement) {
			return;
		}
		const containerHeight = style.getSize(scrollingElement).height;
		scrollingElement.scrollTop = element.offsetTop - containerHeight / 2;
	}

	/**
	 * First removes all suggestion highlightings, then highlights the given refactoring suggestion and finally jumps to
	 * the first line of the refactoring suggestion
	 */
	public addRefactoringSuggestion(refactoringSuggestion: RefactoringSuggestion): void {
		this.resetLineMarkers();
		for (let line = refactoringSuggestion.startLine; line <= refactoringSuggestion.endLine; line++) {
			// This check is necessary for nesting depth findings as not the whole
			// method is displayed.
			if (line >= this.firstLine && line <= this.firstLine + this.lineElements.length - 1) {
				const lineNumber = line - this.firstLine;
				this.lineElements[lineNumber]!.classList.add(SourceFormatter.REFACTORING_SUGGESTION_LINE_CLASS);
			}
		}
		this.jumpToLine(Math.max(refactoringSuggestion.startLine, this.firstLine));
	}

	/** Remove all refactoring suggestion highlightings. */
	public resetLineMarkers(): void {
		this.removeCssClass(SourceFormatter.REFACTORING_SUGGESTION_LINE_CLASS);
		this.removeCssClass(CodeSelectionSupport.MARKED_LINE_CLASS);
	}

	/**
	 * Removes all usages of the given css.
	 *
	 * @param cssClass A CSS class to remove from elements that use it.
	 */
	public removeCssClass(cssClass: string): void {
		const elements = tsdom.getElementsByClass(cssClass);
		for (const item of elements) {
			item.classList.remove(cssClass);
		}
	}

	/**
	 * Extracts sequences/regions of consecutive tokens that have the same token style from the JSON from the server and
	 * merges consecutive tokens with the same style. But does not merge identifiers and delimiters, to allow
	 * highlighting.
	 *
	 * @param tokenElement Json representation of a TokenElementInfo from the server.
	 * @param tokenStyleCssCache Object holding all css styles used for token rendering.
	 * @returns The style regions array.
	 * @protected
	 */
	public static extractStyleRegions(
		tokenElement: RenderableCode,
		tokenStyleCssCache: TokenStyleCssCache,
		requiredOffsets: number[]
	): StyleRegion[] {
		const tokens = tokenElement.tokens;
		const tokenStyles = tokenElement.styles;
		return SourceFormatterUtils.buildStyleRegions(tokens, tokenStyles, tokenStyleCssCache, requiredOffsets);
	}

	/** Determines identifier index from the given element. */
	public static getIdentifiersIndex(clickedElement: HTMLElement): number | null {
		const identifierOffset = clickedElement.dataset.tokenOffset;
		if (identifierOffset != null) {
			return parseInt(identifierOffset, 10);
		}
		return null;
	}

	/** Disposes all Tooltips (events) */
	public dispose(): void {
		this.tooltips.forEach(tooltip => tooltip.dispose());
	}
}
