import { linkTo } from 'ts/commons/links/LinkTo';
import { ERequirementsPerspectiveView } from 'ts/perspectives/requirement_tracing/ERequirementsPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Returns a URL for a spec item query. */
export function specItemQuery(project: string, query: string, queryName?: string) {
	return linkTo(ETeamscalePerspective.REQUIREMENTS_TRACING, ERequirementsPerspectiveView.OVERVIEW, project, {
		q: query,
		queryname: queryName
	});
}
