import type { JSX } from 'react';
import { Message } from 'ts/components/Message';
import styles from './NotFound.module.less';

/** The complete not found page. */
export function NotFound(): JSX.Element {
	const url = window.location.href;
	return (
		<Message error className={styles.notFound}>
			<h1>404 &#9785;</h1>
			<p>
				We are sorry the page you requested: <br />
				<span>{url}</span>
				<br /> doesn&apos;t exist.
				<br /> To report this problem, please consider submitting a support request at
				<br />
				<a href="https://www.cqse.eu/en/support/" title="CQSE Help & Support">
					Help & Support
				</a>{' '}
				or send an email to <a href="mailto:support@teamscale.com">support@teamscale.com</a> .
			</p>
		</Message>
	);
}
