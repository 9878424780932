import clsx from 'clsx';
import type { ComponentPropsWithoutRef } from 'react';
import type { SemanticVERTICALALIGNMENTS } from '../Generic';

type BasicTableCellProps = {
	/** A table can be collapsing, taking up only as much space as its rows. */
	collapsing?: boolean;

	/** A cell can be disabled. */
	disabled?: boolean;

	/** A cell can specify that its contents should remain on a single line and not wrap. */
	singleLine?: boolean;

	/** A table cell can adjust its text alignment. */
	textAlign?: 'center' | 'left' | 'right';

	/** A table cell can adjust its text alignment. */
	verticalAlign?: SemanticVERTICALALIGNMENTS;
};

/** Props for TableCell. */
export type TableCellProps = ComponentPropsWithoutRef<'td'> & BasicTableCellProps;

/** The td element of a table. */
export function TableCell({
	className,
	singleLine,
	textAlign,
	verticalAlign,
	disabled,
	collapsing,
	...props
}: TableCellProps) {
	return (
		<td
			className={clsx(
				{
					disabled,
					collapsing,
					'single line': singleLine,
					[textAlign + ' aligned']: textAlign,
					[verticalAlign + ' aligned']: verticalAlign
				},
				className
			)}
			{...props}
		/>
	);
}

/** Props for TableHeaderCell. */
export type TableHeaderCellProps = ComponentPropsWithoutRef<'th'> &
	BasicTableCellProps & {
		/** A header cell can be sorted in ascending or descending order. */
		sorted?: 'ascending' | 'descending';
	};

/** The th element of a table. */
export function TableHeaderCell({
	className,
	sorted,
	singleLine,
	textAlign,
	verticalAlign,
	disabled,
	collapsing,
	...props
}: TableHeaderCellProps) {
	return (
		<th
			className={clsx(
				{
					disabled,
					collapsing,
					'single line': singleLine,
					[textAlign + ' aligned']: textAlign,
					[verticalAlign + ' aligned']: verticalAlign,
					[sorted + ' sorted']: sorted
				},
				className
			)}
			{...props}
		/>
	);
}
