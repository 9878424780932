import { QUERY } from 'api/Query';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import * as TeamscaleCodePerspectiveTemplate from 'soy/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy.generated';
import * as dom from 'ts-closure-library/lib/dom/dom';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import { ButtonSet } from 'ts-closure-library/lib/ui/dialog';
import type { Callback } from 'ts/base/Callback';
import * as soy from 'ts/base/soy/SoyRenderer';
import { Assertions } from 'ts/commons/Assertions';
import { ClipboardUtils } from 'ts/commons/ClipboardUtils';
import { CodeLineSelection } from 'ts/commons/code/CodeLineSelection';
import { CodeSelectionUtils } from 'ts/commons/code/CodeSelectionUtils';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { SemanticUIUtils } from 'ts/commons/SemanticUIUtils';
import { ToastNotification } from 'ts/components/Toast';
import { tsdom } from 'ts/commons/tsdom';
import { UIUtils } from 'ts/commons/UIUtils';
import { TaskDataContainer } from 'ts/data/TaskDataContainer';
import { TaskSelectionDialog } from 'ts/perspectives/quality_control/tasks/dialog/TaskSelectionDialog';
import { TaskUtils } from 'ts/perspectives/quality_control/tasks/utils/TaskUtils';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';

/**
 * Support for highlighting code regions in code views.
 *
 * @deprecated This is only used for legacy code snippets that aren't rendered by the {@link RenderedCode} React
 *   component
 */
export class CodeSelectionSupport {
	/**
	 * The state, in which either no or both lines are selected. The next selection of a line number leads to a new
	 * highlighting.
	 */
	private static readonly NO_OR_BOTH_LINES_SELECTED_STATE = 'No or both lines selected';

	/** The state, in which the first line is selected. */
	private static readonly FIRST_LINE_SELECTED_STATE = 'First line selected';

	/** The class of the 'copy link' action in the actions dropdown. */
	private static readonly COPY_LINK_ACTION_CLASS = 'code-region-copy-link';

	/** The class of the 'remove selection' action in the actions dropdown. */
	private static readonly DESELECT_ACTION_CLASS = 'code-region-remove-selection';

	/** The class of the action menu element. */
	private static readonly ACTION_MENU_CLASS = 'code-region-action-menu';

	/** The class of the 'Add Code Snippet to Task' action in the actions dropdown. */
	private static readonly ADD_CODE_SNIPPET_TO_TASK_CLASS = 'code-region-add-code-snippet-to-task';

	/** The name of the CSS class of line number elements. */
	private static readonly LINENUMBER_CSS_CLASS_NAME = 'linenumber';

	/** The CSS class of a marked code line. */
	public static MARKED_LINE_CLASS = 'code-line-marked';

	/** The project. */
	public project: string | null;

	/** The current selection in the code. */
	private selection: CodeLineSelection | null = null;

	/** The state in which the highlighter resides. */
	private state: string;
	private actionMenuElement: Element | null = null;

	/**
	 * @param project A Teamscale project
	 * @param uniformPath Path to source code file
	 * @param text The textual content of the file
	 * @param codeElement The element containing the source code
	 * @param onChangeSelection The function to execute once the selection has changed
	 * @param findingsCallback Retrieves the finding ids for the findings rendered in the code selection
	 */
	public constructor(
		project: string,
		public uniformPath: string,
		private readonly text: string,
		private readonly codeElement: Element,
		private readonly onChangeSelection: Callback<CodeLineSelection | null>,
		private readonly findingsCallback: (p1: number, p2: number) => string[]
	) {
		this.project = project;
		this.state = CodeSelectionSupport.NO_OR_BOTH_LINES_SELECTED_STATE;
		this.addLineNumberClickListeners();
	}

	/**
	 * Initializes the CodeSelectionSupport with a given initialSelection.
	 *
	 * @param initialSelection The initialSelection to be used initially
	 */
	public setSelection(initialSelection: CodeLineSelection | null): void {
		this.selection = initialSelection;
		if (this.selection != null && this.selection.firstLine === this.selection.lastLine) {
			this.state = CodeSelectionSupport.FIRST_LINE_SELECTED_STATE;
		} else {
			this.state = CodeSelectionSupport.NO_OR_BOTH_LINES_SELECTED_STATE;
		}
		this.updateDisplay();
	}

	/**
	 * Handles the selection of a line number.
	 *
	 * @param selectedLineNumber The selected line number
	 */
	private handleLineNumberSelection(selectedLineNumber: number): void {
		switch (this.state) {
			case CodeSelectionSupport.NO_OR_BOTH_LINES_SELECTED_STATE:
				this.selection = new CodeLineSelection(selectedLineNumber);
				this.state = CodeSelectionSupport.FIRST_LINE_SELECTED_STATE;
				break;
			case CodeSelectionSupport.FIRST_LINE_SELECTED_STATE:
				const firstLineNumber = this.selection!.firstLine;
				if (firstLineNumber <= selectedLineNumber) {
					this.selection = new CodeLineSelection(firstLineNumber, selectedLineNumber);
				} else {
					this.selection = new CodeLineSelection(selectedLineNumber, firstLineNumber);
				}
				this.state = CodeSelectionSupport.NO_OR_BOTH_LINES_SELECTED_STATE;
				break;
			default:
				break;
		}
		this.updateDisplay();
	}

	/** Resets the CodeSelectionSupport. */
	private reset(): void {
		this.selection = null;
		this.state = CodeSelectionSupport.NO_OR_BOTH_LINES_SELECTED_STATE;
		this.updateDisplay();
	}

	/** Updates the line number indicators as well as the action menu in the code view. */
	private updateDisplay(): void {
		this.onChangeSelection(this.selection);
		this.hideActionMenu();
		if (this.selection != null) {
			this.showActionMenu();
		}
	}

	/** Adds click listeners to the line number elements. */
	private addLineNumberClickListeners(): void {
		const elements = tsdom.getElementsByClass(CodeSelectionSupport.LINENUMBER_CSS_CLASS_NAME, this.codeElement);
		elements.forEach(element => {
			const lineNumber = Number(dom.getRawTextContent(element));
			events.listen(element, EventType.CLICK, () => this.handleLineNumberSelection(lineNumber));
		});
	}

	/** Displays the action menu at the current selection. */
	public showActionMenu(): void {
		this.hideActionMenu();
		const firstAnchorLineElement = dom.getElementByClass(CodeSelectionSupport.MARKED_LINE_CLASS, this.codeElement);
		if (firstAnchorLineElement != null) {
			this.actionMenuElement = soy.renderAsElement(TeamscaleCodePerspectiveTemplate.codeRegionActionBar, {
				url: CodeSelectionUtils.generateUrl(this.selection!)
			});
			firstAnchorLineElement.appendChild(this.actionMenuElement);
			SemanticUIUtils.activateDropdown(Assertions.assertIsHtmlElement(this.actionMenuElement));
		}
		this.activateActionMenuListeners();
	}

	/** Hides the action menu. */
	private hideActionMenu(): void {
		this.removeActionMenuListeners();
		const actionMenuElements = tsdom.getElementsByClass(CodeSelectionSupport.ACTION_MENU_CLASS);
		for (const actionMenuElement of actionMenuElements) {
			tsdom.removeNode(actionMenuElement);
		}
	}

	/** Activates the listeners for the action menu entries. */
	private activateActionMenuListeners(): void {
		const copyLinkItem = tsdom.findElementByClass(
			CodeSelectionSupport.COPY_LINK_ACTION_CLASS,
			this.actionMenuElement
		);
		if (copyLinkItem != null) {
			ClipboardUtils.hookCopyToClipboardForElement(copyLinkItem);
		}
		const removeSelectionItem = dom.getElementByClass(
			CodeSelectionSupport.DESELECT_ACTION_CLASS,
			this.actionMenuElement
		);
		if (removeSelectionItem != null) {
			events.listen(removeSelectionItem, EventType.CLICK, () => this.reset());
		}
		const addCodeSnippetToTaskItem = dom.getElementByClass(
			CodeSelectionSupport.ADD_CODE_SNIPPET_TO_TASK_CLASS,
			this.actionMenuElement
		);
		if (addCodeSnippetToTaskItem != null) {
			events.listen(addCodeSnippetToTaskItem, EventType.CLICK, () => {
				const dialog = new TaskSelectionDialog(
					this.project!,
					taskId =>
						void CodeSelectionSupport.addCodeSnippetToTask(
							taskId,
							this.project!,
							this.uniformPath,
							this.selection!,
							this.findingsCallback
						)
				);
				dialog.show();
			});
		}
	}

	/** Adds a code snippet to a new or existing task. */
	public static async addCodeSnippetToTask(
		taskId: number,
		project: string,
		uniformPath: string,
		selection: CodeLineSelection,
		findingIdRetriever: (startLine: number, endLine: number) => string[]
	): Promise<void> {
		let commit = NavigationHash.getCurrentCommit();
		const timeCreated = new Date().getTime();
		let resolvedCommit: CommitDescriptor;
		if (commit == null || commit.isLatestRevision()) {
			// Means the most recent revision of file is shown
			const lastRepositoryLog = await QUERY.getLastChangeLogEntry(project, uniformPath, {
				t: new UnresolvedCommitDescriptor(null, commit?.getBranchName()),
				'exclude-non-code-commits': true
			}).fetch();
			resolvedCommit = lastRepositoryLog!.commit;
			commit = UnresolvedCommitDescriptor.wrap(resolvedCommit);
		} else {
			resolvedCommit = await QUERY.resolveCommit(project, commit).fetch();
		}
		const codeSnippetFindingIds = findingIdRetriever(selection.firstLine, selection.lastLine);
		const textRegionLocation = {
			rawStartOffset: -1,
			rawEndOffset: -1,
			rawStartLine: selection.firstLine,
			rawEndLine: selection.lastLine,
			location: uniformPath,
			uniformPath
		};
		const codeSnippet = {
			location: textRegionLocation,
			commit: resolvedCommit,
			findingIds: codeSnippetFindingIds,
			timeCreated,
			shouldRenderFromFinding: false
		};
		const taskDetails = new TaskDataContainer('Resolve Findings', '', [], [], commit.getBranchName(), codeSnippet);
		await TaskUtils.addCodeSnippetToTask(project, taskId, taskDetails, () => {
			ToastNotification.success(
				`Successfully added code snippet to <a href="${LinkTemplate.taskDetails({
					project,
					id: taskId
				})}">Task ${taskId}</a>.`
			);
		});
	}

	/** Shows a dialog for editing the review comment */
	public static showReviewCommentEditDialog(initialText: string, dialogListener: (text: string) => void): void {
		const reviewCommentDialog = UIUtils.createDialogWithContent(
			'Enter review comment',
			TeamscaleCodePerspectiveTemplate.reviewCommentDialog,
			{
				text: initialText
			}
		);
		reviewCommentDialog.setButtonSet(ButtonSet.createOkCancel());
		const buttonSet = reviewCommentDialog.getButtonSet();
		events.listen(buttonSet!.getButton(Assertions.assertString(buttonSet!.getDefault())), EventType.CLICK, () =>
			dialogListener(Assertions.assertString(tsdom.getValueOfElement('review-comment-text')))
		);
		reviewCommentDialog.setVisible(true);
		const reviewCommentText = tsdom.getHtmlTextAreaElementById('review-comment-text');
		reviewCommentText.focus();
		reviewCommentText.select();
	}

	/** Deactivates the listeners for the action menu entries. */
	private removeActionMenuListeners(): void {
		if (this.actionMenuElement == null) {
			return;
		}
		const copyLinkItem = dom.getElementByClass(CodeSelectionSupport.COPY_LINK_ACTION_CLASS, this.actionMenuElement);
		if (copyLinkItem != null) {
			events.removeAll(copyLinkItem);
		}
		const removeSelectionItem = dom.getElementByClass(
			CodeSelectionSupport.DESELECT_ACTION_CLASS,
			this.actionMenuElement
		);
		if (removeSelectionItem != null) {
			events.removeAll(removeSelectionItem);
		}
	}
}
