import { useEffect } from 'react';

/** Lazily loads the custom css stylesheet. */
export function useCustomCss(customCssUrl?: string): void {
	useEffect(() => {
		if (customCssUrl == null) {
			return;
		}
		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = customCssUrl;
		link.type = 'text/css';
		document.head.appendChild(link);
		return () => {
			document.head.removeChild(link);
		};
	}, [customCssUrl]);
}
