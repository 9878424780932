import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

type QueryParams = Record<string, UnresolvedCommitDescriptor | null | string | number | boolean | undefined | string[]>;

/**
 * Renders a URL which can be parsed by the NavigationHash class. All parameters are automatically URL-escaped, which
 * ensures that the resulting text is safe for usage as a URL.
 *
 * @param perspective The perspective to link to
 * @param view The view to show
 * @param project The project for which to show the perspective
 * @param uniformPath The uniform path for which to show the view or null if not required
 * @param queryParams Additional parameters as a literal object. The keys of the object are turned into the query
 *   parameter names, and the values are serialized to a string value. If the value of a parameter is null or undefined,
 *   it is omitted from the generated link.
 */
export function linkTo(
	perspective: ETeamscalePerspective,
	view: ViewDescriptor,
	project: string | null | undefined,
	uniformPath: string | null | undefined,
	queryParams?: QueryParams
): string;
export function linkTo(
	perspective: ETeamscalePerspective,
	view: ViewDescriptor,
	project: string | null | undefined,
	queryParams?: QueryParams
): string;
export function linkTo(perspective: ETeamscalePerspective, view: ViewDescriptor, queryParams?: QueryParams): string;
export function linkTo(
	perspective: ETeamscalePerspective,
	view: ViewDescriptor,
	...varArgs: Array<QueryParams | string | null | undefined>
): string {
	let urlPath = `${perspective.simpleName}/${encodeURIComponent(view.anchor)}`;
	for (const argument of varArgs) {
		if (argument !== null && typeof argument === 'string') {
			urlPath += '/' + encodeURIComponent(argument);
		}
	}
	const searchParams = new URLSearchParams();
	if (view.action) {
		searchParams.append('action', view.action);
	}
	const lastArgument = varArgs.at(-1);
	if (lastArgument != null && typeof lastArgument !== 'string') {
		Object.entries(lastArgument).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				if (value.length === 0) {
					searchParams.append(`${key}-empty`, 'true');
				} else {
					for (const entry of value) {
						searchParams.append(key, entry);
					}
				}
			} else if (value != null) {
				searchParams.append(key, value.toString());
			}
		});
	}
	return urlPath + (searchParams.size === 0 ? '' : '?' + searchParams.toString());
}
