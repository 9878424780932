import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * A hook that allows optional user control, implements an interface similar to `useState()`. Useful for components
 * which allow uncontrolled and controlled behaviours for users.
 *
 * - DefaultState - default state or factory initializer
 * - State - controllable state, undefined state means internal state will be used
 * - InitialState - Used to initialize state if all user provided states are undefined
 *
 * @see https://reactjs.org/docs/uncontrolled-components.html
 * @see https://reactjs.org/docs/hooks-state.html
 */
export function useAutoControlledValue<T>(options: {
	defaultState?: T;
	state: T;
	initialState: T | (() => T);
}): [NonNullable<T>, (state: T) => void] {
	const initialState = typeof options.defaultState === 'undefined' ? options.initialState : options.defaultState;
	const [internalState, setInternalState] = useState(initialState);

	const state = typeof options.state === 'undefined' ? internalState : options.state;
	const stateRef = useRef(state);

	useEffect(() => {
		stateRef.current = state;
	}, [state]);

	// To match the behavior of the setter returned by useState, this callback's identity
	// should never change. This means it MUST NOT directly reference variables that can change.
	const setState = useCallback((newState: T) => {
		// React dispatch can use a factory
		// https://reactjs.org/docs/hooks-reference.html#functional-updates
		if (typeof newState === 'function') {
			stateRef.current = newState(stateRef.current);
		} else {
			stateRef.current = newState;
		}

		setInternalState(stateRef.current);
	}, []);

	return [state as NonNullable<T>, setState];
}
