import { ExtendedPerspectiveContextProjectInfos } from 'ts/data/ExtendedPerspectiveContextProjectInfos';
import { ExtendedPerspectiveContextUserInfo } from 'ts/data/ExtendedPerspectiveContextUserInfo';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';
import type { PerspectiveContextTeamscaleInfo } from 'typedefs/PerspectiveContextTeamscaleInfo';

/** A perspective context with ExtendedPerspectiveContextProjectInfos */
export class ExtendedPerspectiveContext {
	/** Wrapper around all user-related information in the context. */
	public readonly userInfo: ExtendedPerspectiveContextUserInfo;

	/** Wrapper around the list of projects. */
	public projectsInfo: ExtendedPerspectiveContextProjectInfos;

	/** All Teamscale-related information in the context */
	public readonly teamscaleInfo: PerspectiveContextTeamscaleInfo;

	public constructor(perspectiveContext: PerspectiveContext) {
		this.userInfo = new ExtendedPerspectiveContextUserInfo(perspectiveContext.userInfo);
		this.projectsInfo = new ExtendedPerspectiveContextProjectInfos(perspectiveContext.projectsInfo);
		this.teamscaleInfo = perspectiveContext.teamscaleInfo;
	}

	/**
	 * Returns the analyzed projects form the local projects variable and the not yet fully analyzed initialProjects
	 * from the context in a sorted array.
	 *
	 * @returns All project aliases or IDs.
	 */
	public getAllProjects(): string[] {
		return this.projectsInfo.getAllProjects();
	}

	/**
	 * Determines if a project with the given identifier exists in the system.
	 *
	 * @param projectId The ID of the project to test.
	 * @returns Whether the projects exists.
	 */
	public projectExists(projectId: string | null = null): boolean {
		return this.projectsInfo.projectExists(projectId);
	}
}
