import type { ReactNode } from 'react';

/** Props for {@link SearchCategoryLayout}. */
export type SearchCategoryLayoutProps = {
	/** The rendered category content */
	categoryContent: ReactNode;

	/** The rendered results content */
	resultsContent: ReactNode;
};

export function SearchCategoryLayout({ categoryContent, resultsContent }: SearchCategoryLayoutProps) {
	return (
		<>
			<div className="name">{categoryContent}</div>
			<div className="results">{resultsContent}</div>
		</>
	);
}
