import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { EMetricsPerspectiveView } from 'ts/perspectives/metrics/EMetricsPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the metrics perspective. */
export class MetricsPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.METRICS, EMetricsPerspectiveView);
	}
}
