import { QUERY } from 'api/Query';
import * as WidgetParameterTemplate from 'soy/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy.generated';
import { ProjectResolver } from 'ts/base/ProjectResolver';
import * as soy from 'ts/base/soy/SoyRenderer';
import { DO_NOT_ALLOW_ARCHITECTURE_PATH_TYPES } from 'ts/commons/dialog/PathEntitySelectionModalContent';
import { showProjectAndPathSelectionModal } from 'ts/commons/dialog/ProjectAndPathSelectionModal';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { PathUtils } from 'ts/commons/PathUtils';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { StringUtils } from 'ts/commons/StringUtils';
import { tsdom } from 'ts/commons/tsdom';
import type { UniformPath } from 'ts/commons/UniformPath';
import { EResourceType } from 'typedefs/EResourceType';
import type { EType } from 'typedefs/EType';
import { DialogParameterBase } from './DialogParameterBase';

/** The value returned by the project and path parameter */
export type ProjectPathParameterValue = {
	path: string;
	hiddenInWidgetTitle: boolean;
	isArchitecture: boolean;
	project: string;
};

/** The project and path parameter. */
export class ProjectPathParameter extends DialogParameterBase<ProjectPathParameterValue> {
	/**
	 * Parameter for project and path in project.
	 *
	 * @param name The name of the parameter.
	 * @param description The description of the parameter.
	 * @param forbiddenUniformPathTypes Additional list of {@link UniformPath} types which should not be displayed.
	 * @param showHiddenInWidgetTitleCheckbox Whether the "Hidden in widget title" checkbox should be displayed
	 */
	public constructor(
		name: string,
		description: string,
		private readonly forbiddenUniformPathTypes?: EType[],
		private readonly showHiddenInWidgetTitleCheckbox = true
	) {
		super(name, description);
	}

	public override renderInput(value: ProjectPathParameterValue): void {
		const valueString = value.project + '/' + value.path;
		this.renderStringInput(valueString);
		if (this.showHiddenInWidgetTitleCheckbox) {
			const checkboxTemplate = WidgetParameterTemplate.inputCheckbox;
			const checkboxElement = soy.renderAsElement(checkboxTemplate, {
				checked: value.hiddenInWidgetTitle,
				title: 'Hidden in widget title'
			});
			this.container!.appendChild(checkboxElement);
		}
		if (!StringUtils.isEmptyOrWhitespace(value.project) && !StringUtils.isEmptyOrWhitespace(value.path)) {
			// In case the parameter value is from an existing configuration, the resource could be missing by now
			QUERY.getResourceType(value.project, {
				t: NavigationHash.getCurrentCommit() ?? undefined,
				'uniform-path': value.path,
				'check-default-branch': true
			})
				.fetch()
				.then(resourceType => {
					if (resourceType === EResourceType.UNKNOWN.name) {
						this.setError(PathUtils.NON_EXISTENT_PATH_ERROR);
						this.displayErrorMessage();
					} else {
						this.hideError();
					}
				});
		} else {
			this.hideError();
		}
	}

	private hideError() {
		this.clearError();
		this.removeErrorMessage();
	}

	public override showDialog(): void {
		this.removeErrorMessage();

		const initialProjectAndPath = ProjectAndUniformPath.parse(this.getValue());

		const forbiddenUniformPaths = DO_NOT_ALLOW_ARCHITECTURE_PATH_TYPES;
		if (this.forbiddenUniformPathTypes) {
			this.forbiddenUniformPathTypes.push(...forbiddenUniformPaths);
		}

		showProjectAndPathSelectionModal({
			initialProject: initialProjectAndPath.getProject(),
			initialPath: initialProjectAndPath.getUniformPath(),
			onSave: (project: string, uniformPath: UniformPath) => {
				this.setStringValue(ProjectAndUniformPath.of(project, uniformPath.getPath()).toString());
				this.emitParameterChangeEvent();
			},
			initialCommit: NavigationHash.getCurrentCommit() ?? undefined,
			forbiddenUniformPathTypes: forbiddenUniformPaths,
			disableBranchAndTimeSelection: true
		});
	}

	public override extractValue(): ProjectPathParameterValue {
		const projectAndPath = ProjectAndUniformPath.parse(this.getValue());
		const path = projectAndPath.getPath();
		return {
			project: projectAndPath.getProject(),
			path,
			hiddenInWidgetTitle: this.getHiddenInWidgetTitleInput()?.checked ?? true,
			isArchitecture: path.includes(PathUtils.ARCHITECTURE_STORAGE_PREFIX)
		};
	}

	/** @inheritdoc */
	public override onDialogDispose(): boolean {
		return this.applyViewWithNewProjectIfNecessary();
	}

	/** Returns the checkbox that indicates if the path should be hidden in widget title. */
	private getHiddenInWidgetTitleInput(): HTMLInputElement | null {
		if (!this.showHiddenInWidgetTitleCheckbox) {
			return null;
		}
		return tsdom.getElementsByTagNameAndClass('input', 'checkbox', this.container!)[0]!;
	}

	/** Perform a reload only if the project of the widget has changed and a project was selected before. */
	private applyViewWithNewProjectIfNecessary(): boolean {
		// No reload of the whole page is needed when the dashboard is in edit mode
		if (
			NavigationHash.getCurrent().getViewName() === 'new' ||
			NavigationHash.getCurrent().getViewName() === 'edit'
		) {
			return true;
		}
		const projectFromURL = NavigationHash.getProject();
		const projectAndPathFromDialog = ProjectAndUniformPath.parse(this.getValue());
		if (
			!StringUtils.isEmptyOrWhitespace(projectFromURL) &&
			projectFromURL !== projectAndPathFromDialog.getProject()
		) {
			ProjectResolver.setCurrentProject(projectAndPathFromDialog.getProject());
			const hash = NavigationHash.getCurrent();
			hash.setProjectAndPath(projectAndPathFromDialog);
			hash.navigate(true);
			return false;
		}
		return true;
	}
}
