import type { TokenStyle } from 'typedefs/TokenStyle';
import * as cssom from 'ts-closure-library/lib/cssom/cssom';

/**
 * Holds all css classes that are used to format tokens. The classes are specified in responses from the Teamscale
 * service calls. This class collects the different styles, transforms them to css format and assigns a css-class name
 * to each style.
 *
 * If a later service call (e.g., rendering of a preprocessor expansion) requires a previously unknown style, we add
 * that style and give it a new name. If an existing style is requested again, we reuse the existing style. This ensures
 * that we don't accumulate clones of one token-style CSS class that originate from different service calls.
 */
export class TokenStyleCssCache {
	/** The CSS classes that represent identifiers. */
	public identifierClassNames: string[] = [];

	/** The CSS classes that represent delimiters. */
	public delimiterClassNames: string[] = [];

	/** Map from serialized TokenStyle to css-class name. */
	private readonly tokenStyleMap = new Map();

	/**
	 * A default css-class name. This is the first class name that will be generated, so this will always exist and can
	 * be used as "don't care".
	 */
	public defaultCssClassName: string;

	/**
	 * @param cssClassSuffix A suffix that will be appended to all css classes that are dynamically generated from this
	 *   cache. This allows e.g. to avoid class name clashes in the compare view.
	 */
	public constructor(private readonly cssClassSuffix = '') {
		this.defaultCssClassName = this.buildClassName(0);
	}

	/**
	 * Returns the css-class name for the given token style. Adds the TokenStyle to this cache and generates a new
	 * css-class name if the style is not already present. Otherwise returns the already existing css-class name.
	 *
	 * @param style
	 */
	public getOrAddTokenStyleName(style: TokenStyle): string {
		const key = JSON.stringify(style);
		if (this.tokenStyleMap.get(key)) {
			return this.tokenStyleMap.get(key);
		}
		const className = this.buildClassName(this.tokenStyleMap.size);
		TokenStyleCssCache.registerCssClass(style, className);
		this.tokenStyleMap.set(key, className);
		if (style.identifier) {
			this.identifierClassNames.push(className);
		}
		if (style.delimiter) {
			this.delimiterClassNames.push(className);
		}
		return className;
	}

	/** Returns the css class name for the token style with the given number */
	private buildClassName(classIndex: number): string {
		return 'sourceCSS-' + classIndex + this.cssClassSuffix;
	}

	/** Creates and registers a CSS class for the given style using the provided name. */
	private static registerCssClass(style: TokenStyle, className: string): void {
		let fontWeight = 'normal';
		if (style.bold) {
			fontWeight = 'bold';
		}

		let fontStyle = 'normal';
		if (style.italic) {
			fontStyle = 'italic';
		}

		let cssSpan = 'span.' + className + ' { color: ' + style.color;
		if (style.background != null) {
			cssSpan += '; background: ' + style.background;
		}
		cssSpan += '; font-weight: ' + fontWeight + '; font-style: ' + fontStyle + '; }';
		cssom.addCssText(cssSpan);

		let cssLink = 'a.' + className + ' { color: ' + style.color;
		if (style.background != null) {
			cssLink += '; background: ' + style.background;
		}
		cssLink += '; font-weight: ' + fontWeight + '; font-style: ' + fontStyle + '; }';
		cssom.addCssText(cssLink);
		const cssLinkHover = 'a.' + className + ':hover { text-decoration: underline; }';
		cssom.addCssText(cssLinkHover);
	}
}
