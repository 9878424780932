import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { EArchitecturePerspectiveView } from './EArchitecturePerspectiveView';

/** Descriptor for the architecture perspective. */
export class ArchitecturePerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.ARCHITECTURE, EArchitecturePerspectiveView);
	}
}
