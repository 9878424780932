import { PathUtils } from 'ts/commons/PathUtils';
import { EType } from 'typedefs/EType';
import type { MetricDirectorySchema } from 'typedefs/MetricDirectorySchema';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';
import { EMetricName } from './EMetricName';
import { MarkdownUtils } from './markdown/MarkdownUtils';
import { MetricProperties } from './MetricProperties';

/** Utility functions related to the metrics schema. */
export class MetricSchemaUtils {
	/** Returns the index for a given metric or null if not found. */
	public static getMetricIndex(
		metricName: string,
		metricsSchema: MetricDirectorySchema | null | undefined
	): number | null {
		if (metricsSchema == null) {
			return null;
		}
		const index = metricsSchema.entries.findIndex(entry => entry.name === metricName);
		if (index === -1) {
			// No element found
			return null;
		}
		return index;
	}

	/** Returns the metric directory schema entry for a given metric or null if not found. */
	public static getMetricEntry(
		metricName: string,
		metricsSchema: MetricDirectorySchema | undefined | null
	): MetricDirectorySchemaEntry | null {
		if (metricsSchema == null) {
			return null;
		}
		const schemaEntries = metricsSchema.entries;
		for (const schemaEntry of schemaEntries) {
			if (schemaEntry.name === metricName) {
				return schemaEntry;
			}
		}
		return null;
	}

	/** Returns the names of all metrics. */
	public static getAllMetricNames(metricsSchema: MetricDirectorySchema): string[] {
		return metricsSchema.entries.map(entry => entry.name);
	}

	/**
	 * Returns a description for the metric defined in the given schema entry. The description is rendered to a string
	 * omitting any HTML tags that would be generated during Markdown rendering.
	 */
	public static getMetricDescription(schemaEntry: MetricDirectorySchemaEntry): string {
		return MarkdownUtils.renderToPlainText(schemaEntry.description);
	}

	/**
	 * @param colorMetricName The metric name used to colorize the treemap. It's only safe to pass {@code null} iff a
	 *   code schema is used.
	 * @param uniformPath The uniform path, which helps to pick the right size metric. Can be empty for a code path.
	 * @returns The name of the first metric in the schema that is suitable as a size metric for treemaps, or an empty
	 *   string if no suitable metric.
	 */
	public static getDefaultSizeMetric(
		metricsSchema: MetricDirectorySchema | null | undefined,
		colorMetricName: string | null,
		uniformPath: string
	): string {
		if (metricsSchema == null) {
			return '';
		}
		if (colorMetricName != null && PathUtils.isPathOfType(uniformPath, EType.NON_CODE)) {
			return colorMetricName;
		}
		let metric = MetricSchemaUtils.findMetric(metricsSchema, schemaEntry => {
			// Prefer other size metrics over Files metric.
			return MetricProperties.isSizeMetric(schemaEntry) && schemaEntry.name !== EMetricName.FILES;
		});
		if (metric != null) {
			return metric.name;
		}
		metric = MetricSchemaUtils.getMetricEntry(EMetricName.FILES, metricsSchema);
		if (metric != null) {
			return metric.name;
		}
		return '';
	}

	/**
	 * Returns the first metric from metricsSchema for which the function <code>f</code> returns true, or
	 * <code>null</code> if no such metric can be found.
	 *
	 * @param selectorFunction A function that should return true if the given metric entry matches, false otherwise.
	 */
	public static findMetric(
		metricsSchema: MetricDirectorySchema,
		selectorFunction: (p1: MetricDirectorySchemaEntry) => boolean
	): MetricDirectorySchemaEntry | null {
		for (const entry of metricsSchema.entries) {
			if (selectorFunction(entry)) {
				return entry;
			}
		}
		return null;
	}
}
