/** Generated from EBlacklistingOption.java. Do not modify this file! */


export type EBlacklistingOptionEntry = 'EXCLUDED' | 'ALL' | 'ONLY_BLACKLISTED' | 'ONLY_TOLERATED' | 'ONLY_FALSE_POSITIVES' | 'HIDE_TOLERATED' | 'HIDE_FALSE_POSITIVES' | 'NONE';

export class EBlacklistingOption {
	static readonly EXCLUDED = new EBlacklistingOption(0, 'EXCLUDED', true, false, false);
	static readonly ALL = new EBlacklistingOption(1, 'ALL', true, true, true);
	static readonly ONLY_BLACKLISTED = new EBlacklistingOption(2, 'ONLY_BLACKLISTED', false, true, true);
	static readonly ONLY_TOLERATED = new EBlacklistingOption(3, 'ONLY_TOLERATED', false, false, true);
	static readonly ONLY_FALSE_POSITIVES = new EBlacklistingOption(4, 'ONLY_FALSE_POSITIVES', false, true, false);
	static readonly HIDE_TOLERATED = new EBlacklistingOption(5, 'HIDE_TOLERATED', true, true, false);
	static readonly HIDE_FALSE_POSITIVES = new EBlacklistingOption(6, 'HIDE_FALSE_POSITIVES', true, false, true);
	static readonly NONE = new EBlacklistingOption(7, 'NONE', false, false, false);
	static readonly values = [
		EBlacklistingOption.EXCLUDED,
		EBlacklistingOption.ALL,
		EBlacklistingOption.ONLY_BLACKLISTED,
		EBlacklistingOption.ONLY_TOLERATED,
		EBlacklistingOption.ONLY_FALSE_POSITIVES,
		EBlacklistingOption.HIDE_TOLERATED,
		EBlacklistingOption.HIDE_FALSE_POSITIVES,
		EBlacklistingOption.NONE
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EBlacklistingOptionEntry,
		public readonly showNonFlagged: boolean,
		public readonly showFalsePositives: boolean,
		public readonly showTolerations: boolean
	){
	}

	toString() {
		return this.name;
	}
}

