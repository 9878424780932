import type { SanitizedHtml } from 'ts-closure-library/lib/soy/data';
import { MissingData } from 'ts/commons/components/MetricFormatters';
import { UIUtils } from 'ts/commons/UIUtils';
import { EMetricValueType } from 'typedefs/EMetricValueType';
import type { MetricAssessment } from 'typedefs/MetricAssessment';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';
import type { MetricValue } from 'typedefs/MetricValue';
import { type MetricFormatterOptions } from './formatter/MetricFormatterBase';
import { MetricFormatterFactory } from './formatter/MetricFormatterFactory';
import { NumericValueFormatter } from './formatter/NumericValueFormatter';
import { NavigationHash } from './NavigationHash';

/** Utility functions related to metrics. */
export class MetricUtils {
	/** The string used for metrics that are not available. */
	public static NOT_AVAILABLE_STRING = 'N/A';

	/** The default threshold configuration name if no other was explicitly selected. */
	public static readonly TEAMSCALE_THRESHOLD_CONFIGURATION = 'Teamscale Default';

	/**
	 * Formats a metric value as HTML.
	 *
	 * @deprecated Use formatMetricAsJsx instead
	 * @param value The metric value.
	 * @param schemaEntry The schema entry or metric assessment for the metric.
	 * @param options Further options to override the default options.
	 */
	public static formatMetricAsHtml(
		value: MetricValue | null | undefined,
		schemaEntry: MetricDirectorySchemaEntry,
		options?: MetricFormatterOptions
	): SanitizedHtml {
		const element = this.formatMetricAsJsx(value, schemaEntry, options);
		return UIUtils.sanitizedHtmlFromJsx(element);
	}

	/**
	 * Formats a metric value as HTML.
	 *
	 * @param value The metric value.
	 * @param schemaEntry The schema entry or metric assessment for the metric.
	 * @param options Further options to override the default options.
	 */
	public static formatMetricAsJsx(
		value: MetricValue | null | undefined,
		schemaEntry: MetricDirectorySchemaEntry,
		options: MetricFormatterOptions = {}
	): JSX.Element | null {
		if (value == null) {
			return <MissingData />;
		}

		const formatter = MetricFormatterFactory.createFormatterForMetric(schemaEntry, options.subType);
		return formatter.formatValueAsJsx(value, options);
	}

	/** Formats a metric assessment as JSX. */
	public static formatMetricAssessmentAsJsx(
		metricAssessment: MetricAssessment,
		options?: MetricFormatterOptions
	): JSX.Element | null {
		return this.formatMetricAsJsx(metricAssessment.value, metricAssessment.schemaEntry, {
			additionalTooltip: MetricUtils.formatMetricThresholdsAsText(metricAssessment),
			rating: metricAssessment.rating,
			...options
		});
	}

	/** Formats the yellow and red values of the threshold as human-readable text to be shown in a popup. */
	public static formatMetricThresholdsAsText(metricAssessment: MetricAssessment | undefined) {
		if (metricAssessment == null) {
			return undefined;
		}
		const { metricThresholds, schemaEntry } = metricAssessment;
		const thresholdYellow = metricThresholds.thresholdYellow;
		const thresholdRed = metricThresholds.thresholdRed;

		let formattedThresholdYellow = 'not set';
		if (thresholdYellow !== undefined) {
			formattedThresholdYellow = MetricUtils.formatThresholdText(thresholdYellow, schemaEntry);
		}
		let formattedThresholdRed = 'not set';
		if (thresholdRed !== undefined) {
			formattedThresholdRed = MetricUtils.formatThresholdText(thresholdRed, schemaEntry);
		}

		return `Thresholds: Yellow${schemaEntry.valueType === 'ASSESSMENT' && ' + Red'}: ${formattedThresholdYellow}, Red: ${formattedThresholdRed}`;
	}

	/**
	 * Formats a metric value as text.
	 *
	 * @param value The metric value or a formatted string for a metric assessment.
	 */
	public static formatMetricAsText(
		value: MetricValue | undefined,
		schemaEntry: MetricDirectorySchemaEntry,
		options?: MetricFormatterOptions
	): string {
		const formatter = MetricFormatterFactory.createFormatterForMetric(schemaEntry, options?.subType);
		// E.g., treemaps contain the value only in string form
		if (typeof value === 'string') {
			value = formatter.parseFromString(value);
		}
		if (value == null) {
			return '';
		}
		return formatter.formatValueAsText(value, options ?? {});
	}

	/**
	 * Formats a threshold value as text.
	 *
	 * @param value The metric value or a formatted string for a metric assessment.
	 */
	private static formatThresholdText(value: number, schemaEntry: MetricDirectorySchemaEntry): string {
		if (schemaEntry.valueType === EMetricValueType.ASSESSMENT.name) {
			// Formatting only a single numeric assessment threshold value as percentage.
			return NumericValueFormatter.formatAsPercentage(value, { minDecimals: 0, maxDecimals: 1 });
		} else if (schemaEntry.valueType !== EMetricValueType.NUMERIC.name) {
			return String(value);
		}
		return MetricUtils.formatMetricAsText(value, schemaEntry);
	}

	/**
	 * @returns The threshold profile from the navigation hash iff it is contained in the list of all profiles.
	 *   Otherwise, returns Teamscale's default configuration.
	 */
	public static getActiveThresholdProfile(allThresholdProfiles: string[]): string {
		return (
			MetricUtils.getExistingThresholdFromUrl(allThresholdProfiles) ||
			MetricUtils.TEAMSCALE_THRESHOLD_CONFIGURATION
		);
	}

	/** @returns If none is set or the profile does not exist. */
	public static getExistingThresholdFromUrl(allThresholdProfiles: string[]): string | null {
		const thresholdProfileInUrl = NavigationHash.getCurrent().getThresholdProfile();
		if (thresholdProfileInUrl !== null && allThresholdProfiles.includes(thresholdProfileInUrl)) {
			return thresholdProfileInUrl;
		}
		return null;
	}

	/** The key for the local storage containing whether numeric values should be rounded. */
	public static getNumberFormatStorageKey(visibilitySupportName: string): string {
		return 'round-numeric-in-metrics-table-' + visibilitySupportName;
	}
}
